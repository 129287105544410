import { Routes, Route, Navigate } from 'react-router-dom'

import List from './views/list'
import { useSecurity } from '../../../../config/hooks';
import { P_ROLES } from '../../../../config/helpers/permissionsHash';

const RolesRouter = () => {

    return (
        <Routes>
            <Route path='/' element={<Navigate to="/admin/management/roles/list" />} />
            {useSecurity(P_ROLES) && <Route path='list' element={<List />} />}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default RolesRouter