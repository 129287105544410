import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';

import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: { count: 0, items: [] },
    error: null,
    resultDelete: null,
};

const route = 'routes/admin/management/companies/views/listAgents/redux/slice'

//Extend async Action
export const agentsListSearch = createAsyncThunk('companies/listAgents/search', async ({ searchText, limit, offset, filters, orderBy, onlyDeletedUser = false }, { rejectWithValue }) => {
    try {
        let data = await clientQuery(
            `
                query searchUsers($searchText: String, $limit: Int, $offset: Int, $onlyDeletedUser: Boolean) {
                    result: searchUsers(openSearch: $searchText, limit: $limit, offset: $offset, roleId: 5, onlyDeletedUser: $onlyDeletedUser ) {
                        count
                        items { 
                            id
                            username
                            email
                            firstName
                            lastName
                            fullName
                            imageUrl
                            isAdmin
                            status
                            isDeleted
                            hashp
                            state
                            paridaIVA
                            city
                            address
                            phone1
                            phone2
                            workState
                            workActivity
                            freeStartDate
                            freeEndDate
                            roles {
                                id
                                name
                            }
                            companiesAssigned {
                                id
                                name
                            }
                            supervisors {
                                id
                                fullName
                            }
                        }
                    }
                }
            `,
            {
                searchText, 
                limit, 
                offset, 
                filters, 
                orderBy,
                onlyDeletedUser
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'usersListSearch');
        return rejectWithValue(exc)
    }
})

//Slice
const agentsListRedux = createSlice({
    name: 'agents/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(agentsListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(agentsListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(agentsListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = agentsListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer