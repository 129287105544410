//Global keys
let locales = {
    "emailProgramming_table_subject": "Asunto ",
    "emailProgramming_table_note": " Contenido nota ",
    "emailProgramming_table_emailSchedule": "Fecha de Agendamiento ",
    "emailProgramming_table_updatedOn": " Ultima actualización ",
    "emailProgramming_table_sendStatus": "Estado de envio",
    "emailProgramming_table_updated": "ultima actualizacion por",
    "emailProgramming_showAgent": "Ver agente",
    "emailProgramming_showAffiliati": "Ver Afiliados",
};


export default locales;