import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import {
//   clientQuery,
//   clientMutation,
// } from "../../../../../../../config/helpers/GraphQLApi";

// import { endpoints } from "../../../../../../../config/constants";
// import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../config/redux/actions";

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  error: null,
};

const route = "modules/common/modals/viewContact/slice";

//Slice
const viewContactRedux = createSlice({
  name: "common/viewContact",
  initialState: INIT_STATE,
  reducers: {
    viewContactOpen(state, action) {
      state.isOpen = true
    },
    viewContactClose(state, action) {
      return INIT_STATE;
    },
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = viewContactRedux;

// Extract and export each action creator by name
export const { viewContactOpen, viewContactClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
