import { useEffect, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { Table, notification } from "antd";
import { TableOptions, ConfirmDeleteModal } from "components";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { usersListSearch, deleteUser, reset } from "./redux/slice";
import EditUserModal from "../../modals/editUser";
import { openEditUser } from "../../.config/redux/actions";

import { P_AUTOLOGIN, P_AREA_MANAGER } from "../../../../../../config/helpers/permissionsHash";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
import SendEmailModal from "../../../companies/modals/sendEmail";
import { companiesSendEmailOpen } from "../../../.config/redux/actions";

const STATUS = {
    1: "user_userList_activeStatus",
    2: "user_userList_inactiveStatus",
    0: "user_userList_deletedStatus",
};
const COLORS_TAG = {
    1: "bg-label-primary",
    2: "bg-label-warning",
    3: "bg-label-info",
    4: "bg-label-success",
    5: "bg-label-secondary"
}
const getColorNumber = (n = 1) => ((n - 1) % 5) + 1;

const UserListView = () => {
    
    /**
     * Orden:
     * 1. constantes globales
     * 2. useStates
     * 3. callback
     * 4. effects
     * 5. uso de hooks propios, calculos de valores que se hagan constantes
     */

    //1.
    //manejo de lenguaje
    const intl = useIntl();
    const { messages } = intl;

    //despachador de redux
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //2.
    //manejo de seleccion
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [idToDelete, setIdToDelete] = useState(null);
    const [showFullAgentsAssigned, setShowFullAgentsAssigned] = useState({ id: false })
    const [justDeleted, setJustDeleted] = useState(false)
    //redux del view
    const { loading, data, error, resultDelete } = useSelector(
        (state) => state.userListRedux
    );
    const { user } = useSelector((state) => state.authRedux);

    //4.
    //efecto de carga de view
    useEffect(() => {
        if (error) {
            notification.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error.message,
            });
            reset("error");
        }
        if (resultDelete) {
            notification.success({
                message: messages["deleteSucessfully"].toString(),
            });
            paggination.refresh();
        }
    }, [error, resultDelete]);

    useEffect(() => {
        paggination.refresh();

        //efecto de salida del view
        return () => {
            //reset del redux
            dispatch(reset(null));
        };
    }, []);
    const paggination = usePaggination(usersListSearch);

    const C = useSecurity(P_AREA_MANAGER, ['C']);
    const R = useSecurity(P_AREA_MANAGER, ['R']);
    const U = useSecurity(P_AREA_MANAGER, ['U']);
    const D = useSecurity(P_AREA_MANAGER, ['D']);

    const toggleShowDeleteModal = (id = null) => {
        setIdToDelete(id);
    };

    const onDeleteItem = () => {
        if (idToDelete === -1 && selectedRowKeys.length > 0) {
            for (let i = 0; i < selectedRowKeys.length; i++) {
                const element = selectedRowKeys[i];
                dispatch(deleteUser({ id: element }));
            }
            setSelectedRowKeys([]);
        }
        if (idToDelete > -1) {
            dispatch(deleteUser({ id: idToDelete }));
        }
        toggleShowDeleteModal();
    };

    return (
        <Fragment>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="card ">
                    {/* BARRA DE TAREA (BUSQUEDA BOTONES) */}
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                {R && <input
                                    type="text"
                                    className="form-control form-control-solid w-250px ps-13"
                                    placeholder={messages["search"]?.toString()}
                                    onChange={paggination.onSearchChange}
                                />}
                            </div>
                            <div className="col-md-8 col-sm-12 mt-2 mt-sm-0">
                                {selectedRowKeys.length == 0 ? (
                                    <div className="d-flex justify-content-end">
                                        {R && <button
                                            type="button"
                                            className="btn btn-primary me-3"
                                            onClick={() =>
                                                paggination.refresh()
                                            }
                                        >
                                            <i className="ki-duotone ki-arrow-circle-right fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                            {messages["refresh"]?.toString()}
                                        </button>}
                                        {R && (
                                        <button
                                            className="btn btn-secondary me-1"
                                            onClick={() => {
                                                setJustDeleted(!justDeleted)
                                                paggination.refresh(
                                                    null,
                                                    null,
                                                    {
                                                        onlyDeletedUser: !justDeleted,
                                                    }
                                                );
                                            }}
                                        >
                                            {justDeleted
                                                ? messages[
                                                "companiesList_viewCurrentUsers"
                                                ]
                                                : messages[
                                                "companiesList_viewDeletedUsers"
                                                ]}
                                        </button>)}
                                        {C && <button
                                            type="button"
                                            className="btn btn-primary me-3"
                                            onClick={() =>
                                                dispatch(openEditUser())
                                            }
                                        >
                                            {messages["new"]?.toString()}
                                        </button>}
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div className="fw-bold me-5">
                                            <span
                                                className="me-2"
                                                data-kt-user-table-select="selected_count"
                                            ></span>
                                            {selectedRowKeys.length}{" "}
                                            {messages["selected"]}
                                        </div>
                                        {D && <button
                                            type="button"
                                            className="btn btn-danger"
                                            data-kt-user-table-select="delete_selected"
                                            onClick={() => {
                                                setIdToDelete(-1);
                                            }}
                                        >
                                            {`${messages["deleteSelected"]}`}
                                        </button>}
                                        <button
                                        disabled={selectedRowKeys.length === 0}
                                        className="btn btn-primary m-1"
                                        onClick={() =>
                                            dispatch(
                                                companiesSendEmailOpen({
                                                    customers:
                                                        data.items.filter((x) =>
                                                            selectedRowKeys.find(
                                                                (y) => y == x.id
                                                            )
                                                        ),
                                                })
                                            )
                                        }
                                    >
                                        {messages["companiesList_sendEmails"]}
                                    </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12 mt-4 mb-4">
                            <TextDescriptionCompanies />
                        </div>
                    </div>
                    {/* TABLA */}
                    <div className="card-body py-4 table-responsive">
                        {loading && <div className="linear-loading " />}
                        {R && <Table
                            rowKey="id"
                            scroll={{ x: 1000 }}
                            style={{ minHeight: "calc(100vh - 350px)" }}
                            showSorterTooltip={false}
                            loading={loading}
                            columns={[
                                {
                                    key: "actions",
                                    width: 100,
                                    title: "",
                                    className: "min-vh-30",
                                    render: (values) => {
                                        return (
                                            <TableOptions
                                                label={messages["actions"]}
                                                options={[
                                                    {
                                                        label: messages["edit"],
                                                        show: U,
                                                        onClick: () => {
                                                            dispatch(
                                                                openEditUser({
                                                                    id: values.id,
                                                                })
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: messages[
                                                            "companiesDetail_autologin"
                                                        ],
                                                        show: !!user.isAdmin,
                                                        onClick: () => {
                                                            navigate(
                                                                `/login/${values?.email}/${values?.hashp}`
                                                            );
                                                            window.location.reload();
                                                        },
                                                        render: () => useSecurity(P_AUTOLOGIN) && <a className="dropdown-item" href={`/login/${values?.username}/${values?.hashp}`} target="blank" >{messages[
                                                            "companiesDetail_autologin"
                                                        ]}</a>
                                                    },
                                                    {
                                                        label: messages[
                                                            "delete"
                                                        ],
                                                        show: D && !values?.isAdmin,
                                                        onClick: () => {
                                                            toggleShowDeleteModal(
                                                                values.id
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                                {
                                    title: "User",
                                    key: "fullName",
                                    dataIndex: "fullName",
                                    width: 250,
                                    render: (fullName, values) => {
                                        let src =
                                            "/assets/images/avatars/blank.png";
                                        if (values?.imageUrl) {
                                            src = values.imageUrl;
                                        }
                                        return (
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="avatar-wrapper me-2">
                                                    <img
                                                        className="rounded-circle"
                                                        src={src}
                                                        height="40"
                                                        width="40"
                                                        alt="User avatar"
                                                    />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <label className="text-body text-truncate fw-mediumml-2">
                                                        {fullName}
                                                    </label>
                                                    <small className="text-muted">
                                                        {values.username}
                                                    </small>
                                                </div>
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: messages["email"]?.toString(),
                                    key: "email",
                                    dataIndex: "email",
                                    width: 250,
                                },
                                {
                                    title: messages["users_agents"]?.toString(),
                                    key: "agents",
                                    width: 250,
                                    render: (values) => {
                                        const assignedUsers = values?.assignedUsers;
                                        const id = values.id;
                                        return (
                                            <span>
                                                <ul className="text-start">
                                                    {assignedUsers && assignedUsers.slice(0, showFullAgentsAssigned[id] ? 100 : 5).map(v => <li className="fw-medium me-2" key={v.id}>
                                                        <a target="_blank"  href={window.location.pathname.replace('companies/list4', 'users/detail/' + v.id)} rel="noreferrer" >{v.fullName}</a>
                                                    </li>)}
                                                    {assignedUsers?.length > 5 && <a onClick={() => setShowFullAgentsAssigned({ ...showFullAgentsAssigned, [id]: !showFullAgentsAssigned[id] })}>{showFullAgentsAssigned[id] ? '^' : '...' + messages['seeMore']}</a>}
                                                </ul>
                                            </span>
                                        )
                                    },
                                },
                                {
                                    title: messages["roles"]?.toString(),
                                    key: "roles",
                                    dataIndex: "roles",
                                    width: 250,
                                    render: (value) => {
                                        return (
                                            <div className="d-flex">
                                                {value?.length ? (
                                                    value.map((v, i) => (
                                                        <span
                                                            key={v?.id}
                                                            className={`badge ${COLORS_TAG[getColorNumber(i + 1)]} mr-1`}
                                                        >
                                                            {v?.name}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: messages["status"]?.toString(),
                                    key: "status",
                                    width: 50,
                                    render: (values) => {
                                        if (values.isDeleted) {
                                            return (
                                                <span className="badge bg-label-danger">
                                                    {messages[STATUS[0]]}
                                                </span>
                                            );
                                        }
                                        switch (values.status) {
                                            case 1:
                                                return (
                                                    <span className="badge bg-label-success">
                                                        {messages[STATUS[1]]}
                                                    </span>
                                                );
                                            case 2:
                                                return (
                                                    <span className="badge bg-label-secondary">
                                                        {messages[STATUS[2]]}
                                                    </span>
                                                );
                                            default:
                                                return <></>;
                                        }
                                    },
                                },
                            ]}
                            dataSource={data.items}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: (newSelectedRowKeys) => {
                                    setSelectedRowKeys(newSelectedRowKeys);
                                },
                            }}
                            onChange={paggination.onChange}
                            pagination={{
                                total: data?.count || 0,
                                ...paggination.tablePaggination,
                            }}
                        />}
                    </div>
                </div>
            </div>
            <EditUserModal blockRole={8} onRefresh={(refresh) => refresh && paggination.refresh()} />
            <ConfirmDeleteModal
                show={idToDelete}
                onCancel={toggleShowDeleteModal}
                onOk={onDeleteItem}
            />
            <SendEmailModal areUsers={true} />
        </Fragment>
    );
};

export default UserListView;
