import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    resultDelete: null,
    error: null,
    isUserAnnouncement: false
};

const route = 'modules/client/dashboard/components/listNote/slice'

//Extend async Action
export const dashboarNotesListSearch = createAsyncThunk('dashboard/listNote/dashboarNotesListSearch', async (
    { searchText, limit, offset, companyAnnouncementId, all, isUserAnnouncement = false },
    { rejectWithValue, dispatch, getState }
) => {
    const { isUserAnnouncement: usa } = getState().dashboardNotesListRedux;

    const serviceName = isUserAnnouncement || usa ? 'searchUserAnnouncementNotes' : 'searchCompanyCompanyAnnouncementNotes';
    const paramName = isUserAnnouncement || usa ? 'userAnnouncementId' : 'companyAnnouncementId'
    try {
        let data = await clientQuery(
            `query ${serviceName}($searchText: String, $limit: Int, $offset: Int, $companyAnnouncementId: Int, $all: Boolean){
                notes: ${serviceName}(searchText: $searchText, limit: $limit, offset: $offset, ${paramName}: $companyAnnouncementId, all: $all) {
                    items {
                        id
                        ${isUserAnnouncement || usa ? 'userId' : 'companyId'}
                        ${isUserAnnouncement || usa ? 'userAnnouncementId' : 'companyAnnouncementId'}
                        campaignDetailId
                        note
                        createdOn
                        updatedOn
                        isDeleted
                        emailSchedule
                        sendStatus
                        error
                        created {
                            id
                            firstName
                            lastName
                        }
                        ${all ? `
                        changes {
                            id
                            note
                            emailSchedule
                            createdBy
                            createdOn
                            created {
                                id
                                firstName
                                lastName
                            }
                        }
                        `: ''}
                        ${isUserAnnouncement || usa ? `
                            formAnswer {
                                id
                                answers
                            }
                            form{
                                questions{
                                    id
                                    question
                                    questionType
                                    typeData
                                }
                            }` : ''}
                    }
                    count
                }
            }`,
            {
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0,
                companyAnnouncementId,
                all
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.notes || null
    } catch (exc) {
        ExceptionManager(exc, route, 'dashboarNotesListSearch', dispatch);
        return rejectWithValue(exc)
    }
})

export const dashboardNoteDelete = createAsyncThunk('dashboard/listNote/dashboardNoteDelete', async (
    { id },
    { rejectWithValue, dispatch, getState }
) => {
    try {
        const { isUserAnnouncement } = getState().dashboardNotesListRedux;

        const serviceName = isUserAnnouncement ? 'deleteUserAnnouncementNote' : 'deleteCompanyAnnouncementNote';
        const result = await clientMutation(
            `mutation ${serviceName} ($id: Int) {
                ${serviceName} (id: $id)
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return result;

    } catch (exc) {
        ExceptionManager(exc, route, 'dashboardNoteDelete', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const dashboardNotesListRedux = createSlice({
    name: 'dashboard/notesList',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        setIsUserAnnouncement(state, action) {
            state.isUserAnnouncement = action;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //thunk dashboarNotesListSearch
        builder.addCase(dashboarNotesListSearch.pending, (state, action) => {
            state.isUserAnnouncement = action.meta.arg?.isUserAnnouncement
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(dashboarNotesListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
            state.error = null
        })
        builder.addCase(dashboarNotesListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk dashboardNoteDelete
        builder.addCase(dashboardNoteDelete.pending, (state) => {
            state.loading = true
            state.resultDelete = null
            state.error = null
        })
        builder.addCase(dashboardNoteDelete.fulfilled, (state, { payload }) => {
            state.loading = false
            state.resultDelete = payload
            state.error = null
        })
        builder.addCase(dashboardNoteDelete.rejected, (state, action) => {
            state.loading = false
            state.resultDelete = null
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dashboardNotesListRedux;

// Extract and export each action creator by name
export const { reset, setIsUserAnnouncement } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;