import { Routes, Route, Navigate } from 'react-router-dom';
import ParameterListView from './views/list'
import { useSecurity } from '../../../../config/hooks';
import { P_CONFIGURATIONS } from '../../../../config/helpers/permissionsHash';
import { WorkFlowManagementListView } from '../workFlowStates/views/list';


const ParametersRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/management/parameters/list`} />}></Route>
            {useSecurity(P_CONFIGURATIONS) && <Route path='/list' element={<ParameterListView />} />}
            {useSecurity(P_CONFIGURATIONS) && <Route path='/workflow/list' element={<WorkFlowManagementListView />} />}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default ParametersRouter;