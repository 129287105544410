//Global keys
let locales = {
    "client_menu": "Cliente",
    "agenty_menu": "Agente",
};

//Merge Modules
import dashboard from '../../dashboard/.config/lan/es'
import listNotes from '../../dashboard/components/listNotes/lan/es'

//Merge Email Programming
import emailProgramming from '../../emailProgramming/.config/lan/es'
import notificationsList from '../../notificationsList/.config/lan/es'
import sidebar from '../../../../../core/layouts/sidebar/lan/es'

// Merge Documents And Contracts
import documentsAndContracts from '../../documentsAndContracts/.config/lan/es'

import announcementContracts from '../../announcementsContractManage/.config/lan/es'


locales = {
    ...locales,
    ...dashboard,
    ...listNotes,
    ...emailProgramming,
    ...notificationsList,
    ...sidebar,
    ...documentsAndContracts,
    ...announcementContracts
};

export default locales;