import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clientQuery,
  clientMutation,
} from "../../../../../../../config/helpers/GraphQLApi";

import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  error: null,
  //
  isSendingEmail: false,
  resultTestEmail: null,
};

const route = "modules/management/parameters/modals/editCampaignFrom/slice";

//Extend async Action
export const editCampaignFromEditOpen = createAsyncThunk(
  "parameter/editCampaignFrom/open",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      //si es una creacion se retorna
      if (!id) {
        return { data: null };
      }

      //si es edicion se optiene la informacion
      let data = await clientQuery(
        `query getCampaignFromById($id: Int!) {
                campaignfrom: getCampaignFromById(id: $id) {
                    id
                    name
                    email
                    name
                    host
                    port
                    secure
                    user
                    password
                    isDefault
                }
             }`,
        {
          id: Number(id),
        },
        endpoints.GRAPHQL_GENERAL
      );
      //Se retorna la data del server
      return { data: data.campaignfrom };
    } catch (exc) {
      //Se guarda la excepcion
      ExceptionManager(exc, route, "companiesEditOpen", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const campaignFromEditSave = createAsyncThunk(
  "parameters/campaignFrom/save",
  async (
    { id, email, name, host, port, secure, user, password,isDefault },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let data;
      if (id) {
        data = await clientMutation(
          `mutation updateCampaignsFrom (
              $id: Int!, 
              $email: String, 
              $name: String, 
              $host: String
              $port: String
              $secure: Int
              $user: String
              $password: String
              $isDefault: Int
            ) {
                updateCampaignsFrom (
                  id: $id, 
                  email: $email, 
                  name: $name, 
                  host: $host, 
                  port: $port, 
                  secure: $secure, 
                  user: $user, 
                  password: $password,
                  isDefault: $isDefault
                ) {
                  id
                }
            }`,
          {
            id,
            email,
            name,
            host,
            port: `${port}`,
            secure: secure ? 1 : 0,
            user,
            password,
            isDefault: isDefault ? 1 : 0,
          },
          endpoints.GRAPHQL_GENERAL
        );
        return data.updateCampaignsFrom.id;
      } else {
        //create
        data = await clientMutation(
          `mutation createCampaignsFrom (
              $email: String!, 
              $name: String!, 
              $host: String!
              $port: String!
              $secure: Int!
              $user: String!
              $password: String!
              $isDefault: Int
            ) {
                createCampaignsFrom (
                  email: $email, 
                  name: $name, 
                  host: $host, 
                  port: $port, 
                  secure: $secure, 
                  user: $user, 
                  password: $password
                  isDefault: $isDefault
                ) {
                    id
                }
            }`,
          {
            email,
            name,
            host,
            port: `${port}`,
            secure: secure ? 1 : 0,
            user,
            password,
            isDefault: isDefault ? 1 : 0,
          },
          endpoints.GRAPHQL_GENERAL
        );

        return data.createCampaignsFrom.id;
      }
    } catch (exc) {
      ExceptionManager(exc, route, "campaignFromEditSave", dispatch);
      return rejectWithValue(exc);
    }
  }
);
export const sendTestEmail = createAsyncThunk(
  "parameters/campaignFrom/sendTestEmail",
  async (
    { fromEmail, toEmail, host, port, secure, user, password},
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await clientMutation(
        `mutation emailTest (
            $fromEmail: String!, 
            $toEmail: String!
            $port: String!
            $host: String!
            $secure: Int!
            $user: String!
            $password: String!
          ) {
              emailTest (
                fromEmail: $fromEmail, 
                toEmail: $toEmail, 
                host: $host, 
                port: $port, 
                secure: $secure, 
                user: $user, 
                password: $password,
              )
          }`,
        {
          fromEmail,
          toEmail,
          host,
          port: `${port}`,
          secure: secure ? 1 : 0,
          user,
          password,
        },
        endpoints.GRAPHQL_GENERAL
      );

      return result;
    } catch (exc) {
      ExceptionManager(exc, route, "sendTestEmail", dispatch);
      return rejectWithValue(exc);
    }
  }
);
//Slice
const campaignFromEditRedux = createSlice({
  name: "parameters/compaignFromEdit",
  initialState: INIT_STATE,
  reducers: {
    campaignFromEditClose(state, action) {
      return INIT_STATE;
    },
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    //thunk editOpen
    builder.addCase(editCampaignFromEditOpen.pending, (state) => {
      state.loading = true;
      state.isOpen = true;
    });
    builder.addCase(
      editCampaignFromEditOpen.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
      }
    );
    builder.addCase(editCampaignFromEditOpen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //thunk save
    builder.addCase(campaignFromEditSave.pending, (state) => {
      state.loading = true;
      state.result = null;
      state.error = null;
    });
    builder.addCase(campaignFromEditSave.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(campaignFromEditSave.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //thunk emailTest
    builder.addCase(sendTestEmail.pending, (state) => {
      state.isSendingEmail = true;
      state.resultTestEmail = null;
      state.error = null;
    });
    builder.addCase(sendTestEmail.fulfilled, (state, { payload }) => {
      state.isSendingEmail = false;
      state.resultTestEmail = payload;
    });
    builder.addCase(sendTestEmail.rejected, (state, action) => {
      state.isSendingEmail = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = campaignFromEditRedux;

// Extract and export each action creator by name
export const { campaignFromEditClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
