let locales = {
    "documentsandContracts_submitDocument": "caricare il documento",
};

// merge modal SubmitDocument
import submitDocument from '../../modals/submitDocument/.config/lan/it'
import listDocument from '../../views/list/lan/it'

locales = {
    ...submitDocument,
    ...listDocument,
};

export default locales;