import { useEffect, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { Table, notification } from "antd";
import {
    TableOptions,
    ConfirmDeleteModal
} from 'components';
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { rolesListSearch, deleteRole, reset } from "./redux/slice";
import ModalEditRole from "../../modals/edit";
import { openEditRole } from "../../.config/redux/actions";
import moment from "moment";

import { P_ROLES } from "../../../../../../config/helpers/permissionsHash";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";

const RolesList = () => {
    /**
     * Orden:
     * 1. constantes globales
     * 2. useStates
     * 3. callback
     * 4. effects
     * 5. uso de hooks propios, calculos de valores que se hagan constantes
     */

    //1.
    //manejo de lenguaje
    const intl = useIntl();
    const { messages } = intl;

    //despachador de redux
    const dispatch = useDispatch();
    // const navigate = useNavigate()

    //2.
    //manejo de seleccion
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [idToDelete, setIdToDelete] = useState(null);
    //redux del view
    const { loading, data, error, resultDelete } = useSelector(
        (state) => state.rolesListRedux
    );

    //4.
    //efecto de carga de view
    useEffect(() => {
        if (error) {
            notification.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error.message,
            });
            reset('error');
        }
        if (resultDelete) {
            notification.success({
                message: messages["deleteSucessfully"].toString(),
            });
            paggination.refresh()
        }
    }, [error, resultDelete])

    useEffect(() => {
        paggination.refresh();

        //efecto de salida del view
        return () => {
            //reset del redux
            dispatch(reset(null));
        };
    }, []);

    const paggination = usePaggination(rolesListSearch);

    const C = useSecurity(P_ROLES, ['C']);
    const R = useSecurity(P_ROLES, ['R']);
    const U = useSecurity(P_ROLES, ['U']);
    const D = useSecurity(P_ROLES, ['D']);

    const toggleShowDeleteModal = (id = null) => {
        setIdToDelete(id);
    }

    const onDeleteItem = () => {
        if (idToDelete === -1 && selectedRowKeys.length > 0) {
            for (let i = 0; i < selectedRowKeys.length; i++) {
                const element = selectedRowKeys[i];
                dispatch(deleteRole({ id: element }));
            }
            setSelectedRowKeys([])
        }
        if (idToDelete > -1) {
            dispatch(deleteRole({ id: idToDelete }));
        }
        toggleShowDeleteModal();
    }

    return (
        <Fragment>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="card ">
                    {/* BARRA DE TAREA (BUSQUEDA BOTONES) */}
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                {R && <input
                                    type="text"
                                    className="form-control form-control-solid w-250px ps-13"
                                    placeholder={messages["search"]?.toString()}
                                    onChange={paggination.onSearchChange}
                                />}
                            </div>
                            <div className="col-md-8 col-sm-12 mt-2 mt-sm-0">
                                {selectedRowKeys.length == 0 ? (
                                    <div className="d-flex justify-content-end">
                                        {R && <button
                                            type="button"
                                            className="btn btn-primary me-3"
                                            onClick={() =>
                                                paggination.refresh()
                                            }
                                        >
                                            <i className="ki-duotone ki-arrow-circle-right fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                            {messages["refresh"]?.toString()}
                                        </button>}

                                        {C && <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                dispatch(openEditRole({}))
                                            }
                                        >
                                            <i className="ki-duotone ki-plus fs-2"></i>
                                            {messages["add"]?.toString()}
                                        </button>}
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div className="fw-bold me-5">
                                            <span
                                                className="me-2"
                                                data-kt-user-table-select="selected_count"
                                            ></span>
                                            {selectedRowKeys.length} {messages["selected"]}
                                        </div>
                                        {D && <button
                                            type="button"
                                            className="btn btn-danger"
                                            data-kt-user-table-select="delete_selected"
                                            onClick={() => { setIdToDelete(-1) }}
                                        >
                                            {`${messages["deleteSelected"]}`}
                                        </button>}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12 mt-4 mb-4">
                            <TextDescriptionCompanies />
                        </div>
                    </div>
                    {/* TABLA */}
                    <div className="card-body py-4 table-responsive">
                        {loading && <div className="linear-loading " />}
                        {R && <Table
                            rowKey="id"
                            scroll={{ x: 1000 }}
                            style={{ minHeight: "calc(100vh - 350px)" }}
                            showSorterTooltip={false}
                            columns={[
                                {
                                    key: "actions",
                                    width: 50,
                                    title: "",
                                    className: 'min-vh-30',
                                    render: (values) => {
                                        return (
                                            <TableOptions options={[
                                                {
                                                    label: messages["edit"],
                                                    show: U,
                                                    onClick: () => {
                                                        dispatch(
                                                            openEditRole(
                                                                {
                                                                    id: values.id,
                                                                }
                                                            )
                                                        );
                                                    }
                                                },
                                                {
                                                    label: messages["delete"],
                                                    show: D,
                                                    onClick: () => {
                                                        toggleShowDeleteModal(values.id)
                                                    }
                                                }
                                            ]} />
                                        );
                                    },
                                },
                                // {
                                //     title: messages["id"],
                                //     dataIndex: "id",
                                //     width: 50,
                                // },
                                {
                                    title: messages[
                                        "name"
                                    ]?.toString(),
                                    key: "name",
                                    dataIndex: "name",
                                },
                                {
                                    title: messages[
                                        "updatedBy"
                                    ]?.toString(),
                                    key: "updated",
                                    dataIndex: "updated",
                                    width: 150,
                                    render: (value) => {
                                        return value?.fullName
                                    }
                                },
                                {
                                    title: messages[
                                        "updatedOn"
                                    ]?.toString(),
                                    key: "updatedAt",
                                    dataIndex: "updatedAt",
                                    width: 150,
                                    render: (value) => {
                                        return moment(value).format('DD/MM/YYYY')
                                    }
                                },
                            ]}
                            dataSource={data.items}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: (newSelectedRowKeys) => {
                                    setSelectedRowKeys(newSelectedRowKeys);
                                },
                            }}
                            onChange={paggination.onChange}
                            pagination={{
                                total: data?.count || 0,
                                ...paggination.tablePaggination,
                            }}
                        />}
                    </div>
                </div>
            </div>
            <ModalEditRole onRefresh={() => paggination.refresh()} />
            <ConfirmDeleteModal show={idToDelete} onCancel={toggleShowDeleteModal} onOk={onDeleteItem} />
        </Fragment>
    );
};

export default RolesList;
