import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    show: false,
    data: null,
    list: {
        items: [],
        count: 0
    },
    result: null,
    resultError: null
};

const route = 'routes/admin/configurations/forms/modals/editCataloh/redux/slice'

//Extend async Action
export const openEditCatalog = createAsyncThunk('forms/editCatalog/open', async ({ id }, { rejectWithValue }) => {
    try {
        if (!id) return null;

        let data = await clientQuery(
            `
            query getCatalogById($id: Int!) {
                result: getCatalogById(id: $id) {
                    id
                    nameCatalog
                    items {
                        id
                        catalogId
                        value
                    }
                }
            }
            `,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL,
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'openEditCatalog');
        return rejectWithValue(exc)
    }
})

export const saveEditCatalog = createAsyncThunk('forms/editCatalog/save', async ({ id, nameCatalog, items}, { rejectWithValue }) => {
    try {

        let data

        if (id) {
            data = await clientMutation(
                `mutation updateFormCatalog($id: Int!, $nameCatalog: String, $items: [CatalogItemInput]) {
                    result: updateFormCatalog(id: $id, nameCatalog: $nameCatalog, items: $items) {
                        id
                    }
                }`,
                {
                    id: Number(id),
                    nameCatalog,
                    items: items.map(v => {
                        const item = {...v}
                        delete item.__typename
                        return item
                    })
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }
        else {
            data = await clientMutation(
                `mutation createFormCatalog($nameCatalog: String, $items: [CatalogItemInput]!) {
                    result: createFormCatalog(nameCatalog: $nameCatalog, items: $items) {
                        id
                    }
                }`,
                {
                    nameCatalog,
                    items
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'saveEditCatalog');
        return rejectWithValue(exc)
    }
})

export const searchFormsCatalogs = createAsyncThunk('forms/editCatalog/search', async ({ searchText, limit, offset}, { rejectWithValue }) => {
    try {

        let result = await clientQuery(
                `query searchFormsCatalogs($searchText: String, $limit: Int, $offset: Int) {
                    result: searchFormsCatalogs(searchText: $searchText, limit: $limit, offset: $offset) {
                        items {
                            id
                            nameCatalog
                            items {
                                id
                                catalogId
                                value
                            }
                        }
                        count
                    }
                }`,
                {
                    searchText: searchText || '',
                    limit: limit || 10,
                    offset: offset || 0,
                },
                endpoints.GRAPHQL_GENERAL,
            );
        
        return result.result

    } catch (exc) {
        ExceptionManager(exc, route, 'searchFormsCatalogs');
        return rejectWithValue(exc)
    }
})


//Slice
const formsCatalogsEditRedux = createSlice({
    name: 'forms/editCatalog',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        hide(state) {
            state.show = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(openEditCatalog.pending, (state) => {
            state.loading = true
            state.show = true
        })
        builder.addCase(openEditCatalog.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(openEditCatalog.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })
        //save
        builder.addCase(saveEditCatalog.pending, (state) => {
            state.loading = true
        })
        builder.addCase(saveEditCatalog.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(saveEditCatalog.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })
        //search
        builder.addCase(searchFormsCatalogs.pending, (state) => {
            state.loading = true
        })
        builder.addCase(searchFormsCatalogs.fulfilled, (state, { payload }) => {
            state.loading = false
            state.list = payload
        })
        builder.addCase(searchFormsCatalogs.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = formsCatalogsEditRedux

// Extract and export each action creator by name
export const { reset, hide } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer