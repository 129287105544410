import {
  deleteDocumentContract,
  documentsAndContractsEditOpen,
} from "../../modals/submitDocument/.config/redux/slice";
import {
  ConfirmDeleteModal,
  TextDescriptionCompanies,
} from "../../../../../../components";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { P_FILES } from "../../../../../../config/helpers/permissionsHash";
import { getCatalog } from "../../../../../.config/redux/actions";
import { EditDocumentModal } from "../../modals/submitDocument";
import { getDocumentsList, reset } from "./redux/slice";
import { HiOutlineDocumentText } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Input, Table, notification } from "antd";
import { TableOptions } from "components";
import { useIntl } from "react-intl";
import moment from "moment";
import ListFilters from "../../components/Filters";

export const DocumentsAndContractsListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const { data, loading, error } = useSelector((state) => state.documentsList);
  const { user } = useSelector((state) => state.authRedux);
  const { roles } = useSelector((state) => state.catalogRedux);
  const paggination = usePaggination(getDocumentsList);

  useEffect(() => {
    paggination.refresh();
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    dispatch(getCatalog({ keys: ["roles"] }));
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"]?.toString(),
        description: messages[error] || error.message,
      });
      reset("error");
    }
  }, [error]);

  const C = useSecurity(P_FILES, ["C"]);
  const R = useSecurity(P_FILES, ["R"]);
  const U = useSecurity(P_FILES, ["U"]);
  const D = useSecurity(P_FILES, ["D"]);

  // Función para mapear los números a roles
  const findRoleForId = (value) => {
    const roleName = roles?.find((v) => v.id == value);
    return roleName?.name;
  };

  // Funcion para cerrar el modal
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDocumentToDelete(null);
  };

  // funcion para abrir el modal
  const handleDeleteModal = (id) => {
    setDocumentToDelete(id);
    setOpenDeleteModal(true);
  };

  //funcion para eliminar el documento pasando el dispatch

  const delteDocument = () => {
    dispatch(deleteDocumentContract({ id: documentToDelete }));
    setDocumentToDelete(null);
    setOpenDeleteModal(false);
  };

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {R && (
                <Input
                  type="text"
                  className="form-control my-1 w-100"
                  placeholder={messages["search"]}
                  onChange={paggination.onSearchChange}
                  value={paggination.searchText}
                />
              )}
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                {R && (
                  <>
                    <ListFilters onApply={(values) => {
                      paggination.refresh(null, null, {filters: values})
                    }}/>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => {
                        paggination.refresh();
                      }}
                    >
                      {messages["refresh"]}
                    </button>
                  </>
                )}
                {C && (
                  <button
                    className="btn btn-primary m-1"
                    onClick={() => {
                      dispatch(documentsAndContractsEditOpen({ id: null }));
                    }}
                  >
                    {messages["documentsandContracts_submitDocument"]}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>
          <div className="mt-4 table-responsive">
            {R && (
              <Table
                rowKey="id"
                loading={loading}
                columns={[
                  U || D
                    ? {
                        width: 50,
                        className: "min-vh-30",
                        render: (values) => {
                          return (
                            <TableOptions
                              options={[
                                {
                                  show: U,
                                  label: messages["edit"],
                                  onClick: () =>
                                    dispatch(
                                      documentsAndContractsEditOpen({
                                        id: values?.id,
                                      })
                                    ),
                                },
                                {
                                  show: D,
                                  label: messages["delete"],
                                  onClick: () => handleDeleteModal(values?.id),
                                },
                              ]}
                            />
                          );
                        },
                      }
                    : {},
                  {
                    title: messages["documentsandContracts_dateOfCreation"],
                    key: "createdOn",
                    // dataIndex: "createdOn",
                    width: 200,
                    render: (row) =>
                      row.createdOn &&
                      moment(row.createdOn).format("DD/MM/yyyy, h:mm:ss a"),
                  },
                  {
                    title: messages["documentsandContracts_nomeFile"],
                    key: "nome",
                    dataIndex: "name",
                    width: 300,
                  },
                  {
                    title: messages["documentsandContracts_noteFile"],
                    key: "note",
                    dataIndex: "note",
                    width: 650,
                  },
                  {
                    title: messages["documentsandContracts_file"],
                    key: "files",
                    dataIndex: "files",
                    width: 200,
                    render: (row) => (
                      <div>
                        {
                          <a
                            className="d-flex flex-row align-items-center"
                            href={row[0].url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <HiOutlineDocumentText size={30} />
                            <p className="m-2">
                              {row[0].name.substring(0, 10)}...
                            </p>
                          </a>
                        }
                      </div>
                    ),
                  },
                  user.isAdmin
                    ? {
                        title: messages["documentsandContracts_visibleTo"],
                        key: "visibleTo",
                        // dataIndex: "visibleTo",
                        width: 300,
                        render: (row) => (
                          <div className="d-flex flex-column">
                            {row.visibleTo?.map((v) => (
                              <label key={v}>{<p>{findRoleForId(v)}</p>}</label>
                            ))}
                          </div>
                        ),
                      }
                    : {},
                ]}
                dataSource={data?.items || []}
                pagination={{
                  total: data?.count || 0,
                  ...paggination.tablePaggination,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <EditDocumentModal
        onClose={(refresh) => {
          refresh && paggination.refresh();
        }}
      />
      <ConfirmDeleteModal
        show={openDeleteModal}
        onCancel={() => closeDeleteModal()}
        onOk={() => delteDocument()}
      />
    </Fragment>
  );
};
