import { Fragment, useEffect, useMemo } from "react";
import {
    getCatalog,
    getHistoryPAStates,
} from "../../../../../.config/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { ModalHistoryNotesByPAState } from "../../modals/historyNotesByPAState";
import { AnimatedClockComponent, Timer } from "../../../../../../components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

export const NotificationPAState = (props) => {
    const { id, companyId, open, buttonDispatch } = props;
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const { pAStates } = useSelector(
        (state) => state.catalogRedux
    );
    const { data,} = useSelector((state) => state.historyPAStateList);

    useEffect(() => {
        dispatch(getCatalog({ keys: ["pAStates"] }));
    }, [])

    useEffect(() => {
        dispatch(getHistoryPAStates({ id: id, companyId: companyId }));
    }, [id, companyId]);

    const currentPAState = pAStates?.find((pAState) => pAState?.id == id);
    const lastState = data?.length > 0 ? data[0] : null;

    const { showTimeRemaining, showMessage } = useMemo(() => {
        if (!lastState || !currentPAState || !open) {
            return { showTimeRemaining: false, showMessage: false }
        }

        if (currentPAState?.limitHours == 0) {
            return { showTimeRemaining: false, showMessage: false }
        }

        const { timeRemainingSeconds } = lastState;
        if (timeRemainingSeconds <= 0) {
            return { showTimeRemaining: false, showMessage: true }
        }

        return { showTimeRemaining: true, showMessage: false }
    }, [currentPAState, lastState?.timeRemainingSeconds])

    return (
        <Fragment >

            <div
                style={{ borderColor: currentPAState?.color, borderWidth: '0 0 4px 0' }}
                className="card mb-2 d-flex "
            >

                <div className="d-flex flex-row pb-0">
                    <div>
                        <div className="card-header p-3 pb-0 d-flex justify-content-between">
                            <h5 className="card-title mb-sm-0 mb-2">
                                {currentPAState?.name} {lastState?.stateId == 9 && <i className='bx bxs-user-check fs-2' />}
                            </h5>

                        </div>
                        <div className="card-body p-3 pt-0 pb-0 d-flex flex-column">
                            {lastState?.updatedAt && (
                                <span>
                                    {messages["workflow_card_updatedAt"]}
                                    {moment(lastState?.updatedAt).format("L")}
                                </span>
                            )}
                            <span>
                                {messages["workflow_card_description"]}{" "}
                                {currentPAState?.description}
                            </span>
                            <span>
                                {messages["workflow_card_helpText"]}{" "}
                                {currentPAState?.helpText}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex p-3 pb-0 align-items-center">

                        {open && (
                            <button
                                className="btn rounded-4 d-flex justify-content-between"
                                onClick={() => buttonDispatch()}
                                style={
                                    {
                                        backgroundColor: currentPAState?.color,
                                        color: '#ffffff',
                                        border: 'none',
                                        padding: '8px 16px',
                                        cursor: 'pointer',
                                        '--fa-animation-duration': '3s',
                                    }
                                }>

                                <i className="pe-1">{messages["companies_paState"]}</i>

                                <FontAwesomeIcon className="fas fa-bullseye fa-fw fa-beat-fade" icon={faBullseye} />
                            </button>
                        )}

                    </div>
                    <div className="d-flex align-items-start">
                    {(showTimeRemaining) && <>
                        <AnimatedClockComponent />
                        <Timer key={companyId} seconds={lastState?.timeRemainingSeconds || 0} />
                    </>}
                    {showMessage && <span className="alert-danger p-1 rounded-bottom">
                        <i className="bx bxs-error fs-4 " />
                        Il processo non è stato completato in tempo</span>}
                </div>

                </div>
                
            </div>

            <ModalHistoryNotesByPAState isOpen={true} />
        </Fragment>
    );
};
