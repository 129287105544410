let locales = {
    dashboardDetail_title: `Anuncio`,
    dashboardDetail_title1: `Descripción del presupuesto en línea`,
    dashboardDetail_1: `Trabajo a realizar`, 
    dashboardDetail_2: `Precio máximo`,
    dashboardDetail_3: `Fecha estimada del proyecto`,
    dashboardDetail_4: `Tipo de solicitud`,
    dashboardDetail_5: `Qué está buscando`,
    dashboardDetail_6: `Dónde lo necesita`,
    dashboardDetail_7: `Qué está buscando`,
    dashboardDetail_8: `Encuesta`,
    dashboardDetail_9: `Código de solicitud`,
    dashboardDetail_title2: `Solicitud de presupuesto de autor`,
    dashboardDetail_author1: `Nombre`, 
    dashboardDetail_author2: `Común`,
    dashboardDetail_author3: `Provincia`,
    dashboardDetail_author4: `Teléfono`,
    dashboardDetail_author5: `E-mail`,
    dashboardDetail_author6: `Horas de contacto`,
    dashboardDetail_notSpecified: `No especificado`,
    dashboardDetail_notes: 'Notas',
    dashboardDetail_notesAdd: 'Agregar Notas',
    dashboardDetail_notesEdit: 'Editar Nota',
    dashboardDetail_deleteNote: 'Eliminar Nota',
    dashboardDetail_deleteNoteDescription: 'Esta acción es irreversible',
    dashboardDetail_whatsapp: "Mensaje en WhatsApp",
    dashboardDetail_contract: "Contrato",
    dashboardDetail_emptyHistory: "todavia no se ha subido ningun documento",
    dashboardDetail_DeletedBy: "Eliminado por: ",
};

export default locales;