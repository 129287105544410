import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';

import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: { count: 0, items: [] },
    error: null,
    resultDelete: null,
};

const route = 'routes/admin/management/users/views/list/redux/slice'

//Extend async Action
export const usersListSearch = createAsyncThunk('users/list/search', async ({ searchText, limit, offset, filters, orderBy, roleId }, { rejectWithValue }) => {
    try {
        let data = await clientQuery(
            `
                query searchUsers($searchText: String, $limit: Int, $offset: Int) {
                    result: searchUsers(openSearch: $searchText, limit: $limit, offset: $offset) {
                        count
                        items { 
                            id
                            username
                            email
                            firstName
                            lastName
                            fullName
                            imageUrl
                            companyId
                            isAdmin
                            status
                            isDeleted
                            company {
                                name
                            }
                            hashp
                            roles {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            {
                searchText, limit, offset, filters, orderBy, roleId
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'usersListSearch');
        return rejectWithValue(exc)
    }
})

export const deleteUser = createAsyncThunk('users/list/delete', async ({ id }, { rejectWithValue }) => {
    try {
        let data = await clientMutation(
            `
                mutation deleteUser($id: Int!) {
                    result: deleteUser(id: $id)
                }
            `,
            {
                id: Number(id),
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'deleteUser');
        return rejectWithValue(exc)
    }
})


//Slice
const usersListRedux = createSlice({
    name: 'users/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(usersListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(usersListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(usersListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteUser.pending, (state) => {
            state.loading = true
            state.resultDelete = null
        })
        builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
            state.loading = false
            state.resultDelete = payload
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = usersListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer