import { default as DatePickerInternal } from "react-datepicker";
import cx from 'classnames'

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export const DatePicker = ({ value, onChange, className, minDate, maxDate }) => {

    return (
        <DatePickerInternal
            selected={value?.toDate()}
            onChange={(e) => {
                if (onChange)
                    onChange(e && moment(e))
            }}
            className={cx(className)}
            minDate={minDate}
            maxDate={maxDate}
        />
    )
}