import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Modal, Form, Input, Skeleton, notification } from "antd";
// import UserCard from '../../components/userCard'

import { hide, reset, saveEditRole } from "./redux/slice";
import ManagementRolePermission from "../../components/ManagementRolePermission";

const ModalEditRole = ({ onRefresh }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { messages } = intl;
    const [notificationApi, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();

    const { show, data, loading, resultError, result } = useSelector(
        (state) => state.roleEditRedux
    );

    const [id, setId] = useState();

    useEffect(() => {
        if (data && !id) {
            setId(data.id);
            form.setFieldsValue({
                ...data,
            });
        }
    }, [data]);

    useEffect(() => {
        dispatch(reset({ result: null, resultError: null }));

        if (result) {
            notificationApi.success({
                message: messages["saveSuccessfullyTitle"].toString(),
                description: messages["saveSuccessfully"].toString(),
            });

            close(true);
        }

        if (resultError) {
            const {error} = resultError;
            notificationApi.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error,
            });
        }
    }, [resultError, result]);

    const close = useCallback((refresh) => {
        dispatch(hide());
        setId(null);
        form.resetFields();
        dispatch(reset(null));

        if (refresh && onRefresh) onRefresh();
    }, []);

    return (
        <Modal
            title={
                <h5>
                    {id
                        ? messages["roleEdit_title"]?.toString()
                        : messages["roleEdit_titleEdit"]?.toString()}
                </h5>
            }
            open={show}
            width={1000}
            onCancel={() => close()}
            footer={null}
        >
            {contextHolder}
            <div className="p-3 p-md-5">
                <div className="card-body">
                    <Form
                        form={form}
                        layout="vertical"
                        onValuesChange={() => {}}
                        onFinish={(values) => {
                            dispatch(saveEditRole({ id, ...values }));
                        }}
                    >
                        {loading ? (
                            <>
                                <Skeleton active />
                            </>
                        ) : (
                            <div className="row g-3">
                                <Form.Item
                                    className="col-12"
                                    label={<label className="form-label">{messages["name"]}</label>}
                                    name="name"
                                    normalize={(value) => value?.toUpperCase()}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                messages[
                                                    "required_field"
                                                ],
                                        },
                                    ]}
                                >
                                    <Input
                                        className="form-control"
                                        placeholder={messages[
                                            "name"
                                        ]?.toString()}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="col-12"
                                    label={<label className="form-label">{messages["role_permissions"]}</label>}
                                    name="permissions"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                messages[
                                                    "required_field"
                                                ],
                                        },
                                    ]}
                                >
                                    <ManagementRolePermission />
                                </Form.Item>
                            </div>
                        )}
                    </Form>
                </div>
                <div className="row pt-5">
                    <div className="text-center pt-10">
                        <button
                            className="btn btn-primary me-sm-3 me-1"
                            disabled={loading}
                            onClick={() => form.submit()}
                        >
                            {messages["save"]?.toString()}
                        </button>
                        <button
                            className="btn btn-label-secondary"
                            onClick={() => close({})}
                        >
                            {messages["close"]?.toString()}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalEditRole;
