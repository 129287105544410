//Global keys
let locales = {
    listNotificacionStatusEmail: 'Invio Email Programmato',
    listNotificacionStatusPending: 'In Attesa',
    listNotificacionStatusSuccess: 'Inviata',
    listNotificacionStatusError: 'Errore di spedizione',
    listNotificacion_motification: 'modificata dall`Amministrazione',
    listNotificacion_isAdmin: 'Amministrazione',
}


export default locales;