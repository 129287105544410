import { InputNumber } from 'antd';

function NumberProps(props) {
    const { values, onChange } = props;
    return (
        <div className='col-12'>
            <div className='row mb-2'>
                <label className="form-label">Default value</label>
                <InputNumber value={values['defaultValue']} onChange={onChange('defaultValue')} placeholder='Default Value'/>
            </div>
            <div className='row mb-2'>
                <label className="form-label">Number of decimals</label>
                <InputNumber value={values['decimalsNumber']} onChange={onChange('decimalsNumber')} placeholder='Number of decimals' min={0} max={10}/>
            </div>
            <div className='row mb-2'>
                <label className="form-label">Range</label>
                <div className='col-6'>
                <InputNumber className='w-100' value={values['min']} onChange={onChange('min')} placeholder='Min' />
                </div>
                <div className='col-6'>
                <InputNumber className='w-100' value={values['max']} onChange={onChange('max')} placeholder='Max' />
                </div>
            </div>
        </div>
    )
}

export default NumberProps