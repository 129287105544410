let locales = {
    parameters_editTemplate_edit: 'Modifica template',
    parameters_editTemplate_create: 'Creare template',
    parameters_editTemplate_name: 'Nome',
    parameters_editTemplate_body: 'Body',
    parameters_editTemplate_subject: 'Oggetto',
    parameters_parameters: 'Parametros',
    parameters_companyParameters: 'Company Parameters'
};

export default locales;