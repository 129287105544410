import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Loading, RichTextEditor } from "components";
import { Form, Modal, Input, notification } from "antd";

import {
  getCatalog
} from 'config/redux/actions';

import { viewContactClose, reset } from './redux/slice'

const ViewContactModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { loadingContact, contact } = useSelector((state) => state.catalogRedux);
  const { isOpen } = useSelector((state) => state.viewContactRedux)

  //Verificacion de carga
  useEffect(() => {
    if (isOpen && !contact) {
      dispatch(getCatalog({ keys: ['contact'] }))
    }
  }, [isOpen]);

  //se valida y se limpia todo lo que pueda causar errores
  const closeModal = useCallback((refresh) => {
    dispatch(viewContactClose())
  });

  return (
    <Modal
      open={isOpen}
      width={800}
      onCancel={() => closeModal()}
      footer={null}
      maskClosable={false}
    >
      <Loading loading={loadingContact}>
        <div className="p-3 p-md-5">
          {
            contact &&
            <div dangerouslySetInnerHTML={{ __html: contact.value}} />
          }
        </div>
      </Loading>
    </Modal>
  );
};

export default ViewContactModal;
