let locales = {
    users_userList_title: 'Listado de Usuarios ',
    users_userList_username: 'Usuario',
    users_userList_email: 'Email',
    users_userList_roles: 'Roles',
    users_userList_lastActivity: 'Última Actividad',
    users_userList_joinedDate: 'Fecha de Creacion',

    users_userList_addUser: 'Agregar Usuario',

    user_userList_activeStatus: 'ACTIVO',
    user_userList_inactiveStatus: 'INACTIVO',
    user_userList_deletedStatus: 'ELIMINADO',
};

export default locales;