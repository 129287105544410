import { Routes, Route, Navigate } from 'react-router-dom';
import CampaignsListView from './views/list'
import { useSecurity } from '../../../../config/hooks';
import { P_EMAIL_SENT } from '../../../../config/helpers/permissionsHash';

const CampaignsRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/management/campaigns/list`} />}></Route>
            {useSecurity(P_EMAIL_SENT) && <Route path='/list' element={<CampaignsListView />} />}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default CampaignsRouter;