import { Badge, Divider, Dropdown } from 'antd'
import React, { Fragment, useEffect, useMemo } from 'react'
import { AiOutlineBell, AiOutlineMail } from 'react-icons/ai';
import { GoDotFill } from 'react-icons/go';
import './notifications.scss';
import { useDispatch, useSelector } from 'react-redux';
import {getListNotifications, updateNotification} from './.config/redux/slice'
import moment from 'moment';
import { useActionNotification } from '../../config/hooks/tools';
import { useIntl } from 'react-intl';
import { Link, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//const { useToken } = theme;


const menuStyle = {
    boxShadow: 'none',
    overflowY: 'auto',
    maxHeight: '60vh',
};

const DEFAULT_DAYS = 180;
export const notificationsButton = () => {

   const { messages } = useIntl();
   const dispatch = useDispatch();
   const actionInterpreter = useActionNotification();
   const [searchParams] = useSearchParams();
   const navigate = useNavigate()
   const notificationId = searchParams.get('notificationId');

   useEffect(() => {
     if(notificationId){
       dispatch(updateNotification({id: parseInt(notificationId, 10), read: true})).unwrap().then(() => {
            refreshNotifitication(DEFAULT_DAYS);
        }) 
     }
   }, [notificationId])
   

   const { data, active_notifications } = useSelector( (state) => state.notification )

   useEffect(() => {
      dispatch(getListNotifications({daysAgo: DEFAULT_DAYS}));
      const refreshNotifications = setInterval(() => {
        dispatch(getListNotifications({daysAgo: DEFAULT_DAYS}));
      }, 180000);
      return () => clearInterval(refreshNotifications);
    
   }, [dispatch])

    const items = useMemo(() => {
        if(data?.items && data?.items.length > 0){
            let _items = data?.items.map(item => ({
                key: item.id,
                onClick: () => markAsRead(item),
                label: (
                    <div className="d-flex border-bottom">
                        <div className="flex-shrink-0 me-3">
                            <div className="avatar">
                                <img
                                    className="rounded-circle"
                                    src={item?.user?.imageUrl || "/assets/images/avatars/blank.png"}
                                    alt="User avatar"
                                />
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="mb-1">{`${item?.user.username}`}</h5>
                            <p className="mb-0">{item?.title}</p>
                            <small className="text-muted">{item?.createdOn && moment(item.createdOn).format("DD/MM/YYYY HH:mm")}</small>
                        </div>
                        <div className="flex-shrink-0 dropdown-notifications-actions">
                            {!item?.read &&  
                                <GoDotFill size={20} color="#696cff" />
                            }
                        </div>
                    </div>
                )
            }))
            const current = data.items.filter( item => item.read === false).length
            if(data.unreadCount > current){
                //If the number of unread notification is greater than 20
                _items.push({
                    key: 'showMore',
                    onClick: () => navigate("/admin/client/notifications-list/list"),
                    type: 'text',
                    label: (<div className='d-flex justify-content-center'>
                        <small className='text-muted'>{messages['notificationList_moreNotifications']}</small>
                    </div>)
                })
            }
            return _items
        }
        return []
    }, [data])

    const refreshNotifitication = (daysAgo = DEFAULT_DAYS, isNoRead = false) => {
        dispatch(getListNotifications({daysAgo, isNoRead}));
    }

    //const { token } = useToken();

    // const contentStyle = {
    //     backgroundColor: token.colorBgElevated,
    //     borderRadius: token.borderRadiusLG,
    //     boxShadow: token.boxShadowSecondary,
    // };

    const markAsRead = (item) => {
        dispatch(updateNotification({id: item.id, read: true})).unwrap().then(() => {
            refreshNotifitication(DEFAULT_DAYS);
        })
        actionInterpreter(item?.action);
    }
    const overlayWidth = window.innerWidth >= 768 ? '400px' : '250px';
    return (
        <Fragment>

            <Dropdown 
                overlayStyle={{ width: overlayWidth }}
                menu={{ items }}
                trigger={['click']} placement="bottomLeft"
                autoAdjustOverflow={true}
                dropdownRender={(menu) => (
                    <div className='bg-white rounded shadow-lg'>
                        <div className='dropdown-menu-header border-bottom'>
                            <div className='dropdown-header d-flex justify-content-between p-3'>
                                <h5 className='text-body mb-0 me-auto'> {messages["notificationList_title"]} </h5>
                                <AiOutlineMail size={24} color="#566a7f" />
                            </div>
                        </div>
                        <div className='list-group-item list-group-item-action dropdown-notifications-item'>
                            <div className='d-flex justify-content-center py-2'>
                                <button className='btn btn-primary me-3'  onClick={() => refreshNotifitication(DEFAULT_DAYS)}> {messages["notificationList_buttonAllNotifications"]} </button>
                                <button className='btn btn-primary'  onClick={() => refreshNotifitication(152, true)}> {messages["notificationList_buttonNotReadNotifications"]} </button>
                            </div>
                        </div>
                        <Divider style={{ margin: 1 }} />
                        {menu && React.cloneElement(menu, { style: menuStyle, className: 'scrollable-menu' })}
                        <Divider style={{ margin: 1 }} />
                        <div className='d-flex justify-content-center py-2'>
                            <Link  to="/admin/client/notifications-list/list">
                                <button className='btn btn-primary'> {messages["notificationList_buttonWatchAllNotifications"]}</button> 
                            </Link>
                        </div>
                    </div>
                )}
            >
                <Badge count={active_notifications}>
                    <a onClick={(e) => e.preventDefault()} >
                        < AiOutlineBell size={30} color="#566a7f" />
                        {/*<i className="bx bx-bell bx-sm" /> */}
                    </a>
                </Badge>
            </Dropdown>
        </Fragment>
    )
}
