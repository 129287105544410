let locales = {
    companiesEdit_titleEdit: 'Modificare Affiliato',
    companiesEdit_title: 'Aggiungi società',
    companiesEdit_name: `Nome Azienda/Partner Affiliato`,
    companiesEdit_email: 'Email',
    companiesEdit_firstName: 'Nome',
    companiesEdit_lastName: 'Cognome',
    companiesEdit_paridaIVA: 'Partita Iva/Codice Fiscale',
    companiesEdit_state: 'Provincia',
    companiesEdit_city: 'Città',
    companiesEdit_address: 'Indirizzo',
    companiesEdit_phone1: 'Telefono 1',
    companiesEdit_phone2: 'Telefono 2',
    companiesEdit_workState: 'Provincia/e dove opera prevalentemente',
    companiesEdit_workActivity: 'Attività lavorativa',
    companiesEdit_paymentMethod: 'Metodo di pagamento',
    companiesEdit_price: 'Prezzo',
    companiesEdit_notes: 'Note',
    companiesEdit_freeStartDate: 'TEST DI ATTIVAZIONE',
    companiesEdit_freeEndDate: 'Prova di scadenza',
    companiesEdit_payStartDate: 'Pagamento di attivazione',
    companiesEdit_payEndDate: 'Scadenza Pagamento',
    companiesEdit_dateContracted: 'Data Contrattualizzazione',
    companiesEdit_agentsDesigned: 'Assegna Agente/i Referente',
};

export default locales;