import { Routes, Route, Navigate } from 'react-router-dom';


import { FormsListView } from './views/list';
import EditFormView from './views/edit';


const FormsRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/management/forms/list`} />}></Route>
            <Route path='/list' element={<FormsListView />} />
            <Route path='/edit/:id' element={<EditFormView />} />
            <Route path='/edit' element={<EditFormView />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default FormsRouter;