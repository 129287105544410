//Global keys
let locales = {
};

//Merge Modules
import login from '../../views/login/lan/it';
import recoveryPassword from '../../views/recoveryPassword/lan/it';

locales = {
    ...login,
    ...recoveryPassword
};

export default locales;