import { useRef } from "react";
import { Avatar } from '../Avatar'
import { useIntl } from "react-intl";

export const InputAvatar = ({ value, onChange }) => {
    const intl = useIntl();
    const { messages } = intl;
    const fileUploadRef = useRef()

    let src = '/assets/images/avatars/blank.png';
    if (value?.url) {
        src = value.url;
    } else if (value?.file) {
        src = URL.createObjectURL(value.file);
    }

    return (
        <div className="d-block">
            <div className="mb-3">
                {/* <img src={src} className="rounded-3 img-fluid cursor-pointer" width="90" height="90" onClick={() => { fileUploadRef?.current?.click() }} /> */}
                <Avatar src={src} width="90" onClick={() => { fileUploadRef?.current?.click() }} />
                {
                    value &&
                    <div className="mt-n2">
                        <span className="badge bg-danger cursor-pointer" onClick={() => { onChange() }}>{messages["delete"]}</span>
                    </div>

                }
            </div>
            <div className="custom-file d-none">
                <input ref={fileUploadRef} className="form-control" type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
                    var reader = new FileReader();
                    reader.onload = function () {
                        onChange({ file: e.target.files[0] })
                    };

                    reader.readAsDataURL(e.target.files[0]);
                }} />
            </div>
        </div>
    )
}