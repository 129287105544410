
let locales = {
    permissions_menu: 'Permissions',
    permissions_parentId: 'Parent',
    permissions_hash: 'Hash',
};

let permissions = {
    p_see_agentyAssigned: 'Vedere gli agenti assegnati',
    p_watchDocumentsInNotes: 'Visualizzare i documenti nelle note',
    p_notes: 'Note',
    p_parametrizableTexts: 'Testi parametrizzabili',
    p_files: 'Files Aggiornati',
    p_documentsContracts: 'Documenti e Contratti',
    p_announcementNoteHistory: 'Mostra la cronologia delle note degli annunci',
    p_announcementNotes: `Note dall'annuncio`,
    p_announcemenMailWasOpenedColumn: 'Mostra la colonna "E-mail aperta"',
    p_announcementLastNoteColumns: 'Mostra la colonna "Ultima nota"',
    p_announcementStatusColumn: 'Mostra la colonna "Stato"',
    p_announcementStatusTabs: 'Mostra le tabs per stato',
    p_updateAnnouncementStatus: 'Cambia stato',
    p_companySelector: 'Selettore di aziende e agenti',
    p_reminderNoteHistory: 'Registro Promemoria',
    p_leadManagement: 'Gestione Lead',
    p_leadCenter: 'Centro Lead',
    p_notifications: 'Notifiche degli agenti',
    p_popupContacts: 'Pop-up Contatti',
    p_templates: 'Template',
    p_senders: 'Mittenti',
    p_configurations: 'Configurazion',
    p_emailArchive: 'Archivio Email',
    p_emailSent: 'Email inviate (controllo)',
    p_emailCampaign: 'Campagna Email',
    p_modContractualizationDate: 'Data Contrattualizzazione',
    p_changePasswordUser: 'Cambiare la password',
    p_autologin: 'Autologin',
    p_assignAgentToPA: 'Assegnare agenti',
    p_agent: 'Agente',
    p_affiliatedPartners: 'Partner Affiliati',
    p_marketingServices: 'Servizi Marketing',
    p_companies: 'Affiliati',
    p_usersManagement: 'Gestione degli utenti',
    p_roles: 'Roles',
    p_users: 'Users',
    p_permissions: 'Permissions'
}

//import list from '../../views/list/lan/es'
import edit from '../../modals/edit/lan/it'

locales = {
    ...locales,
    ...permissions,
    //...list,
    ...edit
}

export default locales;