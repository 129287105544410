import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Input, DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

const minutesInterval = 30;
function Promemoria(props) {
    const { onChange, value } = props;
    const { messages } = useIntl();
    const [isSave, setIsSave] = useState(false);
    const [values, setValues] = useState({
        note: "",
        reminder_hour: null,
        reminder: null,
    });

    useEffect(() => {
        if (value) {
            const currValues = { ...values };
            let isDifferent = false;
            for (const key in value) {
                if (
                    Object.prototype.hasOwnProperty.call(currValues, key) &&
                    value[key] != currValues[key]
                ) {
                    isDifferent = true;
                    break;
                }
            }
            if (isDifferent) {
                setValues({
                    note: value?.note,
                    reminder_hour: value?.emailSchedule
                        ? dayjs(value?.emailSchedule)
                        : null,
                    reminder: value?.emailSchedule
                        ? dayjs(value.emailSchedule)
                        : null,
                });
            }
        }
    }, [value]);

    const disabledMinutes = () => {
        const disabled = [];
        for (let i = 0; i < 60; i++) {
            if (i % minutesInterval !== 0) {
                disabled.push(i);
            }
        }
        return disabled;
    };

    const handleForm = (key) => (_value) => {
        setValues((currValues) => ({ ...currValues, [key]: _value }));
    };

    const onSave = () => {
        setIsSave(true);
        const valuesToSubmit = {
            note: values.note,
        };
        const date = values?.reminder;
        const hour = values?.reminder_hour.format("H");
        const min = values?.reminder_hour.format("m");
        valuesToSubmit.emailSchedule = date.hour(hour).minute(min);
        if (onChange) onChange(valuesToSubmit);
    };
    return (
        <div className="col-12">
            <div className="row g-3">
                <div className="col-12 col-md-6">
                    <label className="form-label">
                        {messages["editNoteModal_date"]}
                    </label>
                    <DatePicker
                        value={values.reminder}
                        onChange={handleForm("reminder")}
                        className="col-12"
                    />
                </div>
                {/* timer seleccionado en intervalo de 30 min */}
                <div className="col-12 col-md-6">
                    <label className="form-label">
                        {messages["editNoteModal_hour"]}
                    </label>
                    <TimePicker
                        value={values.reminder_hour}
                        onChange={handleForm("reminder_hour")}
                        className="col-12"
                        format="HH:mm"
                        minuteStep={minutesInterval}
                        disabledTime={disabledMinutes}
                    />
                </div>
                <div className="col-12">
                    <Input.TextArea
                        value={values.note}
                        onChange={(e) => handleForm("note")(e.target.value)}
                        className="form-control"
                        autoSize={{ minRows: 4 }}
                    />
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12 text-center">
                    <button
                        type="button"
                        className="btn btn-primary me-sm-3 me-1"
                        onClick={onSave}
                        disabled={!values?.note || !values?.reminder || !values?.reminder_hour}
                    >
                        {messages["save"]}
                        {isSave && <i className="bx bx-check" />}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Promemoria;
