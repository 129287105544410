//Global keys
let locales = {
    "submitDocument_title": "Cargar Documentos",
    "submitDocument_name":  "Nombre del Archivo",
    "submitDocument_note":  "Nota",
    "submitDocument_descriptionUpload":  "Click or drag file to this area to upload",
    "submitDocument_descriptionFiles":  "Soporta todo tipo de documentos de tamaño menor a 10MB",
    "submitDocument_placeholderDocumentName":  "Registre el nombre del documento",
};

export default locales;