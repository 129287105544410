//Global keys
let locales = {
    "companies_menu": "Campagna Email",
    "companies_changeStatus": "Cambia stato",
    "companies_paState": "Aggiorna/Gestisci Contatto"
};

//Merge Modules
import list from '../../views/list/lan/it'
import detail from '../../views/detail/lan/it'
import detailPayments from '../../components/payments/lan/it'
import detailNotes from '../../components/notes/lan/it'

import edit from '../../modals/edit/lan/it'
import sendEmail from '../../modals/sendEmail/lan/it'
import changePassword from '../../modals/changePassword/lan/it'
import HistoryPAStates from '../../components/historyPAStates/lan/it'
import HistoryNotesByPAStates from '../../modals/historyNotesByPAState/lan/it'
import changePAState from '../../modals/changePAState/lan/it'

locales = {
    ...locales,
    ...list,
    ...detail,
    ...detailPayments,
    ...detailNotes,
    ...edit,
    ...sendEmail,
    ...changePassword,
    ...HistoryPAStates,
    ...HistoryNotesByPAStates,
    ...changePAState
};

export default locales;