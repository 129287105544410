import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { notification, Spin, Modal, Pagination, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import cx from 'classnames'
import moment from "moment";
import {
  dashboarNotesListSearch,
  dashboardNoteDelete,
  reset,
} from "./redux/slice";
import EditNote from "../../modals/editNote";
import { dashboardEditNoteOpen } from "../../.config/redux/actions";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { P_ANNOUNCEMENT_NOTES, P_ANNOUNCEMENT_NOTES_HISTORY, P_GL_AGENTE_NOTES, P_GL_AGENTE_NOTES_HISTORY, P_GL_AREA_MANAGER_NOTES, P_GL_AREA_MANAGER_NOTES_HISTORY  } from "../../../../../../config/helpers/permissionsHash";
import { RenderAnswersForm } from "../../../../../../components/RenderAnwsersForm";

export default function (props) {
  const { companyAnnouncementId, additionalButtons = [], isUserAnnouncement = false, ownerId } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { user } = useSelector(state => state.authRedux)
  const [visibleDeleteAlert, setVisibleDeleteAlert] = useState(null);
  const { resultDelete, data, loading, error } = useSelector(
    (state) => state.dashboardNotesListRedux
  );

  const typeoFNoticationMessage = (item) => {
    if (item == 0) {
      return {
        type: "warning",
        message: messages["listNotificacionStatusPending"]
      }
    } else if (item == 2) {
      return {
        type: "error",
        message: messages["listNotificacionStatusError"]
      }
    } else {
      return {
        type: "success",
        message: messages["listNotificacionStatusSuccess"]
      }
    }
  }


  const paggination = usePaggination(dashboarNotesListSearch, 10);
  useEffect(() => {
    if (companyAnnouncementId) {
      refresh();
    }
    return () => {
      dispatch(reset());
    };
  }, [companyAnnouncementId]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
    }
    if (resultDelete) {
      notification.success({
        message: messages["deleteSucessfully"],
      });
      toggleDeleteAlert();
      refresh();
    }
  }, [error, resultDelete]);

  const C = useSecurity(P_ANNOUNCEMENT_NOTES, ['C']) || useSecurity(P_GL_AGENTE_NOTES, ['C']) || useSecurity(P_GL_AREA_MANAGER_NOTES, ['C']);
  const R = useSecurity(P_ANNOUNCEMENT_NOTES, ['R']) || useSecurity(P_GL_AGENTE_NOTES, ['R']) || useSecurity(P_GL_AREA_MANAGER_NOTES, ['R']);
  const U = useSecurity(P_ANNOUNCEMENT_NOTES, ['U']) || useSecurity(P_GL_AGENTE_NOTES, ['U']) || useSecurity(P_GL_AREA_MANAGER_NOTES, ['U']);
  const D = useSecurity(P_ANNOUNCEMENT_NOTES, ['D']) || useSecurity(P_GL_AGENTE_NOTES, ['D']) || useSecurity(P_GL_AREA_MANAGER_NOTES, ['D']);
  const historyAccess = useSecurity(P_ANNOUNCEMENT_NOTES_HISTORY) || useSecurity(P_GL_AGENTE_NOTES_HISTORY) || useSecurity(P_GL_AREA_MANAGER_NOTES_HISTORY);

  const refresh = () => {
    paggination.refresh(null, null, {
      all: historyAccess,
      companyAnnouncementId: parseInt(companyAnnouncementId),
      isUserAnnouncement
    });
  };

  const toggleDeleteAlert = (id = null) => setVisibleDeleteAlert(id);

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div className="row mb-2">
          <div className="col-6">  <h5 className="card-header">{messages["dashboardDetail_notes"]}</h5></div>
          <div className="col-6 d-flex justify-content-end">
            {additionalButtons}
            {R && <button
              className="btn btn-primary me-sm-3 me-1"
              onClick={() => {
                refresh();
              }}
            >
              {messages["refresh"]}
            </button>}
            {C && <button
              className="btn btn-primary me-sm-3 me-1"
              onClick={() => {
                dispatch(dashboardEditNoteOpen({ companyAnnouncementId, isUserAnnouncement }));
              }}
            >
              {messages["dashboardDetail_notesAdd"]}
            </button>}
          </div>
          {R && <div>
            <Pagination defaultCurrent={paggination.page} total={data?.count} pageSize={10} onChange={paggination.tablePaggination.onChange} />
          </div>}
        </div>

        {R &&
          data?.items?.map(item => {
            return (
              <div key={item.id} className={cx("card mb-3", item.isDeleted && 'bg-label-danger')}>
                <div className="card-header">
                  <div className="d-flex align-items-start">
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <h5 className="mb-1">{`${item?.created?.firstName} ${item?.created?.lastName} `} {item.companyId == ownerId || item.userId == ownerId ? '' : '/' + messages['listNotificacion_isAdmin']}</h5>
                        <div className="client-info d-flex align-items-center">
                          <h6 className="mb-0 me-1">{moment(item.createdOn).format("DD/MM/yyyy HH:mm")}</h6>
                        </div>
                      </div>
                      {item?.emailSchedule && <div className=" d-flex align-items-center">
                        <h6 className="mb-0 me-2">{`${messages["listNotificacionStatusEmail"]} ${moment(item?.emailSchedule).format("DD/MM/yyyy HH:mm")}`}</h6>
                        <Alert
                          message={typeoFNoticationMessage(item?.sendStatus).message}
                          type={typeoFNoticationMessage(item?.sendStatus).type}
                          showIcon
                        />
                      </div>}
                    </div>
                    {
                      !item?.isDeleted && (user?.id == item?.created?.id) &&
                      <div className="ms-auto">
                        <div className="dropdown zindex-2">
                          <button type="button" className="btn dropdown-toggle hide-arrow p-0" data-bs-toggle="dropdown" aria-expanded="false"><i className="bx bx-dots-vertical-rounded"></i></button>
                          <ul className="dropdown-menu dropdown-menu-end">
                            {U && <li><a className="dropdown-item" onClick={() => { dispatch(dashboardEditNoteOpen({ id: item?.id, companyAnnouncementId, isUserAnnouncement })) }}>{messages['edit']}</a></li>}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            {D && <li><a className="dropdown-item text-danger" onClick={() => { toggleDeleteAlert(item?.id) }}> {messages["delete"]}</a></li>}
                          </ul>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-right">
                    {item?.form ?
                      <div>
                        <RenderAnswersForm answers={item?.formAnswer} form={item?.form} />
                      </div> :
                      <p className="mb-0">{item.note}</p>
                    }
                    {
                      historyAccess && item.changes?.length > 0 &&
                      <div className=" ms-auto">
                        <span className="badge bg-label-warning ms-auto">{
                          messages["listNotificacion_motification"]
                        }</span>
                        <br />
                        <span className="text-body fw-normal">{moment(item.updatedOn).format("DD/MM/yyyy HH:mm")}</span>
                      </div>
                    }
                  </div>
                </div>
                {
                  historyAccess &&
                  item?.changes?.map((child, i) => {
                    return (
                      <div key={i} className={cx("card-body border-top", "bg-label-secondary")}>
                        <div className="d-flex align-items-center mb-3">
                          <div className="me-2">
                            <h5 className="mb-1">{`${child?.created?.firstName} ${child?.created?.lastName}`}{item.companyId == ownerId || item.userId == ownerId ? '' : '/' + messages['listNotificacion_isAdmin']}</h5>
                            <div className="client-info d-flex align-items-center">
                              <h6 className="mb-0 me-1">{moment(child.createdOn).format("DD/MM/yyyy HH:mm")}</h6>
                            </div>
                          </div>
                          {child?.emailSchedule && <h6 className="mb-0 me-2">{`${messages["listNotificacionStatusEmail"]} ${moment(child?.emailSchedule).format("DD/MM/yyyy HH:mm")}`}</h6>}
                        </div>
                        <p className="mb-0">{child.note}</p>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }

        <EditNote
          onClose={(ref) => {
            ref && refresh();
          }}
        />
        <Modal
          open={!!visibleDeleteAlert}
          title={messages["dashboardDetail_deleteNote"]}
          onCancel={() => toggleDeleteAlert()}
          footer={null}
          maskClosable={false}
        >
          <div className="p-3 p-md-2">
            <div className="modal-body">
              <div>
                <h6 className="card-subtitle mb-0">
                  {" "}
                  {messages["dashboardDetail_deleteNoteDescription"]}
                </h6>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-primary me-sm-3 me-1"
                  onClick={() => dispatch(dashboardNoteDelete({ id: visibleDeleteAlert }))}
                //loading={loading}
                >
                  {messages["delete"]}
                </button>
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  onClick={() => toggleDeleteAlert()}
                >
                  {messages["cancel"]}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Spin>
    </Fragment>
  );
}
