let locales = {
    companiesDetailNotes_note: 'Nota',
    companiesDetailNotes_add: 'Aggiungere Nota',
    companiesDetailNotes_title: `Nota relativa all'affiliato`,
    companiesDetailNotes_delete: 'Eliminare la nota',
    companiesDetailNotes_addDocument: 'Agregar Documento',
    companiesDetailNotes_columDocuments: 'Documentos',
    companiesDetailNotes_addFilesDescription: 'È consentito un solo documento .PNG, .JPG o .PDF. Dimensione massima di 10 megabyte ',
};

export default locales;