import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    updateResult: null,
    loadingUpdate: false,

    isUserAnnouncement: false, // For userAnnouncements

    isOpen: false,
    codeRichiesta: null,
    initialValue: null,
    id: null,

    error: null,
};

const route = 'modules/client/dashboard/components/changeStatus/redux/slice'

//Extend async Action
export const updateStatusCompanyAnnouncement = createAsyncThunk('dashboard/updateStatusCompanyAnnouncement', async (
    { id, status },
    { rejectWithValue, dispatch, getState }
) => {
    const {isUserAnnouncement} = getState().companyAnnouncementChangesRedux;
    const serviceName = isUserAnnouncement ? 'updateStatusUserAnnouncement' : 'updateStatusCompanyAnnouncement';

    try {
        let data = await clientMutation(
            `mutation ${serviceName}($id: Int, $status: Int){
                result: ${serviceName} (id: $id, status: $status)
            }`,
            {
                id,
                status,
            },
            endpoints.GRAPHQL_GENERAL
        );

        if(data?.result)
            return data.result
        else
            throw "Error: no tienes acceso"
    } catch (exc) {
        ExceptionManager(exc, route, 'updateStatusCompanyAnnouncement', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const companyAnnouncementChangesRedux = createSlice({
    name: 'dashboard/companyAnnouncementDetailRedux',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        setIsUserAnnouncement(state, {payload}) {
            state.isUserAnnouncement = payload
        },
        openChangeStatus(state, {payload}) { 
            state.isOpen = true;
            state.codeRichiesta = payload.codeRichiesta;
            state.initialValue = payload.initialValue;
            state.id = payload.id;
            state.isUserAnnouncement = payload?.isUserAnnouncement
        },
        closeChangeStatus(state) {
            state.isOpen = false;
            state.loadingUpdate = false
            state.updateResult = null
            state.error = null
            state.codeRichiesta = null;
            state.initialValue = null;
            state.id = null;
            state.isUserAnnouncement = false;
        },
        clearUpdateValues(state){
            state.loadingUpdate = false
            state.updateResult = null
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(updateStatusCompanyAnnouncement.pending, (state) => {
            state.loadingUpdate = true
            state.error = null
            state.updateResult = null
        })
        builder.addCase(updateStatusCompanyAnnouncement.fulfilled, (state, { payload }) => {
            state.loadingUpdate = false
            state.updateResult = payload
        })
        builder.addCase(updateStatusCompanyAnnouncement.rejected, (state, action) => {
            state.loadingUpdate = false
            state.error = action.error.message
        })

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companyAnnouncementChangesRedux

// Extract and export each action creator by name
export const { reset, clearUpdateValues, closeChangeStatus, openChangeStatus, setIsUserAnnouncement } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer