let locales = {
    campaignsList_sentBy: 'Enviado por',
    campaignsList_mailingDate: 'Fecha de envío',
    campaignsList_EmailSubject: 'Asunto del correo',
    campaignsList_deliveredEmail: 'Recibió correo',
    campaignsList_dateEmailRead: 'Fecha de lectura',
    campaignsList_emailCompany: 'Correo',
    campaignsList_companyName: 'Nombre empresa',
    campaignsList_date: 'Fecha de envío',
    campaignsList_emailhasError: 'Error de envío',
    campaignsList_emailWasRead: 'Lectura de correo',
    campainsList_filterAction: 'Acción de correo',
    campaignsList_role: 'Rol',
    campaignsList_sendBy: 'Enviado por',
};

export default locales;