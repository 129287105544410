//Global keys
let locales = {
    "parameters_menu": "Parametros",
};

//Merge Modules
import list from '../../views/list/lan/es'
import editCampaignFrom from '../../modals/editCampaignFrom/lan/es'
import editTemplate from '../../modals/editTemplate/lan/es'
import sendersList from '../../components/senders/lan/es'
import templateList from '../../components/templates/lan/es'

import parameterList from '../../views/list/components/parameters/lan/es'
import editParameter from '../../modals/editParameter/lan/es'
import agentConfigNotifications from '../../components/agentConfigNotifications/lan/es'
import editParametrizableText from '../../modals/editParametrizableText/.config/lan/es'
import parametrizableTexts from '../../components/parameterizableTexts/lan/es';
import configStatusLeads from '../../components/configStatusLeads/lan/es';
import editSatutsLeads from '../../modals/editSatutsLeads/lan/es';

locales = {
    ...locales,
    ...list,
    ...editCampaignFrom,
    ...editTemplate,
    ...sendersList,
    ...templateList,
    ...parameterList,
    ...editParameter,
    ...agentConfigNotifications,
    ...parametrizableTexts,
    ...editParametrizableText,
    ...configStatusLeads,
    ...editSatutsLeads,
};

export default locales;