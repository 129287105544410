import { combineReducers } from 'redux';

import themeRedux from './theme/slice';
import security from '../../core/security/.config/redux/reducers';
import modules from '../../modules/.config/redux/reducers';
import notification from '../../components/Notifications/.config/redux/slice';
import emailsList from '../../modules/admin/client/emailProgramming/views/list/redux/slice'
import textDescriptionCompanies from '../../components/TextDescriptionCompanies/.config/redux/slice'
import modalAffiliatePartnerRedux from '../../components/ModalAffiliatePartner/.config/redux/slice';

const reducersModules = {
  themeRedux,
  notification,
  emailsList,
  textDescriptionCompanies,
  modalAffiliatePartnerRedux,
  ...security,
  ...modules,
};

const reducers = combineReducers(reducersModules);

export default reducers;


