let locales = {
    companiesDetail_title: 'Detalle de Compañia',
    companiesDetail_name: 'Nombre',
    companiesDetail_email: 'Email',
    companiesDetail_firstName: 'Nombres',
    companiesDetail_lastName: 'Apellidos',
    companiesDetail_paridaIVA: 'ID Fiscal (Parida IVA)',
    companiesDetail_state: 'Estado/Provincia',
    companiesDetail_city: 'Ciudad',
    companiesDetail_address: 'Direccion',
    companiesDetail_phone1: 'Telefono 1',
    companiesDetail_phone2: 'Telefono 2',
    companiesDetail_workState: 'Estados/Provincias donde trabaja',
    companiesDetail_workActivity: 'Actividad de trabajo',
    companiesDetail_paymentMethod: 'Metodo de Pago',
    companiesDetail_price: 'Precio',
    companiesDetail_notes: 'Notas',
    companiesDetail_freeStartDate: 'Activación Prueba',
    companiesDetail_freeEndDate: 'Caducidad Prueba',
    companiesDetail_payStartDate: 'Activación Pago',
    companiesDetail_payEndDate: 'Caducidad Pago',
    companiesDetail_status: 'Estado',
    companiesDetail_changePassword: 'Cambiar contraseña',
    companiesDetail_autologin: 'Autologin',
    companiesDetail_historyPAStates: 'storia del passo',
};

export default locales;