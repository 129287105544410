import { useRef } from "react";
import { useIntl } from "react-intl";
import { Form } from "antd";
import { Dropdown } from 'bootstrap'


const Filters = (props) => {

  const ref = useRef()
  const { children, onApply } = props;
  const { messages } = useIntl();
  const [form] = Form.useForm();

  return (
    <div>
      <button ref={ref} className="btn btn-primary m-1" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
        <i className='bx bxs-filter-alt me-1'></i>
        {messages["filter"]}
      </button>
      <div className={"dropdown-menu mt-2"} style={{ width: props.width }}>
        <div className="d-flex justify-content-end">
        <button type="button" className="btn-close" onClick={() => new Dropdown(ref.current).toggle()}></button>
        </div>
        <div className="card-body">
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              new Dropdown(ref.current).toggle()
              onApply(values);
            }}
            initialValues={props?.initialValues}
            onValuesChange={props?.onValuesChange}
          >
            {children}
          </Form>

          <div className="row pt-3">
            <div className="col-12 text-center">
              <button className="btn btn-label-secondary me-1" onClick={() => {
                form.resetFields()
                setTimeout(() => {
                  form.submit()
                }, 100);
              }}>
                {messages["clear"]}
              </button>
              <button className="btn btn-primary" onClick={() => form.submit()}>
                {messages["apply"]}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

const Item = ({ name, label, children }) => {
  return (
    <div className="col-12 mb-2" key={name}>
      <Form.Item
        name={name}
        label={label ? <label className="form-label">{label}</label> : null}
      >
        {children}
      </Form.Item>
    </div>
  );
};

Filters.Item = Item;

export default Filters;
