import { useEffect } from "react";
import { Select, DatePicker } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import Filters from "../../../../../../components/Filters";
import { getCatalog } from "../../../../../.config/redux/actions";

const ListFilters = ({onApply}) => {
    const { messages } = useIntl();
    const dispatch = useDispatch()

    const { users } = useSelector(state => state.catalogRedux);
    //const { user } = useSelector(state => state.authRedux);

    useEffect(() => {
        dispatch(getCatalog({
            keys: [{ key: 'users', params: { all: true } }],
        }));
    }, [])

    return <Filters onApply={(values) => {
        let _values = {
            startDate: values?.createdOn? values.createdOn[0]: null, 
            endDate:  values?.createdOn? values.createdOn[1] : null,
            ...values
        };
        delete _values.createdOn
        onApply(_values)
    }} >
        <Filters.Item name="createdOn" label={messages["httpLog_date"]}>
            <DatePicker.RangePicker placement="bottomRight" />
        </Filters.Item>
        <Filters.Item name="userId" label={messages["httpLog_user"]}>
            <Select
                style={{ width: '100%' }}
                allowClear
                options={users}
                fieldNames={{ label: "fullName", value: "id" }}
                showSearch
                optionFilterProp="fullName"
                filterOption={(input, option) =>
                    (option?.fullName ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Filters.Item>
    </Filters>
}

export default ListFilters