import { Fragment, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { TableOptions } from "components";
import { Table, Input, notification } from "antd";

import { usePaggination, useSecurity } from "../../../../../../../../config/hooks";
import EditParameterModal from "../../../../modals/editParameter";

import { parametersListSearch, reset } from "./redux/slice";
import {
  globalParameterEditOpen,
} from "../../../../../../../../config/redux/actions";
import { P_POPUP_CONTACTS } from "../../../../../../../../config/helpers/permissionsHash";

const ParametersListTab = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { data, loading, error } = useSelector((state) => state.parametersListRedux);

  const paggination = usePaggination(parametersListSearch);

  useEffect(() => {
    paggination.refresh();
    return () => {
      dispatch(reset());
    };
  }, []);


  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error
      });
    }
  }, [error])

  const C = useSecurity(P_POPUP_CONTACTS, ['C']);
  const R = useSecurity(P_POPUP_CONTACTS, ['R']);
  const U = useSecurity(P_POPUP_CONTACTS, ['U']);
  const D = useSecurity(P_POPUP_CONTACTS, ['D']);

  const columns = useMemo(
    () => [
      {
        width: 50,
        render: (values) => {
          return (<TableOptions options={[
            {
              label: messages["edit"],
              onClick: () => dispatch(
                globalParameterEditOpen({
                  id: values.id,
                })
              ),
              show: U
            },
            {
              label: messages["delete"],
              onClick:() => {},
              show: D && false
            }
          ]}/>)
        },
      },
      {
        title: messages["parameterList_key"],
        key: "key",
        dataIndex: "key",
        width: 100,
      },
      {
        title: messages["parameterList_description"],
        key: "description",
        dataIndex: "description",
        width: 250,
      },
      {
        title: messages["parameterList_value"],
        key: "value",
        dataIndex: "value",
        //width: 150,
        render: (row) => row && row.slice(0, 300)
      },
      {
        title: messages["parameterList_type"],
        key: "parameterType",
        dataIndex: "parameterType",
        width: 100,
      }
    ],
    []
  );

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          {R && <Input
            type="text"
            className="form-control my-1 w-100"
            placeholder={messages["search"]}
            onChange={paggination.onSearchChange}
            value={paggination.searchText}
          />}
        </div>
        <div className="col-md-8 col-sm-12">
          <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
            {R && <button
              className="btn btn-primary m-1"
              onClick={() => {
                paggination.refresh();
              }}
            >
              {messages["refresh"]}
            </button>}
            {C && <button
              className="btn btn-primary m-1"
              onClick={() => {
                dispatch(globalParameterEditOpen({ id: null }));
              }}
            >
              <i data-feather="users" className="feather-sm fill-white me-1">
                {" "}
              </i>
              {messages["new"]}
            </button>}
          </div>
        </div>
      </div>
      <div className="mt-4 table-responsive">
        {C && <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={data?.items || []}
          pagination={{
            total: data?.count || 0,
            ...paggination.tablePaggination,
          }}
        />}
      </div>
      <EditParameterModal
        onClose={(refresh) => {
          refresh && paggination.refresh();
        }}
      />
    </Fragment>
  );
};

export default ParametersListTab;
