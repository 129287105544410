let locales = {
    companiesDetailNotes_note: 'Nota',
    companiesDetailNotes_add: 'Agregar Nota',
    companiesDetailNotes_title: 'Notas realacionadas al cliente',
    companiesDetailNotes_delete: 'Eliminar nota',
    companiesDetailNotes_addDocument: 'Agregar Documento',
    companiesDetailNotes_columDocuments: 'Documentos',
    companiesDetailNotes_addFilesDescription: 'Solo se permite un documento tipo .PNG, .JPG o .PDF. De maximo 10 megabites de capacidad ',
};

export default locales;