import { Fragment, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import { Modal, Table, Input, DatePicker, Select, Tabs, notification, Alert } from "antd";
import { TableOptions } from "components";
//import Icons from "../../../../../../components/Icons";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";

import { userAnnouncementsListSearch, reset } from "./redux/slice";

import Filters from "../../../../../../components/Filters";
import {
  openStatusChangesHistory,
  openChangeStatus,
} from "../../../../.config/redux/actions";
import { Link } from "react-router-dom";
import {
  ANNOUNCEMENT_STATUS,
  ANNOUNCEMENT_STATUS_OPTIONS,
} from "../../../../../../config/helpers/Utils";
import ChangeStatus from "../../components/changeStatus";
import StatusChangesHistory from "../../modals/statusChangesHistory";
import { getCatalog } from "../../../../../.config/redux/actions";
// Permissions
import { P_GL_AGENTE_EMAIL_WAS_OPENNED_COLUMN, P_GL_AGENTE_STATUS_TABS, P_GL_AGENTE_STATUS_COLUMN, P_GL_AGENTE_LASTNOTE_COLUMN, P_COMPANY_SELECTOR, P_GL_AGENTE, P_GL_AGENTE_UPDATE_ANNOUNCEMENT_STATUS } from "../../../../../../config/helpers/permissionsHash";
import { P_GL_AREA_MANAGER, P_GL_AREA_MANAGER_SELECTOR, P_GL_AREA_MANAGER_UPDATE_ANNOUNCEMENT_STATUS, P_GL_AREA_MANAGER_EMAIL_WAS_OPENNED_COLUMN, P_GL_AREA_MANAGER_STATUS_COLUMN, P_GL_AREA_MANAGER_LASTNOTE_COLUMN, P_GL_AREA_MANAGER_STATUS_TABS } from "../../../../../../config/helpers/permissionsHash";
// Permissions end
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
import { DinamicFormByStep } from "../../modals/dinamicFormByStep";
import { RenderAnswersForm } from "../../../../../../components/RenderAnwsersForm";

const permissionsByRole = {
  agents: {
    selector: P_COMPANY_SELECTOR,
    read: P_GL_AGENTE,
    changeStatus: P_GL_AGENTE_UPDATE_ANNOUNCEMENT_STATUS,
    showHasOpennedCol: P_GL_AGENTE_EMAIL_WAS_OPENNED_COLUMN,
    showStatusCol: P_GL_AGENTE_STATUS_COLUMN,
    showLastNoteCol: P_GL_AGENTE_LASTNOTE_COLUMN,
    statusTabsCol: P_GL_AGENTE_STATUS_TABS
  },
  areaManager: {
    selector: P_GL_AREA_MANAGER_SELECTOR,
    read: P_GL_AREA_MANAGER,
    changeStatus: P_GL_AREA_MANAGER_UPDATE_ANNOUNCEMENT_STATUS,
    showHasOpennedCol: P_GL_AREA_MANAGER_EMAIL_WAS_OPENNED_COLUMN,
    showStatusCol: P_GL_AREA_MANAGER_STATUS_COLUMN,
    showLastNoteCol: P_GL_AREA_MANAGER_LASTNOTE_COLUMN,
    statusTabsCol: P_GL_AREA_MANAGER_STATUS_TABS
  }
}

const UserAnnouncementsListView = (props) => {
  const { roleName = 'agents' } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const navigate = useNavigate();
  const [userListItem, setUserListItem] = useState(null)
  const paggination = usePaggination(userAnnouncementsListSearch);

  //const { user } = useSelector((state) => state.authRedux);
  const { data, loading, error, notesAuthors } = useSelector(
    (state) => state.userAnnouncementsListRedux
  );
  const catalog = useSelector(
    (state) => state.catalogRedux
  );
  const { announcementStatusList } = useSelector((state) => state.catalogRedux);


  const [userId, setUserId] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [openDescModal, setOpenDescModal] = useState({
    author: '',
    date: '',
    text: '',
    isOpen: false,
  });
  const [currFilters, setFilters] = useState();

  const pCompanySelector = useSecurity(permissionsByRole[roleName].selector);
  const R = useSecurity(permissionsByRole[roleName].read, ['R']);
  const pChangeStatus = useSecurity(permissionsByRole[roleName].changeStatus);
  const pShowHasOpennedCol = useSecurity(permissionsByRole[roleName].showHasOpennedCol);
  const pShowStatusCol = useSecurity(permissionsByRole[roleName].showStatusCol);
  const pShowLastNoteCol = useSecurity(permissionsByRole[roleName].showLastNoteCol);
  const pStatusTabsCol = useSecurity(permissionsByRole[roleName].statusTabsCol);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"]?.toString(),
        description: messages[error] || error.message,
      });
      reset('error');
    }
  }, [error])

  useEffect(() => {
    dispatch(getCatalog({ keys: ["announcementStatus"] }))
  }, [])

  useEffect(() => {
    setUserId(null)
    if (pCompanySelector) {
      dispatch(getCatalog({
        keys: [{ key: roleName, params: { showDeleted: true } }]
      })).unwrap().then(v => {
        if (v[roleName]?.length) {
          setUserId(v[roleName][0].id)
          paggination.refresh(null, null, { userId: v[roleName][0].id })
        }
      })
    } else {
      paggination.refresh(1, '', { userId: null })
    }
  }, [roleName]);

  const tabsContent = useMemo(() => {
    const count = data?.countByStatus || {};
    const labelContent = (v) => (
      <span className="d-flex align-center">
        <div className="text-wrap" style={{ width: '7rem' }}>
          {v.name}
        </div>
        {count[v.id] != null && count[v.id] != 0 && (
          <span className="d-inline-flex ps-2 align-items-center ml-2">
            <span className="badge bg-secondary rounded-circle text-white d-flex justify-content-center align-items-center" style={{ width: '25px', height: '25px' }}>
              {count[v.id]}
            </span>
          </span>
        )}
      </span>
    );
    return [
      { key: 0, label: messages["all"], children: "" },
      ...announcementStatusList.map((v) => ({
        key: v.id,
        label: labelContent(v),
        children: "",
      })),
    ]
  }, [data])

  const toggleOpenDescModal = (values = {}) => {
    setOpenDescModal({
      ...values,
      author: values?.author,
      date: values?.date,
      text: values?.text,
      isOpen: values?.isOpen,
    });
  };

  const onChangeFilters = (newFilters) => {
    setFilters({ ...currFilters, ...newFilters })
    paggination.refresh(null, null, { ...currFilters, ...newFilters });
  }

  let columns = [
    {
      width: 10,
      render: (values) => {
        return (<TableOptions options={[
          {
            show: R,
            label: messages["view"],
            onClick: () => {
              navigate(
                `/admin/client/dashboard/detail/user/${values?.announcementId}/${values?.userId}`
              );
            },
          },
          // {
          //   show: pChangeStatus,
          //   label: messages["dashboardList_changeStatus"],
          //   onClick: () => {
          //     dispatch(
          //       openChangeStatus({
          //         codeRichiesta: values.announcementId,
          //         initialValue: values.status,
          //         id: values.id,
          //         isUserAnnouncement: true
          //       })
          //     );
          //   },
          // }
        ]} />)
      },
    },
    {
      title: messages["dashboardList_announcementCode"],
      key: "announcementId",
      dataIndex: "announcementId",
      width: 150,
      render: (row, values) => (
        <Link
          target="blank"
          to={`/admin/client/dashboard/detail/user/${values?.announcementId}/${values?.userId}`}
        >
          {row}
        </Link>
      ),
    },
    {
      title: messages["dashboardList_announcementDate"],
      key: "createdOn",
      width: 200,
      //dataIndex: "createdOn",
      render: (row) =>
        row?.createdOn && moment(row.createdOn).format("DD/MM/yyyy HH:mm"),
    },
    {
      title: messages["dashboardList_announcementTitle"],
      key: "announcement",
      dataIndex: "announcement",
      render: (row) => row?.product,
    },
    {
      title: messages["dashboardList_announcementAuthor"],
      key: "author",
      width: 300,
      //dataIndex: "announcement",
      render: (values) =>
        <div className="d-flex justify-content-start align-items-center">
          <div className="w-50">
            <a>{`${values?.announcement?.name} ${values?.announcement?.surname} `} </a>
          </div>
        </div>
    },
    {
      show: pShowStatusCol,
      title: messages["dashboardList_status"],
      key: "Status",
      dataIndex: "status",
      width: 200,
      render: (value, row) => {
        let bgcolor = null;
        switch (value) {
          case 1:
            bgcolor = "bg-list-blue";
            break;
          case 2:
            bgcolor = "bg-list-yellow";
            break;
          case 3:
            bgcolor = "bg-list-orange";
            break;
          case 4:
            bgcolor = "bg-list-purple";
            break;
          case 5:
            bgcolor = "bg-list-green";
            break;
          case 6:
            bgcolor = "bg-list-red";
            break;
          default:
            bgcolor = "bg-list-green";
            break;
        }
        return (
          <div
            className={`flex-column full-cell-table text-center ${bgcolor}`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                openStatusChangesHistory({ companyAnnouncementId: row.id, isUserAnnouncement: true })
              );
            }}
          >
            <h6 className="mb-2" style={{ fontSize: 16 }}>
              {`${value ? announcementStatusList?.find(v => value == v.id)?.name : 'avvia il cambiamento di stato'}`}
            </h6>
            <h6 className="mb-0" style={{ fontSize: 12 }}>{row?.updatedStatus?.updatedBy.fullName}</h6>
            <h6 style={{ fontSize: 12 }}>{row?.updatedOn ? moment(row?.updatedStatus?.updatedOn).format("DD/MM/YYYY HH:mm") : ''}</h6>
          </div>
        );
      },
    },
    {
      show: pShowLastNoteCol,
      title: messages["dashboardList_lastNote"],
      key: "lastNote",
      //dataIndex: "lastNoteDate",
      render: (row) => (
        <div>
          <p>
            {row?.lastNote?.createdOn &&
              moment(row.lastNote?.createdOn).format("DD/MM/yyyy HH:mm")}
          </p>
          <p> di {row?.numberNotes} note totali </p>
        </div>
      ),
    },
    {
      show: pShowLastNoteCol,
      width: 300,
      title: messages["dashboardList_lastNoteDescription"],
      key: "lastNoteDescription",
      render: (values) => (
        <div>
          {values?.lastNote?.note && (
            <>
              <p className="table-text-overflow">
                {values?.lastNote?.note}
              </p>
              <a
                style={{ color: "#696cff" }}
                onClick={() => toggleOpenDescModal({
                  ...values?.lastNote,
                  author: values?.lastNote?.created.firstName + ' ' + values?.lastNote?.created.lastName,
                  date: values?.lastNote?.createdOn,
                  text: values?.lastNote?.note,
                  isOpen: true,
                })}
              >
                {messages["dashboardList_seeMore"]}
              </a>
              <div>
                {
                  `${values?.totalProgrammedNotes.total} Promemoria `
                }
                <br />
                {`${values?.totalProgrammedNotes.totalSent} Inviati ( ${values?.totalProgrammedNotes.totalSentByAgents} Agente -  ${values?.totalProgrammedNotes.totalSentByAdmins} Admins )`
                }
                <br />
                {
                  `
                   ${values?.totalProgrammedNotes.totalPending} In attessa ( ${values?.totalProgrammedNotes.totalPendingByAgents} Agente -  ${values?.totalProgrammedNotes.totalPendingByAdmins} Admins )
                  `
                }
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      show: pShowHasOpennedCol,
      key: "status",
      width: 50,
      render: (values) => {
        if (values?.totalProgrammedNotes.totalPending > 0) {
          return <Alert
            message={messages["listNotificacionStatusPending"]}
            type={"warning"}
            showIcon
          />
        } else {
          return
        }
      }
    },
  ];

  columns = columns.filter(v => v?.show === undefined || v?.show)

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          {pCompanySelector && (
            <div className="row  mb-2">
              <div className="col-md-6 col-sm-12 mb-2">
                <Select
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setCurrentTab(0);
                    setUserId(value);
                    paggination.refresh(null, null, { userId: value })
                  }}
                  size="large"
                  value={userId}
                  options={
                    catalog[roleName] &&
                    catalog[roleName]?.map((v) => ({
                      value: v.id,
                      label: <span className={v.isDeleted ? 'badge bg-label-danger text-dark' : 'badge bg-label-success text-dark'} >{v.id} - {v.fullName}</span>,
                    }))
                  }
                />
              </div>
            </div>)}
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {R && <Input
                type="text"
                className="form-control my-1 w-100"
                placeholder={messages["search"]}
                onChange={paggination.onSearchChange}
                value={paggination.searchText}
              />}
            </div>
            <div className="col-md-8 col-sm-12">
              {R && <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                <Filters
                  width={300}
                  onApply={(values) => {
                    onChangeFilters({
                      ...values,
                      userId: userId,
                      statusId: currentTab,
                    })
                  }}
                >
                  <Filters.Item
                    name="filterSendDate"
                    label={messages["campaignsList_date"]}
                  >
                    <DatePicker.RangePicker placement="bottomRight" />
                  </Filters.Item>
                </Filters>
                <button
                  className="btn btn-primary m-1"
                  onClick={() => {
                    paggination.refresh();
                  }}
                >
                  {messages["refresh"]}
                </button>
              </div>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <label className="form-label">Data dello status</label>
              <DatePicker.RangePicker allowClear style={{ width: '100%' }} onChange={(dateRange) => onChangeFilters({ updatedStatusDate: dateRange })} />
            </div>
            <div className="col-md-4 col-sm-12">
              <label className="form-label">Stato aggiornato da</label>
              <Select style={{ width: '100%' }} allowClear options={notesAuthors} fieldNames={{ label: "fullName", value: "id" }} onChange={(option) => onChangeFilters({ updateStatusBy: option })} />
            </div>
          </div>
          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>

          <div className="mt-4 table-responsive">
            {pStatusTabsCol && (
              <Tabs
                size="small"
                type="card"
                onChange={(c) => {
                  paggination.refresh(null, null, { userId, statusId: c });
                  setCurrentTab(c);
                }}
                items={tabsContent}
              />
            )}
            {R && <Table
              rowKey="id"
              loading={loading}
              columns={columns}
              dataSource={data?.items || []}
              pagination={{
                total: data?.count || 0,
                ...paggination.tablePaggination,
              }}
            />}
          </div>
        </div>
      </div>
      <StatusChangesHistory
        refresh={() => {
          paggination.refresh(null, null, {
            userId,
            statusId: currentTab,
          });
        }}

      />
      <ChangeStatus.Modal
        refresh={() => {
          paggination.refresh(null, null, {
            userId,
            statusId: currentTab,
          });
        }}
      />
      <Modal
        open={openDescModal.isOpen}
        width={800}
        onCancel={() => {
          toggleOpenDescModal({
            isOpen: false,
          });
        }}
        footer={null}
        maskClosable={false}
      >
        <div className="p-3 p-md-5">
          <h3>{openDescModal.author}</h3>
          <p>{moment(openDescModal.date).format("DD/MM/yyyy HH:mm")}</p>
          {openDescModal?.form ?
            <div>
              <RenderAnswersForm answers={openDescModal?.formAnswer} form={openDescModal?.form} />
            </div> :
            <p>{openDescModal.text}</p>
          }
        </div>
        <div className="row pt-2 ">
          <div className="col-12 text-center">
            <button
              className="btn btn-label-secondary"
              onClick={() => toggleOpenDescModal({
                isOpen: false,
              })}
            >
              {messages["close"]}
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default UserAnnouncementsListView;
