import moment from 'moment';
import {useEffect, useState} from 'react'

export const Timer = (props) => {
    const {startDate, endDate, seconds, stop = false} = props;

    const [time, setTime] = useState(0);

    useEffect(() => {
        const initialSeconds = seconds != undefined ? seconds : startDate && endDate? moment(endDate).diff(moment(startDate), 'seconds'): 0;
        setTime(initialSeconds)
    }, [startDate, endDate, seconds])
    
    useEffect(() => {
        let timer;
        if (time > 0) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        if(stop){
            clearInterval(timer)
        }
        return () => {
            clearInterval(timer);
        };
    }, [time, stop]);
    const formatTime = (_seconds) => {
        const hours = Math.floor(_seconds / 3600);
        const minutes = Math.floor((_seconds % 3600) / 60);
        const remainingSeconds = _seconds % 60;

        return `${String(hours).padStart(2, "0")} ore, ${String(minutes).padStart(
            2,
            "0"
        )} minuti, ${String(remainingSeconds).padStart(2, "0")} secondi `;
    };
  return (
    <span className="m-1 text-truncate fw-medium">{formatTime(time)}</span>
  )
}
