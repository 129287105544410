import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation } from '../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../config/constants';
import { ExceptionManager } from '../../../../../config/helpers/Logging'

import { resetALL } from '../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    result: null
};

const route = 'security/views/recoveryPassword/redux/slice'

//Extend async Action
export const recoveryPassword = createAsyncThunk('security/recoveryPassword/send', async ({ email }, { rejectWithValue }) => {
    try {

        let data = await clientMutation(
            `
                mutation recoveryPassword ($email: String) {
                    result: recoveryPassword (email: $email){
                        code
                        message
                        data
                    }
                }
            `,
            {
                email
            },
            endpoints.GRAPHQL_AUTH
        );


        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'recoveryPassword');
        return rejectWithValue(exc)
    }
})


//Slice
const recoveryPasswordLogin = createSlice({
    name: 'security/recoveryPassword',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(recoveryPassword.pending, (state) => {
            state.loading = true
        })
        builder.addCase(recoveryPassword.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(recoveryPassword.rejected, (state, action) => {
            state.loading = false
            state.result = { error: action.payload }
        })

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = recoveryPasswordLogin

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer