let locales = {
    companiesList_name: 'Nombre',
    companiesList_email: 'Email',
    companiesList_activity: 'Actividad Empresa',
    companiesList_workState: 'Provincia Trabaja',
    companiesList_state: 'Provincia',
    companiesList_freeEndDate: 'Caducidad Prueba',
    companiesList_payEndDate: 'Caudidad Pago',
    companiesList_lastEmailSend: 'Ultimo Envio Email',
    companiesList_notes: 'Notas',
    companiesList_sendEmails: 'Enviar correos',
    companiesList_sendEmailsAceptClient: 'Enviar correos de aceptación de cliente',
    companiesList_viewDeletedUsers: 'Usuarios Eliminados',
    companiesList_viewCurrentUsers: 'Usuarios Activos',
    companiesList_reactivateUser: 'Reactivar',
    companiesList_agentsAssigned: 'Agentes Asignados',
};

export default locales;