let locales = {
    companiesSendEmail_selectAnnouncements: 'Seleccionar Anuncios', 
    companiesSendEmail_newsletter: 'Newsletter', 
    companiesSendEmail_from: 'De',
    companiesSendEmail_to: 'Para', 
    companiesSendEmail_subject: 'Asunto', 
    companiesSendEmail_successMessage: 'Los correos electrónicos se enviaron correctamente a todos los destinatarios.',
    companiesSendEmail_announcement:'Anuncio',
    companiesSendEmail_ConfirmSendEmail:'Confirmación envio de Email',
    companiesSendEmail_Newsletter:'Newsletter',
    companiesSendEmail_Finish:'Finalización',
};

export default locales;