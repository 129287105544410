import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { notification, Spin } from "antd";
import {
    companiesEditOpen,
    companiesChangePasswordOpen,
    getCatalog,
    getHistoryPAStates,
    openPAStateModal,
} from '../../../../../../config/redux/actions'

import { companiesDetailGetById, reset } from './redux/slice'

import EditCompanyModal from '../../modals/edit'
import PaymentsComponent from '../../components/payments'
import NotesComponent from '../../components/notes'
import ChangePasswordModal from '../../modals/changePassword';
import { useSecurity } from '../../../../../../config/hooks';
import { P_AFFILIATED_PARTNERS, P_MARKETING_SERVICES, P_AGENTS, P_AUTOLOGIN, P_CHANGE_PASSWORD, P_ASSIGN_AGENT_TO_PA } from '../../../../../../config/helpers/permissionsHash';
import { NotificationPAState } from '../../components/notificationPAState';
import HistoryPAStates from '../../components/historyPAStates';
import { ChangePAStateModal } from '../../modals/changePAState';

const PERMISSIONS_BY_BUSINESSCODE = {
    0: P_MARKETING_SERVICES,
    1: P_AFFILIATED_PARTNERS,
    2: P_AGENTS
}

const CompaniesDetailView = () => {
    const dispatch = useDispatch();
    const { messages } = useIntl();
    let { id } = useParams();
    const navigate = useNavigate()

    const { states } = useSelector(state => state.catalogRedux)
    const { data, error, loading } = useSelector(state => state.companiesDetailRedux)

    const [currentTab, setCurrentTab] = useState(0);
    const [showFullompaniesAssigned, setShowFullompaniesAssigned] = useState(false)

    useEffect(() => {
        dispatch(companiesDetailGetById({ id }))
        dispatch(getCatalog({ keys: ['states'] }))
    }, []);

    useEffect(() => {
        if (error) {
            notification.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error,
            });
            dispatch(reset({ error: null }));
            if(error == 'UnAuthorized')
                navigate('/unauthorized')
        }
    }, [error])

    const getworkState = useMemo(() => {
        let items = data && JSON.parse(data?.workState) || []
        let workState = items.map(s => states?.find(x => x.id == s))
        return workState.map((x, index) => <div key={index} className='col-4'>
            <i className="me-2 mdi mdi-briefcase-check"></i>{`${x?.name} (${x?.abrev})`}
        </div>)

    }, [data])

    const getagentsAssigned = useMemo(() => {
        return data?.agentsAssigned.map(agent =>
            <li key={agent?.agentId} className='col-12'>
                <a  target="_blank"  href={window.location.pathname.replace('companies/detail/' + data.id, 'users/detail/' + agent?.agentId)} rel="noreferrer" >
                    {`(${agent?.agentId}) - ${agent?.agentProfile.fullName} `}
                </a>
            </li>)
    }, [data])


    const getState = useMemo(() => {
        let state = states?.find(x => x.id == data?.state)
        if (!state)
            return ('')
        return (`${state?.name} (${state?.abrev})`)
    }, [data])

    const U = useSecurity(PERMISSIONS_BY_BUSINESSCODE[data?.businessCode], ["U"]);


    const onChangeTab = (keyTab) => () => {
        setCurrentTab(keyTab);
    }


    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            <div className="row">
                {/* Todo -> cuando se separen los afiliati de companys, cambiar esta liniea para que el componente se renderice correctamente */}
                {data?.stateId ?
                    <div>
                        <NotificationPAState 
                            id={data?.stateId} 
                            companyId={data?.id} 
                            open={true}
                            buttonDispatch={
                                () => {
                                    dispatch(openPAStateModal({ companyId: data?.id }))
                                }
                            }
                            style={{ cursor: "pointer" }
                            }
                        />
                    </div>
                    : null
                }
                <div className="col-md-12 col-lg-4 col-xlg-3 mb-4">
                    <div className="card w-100">
                        <div className="user-avatar-section">
                            <div className="user-info text-center">
                                <h2 className="mb-4 mt-4">{data?.name}</h2>
                            </div>
                        </div>
                        <div className="card-body">
                            <h5 className="pb-2 border-bottom mb-4">{messages["companiesDetail_title"]}</h5>
                            <Spin spinning={loading}>
                            <div className='row'>
                                <ul className="list-unstyled">
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_name"]}:</span>
                                        <span>{data?.name}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_paridaIVA"]}:</span>
                                        <span>{data?.paridaIVA}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_email"]}:</span>
                                        <span>{data?.email}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_firstName"]}:</span>
                                        <span>{data?.user?.firstName}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_lastName"]}:</span>
                                        <span>{data?.user?.lastName}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_state"]}:</span>
                                        <span>{getState}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_city"]}:</span>
                                        <span>{data?.city}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_address"]}:</span>
                                        <span>{data?.address}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_phone1"]}:</span>
                                        <span>{data?.phone1}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_phone2"]}:</span>
                                        <span>{data?.phone2}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_workActivity"]}:</span>
                                        <span>{data?.workActivity}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_workState"]}:</span>
                                        <h6>
                                            <div className='row'>
                                                {getworkState}
                                            </div>
                                        </h6>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_freeStartDate"]}:</span>
                                        <span>{data?.freeStartDate && moment(data.freeStartDate).format('DD/MM/yyyy')}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_freeEndDate"]}:</span>
                                        <span>{data?.freeEndDate && moment(data.freeEndDate).format('DD/MM/yyyy')}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{messages["companiesDetail_payEndDate"]}:</span>
                                        <span>{data?.lastPayment?.payEndDate && moment(data?.lastPayment?.payEndDate).format('DD/MM/yyyy')}</span>
                                    </li>
                                    {(useSecurity(P_ASSIGN_AGENT_TO_PA) && data?.businessCode == 1) &&
                                        <li className="mb-3">
                                            <span className="fw-bold me-2" >
                                                {messages["companiesEdit_agentsDesigned"]}:
                                            </span>
                                            <ul>
                                                {getagentsAssigned}
                                            </ul>
                                        </li>}
                                    {data?.businessCode == 2 &&
                                        <li>
                                            <span className="fw-bold me-2" >
                                                {messages['sidebar_list2']}:
                                            </span>
                                            <ul>
                                                {data?.user?.companiesAssigned && data.user.companiesAssigned.slice(0, showFullompaniesAssigned? 100 : 5).map(v => <li key={v.id}>
                                                    <a target="_blank"  href={window.location.pathname.replace(data.id, v.id)} rel="noreferrer">{v.name}</a>
                                                </li>)}
                                            </ul>
                                            {data?.user?.companiesAssigned?.length > 5 && <a onClick={() => setShowFullompaniesAssigned(!showFullompaniesAssigned)}>{showFullompaniesAssigned ? '^' : '...' + messages['seeMore']}</a>}
                                        </li>
                                    }
                                </ul>
                            </div>
                            </Spin>
                            <div className="d-flex justify-content-center pt-3 flex-wrap">
                                {U &&
                                    <button className="btn btn-primary m-2"
                                        onClick={() => {
                                            dispatch(companiesEditOpen({ id, businessCode: data.businessCode }))
                                        }}
                                    >
                                        <i data-feather="users" className="feather-sm fill-white me-1"> </i>
                                        {messages["edit"]}
                                    </button>}
                                {useSecurity(P_CHANGE_PASSWORD) &&
                                    <button className="btn btn-primary m-2"
                                        onClick={() => {
                                            dispatch(companiesChangePasswordOpen({ email: data?.email }))
                                        }}
                                    >
                                        <i data-feather="users" className="feather-sm fill-white me-1" />
                                        {messages["companiesDetail_changePassword"]}
                                    </button>}
                                {useSecurity(P_AUTOLOGIN) && <a className="btn btn-primary m-2" href={`/login/${data?.email}/${data?.user?.hashp}`} target="blank" >
                                    <i data-feather="users" className="feather-sm fill-white me-1" />
                                    {messages["companiesDetail_autologin"]}
                                </a>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-8 col-xlg-9">
                    <ul className="nav nav-pills flex-column flex-md-row mb-3">
                        <li className="nav-item mr-2">
                            <a className={`nav-link ${currentTab === 0 ? 'active' : ''}`}
                                onClick={onChangeTab(0)}
                            >
                                <i data-feather="users" className="feather-sm fill-white me-1"> </i>
                                {"Notes"}
                            </a>
                        </li>
                        {data?.businessCode == 0 &&
                            <li className="nav-item">
                                <a className={`nav-link ${currentTab === 1 ? 'active' : ''}`}
                                    onClick={onChangeTab(1)}
                                >
                                    <i data-feather="users" className="feather-sm fill-white me-1"> </i>
                                    {"Payments"}
                                </a>
                            </li>
                        }
                        {data?.stateId &&
                            <li className="nav-item">
                                <a className={`nav-link ${currentTab === 2 ? 'active' : ''}`}
                                    onClick={onChangeTab(2)}
                                >
                                    <i data-feather="users" className="feather-sm fill-white me-1"> </i>
                                    {messages["companiesDetail_historyPAStates"]}
                                </a>
                            </li>
                        }
                    </ul>
                    <div className="card min-vh-75">
                        <div style={{ display: currentTab === 0 ? 'contents' : 'none' }}>
                            <NotesComponent companyId={id} />
                        </div>
                        {data?.businessCode == 0 &&
                            <div style={{ display: currentTab === 1 ? 'contents' : 'none' }}>
                                <PaymentsComponent companyId={id} />
                            </div>}
                        {data?.stateId &&
                            <div style={{ display: currentTab === 2 ? 'contents' : 'none' }}>
                                <HistoryPAStates companyId={id} />
                            </div>}
                    </div>
                </div>
            </div>
            <ChangePAStateModal 
                onClose={(refresh) => {
                    if (refresh) {
                        dispatch(companiesDetailGetById({ id }))
                        dispatch(getHistoryPAStates({ id: data?.stateId, companyId: data?.id }))
                    }
                }} 
            />

            <EditCompanyModal onClose={(refresh) => { refresh && dispatch(companiesDetailGetById({ id })) }} />
            <ChangePasswordModal />
        </div>
    )
}

export default CompaniesDetailView;