import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    notesAuthors: [],
    error: null
};

const route = 'modules/client/dashboard/views/listAgent/slice'

//Extend async Action
export const userAnnouncementsListSearch = createAsyncThunk('dashboard/userAnnouncementsListSearch', async (
    { searchText, limit, offset, filterSendDate, userId, statusId, updatedStatusDate, updateStatusBy },
    { rejectWithValue, dispatch }
) => {
    const filterDate = {
        filterSendDateIni: filterSendDate ? filterSendDate[0].format('YYYY-MM-DD') : null,
        filterSendDateEnd: filterSendDate ? filterSendDate[1].format('YYYY-MM-DD') : null,
        updatedStatusDateIni: updatedStatusDate ? updatedStatusDate[0].format('YYYY-MM-DD') : null,
        updatedStatusDateEnd: updatedStatusDate ? updatedStatusDate[1].format('YYYY-MM-DD') : null,
    }

    try {
        let data = await clientQuery(
            `query searchUserAnnouncements($openSearch: String, $limit: Int, $offset: Int, $filters: InputFiltersUserAnnouncement){
                result: searchUserAnnouncements(openSearch: $openSearch, limit: $limit, offset: $offset, filters: $filters) {
                    items {
                        status
                        updatedStatus {
                            updatedBy { 
                                fullName
                            }
                            updatedOn
                        }
                        updatedOn
                        totalProgrammedNotes {
                            total
                            totalSent
                            totalSentByAdmins
                            totalSentByAgents
                            totalPending
                            totalPendingByAdmins
                            totalPendingByAgents
                        }
                        id
                        userId
                        announcementId
                        announcement {
                            id
                            product
                            name
                            surname
                        }
                        numberNotes
                        lastNote {
                            note
                            createdOn
                            created {
                                firstName
                                lastName
                            }
                            formAnswer {
                                id
                                answers
                            }
                            form{
                                questions{
                                    id
                                    question
                                    questionType
                                    typeData
                                }
                            }
                        }
                        hasOpen
                        contactCount
                        viewCount
                        campaignDetailId
                        status
                        campaignDetail {
                            campaignId
                            from
                            to
                            companyName
                            sendDate
                            readDate
                            campaign {
                                from
                                subject
                                status
                            }
                        }
                        createdOn
                        createdBy
                    }
                    countByStatus
                    count
                    notesAuthors {
                        id
                        fullName
                    }
                }
            }`,
            {
                openSearch: searchText || '',
                limit: limit || 10,
                offset: offset || 0,
                filters: {
                    userId: Number(userId),
                    statusId,
                    updateStatusBy,
                    ...filterDate,
                }
            },
            endpoints.GRAPHQL_GENERAL
        );

        return {...data?.result, countByStatus: JSON.parse(data.result.countByStatus)} || []

    } catch (exc) {
        ExceptionManager(exc, route, 'userAnnouncementsListSearch', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const userAnnouncementsListRedux = createSlice({
    name: 'dashboard/userAnnouncements',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(userAnnouncementsListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(userAnnouncementsListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
            state.notesAuthors = payload.notesAuthors
        })
        builder.addCase(userAnnouncementsListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = userAnnouncementsListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer