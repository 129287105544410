import { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import { Input, Table, notification, Tabs } from "antd";

import { TableOptions } from "components";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import {
    companiesEditOpen,
    getCatalog,
    companiesSendEmailOpen,
    //getChangePAStateOpen,
    //openPAStateModal,
} from "../../../../../../config/redux/actions";
import {
    activateCompany,
    companiesListSearch,
    deleteCompany,
    reset,
} from "./redux/slice";

import EditCompanyModal from "../../modals/edit";
import SendEmailModal from "../../modals/sendEmail";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";

import {
    P_AFFILIATED_PARTNERS,
    P_MARKETING_SERVICES,
    P_VIEW_DELETED_USERS_IN_PARNERT_AFILIATY,
} from "../../../../../../config/helpers/permissionsHash";
import CompaniesFilters from "../../components/filters";
//import { ChangePAStateModal } from "../../modals/changePAState";

const PERMISSIONS_BY_BUSINESSCODE = {
    0: P_MARKETING_SERVICES,
    1: P_AFFILIATED_PARTNERS,
}


const CompaniesListView = (props) => {
    const { businessCode } = props;
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const navigate = useNavigate();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [justDeleted, setJustDeleted] = useState(false); // Just for seeing deleted items
    const [filters, setFilters] = useState({ stateId: undefined });
    const [showFullompaniesAssigned, setShowFullompaniesAssigned] = useState({ id: false })

    const paggination = usePaggination(companiesListSearch);

    const { data, data0, data1, result, loading, error } = useSelector((state) => state.companiesListRedux);
    const { pAStates, states } = useSelector((state) => state.catalogRedux);

    useEffect(() => {
        if (result) {
            paggination.refresh(null, null, { businessCode, justDeleted });
        }

        return () => {
            dispatch(reset());
        };
    }, [result]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error,
            });
            dispatch(reset({ error: null }));
        }
    }, [error])


    useEffect(() => {
        setFilters({ stateId: undefined })
        paggination.refresh(null, null, { businessCode, justDeleted, filters: { stateId: undefined } });
        dispatch(getCatalog({ keys: ["states", "pAStates"] }));
    }, [businessCode]);


    const _data = useMemo(() => {
        switch (businessCode) {
            case 0:
                return data0;
            case 1:
                return data1;
            default:
                return data;
        }
    }, [businessCode, data1, data, data0])

    // START--------------------------------------------------Permissions---------------------------------------------
    const C = useSecurity(PERMISSIONS_BY_BUSINESSCODE[businessCode], ["C"]);
    const R = useSecurity(PERMISSIONS_BY_BUSINESSCODE[businessCode], ["R"]);
    const U = useSecurity(PERMISSIONS_BY_BUSINESSCODE[businessCode], ["U"]);
    const D = useSecurity(PERMISSIONS_BY_BUSINESSCODE[businessCode], ["D"]);
    const ReadDeletedUsers = useSecurity(P_VIEW_DELETED_USERS_IN_PARNERT_AFILIATY);
    // END--------------------------------------------------Permissions---------------------------------------------
    const findPAStateinfo = (idSate) => {
        let currentStep = { name: " ", color: 'white' };
        if (pAStates.length > 0 && idSate) {
            currentStep = pAStates?.find(pAState => pAState?.id == idSate);
        }
        return currentStep
    }

    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            <div className="card card-body">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <Input
                            className="form-control my-1 w-100"
                            placeholder={messages["search"]}
                            onChange={paggination.onSearchChange}
                            value={paggination.searchText}
                        />
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                            {selectedRowKeys.length == 0 ? (
                                <>
                                    {R && (
                                        <>
                                            <CompaniesFilters
                                                businessCode={businessCode}
                                                onApply={filtersValues => {
                                                    let _filters = { ...filters, ...filtersValues }
                                                    setFilters(_filters)
                                                    paggination.refresh(null, null, { filters: _filters })
                                                }
                                                }
                                            />

                                            <button
                                                className="btn btn-primary m-1"
                                                onClick={() => {
                                                    paggination.refresh(
                                                        null,
                                                        null,
                                                        {
                                                            businessCode,
                                                            justDeleted,
                                                        }
                                                    );
                                                }}
                                            >
                                                {messages["refresh"]}
                                            </button>
                                        </>
                                    )}
                                    {ReadDeletedUsers && (
                                        <button
                                            className="btn btn-secondary m-1"
                                            onClick={() => {
                                                setJustDeleted(
                                                    !justDeleted
                                                );
                                                paggination.refresh(
                                                    null,
                                                    null,
                                                    {
                                                        businessCode,
                                                        justDeleted:
                                                            !justDeleted,
                                                        filters: { stateId: undefined }
                                                    }
                                                );
                                            }}
                                        >
                                            {justDeleted
                                                ? messages[
                                                "companiesList_viewCurrentUsers"
                                                ]
                                                : messages[
                                                "companiesList_viewDeletedUsers"
                                                ]}
                                        </button>
                                    )}
                                    {C && (
                                        <button
                                            className="btn btn-primary m-1"
                                            onClick={() => {
                                                setJustDeleted();
                                                dispatch(
                                                    companiesEditOpen({
                                                        id: null,
                                                        businessCode,
                                                    })
                                                );
                                            }}
                                        >
                                            <i
                                                data-feather="users"
                                                className="feather-sm fill-white me-1"
                                            />
                                            {messages["new"]}
                                        </button>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="fw-bold me-5">
                                        <span
                                            className="me-2"
                                            data-kt-user-table-select="selected_count"
                                        />
                                        {selectedRowKeys.length} {" "}
                                        {messages["selected"]}
                                    </div>
                                    <button
                                        disabled={selectedRowKeys.length === 0}
                                        className="btn btn-primary m-1"
                                        onClick={() =>
                                            dispatch(
                                                companiesSendEmailOpen({
                                                    customers:
                                                        _data.items.filter((x) =>
                                                            selectedRowKeys.find(
                                                                (y) => y == x.id
                                                            )
                                                        ),
                                                })
                                            )
                                        }
                                    >
                                        {messages["companiesList_sendEmails"]}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-12 mt-4 mb-4">
                        <TextDescriptionCompanies />
                    </div>
                    {R && (
                        <div className="mt-4 table-responsive">
                            {businessCode == 1 && (<div>
                                <Tabs

                                    activeKey={filters.stateId}
                                    onChange={(c) => {
                                        paggination.refresh(null, null, { filters: { ...filters, stateId: Number(c) || undefined } });
                                        setFilters({ ...filters, stateId: Number(c), })
                                    }}
                                    size="small"
                                    type="card"
                                    items={[
                                        { key: 0, label: messages["all"], children: "" },
                                        ...pAStates.map((v) => {
                                            let count = null;
                                            if (_data?.PAStatesCount) {
                                                count = _data?.PAStatesCount[v.id] || 0;

                                            }

                                            const labelContent = (
                                
                                                    <span className="d-flex align-center">
                                                <div className="text-wrap" style={{ width: '7rem'}}>
                                                {v.name}
                                                </div>
                                                    {(v.id !== 5 && v.id !== 9 && v.id !== 11) && count !== null && count !== 0 && (
                                                        <span className="d-inline-flex ps-2 align-items-center ml-2">
                                                            <span className="badge rounded-circle text-white d-flex justify-content-center align-items-center" style={{ width: '25px', height: '25px', backgroundColor: v.color }}>
                                                                {count}
                                                            </span>
                                                        </span>
                                                    )}
                                                </span>
                                            );
                                            return {
                                                key: v.id,
                                                label: labelContent,
                                            };
                                        })
                                    ]}


                                />
                            </div>

                            )}
                            <Table
                                rowKey="id"
                                loading={loading}
                                scroll={{ x: 1900 }}
                                
                                columns={[
                                    {
                                        width: 90,
                                        className: "min-vh-40",
                                        render: (values) => {
                                            return (
                                                <TableOptions
                                                    options={[
                                                        {
                                                            label: messages[
                                                                "view"
                                                            ],
                                                            onClick: () =>
                                                                navigate(
                                                                    `/admin/management/companies/detail/${values.id}`
                                                                ),
                                                            show: true,
                                                        },
                                                        {
                                                            label: messages[
                                                                "edit"
                                                            ],
                                                            onClick: () =>
                                                                dispatch(
                                                                    companiesEditOpen(
                                                                        {
                                                                            id: values.id,
                                                                            businessCode,
                                                                        }
                                                                    )
                                                                ),
                                                            show: U,
                                                        },
                                                        {
                                                            label: messages[
                                                                "delete"
                                                            ],
                                                            onClick: () =>
                                                                dispatch(
                                                                    deleteCompany(
                                                                        {
                                                                            id: values.id,
                                                                        }
                                                                    )
                                                                ),
                                                            show: !values?.isDeleted && D,
                                                        },
                                                        {
                                                            label: messages[
                                                                "companiesList_reactivateUser"
                                                            ],
                                                            onClick: () =>
                                                                dispatch(
                                                                    activateCompany(
                                                                        {
                                                                            id: values.id,
                                                                        }
                                                                    )
                                                                ),
                                                            show: values?.isDeleted && C,
                                                        },
                                                    ]}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: messages[
                                            "companiesList_lastEmailSend"
                                        ],
                                        key: "freeEndDate",
                                        width: 150,
                                        render: (row) =>
                                            row.freeEndDate &&
                                            moment(row.freeEndDate).format(
                                                "DD/MM/yyyy"
                                            ),
                                    },
                                    {
                                        title: messages[
                                            "companiesList_workState"
                                        ],
                                        key: "workState",
                                        width: 250,
                                        render: (row) => {
                                            let items = JSON.parse(
                                                row.workState
                                            );
                                            let workState = items.map((s) =>
                                                states?.find((x) => x.id == s)
                                            );
                                            workState = workState.map(
                                                (x) =>
                                                    `${x?.name} (${x?.abrev})`
                                            );
                                            return workState.join(", ");
                                        },
                                    },
                                    {
                                        title: messages[
                                            "companiesList_activity"
                                        ],
                                        key: "workActivity",
                                        dataIndex: "workActivity",
                                        width: 300,
                                    },
                                    {
                                        title: messages["companiesList_name"],
                                        key: "name",
                                        // dataIndex: "name",
                                        width: 300,
                                        render: (values) => {

                                            if (values?.agentsAssigned.length > 0) {
                                                return <div>
                                                    <h5>{values?.name}</h5>
                                                    <h6>{messages["companiesList_agentsAssigned"]}</h6>
                                                    <span>
                                                        {values?.agentsAssigned.map((agent) => `${agent.agentProfile.firstName} ${agent.agentProfile.lastName}`).join(' | ')}
                                                    </span>
                                                </div>
                                            } else {
                                                return (
                                                    <h5>{values?.name}</h5>
                                                )
                                            }
                                        }
                                    },
                                    businessCode == 2 ? {
                                        title: messages['sidebar_list2'],
                                        key: "companiesAssigned",
                                        width: 300,
                                        align: 'center',
                                        render: (values) => {
                                            let companiesAssigned = values?.user?.companiesAssigned;
                                            const id = values.id;
                                            return (
                                                <span>
                                                    <ul className="text-start">
                                                        {companiesAssigned && companiesAssigned.slice(0, showFullompaniesAssigned[id] ? 100 : 5).map(v => <li className="fw-medium me-2" key={v.id}>
                                                            <a href={`/admin/management/companies/detail/${v.id}`}onClick={() => navigate(`/admin/management/companies/detail/${v.id}`)}>{v.name}</a>
                                                        </li>)}
                                                        {companiesAssigned?.length > 5 && <a onClick={() => setShowFullompaniesAssigned({ ...showFullompaniesAssigned, [id]: !showFullompaniesAssigned[id] })}>{showFullompaniesAssigned[id] ? '^' : '...'}</a>}
                                                    </ul>
                                                </span>
                                            )
                                        }
                                    } : {},
                                    businessCode == 1 ? {
                                        title: 'Stato',
                                        key: "stateId",
                                        // dataIndex: "stateId",
                                        width: 300,
                                        align: 'center',
                                        render: (values) => {
                                            const info = findPAStateinfo(values.stateId)
                                            return (
                                                <div
                                                    className={`full-cell-table text-center p-0`}
                                                    style={values.stateId && { backgroundColor: `${info.color}`, cursor: "pointer" }}
                                                // onClick={() => {
                                                //     values.stateId ? dispatch(openPAStateModal({ companyId: values?.id })) : null
                                                // }}
                                                >
                                                    <h5 className="mb-0 p-2" style={{ color: "white" }}>
                                                        {info?.name} {values?.stateId == 9 && <i className='bx bxs-user-check fs-2' />}
                                                    </h5>
                                                </div>
                                            )
                                        }
                                    } : {},
                                    {
                                        title: messages["companiesList_email"],
                                        key: "email",
                                        dataIndex: "email",
                                        width: 300,
                                    },
                                    {
                                        title: messages["companiesList_state"],
                                        key: "state",
                                        width: 250,
                                        render: (row) => {
                                            let state = states?.find(
                                                (x) => x.id == row.state
                                            );
                                            if (!state) return "";
                                            return `${state?.name} (${state?.abrev})`;
                                        },
                                    },
                                    {
                                        title: messages[
                                            "companiesList_freeEndDate"
                                        ],
                                        key: "freeEndDate",
                                        width: 150,
                                        render: (row) =>
                                            row.freeEndDate &&
                                            moment(row.freeEndDate).format(
                                                "DD/MM/yyyy"
                                            ),
                                    },
                                    {
                                        title: messages[
                                            "companiesList_payEndDate"
                                        ],
                                        key: "lastPayment.payEndDate",
                                        width: 150,
                                        render: (row) =>
                                            row.lastPayment?.payEndDate &&
                                            moment(
                                                row.lastPayment.payEndDate
                                            ).format("DD/MM/yyyy"),
                                    },
                                ]}
                                //pagination={{ position: [top, bottom] }}
                                dataSource={_data?.items || []}
                                rowClassName={(record) => {
                                    if (businessCode != 0) {
                                        return null
                                    }
                                    switch (record.status) {
                                        case 1: // • Pagado – Verde
                                            return "bg-list-green";
                                        case 2: // • Terminada el pago – Rojo
                                            return "bg-list-red";
                                        case 3: // En prueba gratis - Verde claro
                                            return "bg-green-ligth";
                                        case 4: // • Terminado la prueba – Naranja
                                            return "bg-list-orange";
                                        case 5:
                                            return "bg-list-brown";
                                        case 99:
                                            return "bg-list-red-dark";
                                    }
                                }}
                                rowSelection={{
                                    selectedRowKeys,
                                    onChange: (newSelectedRowKeys) => {
                                        setSelectedRowKeys(newSelectedRowKeys);
                                    },
                                }}
                                pagination={{
                                    total: _data?.count || 0,
                                    ...paggination.tablePaggination,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {/* <ChangePAStateModal
                onClose={(refresh) => {
                    if (refresh) {
                        paggination.refresh(null, null, {
                            businessCode,
                            justDeleted,
                        });
                        // dispatch(companiesDetailGetById({ id }))
                        // dispatch(getHistoryPAStates({ id: data?.stateId, companyId: data?.id }))
                    }
                    // refresh &&
                    //     paggination.refresh(null, null, {
                    //         businessCode,
                    //         justDeleted,
                    //     });
                }}
            /> */}
            <SendEmailModal />
            <EditCompanyModal
                onClose={(refresh) => {
                    refresh &&
                        paggination.refresh(null, null, {
                            businessCode,
                            justDeleted,
                        });
                }}
            />
        </div>
    );
};

export default CompaniesListView;
