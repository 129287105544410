import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "../../../../config/helpers/Logging";
import { endpoints } from "../../../../config/constants";
import { resetALL } from "../../../../config/redux/actions";
import { clientQuery } from "../../../../config/helpers/GraphQLApi";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
  };

const route = "components/TextDescriptionCompanies/.config/redux/slice'";

export const getTextDescriptionCompanies = createAsyncThunk('TextDescriptionCompanies/getTextDescriptionCompanies', async(payload,{rejectWithValue, dispatch}) => {
    try {
        let data = await clientQuery(
            `query getAllTextsByPage{
              TextDescriptionCompanies: getAllTextsByPage{
                      id
                      key
                      value
                      description
                      parameterType
                }
            }`,
            null,
            endpoints.GRAPHQL_GENERAL
        );
        
        return data?.TextDescriptionCompanies || null

    } catch (exc) {
      ExceptionManager(exc, route, 'getTextDescriptionCompanies', dispatch);
      return rejectWithValue(exc)
    }
})


export const descriptionCompaniesSlice = createSlice({
    name: 'textsDescriptions',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        
        builder.addCase(resetALL, () => INIT_STATE)

        // getNotifications
        builder.addCase(getTextDescriptionCompanies.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(getTextDescriptionCompanies.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
        })
        builder.addCase(getTextDescriptionCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = descriptionCompaniesSlice;

// Action creators are generated for each case reducer function
export const { reset } = actions;

export default reducer;