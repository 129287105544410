
import { Fragment, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Input, Table, notification } from "antd";

import { TextDescriptionCompanies } from "components";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { P_HTTP_LOGS } from "../../../../../../config/helpers/permissionsHash";
import { searchForms, reset, deleteForm } from "./redux/slice";
//import { openEditForm } from "../../.config/redux/actions";
import { TableOptions } from "../../../../../../components";
import { useNavigate } from "react-router-dom";

export const FormsListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const navigate = useNavigate();

  const { data, loading, error, result } = useSelector((state) => state.formsRedux);
  //const { user } = useSelector((state) => state.authRedux);

  const paggination = usePaggination(searchForms);

  const [currFilters, setCurrFilters] = useState(null);

  useEffect(() => {
    paggination.refresh();
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (result) {
      notification.success({
        message: messages["deleteSucessfully"].toString(),
      });
      paggination.refresh()
      reset("result");
    }
    if (error) {
      notification.error({
        message: messages["saveError"]?.toString(),
        description: messages[error] || error.message,
      });
      reset("error");
    }
  }, [error, result]);

  const R = useSecurity(P_HTTP_LOGS, ["R"]);
  const C = useSecurity(P_HTTP_LOGS, ["C"]);
  const D = useSecurity(P_HTTP_LOGS, ["D"]);
  const U = useSecurity(P_HTTP_LOGS, ["U"]);
  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {R && (
                <Input
                  type="text"
                  className="form-control my-1 w-100"
                  placeholder={messages["search"]}
                  onChange={paggination.onSearchChange}
                  value={paggination.searchText}
                />
              )}
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                {R && (
                  <>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => {
                        paggination.refresh();
                      }}
                    >
                      {messages["refresh"]}
                    </button>
                  </>
                )}
                {C && <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                        navigate(`/admin/management/forms/edit`)
                    }
                >
                    <i className="ki-duotone ki-plus fs-2"></i>
                    {messages["add"]?.toString()}
                </button>}
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>
          <div className="mt-4 table-responsive">
            {R && (
              <Table
                rowKey="id"
                loading={loading}
                showSorterTooltip={false}
                columns={[
                  {
                    width: 90,
                    className: "min-vh-40",
                    render: (values) => {
                        return (
                            <TableOptions
                                options={[
                                      {
                                        label: messages["edit"],
                                        show: U,
                                        onClick: () => {
                                          navigate(`/admin/management/forms/edit/${values?.id}`)
                                        }
                                    },
                                    {
                                        label: messages[
                                            "delete"
                                        ],
                                        onClick: () =>
                                            dispatch(
                                                deleteForm(
                                                    {
                                                        id: values.id,
                                                    }
                                                )
                                            ),
                                        show: !values?.isDeleted && D,
                                    },
                                ]}
                            />
                        );
                    },
                  },
                  {
                    title: messages["forms_title"],
                    key: "title",
                    dataIndex: "title",
                    width: 300
                  },
                  {
                    title: messages["forms_description"],
                    key: "description",
                    dataIndex: "description",
                    width: 300,
                  },
                  {
                    title: messages["dashboardList_status"],
                    key: "status",
                    dataIndex: "status",
                    width: 300,
                    render: (values) => values?.name || ''
                  },
                ]}
                dataSource={data?.items || []}
                pagination={{
                  total: data?.count || 0,
                  ...paggination.tablePaggination,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* <EditForm onRefresh={() => paggination.refresh()} /> */}
    </Fragment>
  );
};
