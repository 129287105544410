import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { resetALL } from "../../../../../../../config/redux/actions";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { endpoints } from "../../../../../../../config/constants";


// State initial
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
}

const route = 'modules/admin/managment/parameters/components/agentConfigNotification/redux/slice'

// extend async Action
export const configurationNotificationsList = createAsyncThunk('parameter/configurationNotificationsList', async (payload, { rejectWithValue, dispatch }) => {

    try {
        let data = await clientQuery(
            `query getAnnouncementNoteNotificacionParams{
                    result: getAnnouncementNoteNotificacionParams{
                            noteCreate_admin 
                            noteUpdate_admin                            
                            noteDelete_admin
                            noteCreate_agenty
                            noteUpdate_agenty
                            noteDelete_agenty
                    }
                }`,
                null,
            endpoints.GRAPHQL_GENERAL
        )
        
        return data?.result
    } catch (exc) {
        ExceptionManager(exc, route, 'configurationNotificationsList', dispatch)
        return rejectWithValue(exc)
    }
})

// extend async Action
export const configurationNotificationsChange = createAsyncThunk('parameter/configurationNotificationsChange', async ({ param, value }, { rejectWithValue, dispatch }) => {
    try {
        let data = await clientMutation(
            `mutation updateAnnouncementNoteNotificacionParam($param: String!, $value: Boolean!){
                result: updateAnnouncementNoteNotificacionParam(param: $param, value: $value){
                    noteCreate_admin
                    noteUpdate_admin
                    noteDelete_admin
                    noteCreate_agenty
                    noteUpdate_agenty
                    noteDelete_agenty
                }
            }`,
            {
                param,
                value
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.result
    } catch (exc) {
        ExceptionManager(exc, route, 'configurationNotificationsChange', dispatch);
        return rejectWithValue(exc)
    }
})


//slice

const configListNotifications = createSlice({
    name: 'configListNotifications',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        builder.addCase(configurationNotificationsList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(configurationNotificationsList.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(configurationNotificationsList.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        // change status of notifications config
        builder.addCase(configurationNotificationsChange.pending, (state) => {
            state.loading = true
        })
        builder.addCase(configurationNotificationsChange.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(configurationNotificationsChange.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    }
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = configListNotifications;

// Extract and export each action creator by name
export const { reset } = actions;

// Export the reducer, either as a default or named export
export default reducer;
