let locales = {
    companiesEdit_titleEdit: 'Editar Compañia',
    companiesEdit_title: 'Agregar Compañia',
    companiesEdit_name: 'Nombre Compañia',
    companiesEdit_email: 'Email',
    companiesEdit_firstName: 'Nombres',
    companiesEdit_lastName: 'Apellidos',
    companiesEdit_paridaIVA: 'ID Fiscal (Parida IVA)',
    companiesEdit_state: 'Estado/Provincia',
    companiesEdit_city: 'Ciudad',
    companiesEdit_address: 'Direccion',
    companiesEdit_phone1: 'Telefono 1',
    companiesEdit_phone2: 'Telefono 2',
    companiesEdit_workState: 'Estados/Provincias donde trabaja',
    companiesEdit_workActivity: 'Actividad de trabajo',
    companiesEdit_paymentMethod: 'Metodo de Pago',
    companiesEdit_price: 'Precio',
    companiesEdit_notes: 'Notas',
    companiesEdit_freeStartDate: 'Activación Prueba',
    companiesEdit_freeEndDate: 'Caducidad Prueba',
    companiesEdit_payStartDate: 'Activación Pago',
    companiesEdit_payEndDate: 'Caducidad Pago',
    companiesEdit_dateContracted: 'Fecha de contractualización',
    companiesEdit_agentsDesigned: 'Asignar persona(s) de contacto',
};

export default locales;