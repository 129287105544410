import {
  Fragment,
  useEffect,
  useState,
  useMemo
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { TableOptions } from "components";
import { Table, Input, DatePicker, Select, notification, } from "antd";

import { usePaggination, useSecurity } from "../../../../../../config/hooks";

import { campaignListSearch, reset } from "./redux/slice";
import Filters from "../../../../../../components/Filters";
import DetailCampaignModal from "../../modals/detail";
import {
  campaignDetailOpen, getCatalog,
} from "../../../../../../config/redux/actions";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
import { P_EMAIL_SENT, P_FILTER_PER_ROLE_USER_CAMPAIGNS } from "../../../../../../config/helpers/permissionsHash";

const CampaignsListView = () => {
  
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { data, loading, error } = useSelector(
    (state) => state.campaignsListRedux
  );

  const { user } = useSelector(state => state.authRedux)
  const { companies, roles, users, agents } = useSelector((state) => state.catalogRedux);
  const {
    loading: sendEmailAgainLoading,
  } = useSelector((state) => state.campaignDetailRedux);

  const [roleId, setRoleId] = useState();

  const paggination = usePaggination(campaignListSearch);

  const pfc = useSecurity(P_FILTER_PER_ROLE_USER_CAMPAIGNS)

  useEffect(() => {
    paggination.refresh();
    let keys =  ["companies"]
    if(pfc){
      keys = ["companies", "roles", { key: 'users', params: {all: true}}, "agents"]
    }
    dispatch(getCatalog({ keys }))

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if(error){
        notification.error({
            message: messages["saveError"]?.toString(),
            description: messages[error] || error.message,
        });
        reset('error');
    }
  }, [error])

  const _users = useMemo(() => {
    if(roleId){
      return users.filter(u => u.roles.find(r => r.id == roleId))
    }
    return users;
  }, [roleId, users])

  const companiesFilters = useMemo(() => {
    return companies.filter(c => c.businessCode != 2)
  }, [companies, agents])

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input
                type="text"
                className="form-control my-1 w-100"
                placeholder={messages["search"]}
                onChange={paggination.onSearchChange}
                value={paggination.searchText}
              />
            </div>
            <div className="col-md-8 col-sm-12">
              {useSecurity(P_EMAIL_SENT, ['R']) && <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                <Filters
                  width={300}
                  onApply={(values) => {
                    paggination.refresh(null, null, values);
                  }}
                  initialValues={{ filterError: 99, filterRead: 99, filterAction: 99 }}
                  onValuesChange={(values) => {
                    if(Object.hasOwnProperty.call(values, 'roleId'))
                      setRoleId(values.roleId)
                  }}
                >
                  <Filters.Item
                    name="filterSendDate"
                    label={messages["campaignsList_date"]}
                  >
                    <DatePicker.RangePicker placement="bottomRight" />
                  </Filters.Item>
                  <Filters.Item
                    name="filterError"
                    label={messages["campaignsList_emailhasError"]}
                  >
                    <Select
                      //defaultValue={99}
                      options={[
                        { value: 99, label: messages["all"] },
                        { value: true, label: messages["yes"] },
                        { value: false, label: messages["no"] },
                      ]}
                    />
                  </Filters.Item>
                  <Filters.Item
                    name="filterRead"
                    label={messages["campaignsList_emailWasRead"]}
                  >
                    <Select
                      //defaultValue={99}
                      options={[
                        { value: 99, label: messages["all"] },
                        { value: true, label: messages["yes"] },
                        { value: false, label: messages["no"] },
                      ]}
                    />
                  </Filters.Item>
                  {!!companiesFilters.length && <Filters.Item
                    name="companyId"
                    label={messages["campaignsList_companyName"]}
                  >
                    <Select
                      //defaultValue={99}
                      allowClear
                      options={companiesFilters}
                      showSearch
                      fieldNames={{ label: "name", value: "id" }}
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Filters.Item>}
                  {!!agents.length && <Filters.Item
                      name="userId"
                      label={"Agente"}
                    >
                      <Select
                        //defaultValue={99}
                        allowClear
                        options={agents}
                        showSearch
                        fieldNames={{ label: "fullName", value: "id" }}
                        optionFilterProp="fullName"
                        filterOption={(input, option) =>
                            (option?.fullName ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                  </Filters.Item>}
                  {pfc && 
                  <>
                    <Filters.Item
                      name="roleId"
                      label={messages["campaignsList_role"]}
                    >
                      <Select
                        allowClear
                        options={roles}
                        showSearch
                        fieldNames={{ label: "name", value: "id" }}
                        optionFilterProp="name"
                        filterOption={(input, option) =>
                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Filters.Item>
                    <Filters.Item
                      name="createdBy"
                      label={messages["campaignsList_sendBy"]}
                    >
                      <Select
                        allowClear
                        options={_users}
                        showSearch
                        fieldNames={{ label: "fullName", value: "id" }}
                        optionFilterProp="fullName"
                        filterOption={(input, option) =>
                            (option?.fullName ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Filters.Item>
                  </>}
                  {/* <Filters.Item
                    name="filterAction"
                    label={messages["campainsList_filterAction"]}
                  >
                    <Select
                      //defaultValue={99}
                      options={[
                        { value: 99, label: messages["all"] },
                        { value: true, label: messages["yes"] },
                        { value: false, label: messages["no"] },
                      ]}
                    />
                  </Filters.Item> */}
                </Filters>
                <button
                  className="btn btn-primary m-1"
                  onClick={() => {
                    paggination.refresh();
                  }}
                >
                  {messages["refresh"]}
                </button>
              </div>}
            </div>
          </div>
          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>
          <div className="mt-4 table-responsive">
            {useSecurity(P_EMAIL_SENT, ['R'])  && <Table
              rowKey="id"
              loading={loading || sendEmailAgainLoading}
              columns={[
                {
                  width: 50,
                  render: (values) => {
                    return (<TableOptions options={[
                        {
                          label: messages["view"],
                          show:  user?.isAdmin,
                          onClick: () => dispatch(campaignDetailOpen({ id: values.id }))
                        },
                        {
                          label: messages["resend"],
                          show: false,
                          //onClick: () => dispatch(sendEmailAgain({ id: values.id }))
                        }
                      ]} />)
                  },
                },
                {
                  title: messages["campaignsList_companyName"],
                  key: "companyName",
                  //dataIndex: "companyName",
                  width: 150,
                  render: (values) => {
                    return values?.userName || values?.companyName 
                  }
                },
                {
                  title: messages["campaignsList_emailCompany"],
                  key: "to",
                  dataIndex: "to",
                  width: 150,
                },
                {
                  title: messages["campaignsList_sentBy"],
                  key: "from",
                  dataIndex: "from",
                  width: 150,
                  render: (from, values) => {
                    let by = values?.createdByUser;
                    let role = "";
                    if(by?.roles?.length){
                        role = by?.roles[0].name;
                    }
                    if(by?.isAdmin) role = messages['listNotificacion_isAdmin'];
                    return <div>
                      <p>{from}</p>
                      <h6>{role}:{by?.fullName}</h6>
                    </div>
                  }
                },
                {
                  title: messages["campaignsList_EmailSubject"],
                  key: "subject",
                  dataIndex: "subject",
                  width: 150,
                },
                {
                  title: messages["campaignsList_mailingDate"],
                  key: "sendDate",
                  width: 150,
                  render: (row) =>
                    row.sendDate &&
                    moment(row.sendDate).format("DD/MM/yyyy, h:mm:ss a"),
                },
                {
                  title: messages["campaignsList_dateEmailRead"],
                  key: "deliveryDate",
                  width: 150,
                  render: (row) =>
                    row.readDate &&
                    moment(row.readDate).format("DD/MM/yyyy, h:mm:ss a"),
                },
                {
                  title: messages["campaignsList_emailhasError"],
                  key: "error",
                  dataIndex: "error",
                  width: 150
                },
              ]}
              dataSource={data?.items || []}
              pagination={{
                total: data?.count || 0,
                ...paggination.tablePaggination,
              }}
            />}
          </div>
        </div>
      </div>
      <DetailCampaignModal
        onClose={(refresh) => {
          refresh && paggination.refresh();
        }}
      />
    </Fragment>
  );
};

export default CampaignsListView;
