let locales = {
    companiesDetail_title: 'Dati Utente',
    companiesDetail_name: 'Nome',
    companiesDetail_email: 'Email',
    companiesDetail_firstName: 'Nome',
    companiesDetail_lastName: 'Cognome',
    companiesDetail_paridaIVA: 'P. Iva o Codice Fiscale',
    companiesDetail_state: 'Provincia',
    companiesDetail_city: 'Città',
    companiesDetail_address: 'Indirizzo',
    companiesDetail_phone1: 'Telefono 1',
    companiesDetail_phone2: 'Telefono 2',
    companiesDetail_workState: 'Provincia/e dove svolge l’attività',
    companiesDetail_workActivity: 'Attività Lavorativa',
    companiesDetail_paymentMethod: 'Metodo de Pago',
    companiesDetail_price: 'Precio',
    companiesDetail_notes: 'Notas',
    companiesDetail_freeStartDate: 'Data Attivazione della Prova',
    companiesDetail_freeEndDate: 'Scadenza Prova',
    companiesDetail_payStartDate: 'Activación Pago',
    companiesDetail_payEndDate: 'Scadenza Pagamento',
    companiesDetail_status: 'Estado',
    companiesDetailPayments_Files: 'File',
    companiesDetail_changePassword: 'Cambia password',
    companiesDetail_autologin: 'Autologin',
    companiesDetail_historyPAStates: 'Storia del passo',
};

export default locales;