//Global keys
let locales = {
    "client_menu": "Centro Lead",
    "agenty_menu": "Agenty",
};

//Merge Modules
import dashboard from '../../dashboard/.config/lan/it'
import listNotes from '../../dashboard/components/listNotes/lan/it'

//Merge Email Programming
import emailProgramming from '../../emailProgramming/.config/lan/it'
import notificationsList from '../../notificationsList/.config/lan/it'
import sidebar from '../../../../../core/layouts/sidebar/lan/it'

// Merge Documents And Contracts
import documentsAndContracts from '../../documentsAndContracts/.config/lan/it'

import announcementContracts from '../../announcementsContractManage/.config/lan/it'

locales = {
    ...locales,
    ...dashboard,
    ...listNotes,
    ...emailProgramming,
    ...notificationsList,
    ...sidebar,
    ...documentsAndContracts,
    ...announcementContracts
};

export default locales;
