import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { Modal, Form, Input, Select, DatePicker, notification } from 'antd'
import { Loading } from '../../../../../../components/Loading'
import { companiesEditClose, companiesEditSave, reset } from './redux/slice'
import { companiesDetailGetById } from '../../.config/redux/actions';
import { getCatalog } from '../../../../../.config/redux/actions';
import { P_ASSIGN_AGENT_TO_PA, P_CONTRACTUALIZATION_DATE } from '../../../../../../config/helpers/permissionsHash';
import { useSecurity } from '../../../../../../config/hooks/tools';
import { emailRegex } from '../../../../../../config/helpers/validations';
import { NotificationPAState } from '../../components/notificationPAState';

const EditCompanyModal = ({ onClose }) => {

    const dispatch = useDispatch()
    const { messages } = useIntl()

    const [form] = Form.useForm()
    const [id, setId] = useState()
    const { loading, isOpen, data, result, error, businessCode } = useSelector(state => state.companiesEdittRedux)

    const { states, companies: dataCompanies, agents, loadingAgents } = useSelector(state => state.catalogRedux)

    const assignAgentToPA = useSecurity(P_ASSIGN_AGENT_TO_PA);

    useEffect(() => {
        //if( assignAgentToPA && businessCode == 1){
        dispatch(getCatalog({
            keys: [{ key: 'companies' }, { key: 'agents' }, { key: 'users' }]
        }));

        //}
    }, [/* businessCode */])

    //Verificacion de cargar de usuario
    useEffect(() => {
        if (data && !id) {
            //se carga la info al form solo una vez
            setId(data.id);
            //se especifica la carga de los valores al form
            form.setFieldsValue({
                ...data,
                username: data.user?.username,
                firstName: data.user?.firstName,
                lastName: data.user?.lastName,
                agentsAssigned: data.agentsAssigned ? data.agentsAssigned?.map(v => v.agentId) : [],
                workState: data.workState ? JSON.parse(data.workState) : [],
                freeRange: [data.freeStartDate && dayjs(data.freeStartDate), data.freeEndDate && dayjs(data.freeEndDate)]
            })
        }

        if (result) {
            if (id) {
                dispatch(companiesDetailGetById({ id }))
            }
            dispatch(reset({ result: null }))
            notification.success({ message: messages["saveSuccessfully"] })//, description: messages["saveSuccessfully"] })
            closeModal(true)
        }

        if (error) {
            dispatch(reset({ error: null }))
            notification.error({ message: messages["saveError"], description: messages[error] || error })
        }
    }, [data, result, error])

    //se valida y se limpia todo lo que pueda causar errores
    const closeModal = useCallback((refresh) => {
        setId()
        form.resetFields()
        dispatch(companiesEditClose())
        onClose && onClose(refresh)
    })

    return (
        <div>
            <Modal
                open={isOpen}
                width={800}
                onCancel={() => { closeModal() }}
                footer={null}
                maskClosable={false}
            >
                
                <div className="p-3 p-md-5">

                    <div className="text-center mb-4">
                        <h3>
                            {id ? messages["companiesEdit_titleEdit"] : messages["companiesEdit_title"]}
                        </h3>
                        {/* <p>pequeña descripcion opcional</p> */}
                    </div>

                    {data?.stateId ?
                        <NotificationPAState id={data?.stateId} /> : null
                    }

                    <Loading loading={loading}>
                        <div className='card-body'>
                            <Form
                                form={form}
                                layout='vertical'
                                onFinish={(values) => {
                                    dispatch(companiesEditSave({
                                        id: id,
                                        ...values,
                                        state: values.state,
                                        workState: values.workState || [],
                                        agentsAssigned: values.agentsAssigned,
                                        businessCode: businessCode,
                                        freeStartDate: values.freeRange?.length > 0 && values.freeRange[0]?.format(),
                                        freeEndDate: values.freeRange?.length > 1 && values.freeRange[1]?.format()
                                    }))
                                }}
                            >
                                <div className="row g-3">
                                    <div className="col-12">
                                        <Form.Item
                                            name="name"
                                            label={<label className="form-label">{messages["companiesEdit_name"]}</label>}
                                            rules={[{ required: true, message: messages['required_field'] }]}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_name"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="paridaIVA"
                                            rules={[
                                                { required: true, message: messages['required_field'] },
                                                {
                                                    validator: async (_, value) => {
                                                        if (value && value != data?.paridaIVA) {
                                                            const duplicated = dataCompanies.find(v => v.paridaIVA === value.trim());
                                                            if (duplicated)
                                                                throw new Error(messages['data_already_exist'])
                                                        }
                                                    }
                                                }
                                            ]}
                                            label={<label className="form-label">{messages["companiesEdit_paridaIVA"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_paridaIVA"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="email"
                                            label={<label className="form-label">{messages["companiesEdit_email"]}</label>}
                                            rules={[
                                                { required: true, message: messages['required_field'] },
                                                { pattern: emailRegex, message: messages['invalid_email'] },
                                                {
                                                    validator: async (_, value) => {
                                                        if (emailRegex.test(value) && value != data?.email) {
                                                            const duplicated = dataCompanies.find(v => v.email === value.trim());
                                                            if (duplicated)
                                                                throw new Error(messages['data_already_exist'])
                                                        }
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_email"]} disabled={id} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="firstName"
                                            rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_firstName"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_firstName"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="lastName"
                                            rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_lastName"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_lastName"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="state"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_state"]}</label>}
                                        >
                                            <Select
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={states}
                                                fieldNames={{ label: "name", value: "id" }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="city"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_city"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_city"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Item
                                            name="address"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_address"]}</label>}
                                        >
                                            <Input.TextArea
                                                maxLength={100}
                                                rows={2}
                                                className="form-control" placeholder={messages["companiesEdit_address"]}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="phone1"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_phone1"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_phone1"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="phone2"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_phone2"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_phone2"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="workState"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_workState"]}</label>}
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                optionFilterProp="name"
                                                filterOption={(input, option) =>
                                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={states}
                                                fieldNames={{ label: "name", value: "id" }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">

                                        <Form.Item
                                            name="workActivity"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_workActivity"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_workActivity"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {useSecurity(P_CONTRACTUALIZATION_DATE) &&
                                            <Form.Item
                                                name="freeRange"
                                                //rules={[{ required: true, message: messages['required_field'] }]}
                                                label={<label className="form-label"> {messages["companiesEdit_dateContracted"]}</label>}
                                            >
                                                {/* <DatePicker className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" /> */}
                                                <DatePicker.RangePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {assignAgentToPA && businessCode == 1 &&
                                            <Form.Item
                                                name="agentsAssigned"
                                                label={<label className="form-label">{messages["companiesEdit_agentsDesigned"]}</label>}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    optionFilterProp="fullname"
                                                    filterOption={(input, option) =>
                                                        (option?.fullname ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    loading={loadingAgents}
                                                    options={agents}
                                                    fieldNames={{ label: "fullName", value: "id" }}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Loading>

                    <div className='row pt-5'>
                        <div className="col-12 text-center">
                            <button disabled={loading} className="btn btn-primary me-sm-3 me-1" onClick={() => form.submit()} >
                                {messages["save"]}
                            </button>
                            <button
                                disabled={loading}
                                className="btn btn-label-secondary"
                                onClick={() => closeModal()}>
                                {messages["cancel"]}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default EditCompanyModal;