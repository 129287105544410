let locales = {
    recovery_password_title: 'Recuperar Contraseña',
    recovery_password_email: 'Email',
    recovery_password_back: 'Retonar al login',
    recovery_password_recovery_title: 'Recuperacion Password',
    recovery_password_recovery_msg: 'Recuperacion Exitosa, se ha enviado un correo con el nuevo password',
    recovery_password_error: 'No se ha podido realizar la recuperacion, valide la informacion y vuelva a intentarlo',
    recovery_password_send: ' Invia la password ➜'
};

export default locales;