
import {
	logout
} from '../../config/redux/actions';

export const GetErrorMessage = (exc, intl) => {
	let message = ''
	if (intl?.messages) {
		message = intl.messages[exc.error] || ''
		if (exc?.errorData?.errors?.length > 0) {
			const firstError = exc.errorData.errors[0]
			if (firstError.extensions?.code)
				message = intl.formatMessage({ id: firstError.extensions?.code }, firstError.extensions?.values)
			else
				message = intl.messages[exc.errorData.errors[0].extensions?.code]
					|| intl.messages[exc.errorData.errors[0].message] || message
		}
	}

	if (message.length == 0) message = exc.error
	return message
}

export const ExceptionManager = function (exc, module, method, dispatch, show = false) {
	try {
		console.log(`Error => Module: ${module} \nMethod: ${method} \nexc:`, exc);

		if (exc.innerError?.networkError?.statusCode == 500) {
			dispatch && dispatch(logout({}))
		}

		if (exc.errorData && exc.errorData.errors) {
			/*exc.errorData.errors.forEach(error => {
				if (error.errorInfo && error.errorInfo.code) {
					NotificationManager.error(
						ErrorHandlerLogin(error.message),
						"Error",
						3000,
						null,
						null,
						'error',
						error.errorInfo?.code
					);
				}
				else if (show) {
					NotificationManager.error(
						ErrorHandlerLogin(error.message),
						"Error",
						3000,
						null,
						null,
						'error'
					);
				}
			});*/
		}
	}
	catch (exc2) {
		console.log("ExceptionManager error: ", exc2)
	}
};

export const LogManager = (...log) => {
	console.log('Log => ', ...log);
};
