

const uris = [
    {
        id: 1,
        name: "La tua Home",
        uri:  "/admin/main",
    },
    {
        id: 2,
        name: "Gestione degli utenti: Users",
        uri: "/admin/management/users/list",
    },
    {
        id: 3,
        name: "Gestione degli utenti: Roles",
        uri: "/admin/management/roles/list",
    },
    {
        id: 4,
        name: "Gestione degli utenti: Permissions",
        uri: "/admin/management/permissions/list",
    },
    {
        id: 5,
        name: "Affiliati 🤝: Servizi Marketing",
        uri: "/admin/management/companies/list",
    },
    {
        id: 6,
        name: "Affiliati 🤝: Partner Affiliati",
        uri: "/admin/management/companies/list2",
    },
    {
        id: 7,
        name: "Affiliati 🤝: Agente",
        uri: "/admin/management/companies/list3",
    },
    {
        id: 8,
        name: "Campagna Email: Email Inviate (controllo)",
        uri: "/admin/management/campaigns/list",
    },
    {
        id: 9,
        name: "Archivio Email (controllo): Configurazioni",
        uri: "/admin/management/parameters/list",
    },
    {
        id: 10,
        name: "Centro Lead: Gestione Lead",
        uri: "/admin/client/dashboard/list",
    },
    {
        id: 11,
        name: "Centro Lead: Registro Promemoria ✉",
        uri: "/admin/client/email-programming/list",
    },
    {
        id: 12,
        name: "Documenti e Contratti: Files Aggiornati",
        uri: "/admin/client/documents-contracts/list",
    },
    {
        id: 13,
        name: "Notifiche",
        uri: "/admin/client/notifications-list/list",
    },
    {
        id: 14,
        name: "Flusso di lavoro",
        uri: "/admin/management/parameters/workflow/list",
    },
]

export default uris;