import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clientQuery,
  clientMutation,
} from "../../../../../../../config/helpers/GraphQLApi";

import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  error: null,
};

const route = "modules/management/campaigns/modals/detail/slice";

//Extend async Action
export const campaignDetailOpen = createAsyncThunk(
  "campaigns/campaignDetailOpen",
  async ({ id }, { rejectWithValue, dispatch }) => {
    if (!id) {
      return { data: null };
    }
    try {
      let data = await clientQuery(
        `query getCampaignDetailById($id: Int!){
                campaign: getCampaignDetailById(id: $id) {
                  id
                  to
                  sendDate
                  readDate
                  from
                  subject
                  body
                  status
                }
            }`,
        {
          id: id,
        },
        endpoints.GRAPHQL_GENERAL
      );

      return { data: data.campaign };
    } catch (exc) {
      ExceptionManager(exc, route, "campaignDetailOpen", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const sendEmailAgain = createAsyncThunk(
  "campaigns/sendEmailAgain",
  async ({ id }, { rejectWithValue, dispatch }) => {
    if (!id) {
      return null;
    }
    try {
      let result = await clientMutation(
        `mutation sendEmailAgain($campaignId: Int!){
                message: sendEmailAgain(campaignId: $campaignId)
            }`,
        {
          campaignId: id,
        },
        endpoints.GRAPHQL_GENERAL
      );

      return result.message;
    } catch (exc) {
      ExceptionManager(exc, route, "campaignResend", dispatch);
      return rejectWithValue(exc);
    }
  }
);

//Slice
const campaignsDetailRedux = createSlice({
  name: "campaign/detail",
  initialState: INIT_STATE,
  reducers: {
    campaignDetailClose(state, action) {
      return INIT_STATE;
    },
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    builder.addCase(campaignDetailOpen.pending, (state) => {
      state.loading = true;
      state.isOpen = true;
    });
    builder.addCase(campaignDetailOpen.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    });
    builder.addCase(campaignDetailOpen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(sendEmailAgain.pending, (state) => {
      state.loading = true;
      state.result = null;
      state.error = null;
    });
    builder.addCase(sendEmailAgain.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(sendEmailAgain.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = campaignsDetailRedux;

// Extract and export each action creator by name
export const { campaignDetailClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
