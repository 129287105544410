import SunEditor from 'suneditor-react';
import 'suneditor/src/assets/css/suneditor.css'
import 'suneditor/src/assets/css/suneditor-contents.css'

export const RichTextEditor = ({ value, onChange, height }) => {

    return (
        <SunEditor
            height={height}
            setContents={value}
            onChange={onChange}
            setOptions={{
                buttonList: [
                    ["font", "fontSize", "formatBlock"],
                    [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                    ],
                    ["align", "horizontalRule", "list", "table"],
                    ["fontColor", "hiliteColor"],
                    ["outdent", "indent"],
                    ["undo", "redo"],
                    ["removeFormat"],
                    ["outdent", "indent"],
                    ["link", "image"],
                    ["preview", "print"],
                    ["fullScreen", "showBlocks", "codeView"],
                ],
            }}
        />
    )
}