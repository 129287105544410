import userListRedux from '../../views/list/redux/slice'
import usersEditRedux from '../../modals/editUser/redux/slice'
import userDetailRedux from '../../views/detail/redux/slice'
import userNotesRedux from '../../components/notes/redux/slice'
//export redux objects
export default {
	userListRedux,
	usersEditRedux,
	userDetailRedux,
	userNotesRedux
};
