import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { resetALL } from '../actions'

//STATE INITIAL
const INIT_STATE = {
    lang: 'it',
    loading: false,
    collapseMenu: false,
    expadnded: false
};

const route = 'config/redux/theme'

const themeRedux = createSlice({
    name: 'theme/menu',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        changeLang(state, action) {
            state.lang = action.payload
        },
        changeMenu(state, action) {
            //state.currentClient = action.payload
            return { ...state, ...action.payload }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = themeRedux

// Extract and export each action creator by name
export const { reset, changeMenu, changeLang } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer