import { useEffect, useMemo } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl";

import Filters from "../../../../../../components/Filters";
import { getCatalog } from "../../../../../.config/redux/actions";

const CompaniesFilters = ({ onApply, businessCode }) => {
    const { messages } = useIntl();
    const dispatch = useDispatch()

    const { states, companies, users } = useSelector(state => state.catalogRedux);
    const { user } = useSelector(state => state.authRedux);
    useEffect(() => {
        dispatch(getCatalog({
            keys: [{ key: 'companies' }, { key: 'states' }, { key: 'users', params: {all: true} }],
        }));
    }, [])

    const agents = useMemo(() => {
        return users.filter(v => v.roles.find(r => r.id == 5))
    }, [companies]);

    const leaders_names = useMemo(() => {
        if(!companies.length || !users.length){
            return []
        }
        let _companies = []
        
        for (let index = 0; index < companies.length; index++) {
            const element = companies[index];
            if(element.businessCode == businessCode){
                const user = users.find(u => u.id == element.userId);
                if(user){
                    _companies.push(user)
                }
            }
        }
        return _companies
    }, [companies, users, businessCode]);

    return <Filters onApply={onApply} >
        <Filters.Item name="userId" label={`${messages['companiesEdit_firstName']} / ${messages['companiesEdit_lastName']}`}>
            <Select
                style={{
                    width: '100%',
                }}
                allowClear
                options={leaders_names}
                showSearch
                fieldNames={{ label: "fullName", value: "id" }}
                optionFilterProp="fullName"
                filterOption={(input, option) =>
                    (option?.fullName ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Filters.Item>
        <Filters.Item name="state" label={messages['companiesList_state']}>
            <Select
                style={{
                    width: '100%',
                }}
                allowClear
                options={states}
                fieldNames={{ label: "name", value: "id" }}
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Filters.Item>
        <Filters.Item name="workState" label={messages['companiesList_workState']}>
            <Select
                mode="multiple"
                style={{
                    width: '100%',
                }}
                allowClear
                options={states}
                fieldNames={{ label: "name", value: "id" }}
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Filters.Item>
        {businessCode == 1 && user.isAdmin && <Filters.Item name="agentAssigned" label={messages['companiesList_agentsAssigned']}>
            <Select
                style={{
                    width: '100%',
                }}
                allowClear
                options={agents}
                fieldNames={{ label: "fullName", value: "id" }}
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Filters.Item>}
    </Filters>
}

export default CompaniesFilters;