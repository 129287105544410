//Global keys
let locales = {
    "campaigns_menu": "Campañas",
};

//Merge Modules
import list from '../../views/list/lan/es'
import campaignDetail from '../../modals/detail/lan/es'
// import list from '../../views/list/lan/es'
// import detail from '../../views/detail/lan/es'
// import detailPayments from '../../components/payments/lan/es'
// import detailNotes from '../../components/notes/lan/es'

// import edit from '../../modals/edit/lan/es'
// import sendEmail from '../../modals/sendEmail/lan/es'

locales = {
    ...locales,
    ...list,
    ...campaignDetail,
    // ...detail,
    // ...detailPayments,
    // ...detailNotes,
    // ...edit,
    // ...sendEmail,
};

export default locales;