import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    show: false,
    data: null,
    result: null,
    resultError: null,
    availableStatus: []
};

const route = 'routes/admin/configurations/forms/modals/edit/redux/slice'

//Extend async Action
export const getFormById = createAsyncThunk('forms/edit/getFormById', async ({ id }, { rejectWithValue }) => {
    try {
        if (!id) return null;

        let data = await clientQuery(
            `
            query getFormById($id: Int!) {
                result: getFormById(id: $id) {
                    id
                    title
                    description
                    statusId
                    status {
                        id
                        name
                        parentId
                    }
                    questions {
                        id
                        formId
                        question
                        helpText
                        props
                        questionType
                        typeData
                        position
                        catalog {
                            id
                            items {
                                id 
                                catalogId
                                value
                            }
                            nameCatalog
                        }
                    }
                }

                availableStatus: availableAnnouncementStatus {
                    id
                    name
                    parentId
                }
            }
            `,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL,
        );

        return data

    } catch (exc) {
        ExceptionManager(exc, route, 'openEditForm');
        return rejectWithValue(exc)
    }
})

export const saveEditForm = createAsyncThunk('forms/edit/save', async ({ id, title, description, questions, statusId }, { rejectWithValue }) => {
    try {

        let data

        if (id) {
            data = await clientMutation(
                `mutation updateForm($id: Int!, $title: String, $description: String, $questions: [QuestionInput], $statusId: Int) {
                    result: updateForm(id: $id, title: $title, description: $description, questions: $questions, statusId: $statusId) {
                        id
                    }
                }`,
                {
                    id: Number(id),
                    title,
                    description,
                    statusId,
                    questions: questions.map(v => {
                        const question = { ...v }
                        delete question.__typename
                        delete question.catalog
                        return question
                    })
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }
        else {
            data = await clientMutation(
                `mutation createForm($title: String, $description: String, $questions: [QuestionInput]!, $statusId: Int!) {
                    result: createForm(title: $title, description: $description, questions: $questions, statusId: $statusId) {
                        id
                    }
                }`,
                {
                    title,
                    description,
                    questions: questions.map(v => {
                        const question = { ...v }
                        delete question.__typename
                        delete question.catalog
                        return question
                    }),
                    statusId
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'saveEditForm');
        return rejectWithValue(exc)
    }
})

export const getAvailableStatus = createAsyncThunk('forms/edit/getAvailableStatus', async (payload, { rejectWithValue }) => {
    try {
        let data = await clientQuery(
            `
            query availableAnnouncementStatus {
                result: availableAnnouncementStatus {
                    id
                    name
                    parentId
                }
            }
            `,
            {
            },
            endpoints.GRAPHQL_GENERAL,
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'getAvailableStatus');
        return rejectWithValue(exc)
    }
})
//Slice
const formsEditRedux = createSlice({
    name: 'forms/edit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        hide(state) {
            state.show = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(getFormById.pending, (state) => {
            state.loading = true
            state.show = true
        })
        builder.addCase(getFormById.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload?.result
            state.availableStatus = payload?.availableStatus
        })
        builder.addCase(getFormById.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })

        builder.addCase(saveEditForm.pending, (state) => {
            state.loading = true
        })
        builder.addCase(saveEditForm.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(saveEditForm.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })
        //getAvailableStatus
        builder.addCase(getAvailableStatus.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAvailableStatus.fulfilled, (state, { payload }) => {
            state.loading = false
            state.availableStatus = payload
        })
        builder.addCase(getAvailableStatus.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = formsEditRedux

// Extract and export each action creator by name
export const { reset, hide } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer