import { useCallback, useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { emailRegex } from '../../../../../../config/helpers/validations';
import { InputAvatar } from "components";
import { Modal, Form, Input, notification, Skeleton, DatePicker, Select } from "antd";
import { hide, saveEditUser, reset } from "./redux/slice";
import { getCatalog } from "../../../../../.config/redux/actions";
import dayjs from 'dayjs';
import { useSecurity } from "../../../../../../config/hooks";
import { P_AGENTE_ASSIGN_SUPERVISORS } from "../../../../../../config/helpers/permissionsHash";


const EditUserModal = ({ onRefresh, blockRole, }) => {
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const [form] = Form.useForm();
    const [id, setId] = useState();
    const [assignSupervisor, setAssignSupervisor] = useState(false);
    const [assignUsers, setAssignUsers] = useState(false);

    const { loading, show, data, result, error } = useSelector(
        (state) => state.usersEditRedux
    );
    const { roles, users: dataUsers, agents, loadingAgents, companies: dataCompanies, states, areaManager, loadingUsers } = useSelector((state) => state.catalogRedux);


    //Verificacion de cargar de usuario
    useEffect(() => {
        if (data && !id) {
            //se carga la info al form solo una vez
            setId(data.id);
            //se especifica la carga de los valores al form
            form.setFieldsValue({
                ...data,
                username: data.username,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                phone1: data.phone1,
                phone2: data.phone2,
                city: data.city,
                avatar: { url: data.imageUrl },
                workState: data.workState ? JSON.parse(data.workState) : [],
                state: data.state,
                roles: data.roles.map((x) => x.id),
                assignedUsersIds: data?.assignedUsers ? data.assignedUsers.map(v => v.id) : [],
                freeRange: [data.freeStartDate && dayjs(data.freeStartDate), data.freeEndDate && dayjs(data.freeEndDate)],
                assignedSupervisorsId: data?.supervisors ? data.supervisors.map(v => v.id) : [],
                assignedCompaniesId: data?.companiesAssigned ? data.companiesAssigned.map(v => v.id) : []
            });

            setAssignSupervisor(data.roles.find(r => r.id == 5))
            setAssignUsers(data.roles.find(r => r.id == 8))
        }
        if (blockRole) {
            form.setFieldValue('roles', [blockRole])
            setAssignSupervisor(blockRole == 5)
            setAssignUsers(blockRole == 8)
        }
    }, [data]);

    useEffect(() => {
        if (result) {
            dispatch(reset());
            notification.success({
                message: messages["saveSuccessfullyTitle"],
                description: messages["saveSuccessfully"].toString(),
            }); //, description: messages["saveSuccessfully"] })
            closeModal(true);
        }

        if (error) {
            dispatch(reset("error"));
            notification.error({
                message: messages["saveError"]?.toString(),
                description: messages[error?.message] || error,
            });
        }
    }, [result, error])


    useEffect(() => {
        let q = [{ key: "roles" }, { key: "users", params: { all: true } }, {key: "states"}, { key: "agents" }, { key: "areaManager" }, { key: "companies", params: {businessCode: 1} }];
        dispatch(getCatalog({ keys: q }));
    }, []);

    const rolesFiltered = useMemo(() => {
        if (roles?.length) {
            return roles.map((v) => ({
                label: v.name,
                value: v.id,
            }))
        } else {
            return []
        }
    }, [roles, open])

    const AS = useSecurity(P_AGENTE_ASSIGN_SUPERVISORS)

    //se valida y se limpia todo lo que pueda causar errores
    const closeModal = useCallback((refresh) => {
        setId();
        form.resetFields();
        dispatch(hide());
        dispatch(reset());
        onRefresh && onRefresh(refresh);
    });

    const onValuesChange = (values) => {
        if(values?.roles){
            const { roles: _roles } = values;
            setAssignSupervisor(_roles.find(r => r.id == 5))
            setAssignUsers(_roles.find(r => r.id == 8))
        }
    }

    return (
        <Modal
            open={show}
            title={
                <h5>
                    {id
                        ? messages["users_editUser_edit"]
                        : messages["users_editUser_create"]}
                </h5>
            }
            onCancel={() => {
                closeModal();
            }}
            width={800}
            footer={null}
        >
            <div className="p-2 p-md-4">
                <div className="card-body">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={(values) => {
                            dispatch(
                                saveEditUser({
                                    id: id,
                                    ...values,
                                    image: values.avatar?.file,
                                    workState: values.workState || [],
                                    freeStartDate: values.freeRange?.length > 0 && values.freeRange[0]?.format(),
                                    freeEndDate: values.freeRange?.length > 1 && values.freeRange[1]?.format()
                                })

                            );
                        }}
                        onValuesChange={onValuesChange}
                    >
                        {loading ? (
                            <>
                                <Skeleton active />
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <Form.Item
                                        name="avatar"
                                        label={
                                            <label className="form-label">
                                                {
                                                    messages[
                                                    "users_editUser_avatar"
                                                    ]
                                                }
                                            </label>
                                        }
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message:
                                        //             messages["required_field"],
                                        //     },
                                        // ]}
                                        className="text-center"
                                    >
                                        <InputAvatar />
                                    </Form.Item>
                                </div>
                                <div className="row g-3">
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="firstName"

                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        messages["required_field"],
                                                },
                                            ]}
                                            label={
                                                <label className="form-label">
                                                    {
                                                        messages[
                                                        "users_editUser_firstName"
                                                        ]
                                                    }
                                                </label>
                                            }
                                        >
                                            <Input className="form-control" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="lastName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        messages["required_field"],
                                                },
                                            ]}
                                            label={
                                                <label className="form-label">
                                                    {
                                                        messages[
                                                        "users_editUser_lastName"
                                                        ]
                                                    }
                                                </label>
                                            }
                                        >
                                            <Input className="form-control" />
                                        </Form.Item>
                                    </div>

                                    <Form.Item
                                        className="col-12 col-md-6"
                                        name="username"
                                        label={
                                            <label className="form-label">
                                                {
                                                    messages[
                                                    "users_editUser_username"
                                                    ]
                                                }
                                            </label>
                                        }
                                        rules={[
                                            { required: true, message: messages['required_field'] },
                                            {
                                                validator: async (_, value) => {
                                                    if (value && value != data?.username) {
                                                        const duplicated = dataUsers.find(v => v.username === value.trim());
                                                        if (duplicated)
                                                            throw new Error(messages['data_already_exist'])
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <Input
                                            className="form-control"
                                            disabled={id}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="col-12 col-md-6"
                                        name="email"
                                        label={
                                            <label className="form-label">
                                                {
                                                    messages[
                                                    "users_editUser_email"
                                                    ]
                                                }
                                            </label>
                                        }
                                        rules={[
                                            { required: true, message: messages['required_field'] },
                                            { pattern: emailRegex, message: messages['invalid_email'] },
                                            {
                                                validator: async (_, value) => {
                                                    if (emailRegex.test(value) && value != data?.email) {
                                                        const duplicated = dataUsers.find(v => v.username === value.trim());
                                                        if (duplicated)
                                                            throw new Error(messages['data_already_exist'])
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <Input
                                            className="form-control"
                                            disabled={id}
                                        />
                                    </Form.Item>

                                    <div className="col-12 col-md-6">
                                        <div
                                            style={{
                                                maxHeight: 400,
                                                overflow: "auto",
                                            }}
                                        >
                                            <Form.Item

                                                name="roles"
                                                label={
                                                    <label className="form-label">
                                                        {
                                                            messages[
                                                            "users_editUser_roles"
                                                            ]
                                                        }
                                                    </label>
                                                }
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message:
                                            //             messages[
                                            //                 "required_field"
                                            //             ],
                                            //     },
                                            // ]}
                                            >
                                                <Select
                                                    disabled={blockRole}
                                                    mode="tags"
                                                    style={{ width: "100%" }}
                                                    options={rolesFiltered}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="paridaIVA"

                                            rules={[
                                                { required: true, message: messages['required_field'] },
                                                {
                                                    validator: async (_, value) => {
                                                        if (value && value != data?.paridaIVA) {
                                                            const duplicated = dataUsers.find(v => v.paridaIVA === value.trim());
                                                            if (duplicated)
                                                                throw new Error(messages['data_already_exist'])
                                                        }
                                                    }
                                                }
                                            ]}
                                            label={<label className="form-label">{messages["companiesEdit_paridaIVA"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_paridaIVA"]} />
                                        </Form.Item>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="state"

                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_state"]}</label>}
                                        >
                                            <Select
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={states}
                                                fieldNames={{ label: "name", value: "id" }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="city"

                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_city"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_city"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 ">
                                        <Form.Item
                                            name="address"

                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_address"]}</label>}
                                        >
                                            <Input
                                                className="form-control"
                                                placeholder={messages["companiesEdit_address"]}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="phone1"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_phone1"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_phone1"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="phone2"
                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label">{messages["companiesEdit_phone2"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_phone2"]} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div style={{
                                            maxHeight: 400,
                                            overflow: "auto",
                                        }}>
                                            <Form.Item
                                                name="workState"
                                                label={<label className="form-label">{messages["companiesEdit_workState"]}</label>}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    optionFilterProp="name"
                                                    filterOption={(input, option) =>
                                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={states}
                                                    fieldNames={{ label: "name", value: "id" }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="workActivity"
                                            label={<label className="form-label">{messages["companiesEdit_workActivity"]}</label>}
                                        >
                                            <Input className="form-control" placeholder={messages["companiesEdit_workActivity"]} />
                                        </Form.Item>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="freeRange"

                                            //rules={[{ required: true, message: messages['required_field'] }]}
                                            label={<label className="form-label"> {messages["companiesEdit_dateContracted"]}</label>}
                                        >
                                            {/* <DatePicker className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" /> */}
                                            <DatePicker.RangePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </div>
                                    {assignUsers && <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="assignedUsersIds"
                                            label={<label className="form-label">{messages["users_agents"]}</label>}
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                optionFilterProp="fullName"
                                                filterOption={(input, option) =>
                                                    (option?.fullname ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                loading={loadingAgents}
                                                options={agents}
                                                fieldNames={{ label: "fullName", value: "id" }}
                                            />
                                        </Form.Item>
                                    </div>}
                                    {assignSupervisor && AS && <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="assignedSupervisorsId"
                                            label={<label className="form-label">{messages["sidebar_areaManager"]}</label>}
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                style={{width: '100%'}}
                                                optionFilterProp="fullName"
                                                filterOption={(input, option) =>
                                                    (option?.fullname ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                loading={loadingUsers}
                                                options={areaManager}
                                                fieldNames={{ label: "fullName", value: "id" }}
                                            />
                                        </Form.Item>
                                    </div>}
                                    {assignSupervisor && AS && <div className="col-12 col-md-6">
                                        <Form.Item
                                            name="assignedCompaniesId"
                                            label={<label className="form-label">{messages["sidebar_list2"]}</label>}
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                style={{width: '100%'}}
                                                optionFilterProp="name"
                                                filterOption={(input, option) =>
                                                    (option?.fullname ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                loading={loadingUsers}
                                                options={dataCompanies}
                                                fieldNames={{ label: "name", value: "id" }}
                                            />
                                        </Form.Item>
                                    </div>}
                                </div>
                            </>
                        )}
                    </Form>
                </div>
                <div className="row pt-5">
                    <div className="text-center pt-10">
                        <button
                            className="btn btn-primary me-sm-3 me-1"
                            disabled={loading}
                            onClick={() => form.submit()}
                        >
                            {messages["save"]}
                        </button>
                        <button
                            className="btn btn-label-secondary"
                            onClick={() => closeModal()}
                        >
                            {messages["cancel"]}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EditUserModal;
