import { Routes, Route, Navigate } from 'react-router-dom';


import { DocumentsAndContractsListView } from './views/list';


const DocumentsAndContractsRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/client/documents-contracts/list`} />}></Route>
            <Route path='/list' element={< DocumentsAndContractsListView />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default DocumentsAndContractsRouter;