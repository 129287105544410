import { Dropdown } from "..";

function TableDropdown({ options = [] }) {
    return (
        <Dropdown
            items={options?.map((v, i) => {
                if (v?.render) {
                    return v.render();
                }
                return v && v?.show != false ? (
                    <div key={i} className="dropdown-item" onClick={v?.onClick}>
                        {v?.label}
                    </div>
                ) : (
                    <></>
                );
            })}
        >
            <Dropdown.Toogle>
                <a>
                    <i className="bx bx-dots-horizontal"></i>
                </a>
            </Dropdown.Toogle>
        </Dropdown>
    );
}

TableDropdown.Item = ({ onClick, label }) => (
    <div className="dropdown-item" onClick={onClick}>
        {label}
    </div>
);

export const TableOptions = TableDropdown;