import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Input, notification, Spin, DatePicker, TimePicker, Checkbox } from "antd";
import { dashboardEditNoteClose, dashboardEditNoteSave, reset } from "./redux/slice";
import dayjs from "dayjs";

export default function (props) {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const [id, setId] = useState();
  const [requiredReminder, setrequiredReminder] = useState(false);

  const { isOpen, data, result, loading, error, companyAnnouncementId } = useSelector(
    (state) => state.dashboardEditNoteRedux
  );

  const minutesInterval = 30;

  useEffect(() => {
    if (data && !id) {
      setId(data.id);
      setrequiredReminder(data?.emailSchedule);
      form.setFields([
        {name: "note", value: data?.note,},
        {name: "reminder_hour", value: data?.emailSchedule? dayjs(data?.emailSchedule): null, },
        {name: "reminder", value: data?.emailSchedule? dayjs(data.emailSchedule): null,}
      ])
    }
    if (result) {
      dispatch(reset({ result: null }));
      notification.success({ message: messages["saveSuccessfully"] }); //, description: messages["saveSuccessfully"] })
      closeModal(true);
    }

    if (error) {
      dispatch(reset({ error: null }));
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
    }
  }, [data, result, error]);

  const closeModal = useCallback((refresh) => {
    setId();
    form.resetFields()
    dispatch(dashboardEditNoteClose());
    onClose && onClose(refresh);
  });

   // Función para mostrar solo las opciones de tiempo con intervalos de 30 minutos
   const disabledMinutes = () => {
    const disabled = [];
    for (let i = 0; i < 60; i++) {
      if (i % minutesInterval !== 0) {
        disabled.push(i);
      }
    }
    return disabled;
  };
  

  return (
    <Modal
      open={isOpen}
      width={800}
      onCancel={() => {
        closeModal();
      }}
      footer={null}
      maskClosable={false}
    >
      <div className="p-3 p-md-5">
        <div className="text-center mb-4">
          <h3>{id ? "Editar nota" : messages['dashboardDetail_notesAdd']}</h3>
        </div>

        <Spin spinning={loading}>
          <div className="card-body">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                const valuesToSubmit = {
                  id: id,
                  companyAnnouncementId: parseInt(companyAnnouncementId, 10),
                  note: values.note,
                }
                if(requiredReminder){
                  const date = values?.reminder;
                  const hour = values?.reminder_hour.format('H');
                  const min = values?.reminder_hour.format('m');
                  valuesToSubmit.emailSchedule =  date.hour(hour).minute(min);
                }
                dispatch(
                  dashboardEditNoteSave(valuesToSubmit)
                );
              }}
            >
              <div className="row g-3">
                <div className="row g-3">
                  <Checkbox 
                    checked={requiredReminder}
                    onChange={(e) => {
                      setrequiredReminder(e.target.checked);
                    }}                
                  >
                    {messages["editNoteModal_chekbox"]}
                  </Checkbox>
              
                </div>
                { requiredReminder && (
                  <div className={`d-flex  justify-content-between` }>
                    <Form.Item
                      name="reminder"
                      label={<label className="form-label">{messages['editNoteModal_date']}</label>}
                      rules={[{required:true}]}
                      className="col-6"
                    >
                        <DatePicker  className="col-11" />
                    </Form.Item>
                    {/* timer seleccionado en intervalo de 30 min */}
                    <Form.Item
                      name="reminder_hour"
                      label={<label className="form-label">{messages['editNoteModal_hour']}</label>}
                      rules={[{required:true }]}
                      className="col-6"
                    >
                        <TimePicker
                          className="col-12"
                          format="HH:mm"
                          minuteStep={minutesInterval}
                          disabledTime={disabledMinutes}
                        />
                    </Form.Item>
                  </div>

                  )}
                
                
                <div className="col-12">
                  <Form.Item
                    name="note"
                    rules={[{ required: true, message: messages['required_field'] }]}
                    //label={
                    //  <label className="form-label">
                    //    {messages['dashboardDetail_notesAdd']}
                    //  </label>
                    //}
                  >
                    <Input.TextArea
                      className="form-control"
                      autoSize={{minRows: 4}}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </Spin>

        <div className="row pt-5">
          <div className="col-12 text-center">
            <button
              className="btn btn-primary me-sm-3 me-1"
              onClick={() => form.submit()}
            >
              {messages["save"]}
            </button>
            <button
              className="btn btn-label-secondary"
              onClick={() => closeModal()}
            >
              {messages["cancel"]}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
