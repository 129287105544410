import { List, Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../../../../components/Loading';
import { historyNotesClose } from './redux/slice';
import moment from 'moment';
import { useIntl } from 'react-intl';

export const ModalHistoryNotesByPAState = () => {

  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { data, loading, error, isOpen } = useSelector((state) => state.historyNotesRedux);


  return (
    <Modal
      open={isOpen}
      width={800}
      onCancel={() => dispatch(historyNotesClose())}
      title={messages["ModalHistoryNotesByPAState_title"]}
      footer={null}
      maskClosable={false}
    >
      <Loading 
      loading={loading}>
        <div className="p-3 p-md-5">
          <List
            dataSource={data?.items}
            renderItem={(item) => (
              <List.Item>
                <p>{item.note}</p>
                <p>{messages["ModalHistoryNotesByPAState_dateCreatedNote"]}: {moment(item.createdAt).format('L')}</p>
              </List.Item>
            )}
          />
        </div>
      </Loading>
    </Modal>
  )
}


