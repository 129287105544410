let locales = {
    "httpLogs_menu": "HTTP logs",
};

// merge modal SubmitDocument
import list from '../../views/list/lan/it'

locales = {
    ...locales,
    ...list,
};

export default locales;