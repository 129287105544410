

export const AnimatedClockComponent = () => {
    return (
        <div className="gif-container">
            <img src='/assets/images/icon-clock-item.gif' alt="GIF animado"
                style={{
                    width: '30px',
                    maxWidth: '100%'
                }}
            />
        </div>
    )
}

export default AnimatedClockComponent;

