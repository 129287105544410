let locales = {
    campaignDetail_title: 'Detalle de envío',
    campaignDetail_emailContent: 'Contenido del correo',
    campaignDetail_emailStatus: 'Estado del correo',
    campaignDetail_emailError: 'Error',
    campaignDetail_emailSent: 'Enviado',
    campaignDetail_emailRead: 'Mensaje leído',
    campaignDetail_emailNoRead: 'Mensaje no leído',
};

export default locales;