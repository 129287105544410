//Global keys
let locales = {
};

//Merge Modules
import login from '../../views/login/lan/es';
import recoveryPassword from '../../views/recoveryPassword/lan/es';

locales = {
    ...login,
    ...recoveryPassword
};

export default locales;