import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

import { useSetThemeValues } from 'config/hooks/theme'

import locales from 'config/locales'
import LoginView from '../security/views/login'
import ErrorView from '../security/views/error'
import ModulesRouter from '../../modules/admin'
import SiteRouter from '../../modules/site'
import RecoveryPasswordView from '../security/views/recoveryPassword'
import ViewContact from '../../modules/common/modals/viewContact'
import UnAuthorizedView from '../security/views/unauthorized';


const MainRouter = () => {

    //useSetThemeValues();

    const { lang } = useSelector(state => state.themeRedux)
    return (
        <IntlProvider locale={lang} messages={locales[lang]}>
            <Routes>
                <Route path='/' element={<Navigate to="/admin" />} />
                {/* <Route path='/site/*' element={<SiteRouter />} /> */}
                <Route path='/admin/*' element={<ModulesRouter />} />
                <Route path='/error' element={<ErrorView />} />
                <Route path='/unauthorized' element={<UnAuthorizedView />} />
                <Route path='/login' element={<LoginView />} />
                <Route path='/login/:u/:h' element={<LoginView />} />
                <Route path='/recoveryPassword' element={<RecoveryPasswordView />} />
                <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
            <ViewContact />
        </IntlProvider >

    )
}

export default MainRouter