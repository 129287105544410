import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { HiOutlineDocumentText } from 'react-icons/hi';
import moment from 'moment';

import {
    TableView,
    Modal,
    Input,
    Button,
    Loading,
    Form,
    DatePicker,
    InputFile,
    TableOptions,
} from 'components';
import { notification, Drawer} from 'antd'

import { companiesDetailSearchPayments, companiesDetailPaymentSave, reset, deletePaymentById} from './redux/slice'

const PaymentsComponent = ({ companyId }) => {

    const dispatch = useDispatch()
    const tableView = TableView.useTableView();
    const form = Form.useForm()
    const { messages } = useIntl()
    const { loading, data, result, error } = useSelector(state => state.companiesDetailPaymentsRedux)
    const [files, setFiles] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [showDeleteAlert, setshowDeleteAlert] = useState({
        visible: false,
        item: null,
    });
    const [isShowPaymentDetail, setIsShowPaymentDetail] = useState({
        visible: false,
        item: null
    });
    

    useEffect(() => {
        dispatch(companiesDetailSearchPayments({ companyId }));
    }, [])

    useEffect(() => {

        if (result) {
            dispatch(reset({ result: null }))
            notification.success({ message: messages["saveSuccessfully"]})
            closeModal(true)
        }

        if (error) {
            dispatch(reset({ error: null }))
            notification.error({ message: messages["saveError"], description: messages[error] || error })
        }
    }, [result, error])

    const closeModal = useCallback((refresh) => {
        setShowModal(false)
        form.resetValues()
        setFiles([]);
        refresh && tableView.refresh()
    })

    const columns = useMemo(() => [
        {
            width: 50,
            render: (values) => {
                return (<TableOptions options={[
                    {
                        label:  messages["view"],
                        onClick: () => showPaymentDetail(values)
                    },
                    {
                        label:  messages["edit"],
                        show: false,
                        //onClick: () => dispatch(companiesEditOpen(values.id))
                    },
                    {
                        label:  messages["delete"],
                        onClick: onShowModalDeleteAlert(true, values)
                    }
                ]} />)
            }
        },
        {
            width: 20,
            key: "files",
            render: (row) => {
                return (
                    <>
                       {
                            row?.files && JSON.parse(row?.files).length > 0 && 
                            <div onClick={() => showPaymentDetail(row)}>
                                <i className="me-2 mdi mdi-file-multiple" />
                            </div>
                       }
                    </>
                )
            }
        },
        {
            title: messages["companiesDetailPayments_paymentMethod"],
            key: "paymentMethod",
            dataIndex: "paymentMethod",
            width: 200,
        },
        {
            title: messages["companiesDetailPayments_price"],
            key: "price",
            dataIndex: "price",
            width: 150,
        },
        {
            title: messages["companiesDetailPayments_payStartDate"],
            key: "payStartDate",
            width: 200,
            render: (row) => (row.payStartDate && moment(row.payStartDate).format('DD/MM/yyyy'))
        },
        {
            title: messages["companiesDetailPayments_payEndDate"],
            key: "payEndDate",
            width: 150,
            render: (row) => (row.payEndDate && moment(row.payEndDate).format('DD/MM/yyyy'))
        },
        {
            title: messages["companiesDetailPayments_Files"],
            width: 200,
            render: (row) => (
                JSON.parse(row.files).map((row, key) =>(
                    <div key={key}>
                    {
                      <a className='d-flex flex-row align-items-center' href={row.url} rel="noreferrer" target="_blank">
                        <HiOutlineDocumentText size={30} />
                      </a>
                    }
                  </div>
             )
                ))
        }
    ]);

    const onShowModalDeleteAlert = (show, item) => () => {
        const newValues = {
            visible: show,
            item: item
        }
        if(!show){
            newValues.item = null;
        }
        setshowDeleteAlert(newValues)
    }
    const onDeletePayment = (id) => () => {
        dispatch(deletePaymentById({id}))
        onShowModalDeleteAlert(false)();
    }

    const onAddFileToPayment = (file) => {
        if(files.length === 5){
            return;
        }
        setFiles([...files, file]);
    }
    const onDeleteFilesPayment = (index) => {
        if (files?.length) {
          let newValues = files.filter((v, i) => index !== i);
          setFiles(newValues);
        }
    };

    const showPaymentDetail = (item) => {
        setIsShowPaymentDetail({
            visible: !!item,
            item: item,
        })
    }

    return (
        <>
            <Drawer open={isShowPaymentDetail.visible} title={messages['companiesDetailPayments_paymentDetail']} onClose={() => showPaymentDetail(null)} >
                <div className='row'>
                        <div className='col-12'>
                            <small className="text-muted">{messages["companiesDetailPayments_paymentMethod"]}</small>
                            <h6>{isShowPaymentDetail?.item?.paymentMethod}</h6>
                        </div>
                        <div className='col-12'>
                            <small className="text-muted">{messages["companiesDetailPayments_price"]}</small>
                            <h6>{isShowPaymentDetail?.item?.price}</h6>
                        </div>
                        <div className='col-12'>
                            <small className="text-muted">{messages["companiesDetailPayments_payStartDate"]}</small>
                            <h6>{isShowPaymentDetail?.item?.payStartDate && moment(isShowPaymentDetail?.item?.payStartDate).format('DD/MM/yyyy')}</h6>
                        </div>
                        <div className='col-12'>
                            <small className="text-muted">{messages["companiesDetailPayments_payEndDate"]}</small>
                            <h6>{isShowPaymentDetail?.item?.payEndDate && moment(isShowPaymentDetail?.item?.payEndDate).format('DD/MM/yyyy')}</h6>
                        </div>
                        <div className='col-12'>
                            <small className="text-muted">{messages["companiesDetailPayments_Files"]}</small>
                            <div className='row px-3'>
                                {
                                    isShowPaymentDetail?.item?.files && JSON.parse(isShowPaymentDetail?.item?.files).map((f,i) => 
                                        <a
                                            key={i}
                                            href={f?.url}
                                            rel="noreferrer"
                                            target="_blank"
                                            className='m-2 icon-hover border rounded'
                                            style={{width: 45, padding: 0}}
                                        >
                                            {
                                                f?.url.split(".").pop() !== 'pdf'? 
                                                    <img src={f?.url} alt={`file-${i}`} width="45" /> 
                                                :
                                                    <i className="me-2 mdi mdi-file-pdf" />
                                            }
                                        </a>
                                    )
                                }
                            </div>
                            
                        </div>
                    </div>
            </ Drawer >
            <div className='col-12'>
            
                <Loading loading={loading}>
                    <div className='card-body'>
                        <div className="d-flex align-items-center">
                            <div>
                                <h5 className="card-header mb-4 p-0"> {messages["companiesDetailPayments_title"]}</h5>
                            </div>
                            <Button type="button" className="ms-auto btn-rounded btn btn-success" onClick={() => { setShowModal(true) }}>
                                {messages["companiesDetailPayments_add"]}
                            </Button>
                        </div>
                        <TableView
                            //isSelectable
                            ref={tableView.ref}
                            columns={columns}
                            height="calc(60vh - 370px)"
                            items={data?.items || []}
                            //searchText={searchText}
                            name='payments'
                            count={data?.count || 0}
                            onChange={(offset, limit) => { dispatch(companiesDetailSearchPayments({ companyId, searchText: "", limit, offset })) }}
                        />
                        {/* ------------------------------------ CREATE MODAL -------------------------------- */}
                        <Modal
                            show={showModal}
                            title={messages["companiesDetailPayments_add"]}
                            dialogClassName='modal-dialog-centered'
                            onHide={() => { closeModal() }}
                        >
                            <div className="p-3 p-md-2">
                                <Loading loading={loading}>
                                    <div className='modal-body'>
                                        <Form
                                            ref={form.ref}
                                            className="form-horizontal mt-4 pt-4 needs-validation"
                                            onValidSubmit={(values) => {
                                                dispatch(companiesDetailPaymentSave({
                                                    //id: id,
                                                    files: files,
                                                    companyId: Number(companyId),
                                                    ...values,
                                                }))
                                            }}
                                            loading={loading}
                                        >
                                            <div className="row">
    
                                                <div className="col-md-6 col-12">
                                                    <Form.Item
                                                        name="paymentMethod"
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                        label={messages["companiesEdit_paymentMethod"]}
                                                    >
                                                        <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Item
                                                        name="price"
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                        label={messages["companiesEdit_price"]}
                                                    >
                                                        <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                    </Form.Item>
                                                </div>
    
                                                <div className="col-md-6 col-12">
                                                    <Form.Item
                                                        name="payStartDate"
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                        label={messages["companiesEdit_payStartDate"]}
                                                    >
                                                        <DatePicker className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Item
                                                        name="payEndDate"
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                        label={messages["companiesEdit_payEndDate"]}
                                                    >
                                                        <DatePicker className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h6 className="card-subtitle mb-2"> {messages["companiesDetailPayments_addFilesDescription"]}</h6>
                                                <div className="col-12">
                                                    <InputFile multi value={files} onChange={onAddFileToPayment} accept=".png, .jpg, .jpeg, application/pdf" onDelete={onDeleteFilesPayment}/>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </Loading>
                            </div>
                            <div className="row pt-5">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary me-sm-3 me-1" onClick={() => form.submit()} loading={loading}>{messages["save"]}</button>
                                    <button type="button" className="btn btn-label-secondary" onClick={() => closeModal()}>{messages["cancel"]}</button>
                                </div>
                            </div>
                        </Modal>
                        {/* ------------------------------------ DELETE MODAL ------------------------------------------ */}
                        <Modal
                            show={showDeleteAlert.visible}
                            title={messages["companiesDetailPayments_deletePayment"]}
                            dialogClassName='modal-dialog-centered'
                            onHide={onShowModalDeleteAlert(false)}
                            footer={null}
                            maskClosable={false}
                        >
                            <div className="p-3 p-md-2">
                                <div className="modal-body">
                                    <div>
                                        <h6 className="card-subtitle mb-0"> {messages["companiesDetailPayments_deletePaymentMessage"]}</h6>
                                    </div>
                                </div>
                                <div className="row pt-5">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn btn-primary me-sm-3 me-1" onClick={onDeletePayment(showDeleteAlert?.item?.id)} loading={loading}>{messages["delete"]}</button>
                                        <button type="button" className="btn btn-label-secondary" onClick={onShowModalDeleteAlert(false)}>{messages["cancel"]}</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </Loading>
            </div>
        </>
    )
}

export default PaymentsComponent