import { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCatalog } from "../../../../../.config/redux/actions";
import _ from "lodash";
import { useIntl } from "react-intl";

const ManagementRolePermission = ({ onChange, value }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { messages } = intl;

    const { permissions } = useSelector((state) => state.catalogRedux);
    useEffect(() => {
        dispatch(getCatalog({ keys: ["permissions"] }));
    }, []);

    const getTablePermission = (value, onChange, items, parentId, level) => {
        if (!items || items.length == 0) return <></>;

        const printItems = items?.filter((item) => {
            if (parentId) return item.parentId == parentId;
            else return !item.parentId;
        });

        return (
            <>
                {printItems?.map((item) => {
                    //Se clonan los valores
                    let newValues = value ? _.clone(value) : [];
                    //Se busca el indice con del valor
                    let selectItemIndex = newValues.findIndex(
                        (val) => val.permissionId == item.id
                    );
                    if(selectItemIndex  === -1 ) {
                        newValues = [...newValues, {permissionId: item.id}]
                        selectItemIndex = newValues.findIndex(
                            (val) => val.permissionId == item.id
                        );
                    }
                    
                    return (
                        <Fragment key={item.id}>
                            <tr>
                                <td
                                    style={{
                                        paddingLeft: level ? level * 15 : 0,
                                    }}
                                >
                                    {parentId && (
                                        <i className="bx bx-subdirectory-right pr-2"/>
                                    )}
                                    {messages[item.name]?.toString() || item.name}
                                </td>
                                <td >
                                    <div className="d-flex">
                                        {!item?.isSingle && <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={
                                                    selectItemIndex > -1 &&
                                                    !!newValues[selectItemIndex]?.C
                                                }
                                                onChange={(e) => {
                                                    newValues[selectItemIndex] = {
                                                        permissionId: item.id,
                                                        ...newValues[
                                                            selectItemIndex
                                                        ],
                                                        C: e.target.checked,
                                                    };
                                                    onChange(newValues);
                                                }}
                                            />
                                            <span className="form-check-label">
                                                {messages["add"]}
                                            </span>
                                        </label>}
                                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={
                                                    selectItemIndex > -1 &&
                                                    !!newValues[selectItemIndex]?.R
                                                }
                                                onChange={(e) => {
                                                    newValues[selectItemIndex] = {
                                                        permissionId: item.id,
                                                        ...newValues[
                                                            selectItemIndex
                                                        ],
                                                        R: e.target.checked,
                                                    };
                                                    onChange(newValues);
                                                }}
                                            />
                                            <span className="form-check-label">
                                                {item?.isSingle ? messages["roles_access"] : messages["view"]}
                                            </span>
                                        </label>
                                        {!item?.isSingle && <>
                                            <label className="form-check form-check-sm form-check-custom form-check-solid     me-5 me-lg-20">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={
                                                        selectItemIndex > -1 &&
                                                        !!newValues[selectItemIndex]?.U
                                                    }
                                                    onChange={(e) => {
                                                        newValues[selectItemIndex] = {
                                                            permissionId: item.id,
                                                            ...newValues[
                                                                selectItemIndex
                                                            ],
                                                            U: e.target.checked,
                                                        };
                                                        onChange(newValues);
                                                    }}
                                                />
                                                <span className="form-check-label">
                                                    {messages["edit"]}
                                                </span>
                                            </label>
                                            <label className="form-check form-check-sm form-check-custom form-check-solid     me-5 me-lg-20">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={
                                                        selectItemIndex > -1 &&
                                                        !!newValues[selectItemIndex]?.D
                                                    }
                                                    onChange={(e) => {
                                                        newValues[selectItemIndex] = {
                                                            permissionId: item.id,
                                                            ...newValues[
                                                                selectItemIndex
                                                            ],
                                                            D: e.target.checked,
                                                        };
                                                        onChange(newValues);
                                                    }}
                                                />
                                                <span className="form-check-label">
                                                    {messages["delete"]}
                                                </span>
                                            </label>
                                        </>}
                                    </div>
                                    
                                </td>
                            </tr>
                            {getTablePermission(
                                value,
                                onChange,
                                items,
                                item.id,
                                level ? level + 1 : 1
                            )}
                        </Fragment>
                    );
                })}
            </>
        );
    };
    return (
        <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5">
                <tbody className="fw-semibold" style={{color: '#435971'}}>
                    {getTablePermission(value, onChange, permissions)}
                </tbody>
            </table>
        </div>
    );
};

export default ManagementRolePermission;
