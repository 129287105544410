import { Input, Alert, Table } from "antd";
import { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useActionNotification, usePaggination } from "../../../../../../config/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getListNotifications, getListNotificationsTable, updateNotification, reset } from "../../../../../../components/Notifications/.config/redux/slice";
import moment from "moment";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
import NotificationsFilters from "../../components/Filters";

const DEFAULT_DAYS = 180;

export const NotificationsListView = () => {

  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { tableNotifications, loading } = useSelector((state) => state.notification)
  const { user } = useSelector(state => state.authRedux);
  const actionInterpreter = useActionNotification();

  const paggination = usePaggination((values) => getListNotificationsTable({ ...values}));

  useEffect(() => {
    dispatch(getListNotificationsTable())
    paggination.refresh();
    return () => {
      dispatch(reset('tableNotifications'));
    };
  }, [])

  const refreshNotifitication = (daysAgo = DEFAULT_DAYS, isNoRead = false) => {
    dispatch(getListNotifications({ daysAgo, isNoRead }));
  }

  const markAsRead = (item) => {
    dispatch(updateNotification({ id: item.id, read: true })).unwrap().then(() => {
      refreshNotifitication(DEFAULT_DAYS);
      actionInterpreter(item?.action);
    })
  }

  const typeoFNoticationMessage = (value) => {
    if (value == true) {
      return {
        type: "success",
        message: messages["notificationsList_read"]
      }
    } else if (value == false) {
      return {
        type: "warning",
        message: messages["notificationsList_not_read"]
      }
    }
  }

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input
                  type="text"
                  className="form-control my-1 w-100"
                  placeholder={messages["search"]}
                  onChange={paggination.onSearchChange}
                  value={paggination.searchText}
                />
            </div>
            <div className="col-md-8 col-sm-12" >
              <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                <NotificationsFilters paggination={paggination}/>
                <button
                  className="btn btn-primary m-1"
                  onClick={() => {
                    paggination.refresh();
                  }}
                >
                  {messages["refresh"]}
                </button>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 mt-4 mb-4">
              <TextDescriptionCompanies />
            </div>
          </div>
          <div className="mt-4 table-responsive">
            <Table
              rowKey="id"
              loading={loading}
              columns={[
                {
                  title: messages["notificationsList_title"],
                  key: "title",
                  // dataIndex: "title",
                  render: (values) => (
                    <Link
                      onClick={() => markAsRead(values)}
                    >
                      {values.title}
                    </Link>
                  )
                },
                (user?.isAdmin ? {
                  title: messages['createdBy'],
                  key: "created",
                  dataIndex: "created",
                  width: 200,
                  render: (row) => row?.fullName
                }: {}),
                // {
                //   title: messages["notificationsList_body"],
                //   key: "body",
                //   dataIndex: "body",
                //   width: 600,
                //   render: (values) => (
                //     <div dangerouslySetInnerHTML={{ __html: values }} />
                //   )
                // },
                {
                  title: messages["notificationsList_createdOn"],
                  key: "createdOn",
                  // dataIndex: "createdOn",
                  width: 200,
                  render: (row) =>
                    row.createdOn &&
                    moment(row.createdOn).format("DD/MM/yyyy, h:mm:ss a")
                },
                {
                  title: messages["notificationsList_state"],
                  key: "read",
                  // dataIndex: "read",
                  width: 150,
                  render: (row) =>
                    <Alert
                      message={typeoFNoticationMessage(row.read).message}
                      type={typeoFNoticationMessage(row.read).type}
                      showIcon
                    />
                },
              ]}
              dataSource={tableNotifications?.items || []}
              pagination={{
                total: tableNotifications?.count || 0,
                ...paggination.tablePaggination,
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
