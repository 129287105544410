let locales = {
    "documentsandContracts_submitDocument": "Subir documento",
};

// merge modal SubmitDocument
import submitDocument from '../../modals/submitDocument/.config/lan/es'
import listDocument from '../../views/list/lan/it'

locales = {
    ...submitDocument,
    ...listDocument,
};

export default locales;