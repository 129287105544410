import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { resetALL } from "../../../../../../../config/redux/actions";
import { getUserProfile } from "../../../../../../../core/security/auth/redux/slice";




//STATE INITIAL
const INIT_STATE = {
    loading: false,
    show: false,
    data: null,
    companyId: null,
    currentStateId: null,
    agentsAssigned: [],
    result: null,
    error: null
};


const route = 'modules/management/companies/modals/changePAState/slice'

export const getChangePAStateOpen = createAsyncThunk('changePAState/getChangePAStateOpen', async ({ limit = 0, offset = 0, companyId = null, stateId = null, historyId = null }, { rejectWithValue, dispatch }) => {

    try {
        let data = await clientQuery(
            `
            query SearchNotesByCompany($limit: Int!, $companyId: Int!, $stateId: Int, $offset: Int!, $historyId: Int) {
                result: searchNotesByCompany(limit: $limit, companyId: $companyId, stateId: $stateId, offset: $offset, historyId: $historyId) {
                    items {
                        companyId
                        id
                        note
                        stateId
                        createdAt
                        createdBy
                        created {
                            fullName
                            id
                        }
                    }
                    count
                    defaultAgentId
                }
                company: getCompanyById (id: $companyId) {
                    id
                    stateId
                    agentsAssigned {
                        agentId
                    }
                }
            }
        `,
            {
                limit: limit || 10,
                offset: offset || 0,
                companyId: Number(companyId),
                stateId: Number(stateId),
                historyId: Number(historyId)
            },
            endpoints.GRAPHQL_GENERAL
        );

        return data
    } catch (exc) {
        //Se guarda la excepcion
        ExceptionManager(exc, route, 'getChangePAStateOpen', dispatch);
        return rejectWithValue(exc)
    }

});

export const saveChangePAState = createAsyncThunk('modals/saveChangePAState', async ({ companyId, defaultAgentId, note , nextStateId}, { rejectWithValue, dispatch }) => {
    try {

        let data = await clientMutation(
            `mutation ChangeState($companyId: Int, $defaultAgentId: Int, $nextStateId: Int, $note: String) {
                result: changeState(companyId: $companyId, defaultAgentId: $defaultAgentId, nextStateId: $nextStateId, note: $note)
              }
                `,
            {
                companyId,
                defaultAgentId,
                note,
                nextStateId
            },
            endpoints.GRAPHQL_GENERAL
        );
        
        dispatch(getUserProfile());

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'saveChangePAState', dispatch);
        return rejectWithValue(exc)
    }
});


export const savePAStateNote = createAsyncThunk('modals/savePAStateNote', async ({ note, companyId, stateId }, { rejectWithValue, dispatch }) => {


    try {

        let data = await clientMutation(
            `mutation createStateNote($stateId: Int, $companyId: Int, $note: String) {
                result: createStateNote(stateId: $stateId, companyId: $companyId, note: $note) {
                  stateId
                  note
                  id
                  createdAt
                  companyId
                  createdBy
                }
              }
                `,
            {
                companyId,
                note,
                stateId,
            },
            endpoints.GRAPHQL_GENERAL
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'savePAStateNote', dispatch);
        return rejectWithValue(exc)
    }

});



export const changePAStateListRedux = createSlice({
    name: 'changePAState',
    initialState: INIT_STATE,
    reducers: {
        changePAStateClose(state, action) {
            return INIT_STATE
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        hide(state) {
            state.show = false
        },
        openPAStateModal(state, action){
            state.show = true
            state.companyId =  action.payload?.companyId
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        // thunk open Modal
        builder.addCase(getChangePAStateOpen.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(getChangePAStateOpen.fulfilled, (state, action) => {
            state.loading = false;
            // state.show = true;
            state.data = action.payload.result
            state.currentStateId =  action.payload.company?.stateId
            state.agentsAssigned =  action.payload.company?.agentsAssigned
        })
        builder.addCase(getChangePAStateOpen.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        // thunk de guardar estado
        builder.addCase(saveChangePAState.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(saveChangePAState.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload
            state.show = false
        })
        builder.addCase(saveChangePAState.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        // thunk de guardar nota
        builder.addCase(savePAStateNote.pending, (state) => {
            state.loading = true;
            state.error = null;
            // state.show = false;
        })
        builder.addCase(savePAStateNote.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload
        })
        builder.addCase(savePAStateNote.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})


// Extract and export the action creators object and the reducer
export const { actions, reducer } = changePAStateListRedux;

// Action creators are generated for each case reducer function
export const { changePAStateClose, hide, openPAStateModal, reset } = actions;

export default reducer;



