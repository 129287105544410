import { Form } from 'antd';
import ComplexSelect from '../../../../../../components/ComplexSelect';
import { openEditCatalog } from '../../.config/redux/actions';
import { useDispatch } from 'react-redux';
import EditFormCatalog from '../../modals/editCatalog';

function SelectProps(props) {
    const { values, onChange } = props;
    const dispatch = useDispatch()
    return (
        <div className='col-12'>
            <div className='row mb-2'>
                <button type="button" className="btn-label-secondary" onClick={() => {dispatch(openEditCatalog({ id: values['catalogId'] }))}}>{values['catalogId'] ? 'Edit options' : 'Add options'}</button>
            </div>
            <EditFormCatalog onSave={(values) => {
                onChange('catalogId')(values.id)
            }} />
        </div>
    )
}

export default SelectProps