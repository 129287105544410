import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
};

const route = 'modules/client/announcementsContractManage/views/list/slice'

//Extend async Action
export const announcementToManageListSearch = createAsyncThunk('dashboard/announcementToManageListSearch', async (
    { searchText, limit, offset },
    { rejectWithValue, dispatch }
) => {


    try {
        let data = await clientQuery(
            `query QUERY($openSearch: String, $limit: Int, $offset: Int){
                result: searchAnnouncementToManage(openSearch: $openSearch, limit: $limit, offset: $offset) {
                    items {
                        announcementId
                        companyAnnouncements {
                            id
                            announcementId
                            companyId
                            createdOn
                            document {
                                id
                                url
                                name
                                type
                            }
                            approvedDocument
                            isRequestingForDocument
                            updatedFileByUser {
                                fullName
                                companyId
                            }
                        }
                        announcement {
                            id
                            product
                            name
                            surname
                        }
                    }
                    count
                }
            }`,
            {
                openSearch: searchText || '',
                limit: limit || 10,
                offset: offset || 0,
            },
            endpoints.GRAPHQL_GENERAL
        );

        return data?.result || []
    } catch (exc) {
        ExceptionManager(exc, route, 'announcementToManageListSearch', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const announcementToManageListRedux = createSlice({
    name: 'dashboard/announcementToManage',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(announcementToManageListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(announcementToManageListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(announcementToManageListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = announcementToManageListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer