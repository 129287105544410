//Global keys
let locales = {
    listNotificacionStatusEmail: 'Envío programado de correo electrónico:',
    listNotificacionStatusPending: 'Pendiente',
    listNotificacionStatusSuccess: 'Enviado',
    listNotificacionStatusError: 'Error',
    listNotificacion_motification: 'modificado por la Administración',
    listNotificacion_isAdmin: 'Administración',
}


export default locales;