import { Fragment } from 'react'
import { TextDescriptionCompanies } from '../../../components/TextDescriptionCompanies';

const Main = () => {

    return (
        <Fragment>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="col-md-8 col-sm-12 mt-4 mb-4">
                    <TextDescriptionCompanies />
                </div>  
            </div>
        </Fragment>
    )
}

export default Main;