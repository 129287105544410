import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { AiFillFilePdf } from 'react-icons/ai';
import { P_WATHCHDOCUMENTSINNOTES, P_NOTES } from '../../../../../../config/helpers/permissionsHash';

import {
    TableView,
    Modal,
    Input,
    Button,
    Loading,
    Form,
    InputFile,
    TableOptions
} from 'components';
import { notification } from 'antd'

import { companiesDetailSearchNotes, companiesDetailNotesSave, reset, deleteNoteById } from './redux/slice'
import { useSecurity } from '../../../../../../config/hooks';


const NotesComponent = ({ companyId }) => {

    const dispatch = useDispatch()
    const tableView = TableView.useTableView();
    const form = Form.useForm()
    const { messages } = useIntl()
    //const { user } = useSelector((state) => state.authRedux);
    const { loading, data, result, error } = useSelector(state => state.companiesDetailNotesRedux)


    // Creamos permisos para poder leer, crear y eliminar notas 
    const C = useSecurity(P_NOTES, ['C']);
    const R = useSecurity(P_NOTES, ['R']);
    const D = useSecurity(P_NOTES, ['D']);

    // Creamos permisos para poder ver documentos adjuntos a las notas.
    const R_Document = useSecurity(P_WATHCHDOCUMENTSINNOTES, ['D']);

    // se agreaga un nuevo File(escalable a 5)
    const [files, setFiles] = useState([]);

    const [showModal, setShowModal] = useState(false)
    const [showDeleteAlert, setshowDeleteAlert] = useState({
        visible: false,
        item: null,
    });

    useEffect(() => {
        dispatch(companiesDetailSearchNotes({ companyId }));
    }, [])

    useEffect(() => {
        if (result) {
            dispatch(reset({ result: null }))
            notification.success({ message: messages["saveSuccessfully"] })
            closeModal(true)
        }

        if (error) {
            dispatch(reset({ error: null }))
            notification.error({ message: messages["saveError"], description: messages[error] || error })
        }
    }, [result, error])

    const closeModal = useCallback((refresh) => {
        setShowModal(false)
        form.resetValues()
        setFiles([]);
        refresh && tableView.refresh()
    })

    const columns = useMemo(() => [
        {
            width: 50,
            render: (values) => {
                return (<TableOptions options={[
                    {
                        label: messages["view"],
                        //onClick: () => showPaymentDetail(values),
                        show: false,
                    },
                    {
                        label: messages["edit"],
                        //onClick: () => dispatch(companiesEditOpen(values.id)),
                        show: false,
                    },
                    {
                        label: messages["delete"],
                        onClick: onShowModalDeleteAlert(true, values),
                        show: D ? true : false,
                    },
                ]} />)
            }
        },
        {
            title: messages["createdOn"],
            key: "createdOn",
            width: 150,
            render: (row) => (row.createdOn && moment(row.createdOn).format('MMMM Do YYYY, h:mm:ss a'))
        },
        {
            title: messages["companiesDetailNotes_note"],
            key: "note",
            width: 200,
        },
        R_Document ?
            {
                title: messages["companiesDetailNotes_columDocuments"],
                key: "files",
                width: 100,
                render: (values) => {
                    const files = JSON.parse(values?.files);
                    // return <>
                    //   {files.map((f,i) => (
                    //     <a key={i} href={f.url} target="_blank">
                    //         <AiFillFilePdf size={30} /> 
                    //     </a>))
                    //   }
                    // </>
                    return (
                        <>
                            {
                                values?.files && files.length > 0
                                &&
                                <a href={files[0].url} rel="noreferrer" target="_blank">
                                    <AiFillFilePdf size={30} />
                                </a>
                            }
                        </>
                    )
                }
            } : null,
            {
                title: messages["createdBy"],
                key: "createdByUser",
                width: 150,
                render: (values) => {
                    const { fullName, id } = values.createdByUser
                    return (
                        <div className="d-flex justify-content-start align-items-center user-name">
                            <div className="d-flex flex-column">
                                <label className="text-body text-truncate fw-mediumml-2">
                                    ({id}) {fullName}
                                </label>
                                {/* <small>
                                    <a target="_blank"  href={window.location.pathname.replace('companies/detail/' + companyId, 'users/list/' + id)} rel="noreferrer">{email}</a>
                                </small> */}
                            </div>
                        </div>
                    );
                }
            }
    ]);

    const onShowModalDeleteAlert = (show, item) => () => {
        const newValues = {
            visible: show,
            item: item
        }
        if (!show) {
            newValues.item = null;
        }
        setshowDeleteAlert(newValues)
    }

    const onDeleteNote = (id) => () => {
        dispatch(deleteNoteById({ id }))
        onShowModalDeleteAlert(false)();
    }


    // Funcionalidades para gestionar los DOCS

    // añade un file a la note
    const addFileToNote = (file) => {
        if (files.length === 1) {
            return;
        }
        setFiles([...files, file]);
    }
    // elimina un file de la nota
    const onDeteleFileToNote = (index) => {
        if (files?.length) {
            let newValues = files.filter((v, i) => index !== i);
            setFiles(newValues);
        }
    }

    return (
        <div className='col-12'>
            <Loading loading={loading}>
                <div className='card-body'>
                    <div className="d-flex align-items-center">
                        <div>
                            <h5 className="card-header mb-4 p-0">{messages["companiesDetailNotes_title"]}</h5>
                        </div>
                        {
                            C && <Button type="button" className="ms-auto btn-rounded btn btn-success" onClick={() => { setShowModal(true) }}>
                                {messages["companiesDetailNotes_add"]}
                            </Button>
                        }
                    </div>
                    {R && <TableView
                        className="min-vh-75"
                        loading={loading}
                        //isSelectable
                        ref={tableView.ref}
                        columns={columns}
                        // height="calc(60vh - 300px)"
                        items={data?.items || []}
                        //searchText={searchText}
                        name='notes'
                        count={data?.count || 0}
                        onChange={(offset, limit) => { dispatch(companiesDetailSearchNotes({ companyId, searchText: "", limit, offset })) }}
                    />}

                    <Modal
                        show={showModal}
                        title={messages["companiesDetailNotes_add"]}
                        dialogClassName='modal-dialog-centered'
                        onHide={() => { closeModal() }}
                    >
                        <div className="p-3 p-md-2">
                            <Loading loading={loading}>
                                <div className='modal-body'>
                                    <Form
                                        ref={form.ref}
                                        className="form-horizontal mt-4 pt-4 needs-validation"
                                        onValidSubmit={(values) => {
                                            dispatch(companiesDetailNotesSave({
                                                //id: id,
                                                companyId: Number(companyId),
                                                files: files,
                                                ...values,
                                            }))
                                        }}
                                        loading={loading}
                                    >
                                        <div className="row">

                                            <div className="col-12">
                                                <Form.Item
                                                    name="note"
                                                    rules={[{ required: true, message: messages['required_field'] }]}
                                                    label={messages["companiesDetailNotes_note"]}
                                                >
                                                    <Input
                                                        type="textarea"
                                                        maxLength={5000}
                                                        rows={4}
                                                        height={100}
                                                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    />
                                                </Form.Item>
                                                <div className="row">
                                                    <h6 className="card-subtitle mb-2"> {messages["companiesDetailNotes_addFilesDescription"]}</h6>
                                                    <div className="col-12">
                                                        <InputFile multi value={files} onChange={addFileToNote} accept=".png, .jpg, .jpeg, application/pdf" onDelete={onDeteleFileToNote} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Loading>
                        </div>
                        <div className="row pt-5">
                            <div className="col-12 text-center">
                                <button type="button" className="btn btn-primary me-sm-3 me-1" onClick={() => form.submit()} loading={loading}>{messages["save"]}</button>
                                <button type="button" className="btn btn-label-secondary" onClick={() => closeModal()}>{messages["cancel"]}</button>

                            </div>
                        </div>
                    </Modal>
                    <Modal
                        show={showDeleteAlert.visible}
                        title={messages["companiesDetailNotes_delete"]}
                        dialogClassName='modal-dialog-centered'
                        onHide={onShowModalDeleteAlert(false)}
                        footer={null}
                        maskClosable={false}
                    >
                        <div className="p-3 p-md-2">
                            <div className="modal-body">
                                <div>
                                    <h6 className="card-subtitle mb-0"> {messages["companiesDetailPayments_deletePaymentMessage"]}</h6>
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-12 text-center">
                                    <Button type="button" className="btn btn-primary me-sm-3 me-1" onClick={onDeleteNote(showDeleteAlert?.item?.id)} loading={loading}>{messages["delete"]}</Button>
                                    <Button type="button" className="btn btn-label-secondary " onClick={onShowModalDeleteAlert(false)}>{messages["cancel"]}</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Loading>
        </div>
    )
}

export default NotesComponent