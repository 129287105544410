import { useEffect } from "react";
import { Select, DatePicker, Input } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import Filters from "../../../../../../components/Filters";
import { getCatalog } from "../../../../../.config/redux/actions";

const ListFilters = ({onApply}) => {
    const { messages } = useIntl();
    const dispatch = useDispatch()

    const { roles } = useSelector(state => state.catalogRedux);
    const { user } = useSelector(state => state.authRedux);

    useEffect(() => {
        dispatch(getCatalog({
            keys: [{ key: 'roles' }],
        }));
    }, [])

    return <Filters onApply={(values) => {
        let _values = {
            insertStartDate: values?.createdOn? values.createdOn[0]: null, 
            insertEndDate:  values?.createdOn? values.createdOn[1] : null,
            ...values
        };
        delete _values.createdOn
        onApply(_values)
    }} >
        <Filters.Item name="createdOn" label={messages["documentsandContracts_dateOfCreation"]}>
            <DatePicker.RangePicker placement="bottomRight" />
        </Filters.Item>
        {user.isAdmin && <Filters.Item name="role" label={messages["documentsandContracts_visibleTo"]}>
            <Select
                style={{ width: '100%' }}
                allowClear
                options={roles}
                fieldNames={{ label: "name", value: "id" }}
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Filters.Item>}
        <Filters.Item name="fileName" label={ messages["documentsandContracts_file"]}>
            <Input
                type="text"
                className="form-control my-1 w-100"
                placeholder={messages["search"]}
            />
        </Filters.Item>
    </Filters>
}

export default ListFilters