import cx from 'classnames'

export const Input = (props) => {
    const {
        id,
        value,
        onChange,
        placeholder,
        className,
        autoComplete,
        type,
        disabled,
        readOnly,
        maxLength,
        rows,
        height
    } = props;

    if (type === "textarea") {
        return (
            <div className="input-container">
                <textarea
                    id={id}
                    placeholder={placeholder}
                    className={cx("form-control", className)}
                    value={value || ""}
                    type={type || "text"}
                    autoComplete={autoComplete || "off"}
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                    disabled={disabled}
                    readOnly={readOnly || false}
                    maxLength={maxLength || 50}
                    rows={rows || 5}
                    style={{
                        height: height || 70,
                        resize: "none"
                    }}
                />
            </div>
        )
    }

    return (

        <input
            id={id}
            placeholder={placeholder}
            className={cx("form-control", className)}
            value={value || ""}
            type={type || "text"}
            autoComplete={autoComplete || "off"}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            disabled={disabled}
            //loading={loading || false} 
            readOnly={readOnly || false}
            maxLength={maxLength || 50}
            required
        />

    )
}