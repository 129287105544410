import { Fragment } from "react";
import { useDispatch } from "react-redux";

import { viewContactOpen } from '../../../config/redux/actions'


const Footer = () => {
    const dispatch = useDispatch()

    return (
        <Fragment>
            <footer className="content-footer footer bg-footer-theme">
                <div className="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                    <div className="mb-2 mb-md-0">
                        {/* ©
                        {moment().year()}
                        , made with ❤️ by {" "}
                        <a href="https://ingeniosoft.co" target="_blank" className="footer-link fw-bolder"> Ingeniosoft</a> */}
                    </div>
                    <div>
                        <a onClick={() => { dispatch(viewContactOpen()) }} className="footer-link me-4" target="_blank">Contatta l'Assistenza</a>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}

export default Footer;