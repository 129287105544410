import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Modal, Form, Input, notification } from "antd";

import { Loading } from "../../../../../../components/Loading";
import {
  changePassword,
  companiesChangePasswordClose,
  reset,
} from "./redux/slice";

const ChangePasswordModal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { messages } = useIntl();
  const { loading, email, userId, result, error, isOpen } = useSelector(
    (state) => state.companiesChangePasswordRedux
  );
  useEffect(() => {
    if (result) {
      dispatch(reset({ result: null }));
      onClose()
      notification.success({ message: messages["saveSuccessfully"] }); //, description: messages["saveSuccessfully"] })
    }

    if (error) {
      dispatch(reset({ error: null }));
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
    }
  }, [result, error]);

  const validatePasswords = (_, value, callback) => {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback(messages["companiesChangePassword_message"]);
    } else {
      callback();
    }
  };
  const onClose = () => {
    dispatch(companiesChangePasswordClose());
    form.resetFields()
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
    >
      <div className="p-3 p-md-5">
        <div className="text-center mb-4">
          <h3>{messages["companiesChangePassword_title"]}</h3>
          <Loading loading={loading}>
            <div className="card-body">
              <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={(values) => {
                  dispatch(
                    changePassword({
                      email,
                      userId,
                      newPassword: values?.newPassword,
                    })
                  );
                }}
                loading={loading}
              >
                <div className="row g-3">
                  <div className="col-12">
                    <Form.Item
                      name="newPassword"
                      label={
                        <label className="form-label">
                          {messages["companiesChangePassword_newPassword"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                      ]}
                    >
                      <Input.Password
                        className="form-control mb-2"
                        placeholder={
                          messages["companiesChangePassword_newPassword"]
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="repeatPassword"
                      label={
                        <label className="form-label">
                          {messages["companiesChangePassword_repeatPassword"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                        {
                          validator: validatePasswords,
                          message: messages["companiesChangePassword_message"]
                        },
                      ]}
                    >
                      <Input.Password
                        className="form-control"
                        placeholder={
                          messages["companiesChangePassword_repeatPassword"]
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Loading>
          <div className="row pt-5">
            <div className="col-12 text-center">
              <button
                className="btn btn-primary me-sm-3 me-1"
                onClick={() => form.submit()}
                loading={loading}
                disabled={loading}
              >
                {messages["save"]}
              </button>
              <button
                className="btn btn-label-secondary"
                onClick={onClose}
              >
                {messages["cancel"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
