let locales = {
    parameters_editTemplate_edit: 'Editar template',
    parameters_editTemplate_create: 'Crear template',
    parameters_editTemplate_name: 'Nombre',
    parameters_editTemplate_body: 'Contenido',
    parameters_editTemplate_subject: 'Asunto',
    parameters_parameters: 'Parametros',
    parameters_companyParameters: 'Company Parameters'
};

export default locales;