import {
  useCallback,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Steps, Result, Select, Form, Input, Modal, notification } from "antd";
import { Loading, ComplexSelect } from "components";

import {
  companiesSendEmailClose,
  sendEmailsToCompanies,
  searchAnnouncements,
  sendEmailsForAcceptanceContract,
  reset
} from "./redux/slice";

import { campaignFromListSearch, campaignTemplateListSearch } from "../../../../../../config/redux/actions";
//areUser = true: just for users not companies
const SendEmailModal = ({areUsers = false}) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [current, setCurrent] = useState(0);
  const [currentTitleModal, setCurrentTitleModal] = useState(messages["companiesList_sendEmails"]);
  const [form] = Form.useForm();
  const [selectedAnnouncements, setSelectedAnnouncements] = useState([])
  const [optionNegative, setOptionNegative] = useState({})

  const {
    loading,
    isOpen,
    result,
    error,
    customers,
    announcements,
    loadingAnnouncements,
  } = useSelector((state) => state.companiesSendEmailRedux);

  const { data: dataTemplates, loading: loadingTemplates } = useSelector((state) => state.templatesRedux);
  const { data: dataSenders, loading: loadingSenders } = useSelector((state) => state.sendersRedux);

  useEffect(() => {
    dispatch(campaignTemplateListSearch({ searchText: "" }));
    dispatch(campaignFromListSearch({ searchText: "" }));
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
      dispatch(reset('error'));
    }
    if (result) {
      setCurrent(3);
      dispatch(reset('result'));
    }
  }, [error, result]);

  const closeModal = useCallback(() => {
    form.resetFields();
    setCurrent(0);
    setSelectedAnnouncements([])
    dispatch(companiesSendEmailClose());
  });

  const routeNegative = (value) => {

    if (value == true) {
      setCurrent(current + 1)
      setCurrentTitleModal(messages["companiesList_sendEmailsAceptClient"])
      setOptionNegative({
        disabled: true,
      })
      form.setFieldValue('subject', dataTemplates?.items.find((v => v.id == 3))?.subject)
      form.setFieldValue('newsletter', 3)
    } else {
      setCurrent(2)
      setOptionNegative({
        disabled: false,
        id: null,
      })
    }
  }

  const changeStep = (stepId) => () => {
    setCurrent(stepId)
  }

  return (
    <div>
      <Modal
        open={isOpen}
        width={800}
        onCancel={() => { closeModal(); }}
        footer={null}
        maskClosable={false}
      >
        <div className="p-3 p-md-5">
          <div className="text-center mb-4">
            <h3>{messages["companiesList_sendEmails"]}</h3>
            {/* <p>pequeña descripcion opcional</p> */}
          </div>

          <Loading loading={loading}>
            <div className="card-body">
              <Form
                form={form}
                //initialValues={{ from: user.email }}
                className="form-horizontal mt-4 pt-4 needs-validation"
                onFinish={(values) => {
                    const sendEmailsValues = {
                      fromId: values.from,
                      to: customers.map((v) => v.id),
                      subject: values.subject,
                      announcementIds: selectedAnnouncements.map(x => x.key),
                      templateId: values.newsletter,
                      areUsers
                    }
                    values?.newsletter == 3 ? 
                    (
                      dispatch(sendEmailsForAcceptanceContract(sendEmailsValues))
                    )
                    : dispatch(sendEmailsToCompanies(sendEmailsValues))
                }}
                onValuesChange={(values) => {
                  if (values.announcements) {
                    setSelectedAnnouncements(values.announcements)
                  }
                  if (values?.newsletter) {
                    const newsLetter = dataTemplates?.items.find(v => v.id === values?.newsletter);
                    form.setFieldValue('subject', newsLetter?.subject)
                  }
                }}
                layout="vertical"
              >
                <Steps
                  current={current}
                  items={[
                    {
                      title: messages["companiesSendEmail_announcement"],
                    },
                    {
                      title: messages["companiesSendEmail_ConfirmSendEmail"],
                      disabled: areUsers
                    },
                    {
                      title: messages["companiesSendEmail_Newsletter"],
                    },
                    {
                      title: messages["companiesSendEmail_Finish"],
                    },
                  ]}
                  progressDot
                />
                {/* VIEWS BY STEPS */}
                {current === 0 && (
                  <div className="mt-3 mb-lg-0">
                    <Form.Item
                      name="announcements"
                      label={
                        <label className="form-label">
                          {currentTitleModal}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                      ]}
                    >
                      {/* <Select
                        labelInValue
                        mode="multiple"
                        showSearch
                        defaultActiveFirstOption={false}
                        notFoundContent={<div>No hay concidencias</div>}
                        style={{ width: "100%" }}
                        onSearch={(value) => {
                          if (window.typingTime) {
                            clearTimeout(window.typingTime);
                          }

                          window.typingTime = setTimeout(() => {
                            if (value) {
                              dispatch(searchAnnouncements({ id: value }));
                            }
                          }, 1000);
                        }}
                        loading={loadingAnnouncements}
                        options={
                          (announcements &&
                            announcements.map((x) => {
                              return {
                                ...x,
                                complexName: `${x.id} - ${x.product}`,
                              };
                            })) ||
                          []
                        }
                        fieldNames={{ label: "complexName", value: "id" }}
                      /> */}
                      <ComplexSelect
                        labelPropName="product"
                        action={searchAnnouncements}
                        mode="multiple"
                        loading={loadingAnnouncements}
                        data={announcements}
                      />
                    </Form.Item>
                  </div>
                )}
                {current === 1 && (
                  <div className="mt-3 mb-lg-0">
                    <div>
                      {`Stai inviando la richiesta: ${selectedAnnouncements.map(x => x.label).join(", ")}  `}
                      <br />
                      {`Al Partner affiliato: ${customers.map(x => x.name).join(", ")}`}
                      <br />
                      {`Sei sicuro che il partner affiliato ha firmato l’accettazione cliente?`}
                    </div>
                  </div>
                )}
                {current === 2 && (
                  <div className="mt-3 mb-lg-0">
                    <div className="mb-3">
                      <Form.Item
                        name="newsletter"
                        label={
                          <label className="form-label">
                            {messages["companiesSendEmail_newsletter"]}
                          </label>
                        }
                      >
                        <Select
                          className="mb-3 mb-lg-0"
                          options={areUsers ? dataTemplates?.items.filter(v => v.id != 3) : dataTemplates?.items}
                          disabled={optionNegative.disabled}
                          fieldNames={{ label: "name", value: "id" }}
                          loading={loadingTemplates}
                        />
                      </Form.Item>
                    </div>
                    <div className="mb-3">
                      <Form.Item
                        name="from"
                        label={
                          <label className="form-label">
                            {messages["companiesSendEmail_from"]}
                          </label>
                        }
                      >
                        <Select
                          className="mb-3 mb-lg-0"
                          options={dataSenders?.items || []}
                          fieldNames={{ label: "email", value: "id" }}
                          loading={loadingSenders}
                        />
                      </Form.Item>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        {messages["companiesSendEmail_to"]}
                      </label>
                      <Input
                        value={customers.map((v) => v.email).toString()}
                        //className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Item
                        name="subject"
                        label={
                          <label className="form-label">
                            {messages["companiesSendEmail_subject"]}
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                )}
                {current === 3 && (
                  <div className="mt-3 mb-lg-0">
                    <Result
                      status="success"
                      title={messages["companiesSendEmail_successMessage"]}
                    />
                  </div>
                )}
              </Form>
            </div>
          </Loading>

          {/* BUTTONS BY STEPS */}
          <div className="row pt-5">
            <div className="col-12 text-center">
              {current === 0 && (
                <button
                  type="button"
                  className="btn btn-primary me-sm-3 me-1"
                  onClick={areUsers ? () => routeNegative(false) : changeStep(1)}
                  disabled={selectedAnnouncements.length == 0}
                >
                  {messages["continue"]}
                </button>
              )}
              {current === 1 && (
                <>
                  <button
                    type="button"
                    className="btn btn-label-secondary me-2"
                    onClick={() => routeNegative(true)}
                  >
                    {messages["no"]} 
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-sm-3 me-1"
                    onClick={() => routeNegative(false)}
                  >
                    {messages["yes"]} 
                  </button>
                </>
              )}
              {current === 2 && (
                <>
                  <button
                    type="button"
                    className="btn btn-label-secondary"
                    style={{ marginRight: 10 }}
                    onClick={changeStep(current - 2)}
                  >

                    {messages["back"]}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-sm-3 me-1"
                    onClick={form.submit}
                    disabled={loading}
                  >
                    {messages["sendEmail"]}
                  </button>
                </>
              )}

              {current === 3 && (
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  onClick={() => {closeModal()}}
                >
                  {messages["close"]}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SendEmailModal;
