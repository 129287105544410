//Global keys
let locales = {
    "notificationsList_title": "Titulo",
    "notificationsList_body": "Mensaje de notificacion",
    "notificationsList_createdOn": "Fecha de creación",
    "notificationsList_state": "Estado",
    "notificationsList_read": "Leído",
    "notificationsList_not_read": "No Leído",
    "notificationsList_user": "Usuario",
    "notificationsList_date": "Fecha"
};


export default locales;