//Global keys
let locales = {
    "campaigns_menu": "campane",
};

//Merge Modules
import list from '../../views/list/lan/it'
import campaignDetail from '../../modals/detail/lan/it'

locales = {
    ...locales,
    ...list,
    ...campaignDetail
};

export default locales;