let locales = {
    parameters_editCampaignFrom_edit: 'Modifica del mittente',
    parameters_editCampaignFrom_create: 'Creare il mittente',
    parameters_editCampaignFrom_name: 'Nome',
    parameters_editCampaignFrom_email: 'Email',
    parameters_editCampaignFrom_host: 'Host',
    parameters_editCampaignFrom_port: 'Port',
    parameters_editCampaignFrom_secure: 'Secure',
    parameters_editCampaignFrom_user: 'User',
    parameters_editCampaignFrom_password: 'Password',
    parameters_editCampaignFrom_test: 'Test',
    parameters_editCampaignFrom_isDefault: 'predefinito',
    parameters_editCampaingFrom_sendTestEmailTo: `Invia un'e-mail di prova a`,
    parameters_editCampaingFrom_emailSuccess: `L'e-mail è stata inviata con successo`,
};

export default locales;