import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery} from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    result: null,
    error: null
};

const route = 'routes/admin/configurations/users/views/detail/redux/slice'

//Extend async Action
export const getUserDetail = createAsyncThunk('users/getDetail', async ({ id }, { rejectWithValue }) => {
    try {
        if (!id) return null;

        let data = await clientQuery(
            `
            query getUserById($id: Int!) {
                result: getUserById(id: $id) {
                    id
                    username
                    email
                    firstName
                    lastName
                    fullName
                    imageUrl
                    fullName
                    isAdmin
                    status
                    isDeleted
                    state
                    paridaIVA
                    city
                    address
                    phone1
                    phone2
                    workState
                    workActivity
                    freeStartDate
                    freeEndDate
                    company {
                        name
                    }
                    hashp
                    roles {
                        id
                        name
                    }
                    companiesAssigned {
                        id
                        name
                    }
                    supervisors {
                        id,
                        fullName
                    }
                }
            }
            `,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL,
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'openEditUser');
        return rejectWithValue(exc)
    }
})



//Slice
const userDetailRedux = createSlice({
    name: 'users/detail',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(getUserDetail.pending, (state) => {
            state.loading = true
            state.show = true
        })
        builder.addCase(getUserDetail.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(getUserDetail.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload.error;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = userDetailRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer