import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    show: false,
    data: null,
    result: null,
    resultError: null
};

const route = 'routes/admin/configurations/roles/modals/edit/redux/slice'

//Extend async Action
export const openEditRole = createAsyncThunk('roles/edit/open', async ({ id }, { rejectWithValue }) => {
    try {
        if (!id) return null;

        let data = await clientQuery(
            `
            query getRoleById($id: Int!) {
                result: getRoleById(id: $id) {
                    id
                    name
                    permissions {
                        permissionId
                        C
                        R
                        U
                        D
                    }
                }
            }
            `,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL,
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'openEditRole');
        return rejectWithValue(exc)
    }
})

export const saveEditRole = createAsyncThunk('roles/edit/save', async ({ id, name, permissions}, { rejectWithValue }) => {
    try {

        let data

        if (id) {
            const _permissions = permissions.map(v => {
                delete v?.__typename
                return v;
            })
            data = await clientMutation(
                `mutation updateRole($id: Int!, $name: String, $permissions: [RolePermissionInput]) {
                    result: updateRole(id: $id, name: $name, permissions: $permissions) {
                        id
                    }
                }`,
                {
                    id: Number(id),
                    name,
                    permissions: _permissions
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }
        else {
            data = await clientMutation(
                `mutation createRole($name: String!, $permissions: [RolePermissionInput]!) {
                    result: createRole(name: $name, permissions: $permissions) {
                        id
                    }
                }`,
                {
                    name,
                    permissions
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'saveEditRole');
        return rejectWithValue(exc)
    }
})


//Slice
const rolesEditRedux = createSlice({
    name: 'roles/edit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        hide(state) {
            state.show = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(openEditRole.pending, (state) => {
            state.loading = true
            state.show = true
        })
        builder.addCase(openEditRole.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(openEditRole.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })

        builder.addCase(saveEditRole.pending, (state) => {
            state.loading = true
        })
        builder.addCase(saveEditRole.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(saveEditRole.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = rolesEditRedux

// Extract and export each action creator by name
export const { reset, hide } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer