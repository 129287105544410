let locales = {
    recovery_password_title: 'Recupera la password',
    recovery_password_email: 'Email',
    recovery_password_back: 'Ritornare al login ↩',
    recovery_password_recovery_title: 'Password recuperata con successo!',
    recovery_password_recovery_msg: `Ti abbiamo appena inviato una email con la nuova password - {email} (Se non trovi la nostra email - Assicurati di controllare anche la cartella indesiderata o spam!)`,
    recovery_password_error: 'Oh no! Recupero non riuscito. Assicurati che la E-mail inserita sia corretta e riprova!',
    recovery_password_send: ' Invia la password ➜'
};

export default locales;