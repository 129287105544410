import { useMemo, useCallback } from 'react'
import TextInput from './TextInput'
import NumberInput from './NumberInput'
import SelectInput from './SelectInput'
import YesOrNoInput from './YesOrNoInput'
import UploadInput from './UploadInput'
import { DateInput } from './DateInput'
import Promemoria from './Promemoria'


function FormQuestion({ question, helpText, props, questionType, typeData, catalog, value, onChange }) {

  const formatProps = useMemo(() => {
    return JSON.parse(props)
  }, [props])

  const getDataType = useCallback(
    (typeData) => {
      switch (typeData) {
        case 'string':
          return <TextInput {...formatProps} value={value} onChange={onChange} />;
        case 'number':
          return <NumberInput {...formatProps} value={value} onChange={onChange} />;
        case 'catalog':
          return <SelectInput {...formatProps} value={value} onChange={onChange} catalog={catalog} questionType={questionType} />;
        case 'boolean':
          return <YesOrNoInput {...formatProps} value={value} onChange={onChange} />;
        case 'file':
          return <UploadInput {...formatProps} value={value} onChange={onChange} />;
        case 'date':
          return <DateInput {...formatProps} value={value} onChange={onChange} questionType={questionType} typeData={typeData} />;
        case 'promemoria':
          return <Promemoria {...formatProps} value={value} onChange={onChange} />;
        default:
          return null;
      }
    },
    [formatProps, value, onChange],
  )

  const getQuestionType = useCallback(
    (questionType, typeData) => {
      switch (questionType) {
        case 'INPUT':
          return getDataType(typeData);
        case 'SELECT':
          return getDataType(typeData);
        case 'MULTISELECT':
          return getDataType(typeData);
        case 'TIME':
          return getDataType(typeData);
        case 'UPLOAD':
          return getDataType(typeData);
        case 'NOTA':
          return getDataType(typeData);
        default:
          return null;
      }
    },
    [getDataType, formatProps],
  )

  return (
    <div className='col-12 m-2'>
      <div className='row'>
        <h5 className='card-header p-0 my-2'>{question}</h5>
      </div>
      <div className='row card-body p-0 mt-2'>
        <p>{helpText || ''}</p>
      </div>
      {getQuestionType(questionType, typeData)}
      {formatProps?.required && <small className='text-muted float-end'>*This question is required</small>}
    </div>
  )
}

export default FormQuestion