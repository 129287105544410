import React, { Fragment, useEffect, useMemo } from 'react'
import { usePaggination } from '../../../../../../config/hooks';
import { Input, Table } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { statusAnnouncementListSearch, reset } from './redux/slice';
import { TableOptions } from "components";
import { EditStatusLeads } from '../../modals/editSatutsLeads';
import { statusLeadsEditOpen } from '../../.config/redux/actions';

export const ConfigStatusLeads = () => {

    const dispatch = useDispatch();
    const { messages } = useIntl();
    const { data, loading, error } =
        useSelector((state) => state.statusAnnouncementListRedux);
    const R = true;
    const paggination = usePaggination(statusAnnouncementListSearch);

    // console.log(data);

    useEffect(() => {
        paggination.refresh();
        return () => {
            dispatch(reset());
        };
    }, []);

    const columns = useMemo(
        () => [
            {
                width: 50,
                render: (values) => {
                    return (<TableOptions options={[
                        {
                            label: messages["edit"],
                            onClick: () => dispatch(
                                statusLeadsEditOpen({
                                    id: values?.id,
                                })
                            ),
                            show: true
                        }
                    ]} />)
                },

            },
            {
                title: 'name',
                key: "name",
                dataIndex: "name",
            },
            {
                title: 'description',
                key: "description",
                dataIndex: "description",
            },
        ]
    )

    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-4 col-sm-12'>
                    {R && <Input
                        type="text"
                        className="form-control my-1 w-100"
                        placeholder={messages["search"]}
                        onChange={paggination.onSearchChange}
                        value={paggination.searchText}
                    />}
                </div>
                <div className="col-md-8 col-sm-12">
                    <div className='text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap'>
                        {R && <button
                            className="btn btn-primary m-1"
                            onClick={() => {
                                paggination.refresh();
                            }}
                        >
                            {messages["refresh"]}
                        </button>}
                    </div>
                </div>
            </div>
            <div className='mt-4 table-responsive'>
                {R && <Table
                    rowKey="id"
                    columns={columns}
                    loading={loading}
                    dataSource={data?.items || []}
                    pagination={{
                        total: data?.count || 0,
                        ...paggination.tablePaggination,
                    }}
                />}
            </div>
            <EditStatusLeads
                onClose={(refresh) => {
                    refresh && paggination.refresh();
                }} />
        </Fragment>
    )
}       
