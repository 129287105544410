import { Routes, Route, Navigate } from 'react-router-dom';


import { HTTPLogsListView } from './views/list';


const HTTPLogsRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/management/http-logs/list`} />}></Route>
            <Route path='/list' element={<HTTPLogsListView />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default HTTPLogsRouter;