import { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux"
import { Carousel, Modal, Button, Result, Spin, notification } from "antd";

import { InputFile } from "components"
import { uploadContract, reset, close } from "./.config/redux/slice";

export const ModalAffiliatePartner = () => {
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const carouselRef = useRef(null);

    const { result, error, open } = useSelector(state => state.modalAffiliatePartnerRedux)
    const { user } = useSelector((state) => state.authRedux);

    useEffect(() => {
        if (result) {
            //notification.success({ message: messages["saveSuccessfully"] })
            //dispatch(reset({ result: null }))
        }
        if (error) {
            notification.error({ message: messages["saveError"], description: messages[error] || error })
            dispatch(reset({ error: null }))
        }
    }, [result, error])

    return (
        <Modal
            open={open}
            width={560}
            onCancel={() => dispatch(close())}
            footer={null}
            maskClosable={false}
        >
            <div className="modal-header text-center">
                <h5 className="modal-title" id="modalToggleLabel">
                    {messages["ModalAffiliatePartner_title"]}
                </h5>
            </div>
            <Carousel ref={carouselRef} dots={false} >
                {user?.partnerAffiliatoDocs.map((v,i) =>  <ContentView key={i} {...v} />)}
            </Carousel>
            {user?.partnerAffiliatoDocs.length > 1 && <div className="d-flex justify-content-between">
                <Button type="text" onClick={() => carouselRef.current.prev()}>
                    <i className="bx bx-chevrons-left lh-1"></i><span>{messages['previous']}</span>
                </Button>
                <Button type="text" onClick={() => carouselRef.current.next()}>
                    <span>{messages['next']}</span><i className="bx bx-chevrons-right lh-1"></i>
                </Button>
            </div>}
        </Modal>
    );
};

function ContentView(props) {
    const {
        clientFullName,
        pendingToApproveDocument,
        pendingToSubmitFile,
        companyAnnouncementId,
        //hasDocument,
        emailContent
    } = props;
    const { messages } = useIntl();
    const dispatch = useDispatch()

    const { loading } = useSelector(state => state.modalAffiliatePartnerRedux)

    const [currentView, setCurrentView] = useState(pendingToSubmitFile? 1 : 3);
    const [file, setFile] = useState(null)

    const handleView = (viewKey) => {
        setCurrentView(viewKey);
    };

    const handleUploadFile = () => {
        if(file){
            dispatch(uploadContract({id: companyAnnouncementId, file: file.file})).unwrap().then(() => handleView(3)).catch((err) => console.error(err))
        }
    }

    return (
        <div>
            {pendingToSubmitFile && <> 
                {/* Negative */}
                {currentView === 0 && <div>
                    <div className="modal-body">
                        {emailContent && <div dangerouslySetInnerHTML={{__html: emailContent}}/>}
                    </div>
                    <div className="modal-footer mt-4">
                        <button
                            className="btn btn-label-secondary me-sm-3"
                            onClick={() => handleView(1)}
                        >
                            {messages["back"]}
                        </button>
                        <button
                            // disabled={loading}
                            className="btn btn-primary"
                            onClick={() => handleView(2)}
                            // loading={loading}
                        >
                            {messages["ModalAffiliatePartner_confirmPDF"]}
                        </button>
                    </div>
                </div>}
                {/* Initial */}
                {currentView === 1 && <div>
                    <div className="modal-body text-center">
                        <h3 className="pt-4">
                            {clientFullName}
                        </h3>
                        <p>
                            {messages["ModalAffiliatePartner_initialText"]}{" "}
                            <a
                                href="https://contratto-firmadigitale-verificata.tetglobalservice.it/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://contratto-firmadigitale-verificata.tetglobalservice.it/
                            </a>
                            ?
                        </p>
                    </div>
                    <div className="modal-footer text-center mt-4">
                        <button
                            className="btn btn-label-secondary me-sm-3"
                            onClick={() => handleView(0)}
                        >
                            {messages["ModalAffiliatePartner_cancelButton"]}
                        </button>
                        <button
                            // disabled={loading}
                            className="btn btn-primary"
                            onClick={() => handleView(2)}
                            // loading={loading}
                        >
                            {messages["ModalAffiliatePartner_confirmPDF"]}
                        </button>
                    </div>
                </div>}
                {/* Positive */}
                {currentView === 2 && <Spin spinning={loading}><div>
                    <div className="modal-body text-center">
                        <h3 className="pt-4 ">
                            {clientFullName}
                        </h3>
                        <p>
                            {messages["ModalAffiliatePartner_submitText"]}{" "}
                        </p>
                        <InputFile value={file} onChange={(value) => setFile(value)}/>
                    </div>
                    <div className="modal-footer text-center mt-4">
                        <button
                            className="btn btn-label-danger me-sm-3"
                            onClick={() => handleView(1)}
                        >
                            {messages["ModalAffiliatePartner_cancel"]}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={handleUploadFile}
                        >
                            {messages["ModalAffiliatePartner_save"]}
                        </button>
                    </div>
                </div></Spin>}
            </>}
            {(currentView === 3 || pendingToApproveDocument) && <div>
                <div className="modal-body text-center">
                    <h3 className="pt-4 ">
                        {clientFullName}
                    </h3>
                    <Result
                        title={messages['ModalAffiliatePartner_awaitingApproval']}
                        subTitle={<>
                            <p>{messages['ModalAffiliatePartner_awaitingApprovalDescription1']}</p>
                            <br />
                            <p>{messages['ModalAffiliatePartner_awaitingApprovalDescription2']}</p>
                        </>}
                    />
                </div>
            </div>}
        </div>
    );
}
