//imports
import { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loading } from '../../../components/Loading'

//Actions
import { getUserProfile, logRoute } from './redux/slice'

//component
const AuthContainer = ({ children, className, mandatory = false }) => {

    const dispatch = useDispatch()
    const { user, loading } = useSelector(state => state.authRedux);
    const navigate = useNavigate()
    const location = useLocation()

    const authCheck = useCallback(() => {
        let token = localStorage.getItem('token');

        if (!user && token) {
            dispatch(getUserProfile({ navigate, mandatory }));
        } else {
            if (mandatory)
                if (!token) navigate('/login');
        }
    })

    useEffect(() => {
        authCheck();
    }, [user])

    useEffect(() => {
        dispatch(logRoute(location.pathname))
    }, [location])
    

    let showChildrens = true
    if (mandatory)
        showChildrens = user ? true : false

    return (
        <div className={className}>
            <Loading loading={loading}>
                {
                    showChildrens &&
                    <Fragment>
                        {children}
                    </Fragment>
                }
            </Loading>
        </div>
    );

}

//Export Component
export default AuthContainer
