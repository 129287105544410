let locales = {
    companiesList_name: 'Nome',
    companiesList_email: 'Email',
    companiesList_activity: 'Attività della società',
    companiesList_workState: 'Provincia/e di lavoro',
    companiesList_state: 'Provincia',
    companiesList_freeEndDate: 'Scadenza Prova',
    companiesList_payEndDate: 'Scadenza Pagamenti',
    companiesList_lastEmailSend: 'Ultima email inviata',
    companiesList_notes: 'Notas',
    companiesList_sendEmails: 'Inviare e-mail',
    companiesList_sendEmailsAceptClient: 'Inviare e-mail accetazione Cliente',
    companiesList_viewDeletedUsers: 'Utenti cancellati',
    companiesList_viewCurrentUsers: 'Utenti attivi',
    companiesList_reactivateUser: 'Riattiva',
    companiesList_agentsAssigned: 'AGENTE ASSEGNATO',
};

export default locales;