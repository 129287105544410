//Global keys
let locales = {
    "companies_menu": "Compañias",
    "companies_changeStatus": "Cambiar Estado"
};

//Merge Modules
import list from '../../views/list/lan/es'
import detail from '../../views/detail/lan/es'
import detailPayments from '../../components/payments/lan/es'
import detailNotes from '../../components/notes/lan/es'

import edit from '../../modals/edit/lan/es'
import sendEmail from '../../modals/sendEmail/lan/es'
import changePassword from '../../modals/changePassword/lan/es'
import HistoryPAStates from '../../components/historyPAStates/lan/es'
import HistoryNotesByPAStates from '../../modals/historyNotesByPAState/lan/es'
import changePAState from '../../modals/changePAState/lan/es'

locales = {
    ...locales,
    ...list,
    ...detail,
    ...detailPayments,
    ...detailNotes,
    ...edit,
    ...sendEmail,
    ...changePassword,
    ...HistoryPAStates,
    ...HistoryNotesByPAStates,
    ...changePAState
};

export default locales;