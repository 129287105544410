import { Form, Input, Modal, Select, Table, notification } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'
import { changePAStateClose, reset, saveChangePAState, hide, savePAStateNote } from './redux/slice'
import { getCatalog, getChangePAStateOpen } from '../../../../../.config/redux/actions'
import { Loading } from '../../../../../../components/Loading'
import { usePaggination, useSecurity } from '../../../../../../config/hooks'
import { P_AGENTS_PASTATES } from '../../../../../../config/helpers/permissionsHash'
import { NotificationPAState } from '../../components/notificationPAState'
import { getUserProfile } from '../../../../../../core/security/auth/redux/slice';

const nextPAStatesList = {
    1: [2],
    2: [3, 4, 5, 6],
    3: [4, 6, 10],
    4: [10],
    5: [],
    6: [7],
    7: [5, 8],
    8: [9],
    9: [],
    10: [3, 4, 6],
    11: []
};

export const ChangePAStateModal = ({ onClose }) => {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const { messages } = useIntl();

    const dispatch = useDispatch();

    const [notificationApi, contextHolder] = notification.useNotification();
    const [modal, contextHolderModal] = Modal.useModal();

    const [nextStepListByCurrenIdStep, setNextStepListByCurrenIdStep] = useState([])

    const { data, loading, error, result, show, companyId, currentStateId, agentsAssigned } = useSelector((state) => state.changePAStateListRedux);
    const { pAStates, agents } = useSelector((state) => state.catalogRedux);
    const { user } = useSelector((state) => state.authRedux);

    const paggination = usePaggination(getChangePAStateOpen);
    const enableAgents = useSecurity(P_AGENTS_PASTATES, ['R']);

    useEffect(() => {
        dispatch(getCatalog({ keys: [{ key: "users", params: { all: true } }, "pAStates", "agents"] }));
    }, []);

    //vericicacion de la carga del currentStep
    useEffect(() => {
        if (companyId && show) {
            paggination.refresh(null, null, { companyId })
        }
    }, [show])

    useEffect(() => {
        setNextStepListByCurrenIdStep(nextPAStatesList[currentStateId])
    }, [currentStateId])

    useEffect(() => {

        if (currentStateId) {
            form.setFieldsValue({
                defaultAgentId: data?.defaultAgentId
            })
        }
    }, [currentStateId, data])

    useEffect(() => {
        if (error) {
            notification.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error.message,
            });
            dispatch(reset("error"));
            _onClose(true)
        }
        if (result) {
            notificationApi.success({
                message: messages["saveSuccessfullyTitle"].toString(),
                description: messages["saveSuccessfully"].toString(),
            });
            const info =  user?.currentPartnerAffiliato
            if(info?.blocked){
                //If menu doesn't show reload page
                location.reload();
            } else {
                paggination.refresh(null, null, { companyId })
                dispatch(getUserProfile({ navigate }))
                dispatch(reset("result"))
                _onClose(true)
            }
        }
    }, [error, result]);

    const columns = [
        {
            title: messages["changePAState_state"],
            key: "status",
            render: (row) =>
                findPAStateinfo(row.stateId)?.name
        },
        {
            title: messages["changePAState_note"],
            key: "note",
            dataIndex: "note",
        },
        {
            title: messages["changePAState_date"],
            key: "createdAt",
            width: 200,
            render: (row) =>
                row?.createdAt && moment(row.createdAt).format("DD/MM/yyyy HH:mm"),
        },
        {
            title: messages["users"],
            key: "uses",
            width: 200,
            render: (row) => {
                if (!row?.created) {
                    return ""
                }
                return row?.created?.fullName
            }
            ,
        },
    ];


    const findPAStateinfo = (idSate) => {
        let currentStep = null;
        if (pAStates.length > 0) {
            currentStep = pAStates?.find(pAState => pAState?.id == idSate);
        }
        return currentStep
    }

    const onSendNote = () => {
        const note = form.getFieldValue('notePAState')
        if (note) {
            modal.confirm({
                title: messages['changePAState_noteMessage'],
                okText: messages['changePAState_confirmOnlyNote'],
                cancelText: messages['cancel'],
                onOk: () => {
                    dispatch(savePAStateNote({ note, companyId, stateId: currentStateId }))
                }
            });
            form.setFieldValue('notePAState', " ")
        } else {
            form.validateFields(['notePAState'])
        }
    }

    const _onClose = useCallback((refresh) => {
        dispatch(changePAStateClose())
        dispatch(hide());
        form.resetFields();
        if (refresh && onClose) {
            onClose(refresh)
        }
        dispatch(reset(null));
    }, []);


    const disableNextSate = (id) => id == 9 || id == 5 || id == 11

    return (
        <Modal
            open={show}
            width={800}
            footer={null}
            onCancel={() => _onClose(false)}
            maskClosable={false}
        >
            {contextHolder}
            {contextHolderModal}

            <Loading
                loading={false}
            >
                <div className="p-2 p-md-2">
                    <div className="text-center mb-4">
                        <h3>Processo di gestione e approvazione</h3>
                    </div>
                    <NotificationPAState key={companyId} id={currentStateId} companyId={companyId} />
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={(values) => {

                            dispatch(saveChangePAState({
                                companyId,
                                nextStateId: values.pAStates,
                                note: values.notePAState,
                                defaultAgentId: data?.defaultAgentId != values.defaultAgentId ? values.defaultAgentId : undefined
                            }));
                        }}
                    >
                        <div className='row g-3'>
                            <div
                                className='col-12 col-md-6'
                            >
                                <Form.Item
                                    name="pAStates"
                                    label={<label className="form-label">{messages["changePAState_nextState"]}</label>}
                                    rules={[{
                                        required: true,
                                        message: `Seleziona un nuovo stato e poi salva il cambio`
                                    }]}
                                    style={{ marginBottom: '3%' }}
                                >
                                    <Select
                                        disabled={disableNextSate(currentStateId)}
                                        style={{
                                            width: "100%",
                                            marginBottom: '3%'
                                        }}
                                        options={
                                            pAStates ?
                                                pAStates?.filter((v) => nextStepListByCurrenIdStep?.includes(v.id)
                                                ) : []
                                        }
                                        fieldNames={{ label: "name", value: "id" }}
                                    />
                                </Form.Item>
                            </div>
                            <div
                                className='col-12 col-md-6'
                            >
                                <Form.Item
                                    name="defaultAgentId"
                                    label={<label className="form-label">{messages["companiesEdit_agentsDesigned"]}</label>}
                                    style={{ marginBottom: '3%' }}
                                >
                                    <Select
                                        disabled={!enableAgents}
                                        showSearch
                                        style={{
                                            width: '100%',
                                            marginBottom: '3%'
                                        }}
                                        optionFilterProp="fullname"
                                        filterOption={(input, option) =>
                                            (option?.fullname ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={agentsAssigned ? agentsAssigned.map(v => {
                                            const agent = agents.find(a => a.id == v.agentId)
                                            return {
                                                id: agent?.id,
                                                fullName: agent?.fullName
                                            }
                                        }) : []}
                                        fieldNames={{ label: "fullName", value: "id" }}
                                    />
                                </Form.Item>
                            </div>


                            {/* <div className='col-12'>
                                <div
                                    className='pt-3 '
                                    style={{ textAlign: 'right' }}
                                >
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={() => {
                                            paggination.refresh(null, null, { companyId: companyId });
                                        }}
                                    >
                                        {messages["refresh"]}
                                    </button>
                                </div>
                            </div> */}
                        </div>

                        <div className="row mt-4 table-responsive">
                            <Table
                                size="small"
                                rowKey="id"
                                loading={loading}
                                columns={columns}
                                dataSource={data?.items || []}
                                // scroll={{ x: 500 }}
                                pagination={{
                                    total: data?.count || 0,
                                    ...paggination.tablePaggination,
                                }}
                            />
                        </div>
                        <div
                            className='row'
                        >
                            <Form.Item
                                className="col-12"
                                name="notePAState"
                                label={
                                    <label className="form-label">
                                        {messages["changePAState_note"]}
                                    </label>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: messages["required_field"],
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    maxLength={500}
                                    rows={3}
                                    className="form-control" placeholder={messages["changePAState_rememberChanges"]}
                                />
                            </Form.Item>

                        </div>
                    </Form>
                    <div className="col-12">
                        <div className='pt-2'
                            style={{ textAlign: 'right' }}
                        >
                            <button className="btn btn-icon btn-label-primary" onClick={() => onSendNote()}>
                                <i className="bx bx-paper-plane"> </i>
                            </button>
                        </div>
                    </div>
                </div>
            </Loading>
            <div className='row pt-5'>
                <div className="col-12 text-center">
                    {!disableNextSate(currentStateId) && <button disabled={loading} className="btn btn-primary me-sm-3 me-1" onClick={() => form.submit()} >
                        {messages["companies_changeStatus"]}
                    </button>}
                    <button
                        disabled={loading}
                        className="btn btn-label-secondary"
                        onClick={() => _onClose(false)}>
                        {messages["close"]}
                    </button>
                </div>
            </div>
        </Modal>
    )
}
