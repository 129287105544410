
let locales = {
    permissions_menu: 'Permisos',
    permissions_parentId: 'Parent',
    permissions_hash: 'Hash',
};

//import list from '../../views/list/lan/es'
import edit from '../../modals/edit/lan/es'

locales = {
    ...locales,
    //...list,
    ...edit
}

export default locales;