import { useEffect, useRef, useState } from "react"
import { validateNumber } from '../../config/helpers/validations'

export const OTPInput = ({ value, onChange }) => {

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()

    const [val1, setVal1] = useState('')
    const [val2, setVal2] = useState('')
    const [val3, setVal3] = useState('')
    const [val4, setVal4] = useState('')
    const [val5, setVal5] = useState('')
    const [val6, setVal6] = useState('')

    useEffect(() => {
        if (val1 != '' && val2 != '' && val3 != '' && val4 != '' && val5 != '' && val6 != '')
            onChange && onChange(`${val1}${val2}${val3}${val4}${val5}${val6}`)
        else
            onChange && onChange(null)
    }, [val1, val2, val3, val4, val5, val6])


    return (
        <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
            <input ref={ref1} value={val1} className="m-2 text-center form-control rounded" type="text" maxLength="1"
                onFocus={(e) => { e.target.select() }}
                onChange={(e) => {
                    let value = validateNumber(e.target.value)
                    setVal1(value)
                    if (value)
                        ref2?.current?.focus()
                }}
                onPaste={(e) => {
                    let values = e.clipboardData.getData('Text')
                    setVal1(validateNumber(values[0]))
                    setVal2(validateNumber(values[1]))
                    setVal3(validateNumber(values[2]))
                    setVal4(validateNumber(values[3]))
                    setVal5(validateNumber(values[4]))
                    setVal6(validateNumber(values[5]))
                }}
            />
            <input ref={ref2} value={val2} className="m-2 text-center form-control rounded" type="text" maxLength="1"
                onFocus={(e) => { e.target.select() }}
                onChange={(e) => {
                    let value = validateNumber(e.target.value)
                    setVal2(value)
                    if (value)
                        ref3?.current?.focus()
                    else
                        ref1?.current?.focus()
                }}
            />
            <input ref={ref3} value={val3} className="m-2 text-center form-control rounded" type="text" maxLength="1"
                onFocus={(e) => { e.target.select() }}
                onChange={(e) => {
                    let value = validateNumber(e.target.value)
                    setVal3(value)
                    if (value)
                        ref4?.current?.focus()
                    else
                        ref2?.current?.focus()
                }}
            />
            <input ref={ref4} value={val4} className="m-2 text-center form-control rounded" type="text" maxLength="1"
                onFocus={(e) => { e.target.select() }}
                onChange={(e) => {
                    let value = validateNumber(e.target.value)
                    setVal4(value)
                    if (value)
                        ref5?.current?.focus()
                    else
                        ref3?.current?.focus()
                }}
            />
            <input ref={ref5} value={val5} className="m-2 text-center form-control rounded" type="text" maxLength="1"
                onFocus={(e) => { e.target.select() }}
                onChange={(e) => {
                    let value = validateNumber(e.target.value)
                    setVal5(value)
                    if (value)
                        ref6?.current?.focus()
                    else
                        ref4?.current?.focus()
                }}
            />
            <input ref={ref6} value={val6} className="m-2 text-center form-control rounded" type="text" maxLength="1"
                onFocus={(e) => { e.target.select() }}
                onChange={(e) => {
                    let value = validateNumber(e.target.value)
                    setVal6(value)
                    if (!value)
                        ref5?.current?.focus()
                }}
            />
        </div>
    )
}