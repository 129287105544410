let locales = {
    agetn_configNotications_create_admin: 'Activar notificaciones para creación de notas(administradores)',
    agetn_configNotications_modificate_admin: 'Activar notificaciones para modificaciones de notas(administradores)',
    agetn_configNotications_delete_admin: 'Activar notificaciones para eliminación de notas(administradores)',
    agetn_configNotications_create_agent: 'Activar notificaciones para creación de notas(agentes)',
    agetn_configNotications_modificate_agent: 'Activar notificaciones para modificaciones de notas(agentes)',
    agetn_configNotications_delete_agent: 'Activar notificaciones para eliminación de notas(agentes)',
   
};

export default locales;