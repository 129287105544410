import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";

//STATE INITIAL
const INIT_STATE = {
  data: null,
  loading: false,
  error: null,
};

const route = "modules/client/documentsAndContracts/views/list/redux/slice";

// searchDocumentsContracts(searchText: String, limit: Int, offset: Int): DocumentContractList

// type DocumentContract {
//     id: Int
//     note: String
//     name: String
//     files: [File]
//     visibleTo: [Int]
//     createdBy: Int
//     createdOn: Datetime
//   }

// Extend async Action

export const getDocumentsList = createAsyncThunk(
  "documentsAndContracts/getDocumentsList",
  async (
    { searchText = null, limit = 10, offset = 0, filters },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let data = await clientQuery(
        `query searchDocumentsContracts(
                $searchText: String,
                $limit: Int,
                $offset: Int,
                $filters: FiltersDocumentContract
                ){ documentsList: searchDocumentsContracts(
                searchText: $searchText,
                limit: $limit,
                offset: $offset,
                filters: $filters){
                count
                items{
                    id
                    note
                    name
                    files {
                        url
                        name
                        id
                        type
                    }
                    visibleTo
                    createdBy
                    createdOn
                    }
                }
            
            }`,
        {
          searchText,
          limit,
          offset,
          filters
        },
        endpoints.GRAPHQL_GENERAL
      );

      console.log("🚀 ~ data:", data);
      return data?.documentsList || null;
    } catch (exc) {
      ExceptionManager(exc, route, "getDocumentsList", dispatch);
      return rejectWithValue(exc);
    }
  }
);

//Slice
export const documentsAndContractsSlice = createSlice({
  name: "documentsAndContractsList",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    // getDocumentsList
    builder.addCase(getDocumentsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDocumentsList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getDocumentsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = documentsAndContractsSlice;

// Extract and export each action creator by name
export const { reset } = actions;

// Export the reducer, either as a default or named export
export default reducer;
