//Global keys
let locales = {
    "users_menu": "Utenti",
    "users": "Utenti",
    "users_usersList": "Lista degli utenti",
    "users_agents": "Agenti"
};

//Merge Modules
import userList from '../../views/list/lan/it'
import editUser from '../../modals/editUser/lan/it'

locales = {
    ...locales,
    ...userList,
    ...editUser
};

export default locales;