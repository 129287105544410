let locales = {
    "forms_menu": "Questionari",
    "forms_title": "Titolo",
    "forms_description": "Descrizione",
    "forms_questions": "Questions"
};

// merge modal SubmitDocument
import list from '../../views/list/lan/it'

locales = {
    ...locales,
    ...list,
};

export default locales;