//Global keys
let locales = {
    ok: 'Accettare',
    close: 'Chiudere',
    add: 'Aggiungere',
    edit: 'Modificare',
    delete: 'Rimuovere',
    seeDetails: 'Vedi dettaglio',
    cancel: 'Annulla',
    print: 'Stampa',
    show: 'Guadare',
    search: 'Cercare',
    continue: 'Continuare',
    back: 'Torna indietro',
    save: "Salvare",
    new: "Nuovo",
    required_field: 'Campo obbligatorio',
    clear: 'Pulire',
    select: 'Selezionare',
    rows: 'Record',
    actions: 'Azioni',
    invalid_email: 'E-mail non valido',
    or: "o",
    refresh: "Refresh",
    filter: "Filtri",
    export: "Esportare",
    deleteSucessfully: "Registrazione eliminata con successo!!",
    saveSuccessfully: "È stato memorizzato con successo!!",
    saveSuccessfullyTitle: "Va bene!!",
    saveError: "Si è verificato un errore",
    selected: "Selezionato/i",
    deleteSelected: "Elimina selezionati",
    table_rows: "Record",
    resend: 'Invia di nuovo',
    validate: 'Convalidare',
    list: 'elenco',
    logout: 'Logout',
    view: "Vedere",
    sendEmail: 'Avanti/Invia',
    createdOn: "Creato",
    createdBy:  "Creato da",
    updatedOn: "Aggiornato su",
    updatedBy:  "Aggiornato da",
    yes: 'Si',
    no: 'No',
    apply: 'Applicare',
    all: 'Tutti',
    write: 'Scrittura',
    read: 'Lettura',
    description: 'Descrizione',
    key: 'Chiave',
    id: 'Id',
    value: 'Valore',
    numeric: 'Numeric',
    text: 'Testo',
    length: 'Lunghezza',
    multiline: 'Multiline',
    option: 'Opzione',
    options: 'Opzioni',
    questions: 'Domande',
    question: 'Domanda',
    mode: 'Modalità',
    rule: 'Regola',
    rules: 'Regole',
    section: 'Sezione',
    sections: 'Sezioni',
    name: 'Nome',
    confirmDelete: 'Sei sicuro di voler rimuovere questo elemento?',
    status: "Stato",
    email: "Email",
    company: "Azienda",
    data_already_exist: "Questo è già stato registrato",
    previous: 'Anteriore',
    next: 'Successivo',
    approve: "Approvare",
    reject: "Rifiutare",
    seeMore: 'vedi tutto'
};

const permissions= {
    puser_management: 'Administración de Usuarios',
    puser_list: 'Listado de Usuarios',
    puser_edit: 'Edición de Usuarios',
    puser_edit_assing_lead: 'Asinación de Lider de Usuario',
    psurvey: 'Encuestas',
    psurvey_list: 'Listado de Encuestas',
    psurvey_edit: 'Edición de Enecuestas'
}

const apiErrors = {
    "Failed to fetch": "Si è verificato un errore nel tentativo di comunicare con il server, verificare se si dispone dell'accesso a Internet. o contattare l'amministratore di sistema",
    DB_Duplicated: "Il record esiste già nel database.",
    loginInvalid: "Username o password non validi",
    loginInvalidIp: "L'ip da cui stai accedendo non è valido, contatta l'amministratore di sistema",
    loginMaxAttemps: "Hai raggiunto il numero massimo di tentativi effettuati, riprova tra {time, number} secondi",
    loginInvalidOtp: "Il codice OTP non è valido o è scaduto",
    loginUserBlocked: "Il tuo account è stato bloccato per vari motivi di sicurezza. Per maggiori informazioni Contatta L’assistenza",
    unAuthorized: "Senza autorizzazione"
}

//Merge Modules
import security from '../../../core/security/.config/lan/it';
import modules from '../../../modules/.config/lan/it';

// Merge notifications
import notifications from '../../../components/Notifications/.config/lan/it';

locales = {
    ...locales,
    ...apiErrors,
    ...security,
    ...modules,
    ...notifications,
    ...permissions
};

export default locales;