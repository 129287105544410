import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetALL } from '../../../../config/redux/actions';
import { clientQuery, clientMutation } from '../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../config/constants';
import { ExceptionManager } from '../../../../config/helpers/Logging'

const INIT_STATE = {
    data: null,
    active_notifications: 0,
    error: null,
    loading: false,
    notificationResult: null,
    tableNotifications: null,
}
const route = 'components/Notifications/.config/redux/slice';

export const getListNotifications = createAsyncThunk('notifications/getListNotifications', async ({daysAgo = null, isNoRead = false}, {rejectWithValue, dispatch}) =>{
    try {
        let data = await clientQuery(
            `query searchNotifications($daysAgo: Int, $isNoRead: Boolean){
               notifications: searchNotifications( daysAgo: $daysAgo, isNoRead: $isNoRead, limit: 20 ) {
                    items {
                        id
                        userId
                        user {
                            id
                            username
                            imageUrl
                        }
                        title
                        body
                        read
                        action
                        createdOn
                    }
                    unreadCount
                }
            }`,
            {
                daysAgo,
                isNoRead
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.notifications || null
    } catch (exc) {
        ExceptionManager(exc, route, 'getListNotifications', dispatch);
        return rejectWithValue(exc)
    }
})



export const getListNotificationsTable = createAsyncThunk('notifications/getListNotificationsTable', async ({searchText, limit = 10, offset = 0, userId = null, date}, {rejectWithValue, dispatch}) =>{
    try {
        let data = await clientQuery(
            `query searchNotificationsByUserId($searchText: String, $limit: Int, $offset: Int, $userId: Int, $startDate: Datetime, $endDate: Datetime){
                result: searchNotificationsByUserId(searchText: $searchText, limit: $limit, offset: $offset, userId: $userId, startDate: $startDate, endDate: $endDate) {
                    items {
                        id
                        userId
                        user {
                            id
                            username
                            imageUrl
                        }
                        created {
                            fullName
                        }
                        title
                        body
                        read
                        action
                        createdOn
                    }
                    count
                }
            }`,
            {
                searchText,
                limit,
                offset,
                userId,
                startDate: date ? date[0].format('YYYY-MM-DD') : null,
                endDate: date ? date[1].format('YYYY-MM-DD') : null
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.result || null
    } catch (exc) {
        ExceptionManager(exc, route, 'getListNotificationsTable', dispatch);
        return rejectWithValue(exc)
    }
})




export const updateNotification = createAsyncThunk('notifications/updateNotification', async({ title = null, body = null, read = null, id  }, {rejectWithValue, dispatch}) => {
    try {
        let data = await clientMutation(
            `mutation updateNotification($id: Int!, $title: String, $body: String, $read: Boolean){
               notification: updateNotification(id: $id , title: $title, body: $body, read: $read) {
                    id
                    userId
                    user {
                        id
                    }
                    title
                    body
                    read
                }
            }`,
            {
                title: title,
                body: body,
                read: read || false,
                id: id,
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.notification || null
        
    } catch (exc) {
        ExceptionManager(exc, route, 'updateNotification', dispatch);
        return rejectWithValue(exc)
    }
} )

//Slice
export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        
        builder.addCase(resetALL, () => INIT_STATE)

        // getNotifications
        builder.addCase(getListNotifications.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(getListNotifications.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.active_notifications = action.payload.unreadCount
        })
        builder.addCase(getListNotifications.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        // updateNotification
        builder.addCase(updateNotification.pending, (state) => {
            state.loading = true;
            state.notificationResult = null;
            state.error = null;
        })
        builder.addCase(updateNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.notificationResult = action.payload;
        })
        builder.addCase(updateNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        // getListNotificationsTable
        builder.addCase(getListNotificationsTable.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(getListNotificationsTable.fulfilled, (state, action) => {
            state.loading = false;
            state.tableNotifications = action.payload
        })
        builder.addCase(getListNotificationsTable.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
});


// Extract and export the action creators object and the reducer
export const { actions, reducer } = notificationsSlice;

// Action creators are generated for each case reducer function
export const { reset } = actions;

export default reducer;
