let locales = {
    campaignsList_sentBy: 'Inviato da',
    campaignsList_mailingDate: 'Data di invio',
    campaignsList_EmailSubject: 'Oggetto del email',
    campaignsList_deliveredEmail: 'Posta ricevuta',
    campaignsList_dateEmailRead: 'Data di lettura',
    campaignsList_emailCompany: 'E-mail',
    campaignsList_companyName: 'Nome della società',
    campaignsList_date: 'Data di spedizione',
    campaignsList_emailhasError: 'Invio non riuscito',
    campaignsList_emailWasRead: 'Lettura della posta',
    campainsList_filterAction: 'Azione e-mail',
    campaignsList_role: 'Role',
    campaignsList_sendBy: 'Inviato da',
};

export default locales;