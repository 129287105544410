let locales = {
    companiesDetailPayments_paymentMethod: 'Metodo de Pago',
    companiesDetailPayments_price: 'Precio',
    companiesDetailPayments_payStartDate: 'Activación Pago',
    companiesDetailPayments_payEndDate: 'Caducidad Pago',
    companiesDetailPayments_add: 'Agregar Pago',
    companiesDetailPayments_title: 'Ultimos pagos realizados',
    companiesDetailPayments_deletePayment: 'Eliminar pago',
    companiesDetailPayments_deletePaymentMessage: 'Esta acción no se puede deshacer.',
    companiesDetailPayments_addFilesDescription: 'Añade hasta 5 archivos en formato jpg, png o pdf.',
    companiesDetailPayments_addFiles: 'Añadir archivos',
    companiesDetailPayments_Files: 'Archivos',
    companiesDetailPayments_paymentDetail: 'Detalle del pago',
};

export default locales;