let locales = {
    "httpLogs_menu": "Registros HTPP",
};

// merge modal SubmitDocument
import list from '../../views/list/lan/es'

locales = {
    ...locales,
    ...list,
};

export default locales;