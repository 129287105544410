let locales = {
    role: 'Role',
    roles: 'Roles',
    role_permissions: 'Permissi',
    roles_access: "Permettere",
};

//import list from '../../views/list/lan/es'
import edit from '../../modals/edit/lan/it'

locales = {
    ...locales,
    //...list,
    ...edit
}

export default locales;