
// import { createStore, applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

// ...
import reducers from "./reducers";

//import { middleware} from '../../modules/management/companies/views/list/redux/service'

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),//.concat([middleware])
  ],
});

export default store;