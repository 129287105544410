import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clientQuery,
  clientMutation,
} from "../../../../../../../config/helpers/GraphQLApi";

import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  error: null,
};

const route = "modules/management/parameters/modals/editParameter/slice";

//Extend async Action
export const globalParameterEditOpen = createAsyncThunk(
  "parameter/editParameter/open",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      //si es una creacion se retorna
      if (!id) {
        return { data: null };
      }

      //si es edicion se optiene la informacion
      let data = await clientQuery(
        `query getParameterById($id: Int!) {
                parameter: getParameterById(id: $id) {
                  id
                  key
                  value
                  parameterType
                  description
                }
             }`,
        {
          id: Number(id),
        },
        endpoints.GRAPHQL_GENERAL
      );

      //Se retorna la data del server
      return { data: data.parameter };
    } catch (exc) {
      //Se guarda la excepcion
      ExceptionManager(exc, route, "globalParameterEditOpen", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const globalParameterEditSave = createAsyncThunk(
  "parameters/editParameter/save",
  async ({ id, key, value, description, type }, { rejectWithValue, dispatch }) => {
    try {
      let data;
      // console.log("globalParameterEditSave", id, key, value, description, type)
      if (id) {
        data = await clientMutation(
          `mutation updateParameter ($id: Int!, $key: String!, $type: String, $description: String, $value: String) {
            updateParameter (id: $id, key: $key, type: $type, description: $description, value: $value)
                {
                  id
                }
            }`,
          {
            id,
            key, value, description, type
          },
          endpoints.GRAPHQL_GENERAL
        );

        return data.updateParameter.id;
      } else {
        //create
        data = await clientMutation(
          `mutation createParameter ($key: String!, $type: String, $description: String, $value: String) {
            createParameter (key: $key, type: $type, description: $description, value: $value) 
                {
                    id
                }
            }`,
          {
            key, value, description, type
          },
          endpoints.GRAPHQL_GENERAL
        );

        return data.createParameter.id;
      }
    } catch (exc) {
      ExceptionManager(exc, route, "globalParameterEditSave", dispatch);
      return rejectWithValue(exc);
    }
  }
);

//Slice
const globalParameterEditRedux = createSlice({
  name: "parameters/editParameter",
  initialState: INIT_STATE,
  reducers: {
    globalParameterEditClose(state, action) {
      return INIT_STATE;
    },
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    //thunk editOpen
    builder.addCase(globalParameterEditOpen.pending, (state) => {
      state.loading = true;
      state.isOpen = true;
    });
    builder.addCase(globalParameterEditOpen.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    });
    builder.addCase(globalParameterEditOpen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //thunk save
    builder.addCase(globalParameterEditSave.pending, (state) => {
      state.loading = true;
      state.result = null;
      state.error = null;
    });
    builder.addCase(globalParameterEditSave.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(globalParameterEditSave.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = globalParameterEditRedux;

// Extract and export each action creator by name
export const { globalParameterEditClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
