import { Form, Input, Select, Modal, Drawer } from "antd";
import { useState, useEffect } from "react";
import PropsSelector from "../../components/PropsSelector";
import { useIntl } from "react-intl";

const QUESTIONS_TYPES = {
    string: "INPUT",
    number: "INPUT",
    boolean: "SELECT",
    catalog: "SELECT",
    multiCatalog: "MULTISELECT",
    file: "UPLOAD",
    date: "TIME",
    time: "TIME",
    datetime: "TIME",
    promemoria: "NOTA"
};
const DATA_TYPES = [
    { label: "Text", value: "string" }, //Input
    { label: "Number", value: "number" }, //Input
    { label: "Yes/No", value: "boolean" }, //Select
    { label: "Drop-down list", value: "catalog" }, // Select
    { label: 'Multiple choice', value: "multiCatalog" }, //MultiSelect
    { label: 'File', value:'file'}, // Upload
    { label: 'Date', value:'date'}, // 
    { label: 'Promemoria', value:'promemoria'}
    /* { label: 'Hour', value:'time'}, 
    { label: 'Date & Time', value:'datetime'} */
];

function EditQuestionModal({ data, show, close, onSave }) {
    const intl = useIntl();
    const { messages } = intl;
    const [form] = Form.useForm();

    const [showProperties, setShowProperties] = useState(false)
    const [type, setType] = useState(null)

    useEffect(() => {
        if(data){
                const _data = {
                    ...data,
                    props: JSON.parse(data.props),
                    typeData:  data.questionType == "MULTISELECT" && data.typeData == 'catalog' ? "multiCatalog" : data.typeData, 
                }
                if(data?.typeData){
                    showDrawer()
                    setType(data?.typeData)
                }
                form.setFieldsValue({
                    ..._data,
                });
        }
    }, [data]);

    const onClose = () => {
        if(data?.isNew){
            form.resetFields();
            onCloseDrawer()
            setTimeout(() => {
                close(true);
            }, 100);
            return;
        }
        form.validateFields().then(() => {
            form.resetFields();
            onCloseDrawer()
            setTimeout(() => {
                close();
            }, 100);
        }).catch(() => {})
    }

    const showDrawer = () => {
        setShowProperties(true);
    };
    
    const onCloseDrawer = () => {
        setShowProperties(false);
        setType(null)
    };

    const required = {
        required: true,
        message: messages["required_field"],
    };
    return (
        <Modal
            title={<h5>{data?.id ? 'Edit Question' : 'New Question'}</h5>}
            open={show}
            width={500}
            onCancel={onClose}
            footer={null}
        >
            <Form
                key="form-editQuestion"
                form={form}
                layout="vertical"
                onValuesChange={(cValue) => {
                    if(cValue?.typeData){
                        showDrawer();
                        setType(cValue?.typeData)
                    }
                }}
                onFinish={(values) => {
                    let typeData = values.typeData
                    let finalValue = {
                        ...data,
                        ...values,
                        questionType: QUESTIONS_TYPES[typeData],
                        typeData: typeData == "multiCatalog" ? "catalog" : typeData,
                        props: values?.props
                                ? JSON.stringify(values.props)
                                : "{}",
                    };
                    form.resetFields()
                    onCloseDrawer()
                    setTimeout(() => {
                        onSave(finalValue);
                    }, 200);
                }}
            >
                    <Form.Item
                        label="Question"
                        className="mb-2"
                        name={"question"}
                        rules={[required]}
                    >
                        <Input
                            className="form-control"
                            placeholder={"Question"}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Help Text"
                        className="mb-2"
                        name={"helpText"}
                    >
                        <Input
                            className="form-control"
                            placeholder={"Help Text"}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        className="mb-2"
                        name={"typeData"}
                        rules={[required]}
                    >
                        <Select options={DATA_TYPES} />
                    </Form.Item>
                    <Drawer 
                        title="Properties" 
                        onClose={onCloseDrawer} 
                        open={showProperties} 
                        autoFocus={false}
                        maskClosable={false}
                        footer={null}
                        mask={false}
                    >
                        <Form.Item
                            label=""
                            className="mb-2"
                            name="props"
                        >
                            <PropsSelector type={type} />
                        </Form.Item>
                    </Drawer>
            </Form>
            <div className="row pt-5">
                    <div className="text-center pt-10">
                        <button
                            className="btn btn-primary me-sm-3 me-1"
                            onClick={() => form.submit()}
                            type="button"
                        >
                            {messages["save"]?.toString()}
                        </button>
                        <button
                            type="button"
                            className="btn btn-label-secondary"
                            onClick={onClose}
                        >
                            {messages["close"]?.toString()}
                        </button>
                    </div>
            </div>
        </Modal>
    );
}

export default EditQuestionModal;
