let locales = {
    users_editUser_edit: 'Editar Usuario',
    users_editUser_create: 'Crear Usuario',
    users_editUser_avatar: 'Avatar',
    users_editUser_username: 'Usuario',
    users_editUser_email: 'Email',
    users_editUser_roles: 'Roles',
    users_editUser_firstName: 'Nombres',
    users_editUser_lastName: 'Apellidos',

};

export default locales;