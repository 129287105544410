let locales = {
    workflow_card_name: 'Nome ',
    workflow_card_color: 'Colore ',
    workflow_card_limitHours: 'Limite orario ',
    workflow_card_limitAttemps: 'Limite dei tentativi ',
    workflow_card_helpText: 'Aiuto ',
    workflow_card_description: 'Descrizione',
    workflow_card_updatedAt:'Data di aggiornamento '
};



// locales = {
//     ...locales,
// }

export default locales;