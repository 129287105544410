import { useMemo } from "react";

export const Avatar = ({ src, text, width = 45, ...props }) => {

    const shortName = useMemo(() => {
        if (text) {
            if (text.length > 3) {
                const textSplit = text.split(" ")
                if (textSplit.length > 1) {
                    return `${textSplit[0].substring(0, 1)}${textSplit[1].substring(0, 1)}`
                }

                return text.substring(0, 2)
            }

            return text
        }
        return ""
    })

    return (
        <div {...props}>
            {
                src
                    ? <img src={src} className="rounded-circle" width={width} height={width} />
                    : <span className="round rounded-circle text-white d-inline-block text-center bg-info">{shortName}</span>
            }
        </div>
    )
}