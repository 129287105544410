import { InputNumber } from 'antd';

function FileProps(props) {
    const { values, onChange } = props;
    return (
        <div className='col-12'>
            <div className='row mb-2'>
            <small className="mt-sm-auto mt-2 order-sm-1 order-0">**Only the following file types are allowed: jpeg, png. Maximum 6MB per file</small>
            </div>
        </div>
    )
}

export default FileProps