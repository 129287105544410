import sendersRedux from '../../components/senders/redux/slice'
import templatesRedux from '../../components/templates/redux/slice'

import editCampaignFromRedux from '../../modals/editCampaignFrom/redux/slice'
import editTemplateRedux from '../../modals/editTemplate/redux/slice'

import parametersListRedux from '../../views/list/components/parameters/redux/slice'
import globalParameterEditRedux from '../../modals/editParameter/redux/slice'
import configListNotifications from '../../components/agentConfigNotifications/redux/slice'
import parametrizableTextsListRedux from '../../components/parameterizableTexts/redux/slice'
import globalParametrizableTextEditRedux from '../../modals/editParametrizableText/redux/slice'
import statusAnnouncementListRedux from '../../components/configStatusLeads/redux/slice'
import statusLeadsEditRedux from '../../modals/editSatutsLeads/redux/slice'

//export redux objects
export default {
	editTemplateRedux,
	editCampaignFromRedux,
	sendersRedux,
	templatesRedux,
	parametersListRedux,
	globalParameterEditRedux,
	parametrizableTextsListRedux,
	globalParametrizableTextEditRedux,
	configListNotifications,
	statusAnnouncementListRedux,
	statusLeadsEditRedux
};
