//Global keys
let locales = {
    "submitDocument_title": "Carica Documenti",
    "submitDocument_name":  "Nome File",
    "submitDocument_note":  "Note",
    "submitDocument_descriptionUpload":  "Fare clic o trascinare il file in quest'area per caricarlo",
    "submitDocument_descriptionFiles":  "Supporta tutti i tipi di documenti di dimensioni inferiori a 10 MB",
    "submitDocument_placeholderDocumentName":  "Inserire il nome del documento",
};

export default locales;