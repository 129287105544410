import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../config//constants';
import { ExceptionManager } from '../../../../config//helpers/Logging'

import { openModalAffiliatePartner, resetALL } from '../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    user: null,
    currentClient: null,
    result: null,
    historyRoutes: []
};

const route = 'core/security/auth/redux/slice'

//Extend async Action
export const getUserProfile = createAsyncThunk('security/auth/getUserProfile', async ({ navigate }, { rejectWithValue, dispatch }) => {
    try {
        let data = await clientQuery(
            `
            {
                user: getUserProfile {
                    id
                    username
                    firstName
                    lastName
                    imageUrl
                    email
                    isAdmin
                    companyId
                    permissions {
                        name
                        hash
                        isSingle
                        C
                        R
                        U
                        D
                    }
                    roles {
                        name
                        id
                    }
                    company {
                        businessCode
                    }
                    currentPartnerAffiliato {
                        agentId
                        companyName
                        companyId
                        timeRemainingSeconds
                        blocked
                        currentState
                        helpText
                        description
                    }
                    partnerAffiliatoDocs {
                        clientFullName
                        pendingToApproveDocument 
                        pendingToSubmitFile 
                        companyAnnouncementId
                        hasDocument 
                        emailContent
                    }
                }
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL
        );

        if (data?.user) {
            if(data.user?.partnerAffiliatoDocs?.length > 0){
                dispatch(openModalAffiliatePartner())
            }
            if (data.user.clients?.length > 0)
                dispatch(setCurrentClient(data.user.clients[0]))
            return data.user
        }
        else
            throw Error("User not Authenticated");

    } catch (exc) {
        ExceptionManager(exc, route, 'getUserProfile');
        dispatch(logout({ navigate, mandatory: true }))
        return rejectWithValue(exc)
    }
})

export const logout = createAsyncThunk('security/auth/logout', async ({ navigate, mandatory }, { rejectWithValue, dispatch }) => {
    try {
        console.log("createAsyncThunk Logout")

        localStorage.removeItem('token');
        localStorage.removeItem('historyRoutes');
        let keys = Object.keys(sessionStorage)
        keys.forEach(key => {
            sessionStorage.removeItem(key)
        })

        dispatch(resetALL())

        if (mandatory)
            navigate && navigate('/login');

    } catch (exc) {
        ExceptionManager(exc, route, 'logout');
        navigate && navigate('/login');
        return rejectWithValue(exc)
    }
})

const authRedux = createSlice({
    name: 'security/auth',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        setCurrentClient(state, action) {
            state.currentClient = action.payload
        },
        logRoute (state, action) {
            let history = localStorage.getItem('historyRoutes')
            let newVal = []
            if(history){
                history = JSON.parse(history)
                if (history.length > 10) {
                    history.pop();
                }
                newVal = [action.payload,...history]
                state.historyRoutes = newVal
                localStorage.setItem('historyRoutes',JSON.stringify(newVal))
            } else {
                newVal = [action.payload]
                localStorage.setItem('historyRoutes', JSON.stringify(newVal))
                state.historyRoutes = newVal
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(getUserProfile.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
            state.loading = false
            state.user = payload
        })
        builder.addCase(getUserProfile.rejected, (state, action) => {
            state.loading = false
            state.result = { error: action.payload }
        })

        builder.addCase(logout.pending, (state) => {
            state.loading = true
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.loading = false
            // state.result = payload
        })
        builder.addCase(logout.rejected, (state, action) => {
            state.loading = false
            state.result = { error: action.payload }
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = authRedux

// Extract and export each action creator by name
export const { reset, setCurrentClient, logRoute } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer