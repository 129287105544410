import {
  useEffect,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { Button, notification } from "antd";
import {
  userAnnouncementDetailGetById,
  updateViewUserAnnouncement,
  reset,
} from "./redux/slice";
//import Icons from "../../../../../../components/Icons";
import NotesList from "../../components/listNotes";
import moment from "moment";
import StatusChangesHistory from "../../modals/statusChangesHistory";
import { openStatusChangesHistory, openChangeStatus, dashboarNotesListSearch } from "../../.config/redux/actions";
import ChangeStatus from "../../components/changeStatus";
import { useSecurity } from "../../../../../../config/hooks";
import { DinamicFormByStep } from "../../modals/dinamicFormByStep";
//permissions
import { P_GL_AGENTE_NOTES, P_GL_AGENTE_STATUS_COLUMN, P_GL_AGENTE_UPDATE_ANNOUNCEMENT_STATUS } from "../../../../../../config/helpers/permissionsHash";
import { P_GL_AREA_MANAGER_NOTES, P_GL_AREA_MANAGER_UPDATE_ANNOUNCEMENT_STATUS, P_GL_AREA_MANAGER_STATUS_COLUMN } from "../../../../../../config/helpers/permissionsHash";
import { getCatalog } from "../../../../../.config/redux/actions";
//permissions end

const permissionsByRole = {
  agents: {
    notes: P_GL_AGENTE_NOTES,
    changeStatus: P_GL_AGENTE_UPDATE_ANNOUNCEMENT_STATUS,
    showStatusCol: P_GL_AGENTE_STATUS_COLUMN,
  },
  areaManager: {
    notes: P_GL_AREA_MANAGER_NOTES,
    changeStatus: P_GL_AREA_MANAGER_UPDATE_ANNOUNCEMENT_STATUS,
    showStatusCol: P_GL_AREA_MANAGER_STATUS_COLUMN,
  }
}

const UserAnnouncementDetailView = ({ roleName = 'agents' }) => {
  let { id, userId } = useParams();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.authRedux);
  const { data, error, result, resultError } = useSelector(
    (state) => state.userAnnouncementDetailRedux
  );
  const { announcementStatusList } = useSelector((state) => state.catalogRedux);

  useEffect(() => {

    dispatch(getCatalog({ keys: ["announcementStatus"] }))

    dispatch(
      userAnnouncementDetailGetById({
        id,
        userId,
      })
    );
    if (!user.isAdmin)
      dispatch(updateViewUserAnnouncement({ id, viewType: "View" }));

    return () => {
      dispatch(reset());
    };
  }, [userId, id]);

  useEffect(() => {
    if (result) {
      notification.success({ message: messages["saveSuccessfully"] })
      dispatch(reset({ result: null }))
      dispatch(
        userAnnouncementDetailGetById({
          id,
          userId,
        })
      );
    }

    if (resultError) {
      notification.error({ message: messages["saveError"], description: messages[resultError] || resultError })
      dispatch(reset({ resultError: null }))
    }

  }, [result, resultError]);

  useEffect(() => {
    if (error) {
      error == 'unAuthorized' ? navigate("/unauthorized") : navigate("/error");
    }
  }, [error]);

  // Implementacion de permisos 
  const pShowStatusCol = useSecurity(permissionsByRole[roleName].showStatusCol);
  const pChangeStatus = useSecurity(permissionsByRole[roleName].changeStatus);
  const pShowNotes = useSecurity(permissionsByRole[roleName].notes);

  const statusAler = () => {
    if (pShowStatusCol && data?.status) {
      let bgcolor = null;
      switch (data?.status) {
        case 1:
          bgcolor = "bg-list-blue";
          break;
        case 2:
          bgcolor = "bg-list-yellow";
          break;
        case 3:
          bgcolor = "bg-list-orange";
          break;
        case 4:
          bgcolor = "bg-list-purple";
          break;
        case 5:
          bgcolor = "bg-list-green";
          break;
        case 6:
          bgcolor = "bg-list-red";
          break;
        default:
          break;
      }

      return (
        <div
          onClick={() =>
            dispatch(
              openStatusChangesHistory({ companyAnnouncementId: data?.id, isUserAnnouncement: true })
            )
          }
          style={{ cursor: "pointer" }}
          className={`alert ${bgcolor}`}
          role="alert"
        >
          <h6 className="alert-heading mb-1 text-white">
            {`${data?.status ? announcementStatusList?.find(v => data?.status == v.id)?.name : 'avvia il cambiamento di stato'}`}
          </h6>
          <span className="text-white">
            {data?.lastStatus?.updatedOn &&
              moment(data.lastStatus.updatedOn).format("DD/MM/yyyy HH:mm a")}
          </span>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="container-fluid flex-grow-1 container-p-y">
      {statusAler()}
      <div className="card mb-4">
        <h5 className="card-header">{messages["dashboardDetail_title"]}</h5>
        <div className="card-body">
          <div className="row">
            <h6 className="card-subtitle mb-2">
              {messages["dashboardDetail_title1"]}:
            </h6>
            {data?.announcement?.description && (
              <div className="col-12 col-xl-12">
                <h6>{data?.announcement?.description}</h6>
              </div>
            )}
            {data?.announcement?.product && (
              <div className="col-12 col-xl-4">
                <small className="text-muted">
                  {messages["dashboardDetail_1"]}
                </small>
                <h6>{data?.announcement?.product}</h6>
              </div>
            )}
            <div className="col-12 col-xl-4">
              <small className="text-muted">
                {messages["dashboardDetail_2"]}
              </small>
              <h6>
                {data?.announcement?.max_price ||
                  messages["dashboardDetail_notSpecified"]}
              </h6>
            </div>
            {data?.announcement?.definition && (
              <div className="col-12 col-xl-4">
                <small className="text-muted">
                  {messages["dashboardDetail_7"]}
                </small>
                <h6>{data?.announcement?.definition}</h6>
              </div>
            )}
            {data?.announcement?.id && (
              <div className="col-12 col-xl-4">
                <small className="text-muted">
                  {messages["dashboardDetail_9"]}
                </small>
                <h6>{data?.announcement?.id}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <h5 className="card-header">{messages["dashboardDetail_title2"]}</h5>
        <div className="card-body">
          {data && (
            <div className="row">
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author1"]}
                </small>
                <h6>
                  {data?.announcement?.name} {data?.announcement?.surname}
                </h6>
              </div>
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author2"]}
                </small>
                <h6>{data?.announcement?.province_name}</h6>
              </div>
              <div className="col-12">
                <small className="text-muted">
                  {messages["dashboardDetail_author3"]}
                </small>
                <h6>{data?.announcement?.comuna_name}</h6>
              </div>
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author4"]}
                </small>
                <h6>{data?.announcement?.phone}</h6>
              </div>
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author5"]}
                </small>
                <h6>{data?.announcement?.email}</h6>
              </div>
              <div className="col-12 col-xl-6">
                <Button
                  icon={<i className="tf-icons bx bxl-whatsapp"></i>}
                  onClick={() => {
                    if (!user.isAdmin) {
                      dispatch(
                        updateViewUserAnnouncement({
                          id,
                          viewType: "Whatsapp",
                        })
                      );
                    }
                    window.open(
                      `https://wa.me/39${data?.announcement?.phone}?text=${data?.announcement?.product}`,
                      "_blank",
                      "noreferrer"
                    );
                  }}
                >
                  {messages["dashboardDetail_whatsapp"]}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <StatusChangesHistory
        onChangeStatus={() => {
          dispatch(
            userAnnouncementDetailGetById({
              id,
              userId,
            })
          );
        }}
        refresh={() => { dispatch(dashboarNotesListSearch({ companyAnnouncementId: data?.id, isUserAnnouncement: true, limit: 10, offset: 0 })) }}
      />
      {pShowNotes && (
        <NotesList isUserAnnouncement={true} companyAnnouncementId={data?.id} ownerId={data?.userId} additionalButtons={[
          (pChangeStatus ? <button
            key={0}
            className="btn btn-primary me-sm-3 me-1"
            // onClick={() => {
            //   dispatch(openChangeStatus({
            //     codeRichiesta: data?.announcement?.id,
            //     initialValue: data?.status,
            //     id: data?.id,
            //     isUserAnnouncement: true
            //   }))
            // }}
            onClick={() => {
              dispatch(openStatusChangesHistory({ companyAnnouncementId: data?.id, isUserAnnouncement: true }))
            }}
          >
            {messages["dashboardList_changeStatus"]}
          </button> : <></>)
        ]} />
      )}
      <ChangeStatus.Modal
        refresh={() => {
          dispatch(
            userAnnouncementDetailGetById({
              id,
              userId,
            })
          );
        }}
      />
      {/* <DinamicFormByStep userAnnouncementId={data?.id} /> */}
    </div>
  );
};

export default UserAnnouncementDetailView;
