import { Routes, Route, Navigate } from 'react-router-dom';
import { useParams } from 'react-router';
import CompaniesListView from './views/list'
import CompaniesDetailView from './views/detail'
import UserListView from '../users/views/listAreaManager'
import {useSecurity} from '../../../../config/hooks';
import { P_AFFILIATED_PARTNERS, P_AGENTS, P_AREA_MANAGER, P_MARKETING_SERVICES } from '../../../../config/helpers/permissionsHash';
import AgentsListView from './views/listAgents';
import UserViewDetail from '../users/views/detail';

const CompaniesRouter = () => {
    const { businessCode } = useParams();

    const readList1 = useSecurity(P_MARKETING_SERVICES, ['R']);
    const readList2 = useSecurity(P_AFFILIATED_PARTNERS, ['R']);
    const readList3 = useSecurity(P_AGENTS, ['R']);
    const readList4 = useSecurity(P_AREA_MANAGER, ['R']);

    return (
        <Routes>
            {/* <Route path='/' element={<Navigate to={`/admin/management/companies/list2`} />}></Route> */}
            {useSecurity(P_MARKETING_SERVICES) && <Route path='/list' element={<CompaniesListView businessCode={0} />} />}
            {useSecurity(P_AFFILIATED_PARTNERS) && <Route path='/list2' element={<CompaniesListView businessCode={1} />} />}
            {useSecurity(P_AGENTS) && <Route path='/list3' element={<AgentsListView />} />}
            {useSecurity(P_AREA_MANAGER) && <Route path='/list4' element={<UserListView/>} />}
            {(readList1 || readList2 || readList3 || readList4) && <Route path='/detail/:id' element={<CompaniesDetailView businessCode={parseInt(businessCode, 10)} />} />}
            {readList3 && <Route path='/user/detail/:id' element={<UserViewDetail />} />}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default CompaniesRouter;