//Global keys
let locales = {
    "emailProgramming_table_subject": "Lead ID/Nome Committente",
    "emailProgramming_table_note": "Contenuto della nota",
    "emailProgramming_table_emailSchedule": "Data/Ora Invio Promemoria",
    "emailProgramming_table_updatedOn": " Data Creazione",
    "emailProgramming_table_sendStatus": " stato della spedizione ",
    "emailProgramming_table_updated": "Aggiornato da:",
    "emailProgramming_showAgent": "Vedi agenti",
    "emailProgramming_showAffiliati": "Vedi affiliati",
};


export default locales;