let locales = {
    users_userList_title: 'Lista degli utenti',
    users_userList_username: 'Username',
    users_userList_email: 'Email',
    users_userList_roles: 'Roles',
    users_userList_lastActivity: 'Última Actividad',
    users_userList_joinedDate: 'Fecha de Creacion',

    users_userList_addUser: 'Agregar Usuario',

    user_userList_activeStatus: 'ATTIVO',
    user_userList_inactiveStatus: 'INATTIVO',
    user_userList_deletedStatus: 'RIMOSSO',
};

export default locales;