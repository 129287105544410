import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Loading } from "components";
import { notification, Modal, Form, Input, Switch, InputNumber, Checkbox } from "antd";

import {
  campaignFromEditClose,
  campaignFromEditSave,
  sendTestEmail,
  reset,
} from "./redux/slice";

const EditCampaignFromModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();
  const [id, setId] = useState();
 const [defaultSmtp, setdefaultSmtp] = useState(null);
  const { loading, isOpen, data, result, error, isSendingEmail, resultTestEmail } = useSelector(
    (state) => state.editCampaignFromRedux
  );

  //Verificacion de carga
  useEffect(() => {
    if (data && !id) {
      //se carga la info al form solo una vez
      setId(data.id);
      //se especifica la carga de los valores al form
      form.setFieldsValue({
        ...data,
      });
    }

    if (result) {
      dispatch(reset({ result: null }));
      notification.success({ message: messages["saveSuccessfully"] }); //, description: messages["saveSuccessfully"] })
      closeModal(true);
    }

    if (error) {
      dispatch(reset({ error: null }));
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
    }
  }, [data, result, error]);

  useEffect(() => {
    if(resultTestEmail){
      notification.success({ message: messages["parameters_editCampaingFrom_emailSuccess"] });
    }
  }, [resultTestEmail])
  

  //se valida y se limpia todo lo que pueda causar errores
  const closeModal = useCallback((refresh) => {
    setId();
    form.resetFields();
    dispatch(campaignFromEditClose());
    onClose && onClose(refresh);
  });

  const sendTestEmailTo = () => {
    const values = form.getFieldsValue();
    dispatch(sendTestEmail({
      ...values,
      fromEmail: values.email,
    }))
  }

  return (
    <div>
      <Modal
        open={isOpen}
        footer={null}
        maskClosable={false}
        width={800}
        onCancel={() => {
          closeModal();
        }}
      >
        <div className="p-3 p-md-5">
          <div className="text-center mb-4">
            <h3>
              {id
                ? messages["parameters_editCampaignFrom_edit"]
                : messages["parameters_editCampaignFrom_create"]}
            </h3>
          </div>

          <Loading loading={!!loading}>
            <div className="card-body">
              <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                  dispatch(
                    campaignFromEditSave({
                      id: id,
                      ...values,
                    })
                  );
                }}
                loading={!!loading}
              >
                <div className="row g-3">
                  <div className="col-12">
                    <Form.Item
                      name="name"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaignFrom_name"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                      ]}
                    >
                      <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Item
                      name="email"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaignFrom_email"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                        { email: true, message: messages["invalid_email"] },
                        {
                          type: "email",
                          message: messages["invalid_email"],
                        },
                      ]}
                    >
                      <Input
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Item
                      name="host"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaignFrom_host"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                      ]}
                    >
                      <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Item
                      name="port"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaignFrom_port"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                      ]}
                    >
                      <InputNumber max={9999} min={1} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-md-6 d-flex">
                    <Form.Item
                      name="secure"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaignFrom_secure"]}
                        </label>
                      }
                      valuePropName="checked"
                      //rules={[
                      //  { required: true, message: messages["required_field"] },
                      //]}
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                    name="isDefault"
                    label={
                      <label className="form-label">
                        {messages["parameters_editCampaignFrom_isDefault"]}
                      </label>
                    }
                    valuePropName="checked"
                    >
                      <Switch  />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Item
                      name="user"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaignFrom_user"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                      ]}
                    >
                      <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Item
                      name="password"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaignFrom_password"]}
                        </label>
                      }
                      rules={[
                        { required: true, message: messages["required_field"] },
                      ]}
                    >
                      <Input.Password className="form form-control-lg mb-3 mb-lg-0" />
                    </Form.Item>
                  </div>
                  {/* Just for testing smtp */}
                  <div className="col-12">
                    <Form.Item
                      name="toEmail"
                      label={
                        <label className="form-label">
                          {messages["parameters_editCampaingFrom_sendTestEmailTo"]}
                        </label>
                      }
                    >
                      <Input className="form form-control-lg mb-3 mb-lg-0" />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Loading>

          <div className="row pt-5">
            <div className="col-12 text-center">
            <button
                type="button"
                className="btn btn-primary me-sm-3 me-1"
                onClick={sendTestEmailTo}
                disabled={!!isSendingEmail}
                loading={isSendingEmail}
              >
                {messages["parameters_editCampaignFrom_test"]}
              </button>
              <button
                type="button"
                className="btn btn-primary me-sm-3 me-1"
                onClick={() => form.submit()}
                disabled={!!loading}
              >
                {messages["save"]}
              </button>
              <button
                type="button"
                className="btn btn-label-secondary"
                onClick={() => closeModal()}
              >
                {messages["cancel"]}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditCampaignFromModal;
