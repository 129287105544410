import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as reduxActions from '../../redux/actions';

export const useActionNotification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigateTo = (jsonSTR) => {
        const {to, options} = JSON.parse(jsonSTR);
        navigate(to, {...options, replace: true});
    };
    const redirect = (uri) => {
        window.location(uri);
    }
    const openNavigatorTab = (uri) => {
        window.open(uri,'_blank');
    }
    const openModal = (jsonSTR) => {
        const {action, payload} = JSON.parse(jsonSTR);
        const reduxAction = reduxActions[action];
        if(reduxAction){
            dispatch(reduxAction(payload));
        }
    };

    const notificationsActions = {
        'NAVIGATE': navigateTo,
        'REDIRECT': redirect,
        'NEW_NAVIGATOR_TAB': openNavigatorTab,
        'OPEN_MODAL': openModal,
    }

    const actionInterpreter = (actions) => {
        if(!actions){
            return;
        }
        
        //Each action must be separated by a vertical bar (|)
        const allActionsToDo = actions.split("|");
        if(allActionsToDo.length){
            for (let index = 0; index < allActionsToDo.length; index++) {
              const actionToDo = allActionsToDo[index];
              //Action has two parts, the action type and the payload, separate by :-
              const [type, payload] = actionToDo.split(":-");
              notificationsActions[type](payload)
            }
        }
    }

    return actionInterpreter;
}

export  default useActionNotification;