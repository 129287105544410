import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    changeMenu
} from '../../../config/redux/actions'

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        let resizeTimer = -1;
        function handleResize() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                setWindowSize({ width: window.innerWidth, height: window.innerHeight });
            }, 50);
        }
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            clearTimeout(resizeTimer);
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return windowSize;
};

export const useSetThemeValues = () => {
    const dispatch = useDispatch()
    const [isHover, setIsHover] = useState(true)

    //const { width } = useWindowSize();
    const { collapseMenu, expadnded } = useSelector(state => state.themeRedux)

    useEffect(() => {

        if (expadnded) {
            document.documentElement.classList.add(['layout-menu-expanded']);
        }
        else {


            document.documentElement.classList.remove(['layout-menu-expanded']);
            document.documentElement.classList.remove(['layout-menu-collapsed']);
            document.documentElement.classList.remove(['layout-menu-hover']);

            setIsHover(false)
            document.documentElement.classList.remove(['layout-menu-expanded']);

            if (collapseMenu) {
                document.documentElement.classList.add(['layout-menu-collapsed']);
            }
        }

    }, [collapseMenu, expadnded])

    useEffect(() => {
        if (!expadnded) {
            if (collapseMenu && isHover) {
                document.documentElement.classList.add(['layout-menu-hover']);
            }
            else {
                document.documentElement.classList.remove(['layout-menu-hover']);
            }
        }
    }, [isHover])

    const onHoverMenu = useCallback((value) => {
        if (!expadnded)
            setIsHover(value)
    })

    const onCollapseMenu = useCallback((value = !collapseMenu) => {
        if (!expadnded) {
            //document.documentElement.classList.remove(['layout-menu-hover']);
            dispatch(changeMenu({ collapseMenu: value }))
            // if (collapseMenu) {
            //     document.documentElement.classList.remove(['layout-menu-hover']);
            //     dispatch(changeMenu({ collapseMenu: value }))
            // }
        }
        else
            dispatch(changeMenu({ expadnded: !expadnded }))
    })

    return { collapseMenu, isHover, expadnded, onCollapseMenu, onHoverMenu }


    // useEffect(() => {

    //     if (width < 768) {
    //         //dispatch(changeThemeConfig({ menuDisplay: "mini-sidebar" }))
    //     }
    //     // else if (width < 1200) {
    //     //     htmlTag.setAttribute("data-sidebar-style", "mini");
    //     // }
    //     else {
    //         //dispatch(changeThemeConfig({ menuDisplay: "full" }))
    //     }

    //     // if (isMenuActive)
    //     //     dispatch(changeThemeConfig({ isMenuActive: false }))

    // }, [width])

}
