import { useState, useRef, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Select, Table, Input } from "antd";
import { Dropdown } from "bootstrap";
import { usePaggination } from "../../config/hooks";

export function ComplexSelect(props) {
    const {
        value,
        onChange,
        data,
        action = () => { },
        loading,
        valuePropName = "id",
        labelPropName = "name",
        mode,
    } = props;
    const { messages } = useIntl();
    const ref = useRef();

    const paggination = usePaggination(action);
    const [_values, setValue] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (value != undefined) {
            setValue(value);
        }
    }, [value]);

    useEffect(() => {
        paggination.refresh();
    }, []);

    const _onChange = (newValues) => {
        if (onChange) {
            onChange(newValues);
        }
        setValue(newValues);
    };

    const dataSource = useMemo(() => {
        if (!data?.items) {
            return [];
        }
        return data.items.map((v) => ({
            ...v,
            key: v[valuePropName],
            label: `${v[valuePropName]} ${v[labelPropName]}`,
            value: v[valuePropName],
        }));
    }, [data]);

    return (
        <>
            <Select
                labelInValue
                mode={mode}
                defaultActiveFirstOption={false}
                style={{ width: "100%" }}
                value={_values}
                loading={loading}
                onChange={_onChange}
                options={dataSource}
                onSearch={(value) => {
                    if (window.typingTime) {
                        clearTimeout(window.typingTime);
                    }
                    window.typingTime = setTimeout(() => {
                        paggination.refresh(1, value);
                        setPage(1);
                    }, 1000);
                }}
            />
            <div>
                <button
                    ref={ref}
                    className="btn btn-primary m-1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    aria-expanded="false"
                >
                    <i className="bx bxs-search" />
                </button>
                <div className={"dropdown-menu mt-2"} style={{ width: "100%" }}>
                    <div className="card-body p-2">
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    className="form-control my-1 w-100"
                                    placeholder={messages["search"]}
                                    onChange={paggination.onSearchChange}
                                    value={paggination.searchText}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <Table
                                size="small"
                                loading={loading}
                                columns={[
                                    {
                                        key: "item",
                                        title: "item",
                                        dataIndex: "label",
                                    },
                                ]}
                                dataSource={dataSource}
                                showHeader={false}
                                pagination={false}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            const exist = _values.find(
                                                (v) => v.key === record.key
                                            );
                                            if (exist) {
                                                const newValues =
                                                    _values.filter(
                                                        (v) =>
                                                            v.key !== record.key
                                                    );
                                                _onChange(newValues);
                                            } else {
                                                _onChange([..._values, record]);
                                            }
                                        },
                                    };
                                }}
                                rowClassName={(record) => {
                                    const exist = _values.find(
                                        (v) => v.key === record.key
                                    );
                                    if (exist) return "bg-label-primary";
                                }}
                            />
                            <nav className="mt-2 d-flex justify-content-end">
                                <ul className="pagination mb-0">
                                    <li className="page-item page-indicator cursor-pointer">
                                        <a className="page-link" onClick={() => {
                                            const _page = page - 1
                                            if (_page > 0) {
                                                setPage(_page)
                                                paggination.tablePaggination.onChange(_page, 10)
                                            }
                                        }}>
                                            <i className='bx bxs-chevron-left' />
                                        </a>
                                    </li>
                                    <li key={page} id={page} className="page-item active">
                                        <a className="page-link">{page}</a>
                                    </li>
                                    <li className="page-item page-indicator cursor-pointer">
                                        <a className="page-link" onClick={() => {
                                            const _page = page + 1
                                            setPage(_page)
                                            paggination.tablePaggination.onChange(_page, 10)
                                        }}>
                                            <i className='bx bx-chevron-right' />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 text-center">
                                <button
                                    type="button"
                                    className="btn btn-label-secondary me-1"
                                    onClick={() =>
                                        new Dropdown(ref.current).toggle()
                                    }
                                >
                                    {messages["close"]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ComplexSelect;
