import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null
};

const route = 'modules/management/companies/views/detail/slice'

//Extend async Action
export const companiesDetailGetById = createAsyncThunk('companies/detail/companiesDetailGetById', async (
    { id },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query getCompanyById($id: Int!){
                company: getCompanyById(id: $id) {
                    id
                    name
                    email
                    paridaIVA
                    state
                    stateId #id de PAState
                    city
                    address
                    phone1
                    phone2
                    businessCode
                    workState
                    workActivity
                    freeStartDate
                    freeEndDate
                    lastPayment {
                        payEndDate
                    }
                    agentsAssigned {
                        companyId
                        agentId
                        agentProfile {
                            id
                            fullName
                        }
                    }
                    user {
                        id
                        username
                        firstName
                        lastName
                        hashp
                        companiesAssigned {
                            id
                            name
                        }
                    }
                    status
                    isDeleted
                }
            }`,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data?.company

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesDetailGetById', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const companiesLDetailRedux = createSlice({
    name: 'companies/detail',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(companiesDetailGetById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(companiesDetailGetById.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(companiesDetailGetById.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companiesLDetailRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer