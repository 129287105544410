import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    result: null,
    error: null,
    companyAnnouncementId: null,
    isUserAnnouncement: false
};

const route = 'modules/client/dashboard/modals/editNote/slice'

//Extend async Action
export const dashboardEditNoteOpen = createAsyncThunk('dashboard/editNote/open', async (
    payload,
    { rejectWithValue, dispatch, getState }
) => {
    try {

        if (!payload?.id) {
            return { data: null, companyAnnouncementId: payload?.companyAnnouncementId }
        }
        const { isUserAnnouncement } = getState().dashboardEditNoteRedux;
        const serviceName = isUserAnnouncement ? 'getUserAnnouncementNoteById' : 'getCompanyAnnouncementNoteById';

        let data = await clientQuery(
            `query ${serviceName}($id: Int) {
                note: ${serviceName}(id: $id) {
                    id
                    ${isUserAnnouncement ? 'userId' : 'companyId'}
                    ${isUserAnnouncement ? 'userAnnouncementId' : 'companyAnnouncementId'}
                    campaignDetailId
                    emailSchedule
                    sendStatus
                    error
                    note
                    createdOn
                }
             }`,
            {
                id: Number(payload?.id)
            },
            endpoints.GRAPHQL_GENERAL
        );
        //Se retorna la data del server
        return { data: data.note, companyAnnouncementId: payload.companyAnnouncementId }
    } catch (exc) {
        //Se guarda la excepcion
        ExceptionManager(exc, route, 'dashboardEditNoteOpen', dispatch);
        return rejectWithValue(exc)
    }
})

export const dashboardEditNoteSave = createAsyncThunk('dashboard/editNote/save', async (
    {
        id, companyAnnouncementId, note, emailSchedule,
    },
    { rejectWithValue, dispatch, getState }
) => {
    const { isUserAnnouncement } = getState().dashboardEditNoteRedux;
    const serviceNameUpdate = isUserAnnouncement ? 'updateUserAnnouncementNote' : 'updateCompanyAnnouncementNote';
    const serviceNameCreate = isUserAnnouncement ? 'createUserAnnouncementNote' : 'createCompanyAnnouncementNote';
    const paramCreate = isUserAnnouncement ? 'userAnnouncementId' : 'companyAnnouncementId';
    try {
        let data
        if (id) {
            data = await clientMutation(
                `mutation ${serviceNameUpdate} ($id: Int, $note: String, $emailSchedule: Datetime) {
                    ${serviceNameUpdate} (id: $id, note: $note, emailSchedule: $emailSchedule)
                    {
                        id
                    }
                }`,
                {
                    id,
                    note,
                    emailSchedule: emailSchedule ? emailSchedule.format() : null,
                },
                endpoints.GRAPHQL_GENERAL
            );
        }
        else {
            //create
            data = await clientMutation(
                `mutation ${serviceNameCreate} ($companyAnnouncementId: Int, $note: String, $emailSchedule: Datetime) {
                    ${serviceNameCreate} (${paramCreate}: $companyAnnouncementId, note: $note, emailSchedule: $emailSchedule) 
                    {
                        id
                    }
                }`,
                {
                    companyAnnouncementId,
                    note,
                    emailSchedule: emailSchedule ? emailSchedule.format() : null,
                },
                endpoints.GRAPHQL_GENERAL
            );
        }
        return data
    } catch (exc) {
        ExceptionManager(exc, route, 'dashboardEditNoteSave', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const dashboardEditNoteRedux = createSlice({
    name: 'dashboard/editNote',
    initialState: INIT_STATE,
    reducers: {
        dashboardEditNoteClose(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //thunk dashboardEditNoteOpen
        builder.addCase(dashboardEditNoteOpen.pending, (state, action) => {
            state.isUserAnnouncement = action.meta.arg?.isUserAnnouncement
            state.loading = true
            state.isOpen = true
        })
        builder.addCase(dashboardEditNoteOpen.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload.data
            state.companyAnnouncementId = payload.companyAnnouncementId
        })
        builder.addCase(dashboardEditNoteOpen.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk dashboardEditNoteSave
        builder.addCase(dashboardEditNoteSave.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(dashboardEditNoteSave.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(dashboardEditNoteSave.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dashboardEditNoteRedux;

// Extract and export each action creator by name
export const { dashboardEditNoteClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;