import {
  HiDocumentCheck,
  HiDocumentMagnifyingGlass,
  HiDocumentMinus,
} from "react-icons/hi2";

export default (isRequestingForDocument, document, approvedDocument) => {
  if (approvedDocument) {
    return <HiDocumentCheck size={40} color="#47D347" />;
  }
  if (!isRequestingForDocument && !document) {
    return <HiDocumentMinus size={40} color="#566a7f" />;
  }
  if (document && isRequestingForDocument && !approvedDocument) {
    return (
      <div className="animated-icon">
        <HiDocumentMagnifyingGlass size={40} color="#FFD700" />
      </div>
    );
  }
  if (!document) {
    return <HiDocumentMinus size={40} color="#EF352A" />;
  }
  return <></>;
};
