import moment from 'moment'
import 'moment/locale/it';
import React, { Fragment, useEffect, useState } from 'react'



export const DateAndClock = () => {

    const [time, setTime] = useState(moment().locale('it').format('dddd L [ - 🕒 ] LT')); // Inicializa con la hora actual

    // Actualiza la hora cada segundo
    useEffect(() => {
        const intervalId = setInterval(() => {
        setTime(moment().locale('it').format('dddd L [ 🕒 - ] LT'));
        }, 1000);

        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId);
    }, []);


  return (
    <div className="d-flex align-items-center  letter_capitalice" >
      
      <h5 className='m-0 text-capitalize' >{time}</h5> 
  
    </div>
  )
}
