import { Fragment, useEffect } from 'react'
import { StateCard } from '../../components/card/index'
import { TextDescriptionCompanies } from '../../../../../../components/TextDescriptionCompanies'
import { useDispatch, useSelector } from 'react-redux'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { openEditStep } from '../../.config/redux/actions';
import { useIntl } from "react-intl"
import { getStatesList } from './redux/slice'
import { EditStepModal } from '../../modals/editStepForm'
import { Loading } from '../../../../../../components/Loading'

import { DEFAULT_DEPRECATION_REASON } from 'graphql'
import { Table } from 'antd'



export const WorkFlowManagementListView = () => {

  const dispatch = useDispatch();
  const { messages } = useIntl();
  useEffect(() => {
    dispatch(getStatesList());
  }, [])


  const { loading, data, error } = useSelector((state) => state.workFlowStatesListRedux);

 //console.log(data, "si funciona la data?")


  


  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="container-fluid">
          <div className="card card-body">
            <div className="col-md-8 col-sm-12 mt-4 mb-4">
              <TextDescriptionCompanies />
            </div>
            <Loading
              loading={loading}
            >
              <div className="mt-4 table-responsive">
                <Table
                rowKey="id"
                columns={
                  [
                    {
                      dataIndex: 'id',
                      key: 'id',
                      render:(text, record) => (
                        <div>
                        <EditOutlined key="edit" size={60} onClick={() => dispatch(openEditStep({ id: record.id }))} />
                        </div>
                      )
                    },
                    {
                      title: messages['workflow_card_name'],
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: messages['workflow_card_color'],
                      dataIndex: 'color',
                      key: 'color',
                      render: (text, record) => (
                        <div style={{ backgroundColor: record.color, width: '30px', height: '30px', borderRadius: '50%' }}></div>
                      )
                    },
                    {
                      title: messages['workflow_card_limitHours'],
                      dataIndex: 'limitHours',
                      key: 'limitHours',
                    },
                    {
                      title: messages['workflow_card_limitAttemps'],
                      dataIndex: 'limitAttemps',
                      key: 'limitAttemps',
                    },
                    {
                      title: messages['workflow_card_helpText'],
                      dataIndex: 'helpText',
                      key: 'helpText',
                    },
                    {
                      title: messages['workflow_card_description'],
                      dataIndex: 'description',
                      key: 'description',
                    },
                  ]
                }
                dataSource={data || []}
                />
              </div>
              
              
            </Loading>
          </div>
        </div>
      </div>
      <EditStepModal />
    </Fragment>
  )
}
