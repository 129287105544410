import { useMemo } from "react"
import { Input } from "antd";

function TextInput(props) {
  const { textArea, maxLength, regExp, value, onChange } = props
  const defaultProps = useMemo(() => {
    return { maxLength }
  }, [props])

  return (
    <div className='row'>
      {textArea ? <Input.TextArea {...defaultProps} value={value} onChange={onChange} /> : <Input {...defaultProps} value={value} onChange={onChange} />}
    </div>
  )
}

export default TextInput