import { Routes, Route, Navigate } from 'react-router-dom';
import { useParams } from 'react-router';

import { NotificationsListView } from './views/list';


const NotificationsListRouter = () => {
    
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/client/notifications-list/list`} />}></Route>
            <Route path='/list' element={<NotificationsListView />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default NotificationsListRouter;