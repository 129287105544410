import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: [],
    result: null,
    error: null
};

const route = 'modules/management/companies/views/detail/components/payments/slice'

//Extend async Action
export const companiesDetailSearchPayments = createAsyncThunk('companies/detail/payments/searchPayments', async (
    { companyId, searchText, limit, offset },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query searchCompanyPaymentsByCompanyId($companyId: Int!, $searchText: String, $limit: Int, $offset: Int){
                payments: searchCompanyPaymentsByCompanyId(companyId: $companyId, searchText: $searchText, limit: $limit, offset: $offset) {
                    items {
                        id
                        paymentMethod
                        price
                        payStartDate
                        payEndDate
                        files
                    }
                    count
                }
            }`,
            {
                companyId: Number(companyId),
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data?.payments || []

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesDetailSearchPayments', dispatch);
        return rejectWithValue(exc)
    }
})

export const companiesDetailPaymentSave = createAsyncThunk('companies/detail/payments/save', async (
    {
        id, companyId, paymentMethod, price, payStartDate, payEndDate, files
    },
    { rejectWithValue, dispatch }
) => {

    try {
        let data
        if (id) {
            // data = await clientMutation(
            //     `mutation updateCompany ($id: Int!, $email: String!, $firstName: String, $lastName: String, $name: String, $paridaIVA: String, $state: Int, $city: String,
            //         $address: String, $phone1: String, $phone2: String, $workState: [Int], $workActivity: String, $paymentMethod: String, $price: String,
            //         $notes: String, $freeStartDate: Datetime, $freeEndDate: Datetime, $payStartDate: Datetime, $payEndDate: Datetime) {
            //         updateCompany (id: $id, email: $email, firstName: $firstName, lastName: $lastName, name: $name, paridaIVA: $paridaIVA, state: $state, city: $city,
            //             address: $address, phone1: $phone1, phone2: $phone2, workState: $workState, workActivity: $workActivity, paymentMethod: $paymentMethod, price: $price,
            //             notes: $notes, freeStartDate: $freeStartDate, freeEndDate: $freeEndDate, payStartDate: $payStartDate, payEndDate: $payEndDate) {
            //             id
            //         }
            //     }`,
            //     {
            //         id, email, firstName, lastName, name, paridaIVA, state, city, address, phone1, phone2, workState,
            //         workActivity, paymentMethod, price, notes, freeStartDate, freeEndDate, payStartDate, payEndDate
            //     },
            //     endpoints.GRAPHQL_GENERAL
            // );

            // return data.updateCompany.id

        }
        else {
            //create
            data = await clientMutation(
                `mutation createCompanyPayment ($companyId: Int!, $paymentMethod: String!, $price: String!, $payStartDate: Datetime!, $payEndDate: Datetime!, $files: [Upload]) {
                    result: createCompanyPayment (companyId: $companyId,paymentMethod: $paymentMethod, price: $price, payStartDate: $payStartDate, payEndDate: $payEndDate, files: $files) {
                        id
                    }
                }`,
                {
                    companyId, paymentMethod, price, payStartDate, payEndDate, files
                },
                endpoints.GRAPHQL_GENERAL
            );

            return data.result.id

        }

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesDetailSearchPaymentSave', dispatch);
        return rejectWithValue(exc)
    }
})

export const deletePaymentById = createAsyncThunk('companies/detail/payments/deletePaymentById', async (
    { id },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation deleteCompanyPayment ($id: Int!) {
                result: deleteCompanyPayment (id: $id) 
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'deletePaymentById', dispatch);
        return rejectWithValue(exc)
    }
})
//Slice
const companiesDetailPaymentsRedux = createSlice({
    name: 'companies/detail/payments',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(companiesDetailSearchPayments.pending, (state) => {
            state.loading = true
        })
        builder.addCase(companiesDetailSearchPayments.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(companiesDetailSearchPayments.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk save
        builder.addCase(companiesDetailPaymentSave.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(companiesDetailPaymentSave.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(companiesDetailPaymentSave.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
        //thunk delete
        builder.addCase(deletePaymentById.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(deletePaymentById.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(deletePaymentById.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companiesDetailPaymentsRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer