import { DatePicker } from "antd"
import dayjs from "dayjs";


export const DateInput = (props) => {

    const { dateRange, futureDates, typeData, value, onChange } = props
    const { RangePicker } = DatePicker;

    const formatDateInput = (value) => {
        if (dateRange) {
            value?.length ? onChange([value[0]?.format(), value[1]?.format()]) : onChange(value)
        } else {
            onChange(value?.format())
        }
    }

    return (
        <div className="row">
            {
                dateRange == true ?
                    <RangePicker value={value ? [dayjs(value[0]), dayjs(value[1])] : value} onChange={formatDateInput} />
                    : <DatePicker value={value ? dayjs(value) : value} onChange={formatDateInput} />
            }
        </div>
    )
}
