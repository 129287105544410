import { Fragment } from "react";
import { useIntl } from "react-intl";

import { Tabs } from "antd";

import Senders from "../../components/senders";
import Templates from "../../components/templates";
import Parameters from './components/parameters'
import { AgentConfigNotifications as ConfigNotifications } from '../../components/agentConfigNotifications'
import { useSecurity } from "../../../../../../config/hooks";
import { P_NOTIFICATIONS, P_POPUP_CONTACTS, P_SENDERS, P_TEMPLATES } from "../../../../../../config/helpers/permissionsHash";
import { ParametrizableText } from "../../components/parameterizableTexts";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
import { useLocation } from "react-router-dom";
import { ConfigStatusLeads } from "../../components/configStatusLeads";

const ParametersListView = () => {
  const { messages } = useIntl();
  const ps = useSecurity(P_SENDERS);
  const pt = useSecurity(P_TEMPLATES);
  const pp = useSecurity(P_POPUP_CONTACTS);
  const pcn = useSecurity(P_NOTIFICATIONS, ['R', 'U']);

  let items = [
    {
      show: ps,
      key: "1",
      label: messages["parameters_senders"],
      children: <Senders />,
    },
    {
      show: pt,
      key: "2",
      label: messages["parameters_templates"],
      children: <Templates />,
    },
    {
      show: pp,
      key: "3",
      label: messages["parameterList_title"],
      children: <Parameters />,
    },
    {
      show: pcn,
      key: "4",
      label: messages["parameters_config_agent_notifications"],
      children: <ConfigNotifications />,
    },
    {
      show: pcn,
      key: "5",
      label: messages["parameters_parametizableText"],
      children: <ParametrizableText />,
    },
    {
      show: pcn,
      key: "6",
      label: messages["parameter_configStatusLeads"],
      children: <ConfigStatusLeads />,
    },
  ]
  items = items.filter(v => v.show)
  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="container-fluid">
          <div className="card card-body">
            <div className="col-md-8 col-sm-12 mt-4 mb-4">
              <TextDescriptionCompanies />
            </div>
            <br /><br />
            <Tabs
              defaultActiveKey="1"
              size="small"
              type="card"
              items={items}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ParametersListView;
