let locales = {
    dashboardDetail_title: `Annuncio`,
    dashboardDetail_title1: `Descrizione preventivo online`,
    dashboardDetail_1: `Lavoro da realizzare`, 
    dashboardDetail_2: `Prezzo massimo`,
    dashboardDetail_3: `Data prevista del progretto`,
    dashboardDetail_4: `Tipo di richiesta`,
    dashboardDetail_5: `Cosa cerchi`,
    dashboardDetail_6: `Dove gli serve`,
    dashboardDetail_7: `Cosa sta cercando`,
    dashboardDetail_8: `Sopralluogo`,
    dashboardDetail_9: `Code richiesta`,
    dashboardDetail_title2: `Autore richiesta preventivo`,
    dashboardDetail_author1: `Nome`, 
    dashboardDetail_author2: `Comune`,
    dashboardDetail_author3: `Provincia`,
    dashboardDetail_author4: `Telefono`,
    dashboardDetail_author5: `E-mail`,
    dashboardDetail_author6: `Orario da contattare`,
    dashboardDetail_notSpecified: `Non specificato`,
    dashboardDetail_notes: 'Notas',
    dashboardDetail_notesAdd: 'Aggiungere Nota',
    dashboardDetail_notesEdit: 'Modifica Nota',
    dashboardDetail_deleteNote: 'Elimina Nota',
    dashboardDetail_deleteNoteDescription: 'Questa azione è irreversibile',
    dashboardDetail_whatsapp: 'Messaggio su WhatsApp',
    dashboardDetail_contract: "Accetazione cliente",
    dashboardDetail_emptyHistory: "Non è stato caricato nessun documento di Accettazione Cliente",
    dashboardDetail_DeletedBy: "Eliminato da: ",
};

export default locales;