//Global keys
let locales = {
    "user": "Administración",
    "admin_contractorList_menu": "Empresas",
    "admin_contractorDetail_menu": "Mi Información",
    "admin_usersList_menu": "Usuarios",
    "admin_roleList_menu": "Roles",
};

//Merge Modules
import management from '../../management/.config/lan/it'
import client from '../../client/.config/lan/it'

locales = {
    ...locales,
    ...management,
    ...client
};

export default locales;