//Global keys
let locales = {
    ok: 'Aceptar',
    close: 'Cerrar',
    add: 'Agregar',
    edit: 'Editar',
    delete: 'Eliminar',
    seeDetails: 'Ver Detalle',
    cancel: 'Cancelar',
    print: 'Imprimir',
    show: 'Ver',
    search: 'Buscar',
    continue: 'Continuar',
    back: 'Atras',
    save: "Guardar",
    new: "Nuevo",
    required_field: 'Campo Requerido',
    clear: 'Limpiar',
    select: 'Selecccionar',
    rows: 'Registros',
    actions: 'Acciones',
    invalid_email: 'Email Invalido',
    or: "o",
    refresh: "Refrescar",
    filter: "Filtrar",
    export: "Exportar",
    deleteSucessfully: "Se elimino el registro exitosamente!!",
    saveSuccessfully: "Se almaceno exitosamente!!",
    saveSuccessfullyTitle: "Bien!!",
    saveError: "Ocurrio un Error",
    selected: "Selecionado(s)",
    deleteSelected: "Eliminar Seleccionado(s)",
    table_rows: "Registros",
    resend: 'Reenviar',
    validate: 'Validar',
    list: 'Listado',
    logout: 'Cerrar Sesion',
    view: "Ver",
    sendEmail: 'Enviar emails',
    createdOn: "Creado",
    createdBy:  "Creado Por",
    updatedOn: "Actualizado",
    updatedBy:  "Actualizado Por",
    yes: 'Sí',
    no: 'No',
    apply: 'Aplicar',
    all: 'Todos',
    write: 'Escritura',
    read: 'Lectura',
    description: 'Descripción',
    key: 'Key',
    id: 'Id',
    value: 'Valor',
    numeric: 'Numérico',
    text: 'Texto',
    length: 'Longitud',
    multiline: 'Multilínea',
    option: 'Opción',
    options: 'Opciones',
    questions: 'Preguntas',
    question: 'Pregunta',
    mode: 'Modo',
    rule: 'Regla',
    rules: 'Reglas',
    section: 'Sección',
    sections: 'Secciones',
    name: 'Nombre',
    confirmDelete: '¿Seguro que quieres eliminar este elemento?',
    status: "Estatus",
    email: "Email",
    company: "Compañia",
    data_already_exist: "Este dato ya se ha registrado",
    previous: 'Anterior',
    next: 'Siguiente',
    approve: 'Aprobar',
    reject: 'Rechazar',
    seeMore: 'Ver más'
};


const apiErrors = {
    "Failed to fetch": "Hay un error al intentar comunicarse con el servidor por favor verifique si acceso a internet. o contacte con el administrador del sistema",
    DB_Duplicated: "El registro ya existe en la base de datos.",
    loginInvalid: "Usuario o password invalidos",
    loginInvalidIp: "La ip desde la que esta accediendo es invalida, favor comuniquese con el administrador del sistema",
    loginMaxAttemps: "Ha alcanzado el maximo de intentos realizados, intente de nuevo en {time, number} segundos",
    loginInvalidOtp: "El codigo OTP es invalido o esta vencido.",
    loginUserBlocked: "Su cuenta ha sido bloqueada por diversos motivos de seguridad. Para más información contacte con Soporte",
    unAuthorized: "Senza autorizzazione"
}

const permissions= {
    puser_management: 'Administración de Usuarios',
    puser_list: 'Listado de Usuarios',
    puser_edit: 'Edición de Usuarios',
    puser_edit_assing_lead: 'Asinación de Lider de Usuario',
    psurvey: 'Encuestas',
    psurvey_list: 'Listado de Encuestas',
    psurvey_edit: 'Edición de Enecuestas'
}

//Merge Modules
import security from '../../../core/security/.config/lan/es';
import modules from '../../../modules/.config/lan/es';

// Merge notifications
import notifications from '../../../components/Notifications/.config/lan/es';

locales = {
    ...locales,
    ...apiErrors,
    ...security,
    ...modules,
    ...notifications,
    ...permissions
};

export default locales;