import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { endpoints } from "../../../../../../../config/constants";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientQuery } from "../../../../../../../config/helpers/GraphQLApi";



//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

const route = "modules/management/parameters/views/list/configStatusLeads/redux/slice";


export const statusAnnouncementListSearch = createAsyncThunk('parameters/list/parametrizableTextsSearch', async (
    { searchText, limit, offset },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query searchAnnouncementStatusByPage($searchText: String, $limit: Int, $offset: Int) {
                result: searchAnnouncementStatusByPage(searchText: $searchText, limit: $limit, offset: $offset) {
                  items {
                    id
                    name
                    description
                  }
                  count
                }
              }`,
            {
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0
            },
            endpoints.GRAPHQL_GENERAL
        )

        return data?.result || []
    } catch (exc) {
        ExceptionManager(exc, route, 'parametrizableTextsSearch', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const statusAnnouncementListRedux = createSlice({

    name: 'parameters/list/statusAnnouncementSearch',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(statusAnnouncementListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(statusAnnouncementListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(statusAnnouncementListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = statusAnnouncementListRedux;

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer



