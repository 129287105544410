import { useEffect } from "react";
import { useIntl } from "react-intl";
import {useSelector, useDispatch} from "react-redux"

import { DatePicker, Select } from "antd";

import Filters from "../../../../../components/Filters";
import { getCatalog } from "../../../../.config/redux/actions";

function NotificationsFilters({paggination}) {
    const { messages } = useIntl();
    const dispatch = useDispatch()

    const { users } = useSelector((state) => state.catalogRedux);
    const { user } = useSelector(state => state.authRedux);
    useEffect(() => {
        dispatch(getCatalog({
            keys: [{ key: 'users', params: {all: true} }],
        }));
    }, [])

    return (
        <Filters
            width={300}
            onApply={(values) => {
                paggination.refresh(null, null, values);
            }}
        >
            <Filters.Item
                name="date"
                label={messages["notificationsList_date"]}
            >
                <DatePicker.RangePicker placement="bottomRight" />
            </Filters.Item>
            {user?.isAdmin && <Filters.Item name="userId" label={`${messages['notificationsList_user']}`}>
                <Select
                    style={{
                        width: '100%',
                    }}
                    allowClear
                    options={users}
                    showSearch
                    fieldNames={{ label: "fullName", value: "id" }}
                    optionFilterProp="fullName"
                    filterOption={(input, option) =>
                        (option?.fullName ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                />
            </Filters.Item>}
        </Filters>
    );
}

export default NotificationsFilters;
