import { Checkbox } from 'antd';

function DateProps(props) {
    const { values, onChange } = props;
    return (
        <div className='col-12'>
            <div className='row mb-2'>
                <Checkbox onChange={(e) => onChange('futureDates')(e.target.checked)} checked={values['futureDates']}>Allow to enter future dates</Checkbox>
            </div>
            <div className='row mb-2'>
                <Checkbox onChange={(e) => onChange('dateRange')(e.target.checked)} checked={values['dateRange']}>Date range</Checkbox>
            </div>
        </div>
    )
}

export default DateProps