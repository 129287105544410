import companiesListRedux from '../../views/list/redux/slice'
import companiesDetailRedux from '../../views/detail/redux/slice'
import companiesDetailPaymentsRedux from '../../components/payments/redux/slice'
import companiesDetailNotesRedux from '../..//components/notes/redux/slice'
import companiesEdittRedux from '../../modals/edit/redux/slice'
import companiesSendEmailRedux from '../../modals/sendEmail/redux/slice'
import companiesChangePasswordRedux from '../../modals/changePassword/redux/slice'

import historyPAStateList from '../../components/notificationPAState/redux/slice'

import historyNotesRedux from '../../modals/historyNotesByPAState/redux/slice'

import changePAStateListRedux from '../../modals/changePAState/redux/slice'
import agentsListRedux from '../../views/listAgents/redux/slice'

//export redux objects
export default {
	companiesListRedux,
	companiesDetailRedux,
	companiesEdittRedux,
	companiesDetailPaymentsRedux,
	companiesDetailNotesRedux,
	companiesSendEmailRedux,
	companiesChangePasswordRedux,
	historyPAStateList,
	historyNotesRedux,
	changePAStateListRedux,
	agentsListRedux
};
