import { ColorPicker, Form, Input, InputNumber, Modal, notification } from 'antd'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../../components/Loading'
import { globalWorkflowStepsEditClose, reset, saveEditStep } from './redux/slice'
import { getStatesList } from '../../.config/redux/actions'

export const EditStepModal = ({ onClose }) => {

    const [id, setId] = useState();
    const [colorId, setColorId] = useState();
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const [form] = Form.useForm();

    const { loading, isOpen, data, result, error } = useSelector((state) => state.globalWorkflowStepsEditRedux);


    //Verificacion de carga
    useEffect(() => {
        if (data && !id) {
            //se carga la info al form solo una vez
            setId(data.id);
            //se especifica la carga de los valores al form
            form.setFieldsValue({
                ...data,
            });
        }

        if (result) {
            dispatch(reset({ result: null }));
            notification.success({ message: messages["saveSuccessfully"] }); //, description: messages["saveSuccessfully"] })
            dispatch(getStatesList());
            closeModal();
        }

        if (error) {
            dispatch(reset({ error: null }));
            notification.error({
                message: messages["saveError"],
                description: messages[error] || error,
            });
        }
    }, [data, result, error]);


    //se valida y se limpia todo lo que pueda causar errores
    const closeModal = useCallback((refresh) => {
        setId();
        form.resetFields();
        dispatch(globalWorkflowStepsEditClose());
        onClose && onClose(refresh);
    });

    // Funcionalidad para ejecutar el proceso de captura del color
    const handleColorChange = (color) => {
        const hexColor = color?.toHexString();
        return setColorId(hexColor);
    };

    return (
        <Fragment>
            <Modal
                title={
                    <h5>
                        {
                            "Editar Step del workflow"
                        }
                    </h5>
                }
                open={isOpen}
                footer={null}
                maskClosable={false}
                width={800}
                onCancel={() => {
                    closeModal();
                }}
            >

                <div>
                    <Loading
                        loading={loading}
                    >
                        <div className='card-body'>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={(values) => {
                                    dispatch(saveEditStep({
                                        ...values,
                                        id: id,
                                        color: colorId,
                                    }))
                                }}
                            >
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-12 m-2'>
                                                <Form.Item
                                                    name="name"
                                                    label={
                                                        <label className="form-label">
                                                            {messages["workflow_card_name"]}
                                                        </label>
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: messages["required_field"],
                                                        },
                                                    ]}
                                                >
                                                    <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                </Form.Item>
                                            </div>
                                            <div className='d-flex flex-column flex-md-row'>
                                                <div className='col-12 col-md-4 p-2'>
                                                    <Form.Item
                                                        name="limitHours"
                                                        label={
                                                            <label className="form-label">
                                                                {messages["workflow_card_limitHours"]}
                                                            </label>
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: messages["required_field"],
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            min={2}
                                                            size="small"
                                                            parser={(value) => (value ? parseInt(value, 10) : undefined)}
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                    </Form.Item>
                                                </div>
                                                <div className='col-12 col-md-4 p-2'>
                                                    <Form.Item
                                                        name="limitAttemps"
                                                        label={
                                                            <label className="form-label">
                                                                {messages["workflow_card_limitAttemps"]}
                                                            </label>
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: messages["required_field"],
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            min={2}
                                                            size="small"
                                                            parser={(value) => (value ? parseInt(value, 10) : undefined)}
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                    </Form.Item>
                                                </div>
                                                <div className='col-12 col-md-4 p-2'>
                                                    <Form.Item
                                                        name="color"
                                                        label={
                                                            <label className="form-label">
                                                                {messages["workflow_card_color"]}
                                                            </label>
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: messages["required_field"],
                                                            },
                                                        ]}
                                                    >
                                                        <ColorPicker
                                                            defaultValue="string"
                                                            defaultFormat="hex"
                                                            format="hex"
                                                            onChange={(value) => handleColorChange(value)}
                                                            showText
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 m-2'>
                                            <Form.Item
                                                name="description"
                                                label={
                                                    <label className="form-label">
                                                        description
                                                    </label>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: messages["required_field"],
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                            </Form.Item>
                                        </div>
                                        <div className='col-12 m-2'>
                                            <Form.Item
                                                name="helpText"
                                                label={
                                                    <label className="form-label">
                                                        helpText
                                                    </label>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: messages["required_field"],
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Loading>
                    <div className="row pt-5">
                        <div className="col-12 text-center">
                            <button
                                type="button"
                                className="btn btn-primary me-sm-3 me-1"
                                onClick={() => form.submit()}
                            >
                                {messages["save"]}
                            </button>
                            <button
                                type="button"
                                className="btn btn-label-secondary"
                                onClick={() => closeModal()}
                            >
                                {messages["cancel"]}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}
