import { Routes, Route, Navigate } from 'react-router-dom'

import List from './views/list'
import { useSecurity } from '../../../../config/hooks';
import { P_PERMISSIONS } from '../../../../config/helpers/permissionsHash';

const PermissionsRouter = () => {

    return (
        <Routes>
            <Route path='/' element={<Navigate to="/admin/management/permissions/list" />} />
            {useSecurity(P_PERMISSIONS, ['C','R','U']) && <Route path='list' element={<List />} />}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default PermissionsRouter