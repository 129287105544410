import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { resetALL } from "../../../../../../../config/redux/actions";


//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    result: null,
    error: null
};


const route = 'modules/management/companies/components/notificationPAState/slice'


export const getHistoryPAStates = createAsyncThunk('components/notificationPAState', async ({ id, companyId }, { rejectWithValue, dispatch }) => {

    try {

        let data = await clientQuery(
            `
            query GetAllHistoryPAStates($stateId: Int, $companyId: Int) {
                result: getAllHistoryPAStates(stateId: $stateId, companyId: $companyId) {
                  id
                  companyId
                  stateId
                  updatedAt
                  updatedBy
                  timeRemainingSeconds
                }
              } 
            `,
            {
                id: Number(id),
                companyId: Number(companyId)
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data.result
    } catch (exc) {
        //Se guarda la excepcion
        ExceptionManager(exc, route, 'companiesEditOpen', dispatch);
        return rejectWithValue(exc)
    }
})


// Slice

const historyPAStateList = createSlice({
    name: 'companies/edit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //thunk getHistoryPAStates
        builder.addCase(getHistoryPAStates.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getHistoryPAStates.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(getHistoryPAStates.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
        })
    }
})


// Extract and export the action creators object and the reducer
export const { actions, reducer } = historyPAStateList

// Extract and export each action creator by name
export const { companiesEditClose, reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer