let locales = {
    workflow_card_name: 'Nombre:',
    workflow_card_color: 'Color:',
    workflow_card_limitHours: 'Límite de Horas:',
    workflow_card_limitAttemps: 'Límite de Intentos:',
    workflow_card_helpText: 'Ayuda:',
    workflow_card_description: 'Descripción:',
    workflow_card_updatedAt:'Fecha de actalización: '
};



// locales = {
//     ...locales,
// }

export default locales;