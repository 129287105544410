import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: [],
    result: null,
    error: null
};

const route = 'modules/management/companies/views/detail/components/notes/slice'

//Extend async Action
export const companiesDetailSearchNotes = createAsyncThunk('companies/detail/payments/searchNotes', async (
    { companyId, searchText, limit, offset },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query searchCompanyNotesByCompanyId($companyId: Int!, $searchText: String, $limit: Int, $offset: Int){
                notes: searchCompanyNotesByCompanyId(companyId: $companyId, searchText: $searchText, limit: $limit, offset: $offset) {
                    items {
                        id
                        note,
                        files
                        createdBy
                        createdOn
                        createdByUser {
                            id
                            fullName
                            email
                        }
                    }
                    count
                }
            }`,
            {
                companyId: Number(companyId),
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.notes || []

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesDetailSearchNotes', dispatch);
        return rejectWithValue(exc)
    }
})

export const companiesDetailNotesSave = createAsyncThunk('companies/detail/notes/save', async (
    {
        id, companyId, note, files
    },
    { rejectWithValue, dispatch }
) => {

    try {
        let data
        if (id) {
            // data = await clientMutation(
            //     `mutation updateCompany ($id: Int!, $email: String!, $firstName: String, $lastName: String, $name: String, $paridaIVA: String, $state: Int, $city: String,
            //         $address: String, $phone1: String, $phone2: String, $workState: [Int], $workActivity: String, $paymentMethod: String, $price: String,
            //         $notes: String, $freeStartDate: Datetime, $freeEndDate: Datetime, $payStartDate: Datetime, $payEndDate: Datetime) {
            //         updateCompany (id: $id, email: $email, firstName: $firstName, lastName: $lastName, name: $name, paridaIVA: $paridaIVA, state: $state, city: $city,
            //             address: $address, phone1: $phone1, phone2: $phone2, workState: $workState, workActivity: $workActivity, paymentMethod: $paymentMethod, price: $price,
            //             notes: $notes, freeStartDate: $freeStartDate, freeEndDate: $freeEndDate, payStartDate: $payStartDate, payEndDate: $payEndDate) {
            //             id
            //         }
            //     }`,
            //     {
            //         id, email, firstName, lastName, name, paridaIVA, state, city, address, phone1, phone2, workState,
            //         workActivity, paymentMethod, price, notes, freeStartDate, freeEndDate, payStartDate, payEndDate
            //     },
            //     endpoints.GRAPHQL_GENERAL
            // );

            // return data.updateCompany.id

        }
        else {
            //create
            data = await clientMutation(
                `mutation createCompanyNote ($companyId: Int!, $note: String!, $files: [Upload]) {
                    result: createCompanyNote (companyId: $companyId, note: $note, files: $files) {
                        id
                    }
                }`,
                {
                    companyId, 
                    note,
                    files
                },
                endpoints.GRAPHQL_GENERAL
            );

            return data.result.id

        }

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesDetailNotesSave', dispatch);
        return rejectWithValue(exc)
    }
})

export const deleteNoteById = createAsyncThunk('companies/detail/notes/deleteNoteById', async (
    { id },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation deleteCompanyNote ($id: Int!) {
                result: deleteCompanyNote (id: $id)
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'deleteNoteById', dispatch);
        return rejectWithValue(exc)
    }
})
//Slice
const companiesDetailNotesRedux = createSlice({
    name: 'companies/detail/notes',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(companiesDetailSearchNotes.pending, (state) => {
            state.loading = true
        })
        builder.addCase(companiesDetailSearchNotes.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(companiesDetailSearchNotes.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk save
        builder.addCase(companiesDetailNotesSave.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(companiesDetailNotesSave.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(companiesDetailNotesSave.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk delete
        builder.addCase(deleteNoteById.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(deleteNoteById.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(deleteNoteById.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companiesDetailNotesRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer