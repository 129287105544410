import usersReducers from '../../users/.config/redux/reducers'
import companiesReducers from '../../companies/.config/redux/reducers'
import campaignsReducers from '../../campaigns/.config/redux/reducers'
import parameterReducers from '../../parameters/.config/redux/reducers'
import permissionsReducers from '../../permissions/.config/redux/reducers'
import rolesReducers from '../../roles/.config/redux/reducers'
import workStateReducers from '../../workFlowStates/.config/redux/reducers'
import httpLogsReducers from '../../httpLogs/.config/redux/reducers'
import formsReducers from '../../forms/.config/redux/reducers'
//export redux objects
export default {
	//...usersReducers,
	...companiesReducers,
	...campaignsReducers,
	...parameterReducers,
	...permissionsReducers,
	...rolesReducers,
	...usersReducers,
	...workStateReducers,
	...httpLogsReducers,
	...formsReducers
};
