import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";
import { endpoints } from "../../../../../../../config/constants";


//STATE INITIAL
const INIT_STATE = {
    data: null,
    loading: false,
    error: null
};

const route = 'modules/management/parameters/workFlowStates/views/list/redux/slice';

export const getStatesList = createAsyncThunk('workFlowStates/getStatesList', async (
    payload, 
    { rejectWithValue, dispatch }) => {
    try {

        let data = await clientQuery(
            `query GetAllPAStates {
                 result: getAllPAStates {
                  color
                  description
                  helpText
                  id
                  limitAttemps
                  limitHours
                  name
                }
              }`,
            {

            },
            endpoints.GRAPHQL_GENERAL
        );

        return data?.result || null
        
    } catch (exc) {
        ExceptionManager(exc, route, 'getStatesList', dispatch);
        return rejectWithValue(exc)
    }
})

export const workFlowStatesListRedux = createSlice({
    name: 'states/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {

        builder.addCase(resetALL, () => INIT_STATE)

        // getStatesList
        builder.addCase(getStatesList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getStatesList.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(getStatesList.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    }
});


// Extract and export the action creators object and the reducer
export const { actions, reducer } = workFlowStatesListRedux;

// Extract and export each action creator by name
export const { reset } = actions;

// Export the reducer, either as a default or named export
export default reducer;