import { Routes, Route, Navigate } from 'react-router-dom';

import UserListView from './views/list'
import { useSecurity } from '../../../../config/hooks';
import { P_USERS } from '../../../../config/helpers/permissionsHash';
import UserViewDetail from './views/detail';

const UsersRouter = () => {

    // return (<div>Management</div>)
    const PU = useSecurity(P_USERS);

    return (
        <Routes>
            <Route path='/' element={<Navigate to="/admin/management/users/list" />}></Route>
            {PU && <>
                <Route path='/list' element={<UserListView roleFilter={8} />} />
                <Route path='/detail/:id' element={<UserViewDetail />} />
            </>}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default UsersRouter;