import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';

import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: { count: 0, items: [] },
    error: null,
    resultDelete: null,
};

const route = 'routes/admin/management/roles/views/list/redux/slice'

//Extend async Action
export const rolesListSearch = createAsyncThunk('roles/list/search', async ({ searchText, limit, offset, filters, orderBy }, { rejectWithValue }) => {
    try {
        let data = await clientQuery(
            `
                query searchRoles($searchText: String, $limit: Int, $offset: Int) {
                    result: searchRoles(searchText: $searchText, limit: $limit, offset: $offset) {
                        count
                        items { 
                            id
                            name
                            updated {
                                id
                                fullName
                            }
                            updatedAt
                        }
                    }
                }
            `,
            {
                searchText, limit, offset, filters, orderBy
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'rolesListSearch');
        return rejectWithValue(exc)
    }
})

export const deleteRole = createAsyncThunk('roles/list/delete', async ({ id }, { rejectWithValue }) => {
    try {
        let data = await clientMutation(
            `
                mutation deleteRole($id: Int!) {
                    result: deleteRole(id: $id)
                }
            `,
            {
                id: Number(id),
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'deleteRole');
        return rejectWithValue(exc)
    }
})


//Slice
const rolesListRedux = createSlice({
    name: 'roles/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(rolesListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(rolesListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(rolesListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteRole.pending, (state) => {
            state.loading = true
            state.resultDelete = null
        })
        builder.addCase(deleteRole.fulfilled, (state, { payload }) => {
            state.loading = false
            state.resultDelete = payload
        })
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = rolesListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer