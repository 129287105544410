let locales = {
    companiesSendEmail_selectAnnouncements: 'Selezionare gli annunci',
    companiesSendEmail_newsletter: 'Newsletter',
    companiesSendEmail_from: 'Da',
    companiesSendEmail_to: 'A', 
    companiesSendEmail_subject: 'Oggetto',
    companiesSendEmail_successMessage: 'Le e-mail sono state inviate con successo a tutti i destinatari.',
    companiesSendEmail_announcement:'Annuncio/Lead',
    companiesSendEmail_ConfirmSendEmail:'Conferma Invio Email',
    companiesSendEmail_Newsletter:'Newsletter',
    companiesSendEmail_Finish:'Finito',
};

export default locales;