import { forwardRef } from 'react'
import { default as DropdownInternal } from 'react-bootstrap/Dropdown';

export const Dropdown = ({ children, items }) => {

    return (
        <DropdownInternal>
            {children}

            <DropdownInternal.Menu>
                {
                    items.map((item, key) => {
                        return (<span key={key}>{item}</span>)
                    })
                }
            </DropdownInternal.Menu>
        </DropdownInternal>
    )
}

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </div>
));

Dropdown.Toogle = ({ children }) => {
    return (
        <DropdownInternal.Toggle as={CustomToggle}>
            {children}
        </DropdownInternal.Toggle>
    )
}