import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    isOpen: false,
    companyAnnouncementId: null,
    isUserAnnouncement: false, // For userAnnouncements
    loading: false,
    data: null,
    error: null,
};

const route = 'modules/client/dashboard/modals/statusChangesHistory/redux/slice'

//Extend async Action
export const openStatusChangesHistory = createAsyncThunk('dashboard/openStatusChangesHistory', async (
    {companyAnnouncementId},
    { rejectWithValue, dispatch }
) => {
    try {
        dispatch(searchCompanyAnnouncementChange({companyAnnouncementId}));
        return companyAnnouncementId;
    } catch (exc) {
        ExceptionManager(exc, route, 'openStatusChangesHistory', dispatch);
        return rejectWithValue(exc)
    }
})

export const searchCompanyAnnouncementChange = createAsyncThunk('dashboard/searchCompanyAnnouncementChange', async (
    { limit = 10, offset = 0, companyAnnouncementId },
    { rejectWithValue, dispatch, getState }
) => {
    const {isUserAnnouncement} = getState().statusChangesHistoryRedux;

    const serviceName = isUserAnnouncement ? 'searchUserAnnouncementChange' : 'searchCompanyAnnouncementChange';
    const paramName =  isUserAnnouncement ? 'userAnnouncementId' : 'companyAnnouncementId';

    try {
        let data = await clientQuery(
            `query ${serviceName}($companyAnnouncementId: Int, $limit: Int, $offset: Int){
                result: ${serviceName}(${paramName}: $companyAnnouncementId, limit: $limit, offset: $offset) {
                    count
                    items {
                        id
                        ${paramName}
                        status
                        updatedOn
                        user {
                            firstName
                            lastName
                        }
                    }
                }
            }`,
            {
                companyAnnouncementId,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL
        );

        if(data?.result)
            return data.result
        else
            throw "Error: no tienes acceso"
    } catch (exc) {
        ExceptionManager(exc, route, 'searchCompanyAnnouncementChange', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const statusChangesHistoryRedux = createSlice({
    name: 'dashboard/statusChangesHistoryRedux',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        closeStatusChangesHistory(state) {
            state.isOpen = false;
            state.companyAnnouncementId = null;
            state.isUserAnnouncement = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(openStatusChangesHistory.pending, (state, action) => {
            state.isOpen = true;
            state.isUserAnnouncement = action.meta.arg?.isUserAnnouncement
        })
        builder.addCase(openStatusChangesHistory.fulfilled, (state, action) => {
            state.companyAnnouncementId = action.payload;
        })

        builder.addCase(searchCompanyAnnouncementChange.pending, (state) => {
            state.loading = true
        })
        builder.addCase(searchCompanyAnnouncementChange.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(searchCompanyAnnouncementChange.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = statusChangesHistoryRedux

// Extract and export each action creator by name
export const { reset, closeStatusChangesHistory } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer