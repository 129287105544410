import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";


//STATE INITIAL
const INIT_STATE = {
    data: null,
    loading: false,
    error: null
};

const route = 'modules/client/emailProgramming/views/list/redux/slice';

//Extend async Action
export const getEmailsList = createAsyncThunk('emailProgramming/getEmailsList', async({ 
    searchText = '',
    limit = 10,
    offset = 0,}, {rejectWithValue, dispatch}) => {
    try {
        let data = await clientQuery(
            `query searchProgrammedNotesByUser(
                $searchText: String,
                $limit: Int,
                $offset: Int,
            ){
                emailsList: searchProgrammedNotesByUser(
                    searchText: $searchText,
                    limit: $limit,
                    offset: $offset){
                    items{
                        created{
                            id
                            username
                            fullName
                        }
                        updated{
                            id
                            username
                            fullName
                            firstName
                            lastName
                        }
                        companyAnnouncement{
                            companyId
                            announcementId
                            campaignDetailId
                        }
                        id
                        note
                        sendStatus
                        subject
                        sendDate
                        emailSchedule
                        createdOn
                        updatedOn
                    },
                    count
                }

            } `,
            {
                searchText,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.emailsList || null  
    } catch (exc) {
        ExceptionManager(exc, route, 'getEmailsList', dispatch);
        return rejectWithValue(exc)
    }
}

)

export const getUserEmailsList = createAsyncThunk('emailProgramming/getUserEmailsList', async({ 
    searchText = '',
    limit = 10,
    offset = 0,}, {rejectWithValue, dispatch}) => {
    try {
        let data = await clientQuery(
            `query searchUserAnnouncementeProgrammedNotesByUser(
                $searchText: String,
                $limit: Int,
                $offset: Int,
            ){
                emailsList: searchUserAnnouncementeProgrammedNotesByUser(
                    searchText: $searchText,
                    limit: $limit,
                    offset: $offset){
                    items{
                        created{
                            id
                            username
                            fullName
                        }
                        updated{
                            id
                            username
                            fullName
                            firstName
                            lastName
                        }
                        userAnnouncement{
                            userId
                            announcementId
                            campaignDetailId
                        }
                        note
                        sendStatus
                        subject
                        sendDate
                        emailSchedule
                        createdOn
                        updatedOn
                    },
                    count
                }

            } `,
            {
                searchText,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.emailsList || null  
    } catch (exc) {
        ExceptionManager(exc, route, 'getUserEmailsList', dispatch);
        return rejectWithValue(exc)
    }
}

)

//Slice
export const scheduledEmailsSlice = createSlice({
    name: 'scheduledEmailsList',
    initialState:INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        // getEmailsProgrammed
        builder.addCase(getEmailsList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getEmailsList.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(getEmailsList.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        // getUserEmailsList
        builder.addCase(getUserEmailsList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUserEmailsList.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(getUserEmailsList.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    }
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = scheduledEmailsSlice;

// Extract and export each action creator by name
export const { reset } = actions;

// Export the reducer, either as a default or named export
export default reducer;


