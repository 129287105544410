import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clientQuery,
  clientMutation,
} from "../../../../../../../config/helpers/GraphQLApi";

import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";

//STATE INITIAL
const INIT_STATE = {
  customers: [],
  announcements: null,
  loadingAnnouncements: false,
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  error: null,
};

const route = 'modules/management/companies/modals/edit/slice'

//Extend async Action
export const sendEmailsToCompanies = createAsyncThunk(
  "companies/sendEmail/sendEmailsToCompanies",
  async ({ fromId, subject, to, templateId, announcementIds, areUsers = false }, { rejectWithValue, dispatch }) => {
    try {
      const result = await clientMutation(
        `
          mutation createCampaing ($fromId: Int, $to: [Int], $subject: String!, $templateId: Int!, $items: [Int], $areUsers: Boolean) {
            createCampaing (fromId: $fromId, to: $to, subject: $subject, templateId: $templateId, items: $items, areUsers: $areUsers)
          }
        `,
        {
          fromId,
          templateId,
          subject,
          to,
          items: announcementIds,
          areUsers
        },
        endpoints.GRAPHQL_GENERAL
      );

      return result;
    } catch (exc) {
      ExceptionManager(exc, route, "sendEmailsToCompanies", dispatch);
      return rejectWithValue(exc);
    }
  }
);

//Extend async Action
export const sendEmailsForAcceptanceContract = createAsyncThunk("companies/sendEmail/sendEmailsForAcceptanceContract", async ({ fromId, subject, to, templateId, announcementIds }, { rejectWithValue, dispatch }) => {

  try {

    const result = await clientMutation(
      `
      mutation sendCustomerAcceptanceContract ($fromId: Int, $to: [Int], $subject: String!, $templateId: Int!, $announcementIds: [Int]!) {
        sendCustomerAcceptanceContract (fromId: $fromId, to: $to, subject: $subject, templateId: $templateId, announcementIds: $announcementIds)
      }
      `,
      {
        fromId,
        templateId,
        subject,
        to,
        announcementIds,
      },
      endpoints.GRAPHQL_GENERAL
    )
    return result;

  } catch (exc) {
    ExceptionManager(exc, route, "sendEmailsForAcceptanceContract", dispatch);
    return rejectWithValue(exc);
  }
}
);


export const searchAnnouncements = createAsyncThunk(
  "companies/sendEmail/searchAnnouncements",
  async ({ searchText, limit, offset }, { rejectWithValue, dispatch }) => {
    try {
      const result = await clientQuery(
        `query getAnnouncementSearchable($searchText: String, $limit: Int, $offset: Int) {
              announcements: getAnnouncementSearchable (searchText: $searchText, limit: $limit, offset: $offset)
              {
                items { 
                  id
                  name,
                  product
                }
              }
        }`,
        {
          searchText: searchText.trim() || '',
          limit: limit || 10,
          offset: offset || 0,
        },
        endpoints.GRAPHQL_GENERAL
      );

      return result.announcements;
    } catch (exc) {
      ExceptionManager(exc, route, "searchAnnouncements", dispatch);
      return rejectWithValue(exc);
    }
  }
);
//Slice
const sendEmailRedux = createSlice({
  name: "companies/sendEmail",
  initialState: INIT_STATE,
  reducers: {
    companiesSendEmailClose() {
      return INIT_STATE;
    },
    companiesSendEmailOpen(state, { payload }) {
      state.isOpen = true;
      state.customers = payload.customers;
    },
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    builder.addCase(searchAnnouncements.pending, (state) => {
      state.loadingAnnouncements = true;
      state.error = null;
    });
    builder.addCase(searchAnnouncements.fulfilled, (state, { payload }) => {
      state.loadingAnnouncements = false;
      state.announcements = payload;
    });
    builder.addCase(searchAnnouncements.rejected, (state, action) => {
      state.loadingAnnouncements = false;
      state.error = action.payload.error;
    });

    builder.addCase(sendEmailsToCompanies.pending, (state) => {
      state.loading = true;
      state.result = null;
      state.error = null;
    });
    builder.addCase(sendEmailsToCompanies.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(sendEmailsToCompanies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

    //sendEmailsForAcceptanceContract
    builder.addCase(sendEmailsForAcceptanceContract.pending, (state) => {
      state.loading = true;
      state.result = null;
      state.error = null;
    });
    builder.addCase(sendEmailsForAcceptanceContract.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(sendEmailsForAcceptanceContract.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = sendEmailRedux;

// Extract and export each action creator by name
export const { companiesSendEmailClose, reset, companiesSendEmailOpen } =
  actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
