import { useEffect, useState } from "react"
import { Radio } from 'antd';

const YesOrNoInput = (props) => {

    const { value, onChange } = props;

    const changeOption = (e) => {
        onChange(e.target.value);
    }

    return (
        <div className="row">
            <Radio.Group name="radiogroup" value={value} onChange={changeOption}>
                <Radio value={true}  >Yes</Radio>
                <Radio value={false} >No</Radio>
            </Radio.Group>
        </div>
    )
}

export default YesOrNoInput
