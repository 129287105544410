import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";


//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  error: null,
};

const route = "modules/management/parameters/modals/editParametrizableText/slice";


export const globalParametrizableTextEditOpen = createAsyncThunk(
  "editParametrizableText/editParameter/open",
  async ({ id }, { rejectWithValue, dispatch }) => {

    try {
      //si es una creacion se retorna
      if (!id) {
        return { data: null };
      }
      //si es edicion se optiene la informacion
      let data = await clientQuery(
        `query getTextByPageById($id: Int!) {
          getTextByPageById(id: $id) {
            content
            description
            id
            roles {
              name
            }
            rolesId
            uri
          }
        }`,
        {
          id: Number(id),
        },
        endpoints.GRAPHQL_GENERAL
      );

      // retorna la data del server
      // console.log("data desde getTextByPageById", data)
      return data.getTextByPageById

    } catch (exc) {

      //Se guarda la excepcion
      ExceptionManager(exc, route, "globalParametrizableTextEditOpen", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const globalParametrizableTextEditSave = createAsyncThunk("parameters/editParametrizableText/save",
  async ({ id, uri, content, description, rolesId }, { rejectWithValue, dispatch }) => {

    try {
      let data;

      if (id) {
        // Update
        data = await clientMutation(
          `mutation updateTextByPage ($id: Int!, $uri: String, $content: String, $description: String, $rolesId: [Int]) {
            updateTextByPage (id: $id, uri: $uri, content: $content, description: $description, rolesId: $rolesId)
                {
                  id
                }
            }`,
          {
            id,
            uri, content, description, rolesId
          },
          endpoints.GRAPHQL_GENERAL
        );

        return data.updateTextByPage.id;

      } else {
        // Create
        data = await clientMutation(
          `mutation createTextByPage ( $uri: String, $content: String, $description: String, $rolesId: [Int]) {
            createTextByPage ( uri: $uri, content: $content, description: $description, rolesId: $rolesId)
                {
                  id
                }
            }`,
          {
            uri, content, description, rolesId
          },
          endpoints.GRAPHQL_GENERAL
        );
        return data.createTextByPage.id;
      }

    } catch (exc) {
      ExceptionManager(exc, route, "globalParametrizableTextEditSave", dispatch);
      return rejectWithValue(exc);
    }
  })



//Slice
const globalParametrizableTextEditRedux = createSlice({
  name: "parameters/editParametrizableText",
  initialState: INIT_STATE,
  reducers: {
    globalParametrizableTextEditClose(state, action) {
      return INIT_STATE;
    },
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    //thunk editOpen
    builder.addCase(globalParametrizableTextEditOpen.pending, (state) => {
      state.loading = true;
      state.isOpen = true;
    });
    builder.addCase(globalParametrizableTextEditOpen.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(globalParametrizableTextEditOpen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //thunk save
    builder.addCase(globalParametrizableTextEditSave.pending, (state) => {
      state.loading = true;
      state.result = null;
      state.error = null;
    });
    builder.addCase(globalParametrizableTextEditSave.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(globalParametrizableTextEditSave.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = globalParametrizableTextEditRedux;

// Extract and export each action creator by name
export const { globalParametrizableTextEditClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;




