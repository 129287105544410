import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Modal, Form, Input, Skeleton, notification, Button } from "antd";

import { hide, reset, saveEditCatalog, searchFormsCatalogs } from "./redux/slice";
import ComplexSelect from "../../../../../../components/ComplexSelect";

function EditFormCatalog({ onRefresh, onSave }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { messages } = intl;
    const [notificationApi, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();

    const { show, data, loading, resultError, result, list } = useSelector(
        (state) => state.formsCatalogsEditRedux
    );

    const [id, setId] = useState();
    const [selectedValue, setSelectedValue] = useState(null)

    useEffect(() => {
        if (data && !id) {
            setId(data.id);
            form.setFieldsValue({
                ...data,
            });
        }
    }, [data]);
    

    useEffect(() => {
        dispatch(reset({ result: null, resultError: null }));

        if (result) {
            notificationApi.success({
                message: messages["saveSuccessfullyTitle"].toString(),
                description: messages["saveSuccessfully"].toString(),
            });
            onSave(result)
            close(true);
        }

        if (resultError) {
            const {error} = resultError;
            notificationApi.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error,
            });
        }
    }, [resultError, result]);

    const close = useCallback((refresh) => {
        dispatch(hide());
        setId(null);
        form.resetFields();
        dispatch(reset(null));

        if (refresh && onRefresh) onRefresh();
    }, []);

    const required = {
        required: true,
        message:
            messages["required_field"],
    }

  return (
    <Modal
        title={<h5>Catalog</h5>}
        open={show}
        width={1000}
        onCancel={() => close()}
        footer={null}
    >
        {contextHolder}
        <div className="p-3 p-md-5">
            <div className="card-body">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        dispatch(saveEditCatalog({ id, ...values }));
                    }}
                >
                    {loading ? (
                        <>
                            <Skeleton active />
                        </>
                    ) : (
                        <div className="row g-3">
                            {/* <div className="col-12">
                                <label className="form-label">Search</label>
                                <ComplexSelect 
                                    onChange={setSelectedValue} 
                                    value={selectedValue}
                                    labelPropName="catalogName"
                                    action={searchFormsCatalogs}
                                    data={list}
                                    loading={loading}
                                />
                            </div> */}
                            <Form.Item
                                className="col-12"
                                label={<label className="form-label">{messages["forms_title"]}</label>}
                                name="nameCatalog"
                                rules={[required]}
                            >
                                <Input
                                    className="form-control"
                                    placeholder={messages[
                                        "forms_title"
                                    ]?.toString()}
                                />
                            </Form.Item>

                            <Form.Item
                                className="col-12"
                                label={<label className="form-label">{"Items"}</label>}
                                rules={[required]}
                            >
                                <Form.List 
                                    name="items"
                                    rules={[required]}
                                >
                                    {(fields, { add, remove }) => (
                                        <div
                                            className="row"
                                        >
                                            {fields.map((field) => (
                                                <div
                                                    className="col-12 col-md-6 cardMaster bg-lighter rounded-2 p-3 mb-3 border"
                                                    key={field.key}
                                                >
                                                    <div className="text-end">
                                                        <button type="button" className="btn-close"  onClick={() => { remove(field.name)}}></button>
                                                    </div>
                                                    <Form.Item
                                                        className="col-12"
                                                        label={<label className="form-label">{"Value"}</label>}
                                                        name={[field.name, 'value']}
                                                        rules={[required]}
                                                    >
                                                        <Input
                                                            className="form-control"
                                                            placeholder={"Value"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            ))}
                                            <Button type="dashed" onClick={() => add()} block>
                                            + Add Item
                                            </Button>
                                        </div>
                                    )}
                                </Form.List>
                            </Form.Item>
                        </div>
                    )}
                </Form>
            </div>
            <div className="row pt-5">
                <div className="text-center pt-10">
                    <button
                        type="button"
                        className="btn btn-primary me-sm-3 me-1"
                        disabled={loading}
                        onClick={() => form.submit()}
                    >
                        {messages["save"]?.toString()}
                    </button>
                    <button
                        type="button"
                        className="btn btn-label-secondary"
                        onClick={() => close({})}
                    >
                        {messages["close"]?.toString()}
                    </button>
                </div>
            </div>
      </div>
    </Modal>
  )
}

export default EditFormCatalog