//Global keys
let locales = {
};

//Merge Modules
import admin from '../../admin/.config/lan/it'
import ModalAffiliatePartner from '../../../components/ModalAffiliatePartner/.config/lan/it'

locales = {
    ...locales,
    ...admin,
    ...ModalAffiliatePartner
};

export default locales;