import { useEffect } from 'react'
import { Loading } from '../../../../../../components/Loading'
import { Timeline } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotesByStepOpen, getCatalog, getHistoryPAStates } from '../../../../../.config/redux/actions';
import moment from "moment";
import { useIntl } from 'react-intl';
import { ModalHistoryNotesByPAState } from '../../modals/historyNotesByPAState';

export const HistoryPAStates = (props) => {
    const { companyId } = props;

    const { messages } = useIntl();
    const dispatch = useDispatch();
    const { pAStates } = useSelector((state) => state.catalogRedux);
    const { data } = useSelector(
        (state) => state.historyPAStateList
    );

    useEffect(() => {
        dispatch(getCatalog({ keys: ["pAStates"] }));
    }, []);

    useEffect(() => {
        dispatch(getHistoryPAStates({ companyId: companyId }))
    }, []);

    const findStepInfo = (idSate) => {
        let currentStep = { color: "white", name: "default" }
        if (pAStates.length > 0) {
            currentStep = pAStates?.find(pAState => pAState?.id == idSate);
        }
        return currentStep
    }

    return (
        <div className='col-12'>
            <Loading loading={false}>
                <div className='card-body'>
                    <h5> {messages["HistoryPAStates_title"]}</h5>
                </div>
            </Loading>

            <div className='m-4'>
                <Timeline >
                    {
                        data?.map(element => {
                            return (
                                <Timeline.Item
                                    key={element.stateId}
                                    color={findStepInfo(element.stateId).color}
                                    children={
                                        <div>
                                            <p>{findStepInfo(element.stateId).name}</p>
                                            <p>{moment(element.updatedAt).format('L')}</p>
                                            <br />
                                            <button 
                                            className='btn btn-primary me-sm-3 me-1'
                                            onClick={() => {
                                                dispatch(getAllNotesByStepOpen({ companyId, stateId: element.stateId, historyId: element.id }))
                                            }}>
                                               {messages["HistoryPAStates_seeMore"]}
                                            </button>
                                        </div>
                                    }
                                >
                                </Timeline.Item>
                            )
                        }
                        )
                    }
                </Timeline>
            </div>
            <ModalHistoryNotesByPAState />
        </div>
    )
}

export default HistoryPAStates;