import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useActionNotification from "./useActionNotification";
import { AnimatedClockComponent } from "../../../components";

const useBlockRoute = () => {
    //For Partner affiliati process. block a specific route
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [blocked, setBlocked] = useState(false);
    const [alertInfo, setAlertInfo] = useState({title: '', type: ''})

    const { user } = useSelector((state) => state.authRedux);

    useEffect(() => {
        const info =  user?.currentPartnerAffiliato;

        var lastVarIndex = pathname.lastIndexOf("/");
        var baseRoute = pathname.slice(0, lastVarIndex + 1);

        if ((info && info?.blocked)) {
            setAlertInfo({title: `Il processo con partnert affiliato ${info?.companyName.toUpperCase()} non è stato completato entro i termini previsti, è necessario terminarlo`, type: 'danger'})
            setBlocked(true);
            navigate(`/admin/management/companies/detail/${user?.currentPartnerAffiliato?.companyId}`);
        }

        if(info?.companyId && !info?.blocked){
            let show = true
            if(baseRoute == `/admin/management/companies/detail/`){
                show = false
            }
            setAlertInfo({show, title: `È il tempo massimo per ${info.description} - Partner Affiliato ${info.companyName.toUpperCase()}`, type: 'warning', timer: info.timeRemainingSeconds})
        }
    }, [pathname, user]);
    
    return {
        blocked,
        Alert: () => user?.currentPartnerAffiliato?.companyId   && <AlertBlocked {...alertInfo} />,
    };
};

function AlertBlocked({ title, type = "info", timer = 0, show = true }) {
    //warning - danger - info - success
    const initialSeconds = timer > 0 ? timer : 0;
    const [time, setTime] = useState(initialSeconds);
    const actionInterpreter = useActionNotification();
    const { user } = useSelector((state) => state.authRedux);

    useEffect(() => {
        let timer;
        if (time > 0) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        if(!show){
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [time, show]);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${String(hours).padStart(2, "0")} ore, ${String(minutes).padStart(
            2,
            "0"
        )} minuti, ${String(remainingSeconds).padStart(2, "0")} secondi - `;
    };

    if(!show){
        return <></>
    }

    let iconName = <></>;

    switch (type) {
        case "danger":
            iconName = <i className="bx bxs-error fs-4" />;
            break;
        case "warning":
            iconName = <AnimatedClockComponent />;
            break;
        default:
            break;
    }

    return (
        <div className="d-flex justify-content-center p-2 pb-0">
            <div className={`alert alert-${type} d-flex align-items-center flex-wrap mb-0`}>
                {iconName}
                <h6 className="alert-heading text-center ms-2 mb-0 me-2">{time > 0  && formatTime(time)} {title}</h6>
                <button className={`btn btn-${type}`} onClick={() => actionInterpreter(`NAVIGATE:-{"to": "/admin/management/companies/detail/${user?.currentPartnerAffiliato?.companyId}"}|OPEN_MODAL:-{"action": "getChangePAStateOpen", "payload":{"companyId": "${user?.currentPartnerAffiliato?.companyId}"}}`) }>CONTROLLA SUBITO <i className='bx bx-right-arrow-alt'/></button>
            </div>
        </div>
    );
}

export default useBlockRoute;
