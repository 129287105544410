let locales = {
    companiesDetailPayments_paymentMethod: 'Metodo di pagamento',
    companiesDetailPayments_price: 'Prezzo',
    companiesDetailPayments_payStartDate: 'Data di attivazione del pagamento',
    companiesDetailPayments_payEndDate: 'Scadenza del pagamento',
    companiesDetailPayments_add: 'Aggiungi pagamento',
    companiesDetailPayments_title: "Ultimi pagamenti effettuati",
    companiesDetailPayments_deletePayment: "Elimina pagamento",
    companiesDetailPayments_deletePaymentMessage: "Questa azione non può essere annullata",
    companiesDetailPayments_addFilesDescription: 'Aggiungi fino a 5 file in formato jpg, png o pdf',
    companiesDetailPayments_addFiles: 'Aggiungi file',
    companiesDetailPayments_Files: 'File',
    companiesDetailPayments_paymentDetail: 'Dettaglio pagamento',
};

export default locales;