//Global keys
let locales = {
    "users_menu": "Usuarios",
    "users": "Usuarios",
    "users_usersList": "Listado de Usuarios",
    "users_agents": "Agentes"
};

//Merge Modules
import userList from '../../views/list/lan/es'
import editUser from '../../modals/editUser/lan/es'
import userDetail from '../../views/detail/lan/es'

locales = {
    ...locales,
    ...userList,
    ...editUser,
    ...userDetail
};

export default locales;