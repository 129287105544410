import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsXOctagon } from 'react-icons/bs';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {createHttpErrorLog} from '../../../../modules/admin/management/httpLogs/views/list/redux/slice';

//import './login.scss'
const htmProperties = { "lang": "en", "data-placement": "vertical", "data-behaviour": "pinned", "data-layout": "fluid", "data-radius": "flat", "data-color": "light-blue", "data-navcolo": "default", "data-show": "true" }

const LoginView = ({ history }) => {
    const dispatch = useDispatch()
    const { messages } = useIntl();
    const navigate = useNavigate()

    const { historyRoutes } = useSelector(state => state.authRedux);

    useEffect(() => {
        console.log('log')
        const currentHost = window.location.origin
        dispatch(createHttpErrorLog({originLink: `${currentHost}${historyRoutes[1]}`, destinationLink: `${currentHost}${historyRoutes[0]}`, code: 404}))
    }, [])

    return (
        <div className="main-wrapper d-flex justify-content-center align-items-center vh-100">
            <div className="error-box">
                <div className="error-body text-center">
                    <h1 className="error-title text-danger">404 <BsXOctagon size={50} /></h1>
                    <h3 className="text-uppercase error-subtitle">PAGINA NON TROVATA !</h3>
                    <p className="text-muted mt-4 mb-4">SEMBRA CHE STIA CERCANDO DI TROVARE LA STRADA DI CASA</p>
                    <a onClick={()=> navigate("/")} className="btn btn-danger btn-rounded waves-effect waves-light mb-5">TORNA A CASA</a> </div>
            </div>
        </div>
    )
}

export default LoginView;