import { InputNumber } from "antd"
import { useEffect, useMemo, useState } from "react"


const NumberInput = (props) => {

    const { max, min, decimalsNumber = 2, value = 0, onChange } = props
    const [valueParsed, setValueParsed] = useState(value)
    const [intNumb, setIntNumb] = useState(0)
    const [floatNumb, setFloatNumb] = useState(0)

    const defaultProps = useMemo(() => {
        return { min, max }
    }, [props])

    const handleKeyPressDecimal = (event) => {
        const input = event.target;
        const allowedKeys = /^[0-9]$/;
        const maxDigits = decimalsNumber;

        const navigationKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

        if (navigationKeys.includes(event.key)) {
            return;
        }
        if (!allowedKeys.test(event.key)) {
            event.preventDefault();
        }
        if (input.value.length >= maxDigits) {
            event.preventDefault();
        }
    };

    const handleKeyPressInt = (event) => {
        const allowedKeys = /^[0-9]$/;
        const navigationKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
        if (navigationKeys.includes(event.key)) {
            return;
        }
        if (!allowedKeys.test(event.key)) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (value != valueParsed) {
            setValueParsed(value)
        }
    }, [value])

    const maxNumber = (digitos) => {
        return Math.pow(10, digitos) - 1;
    }

    const _onChange = (isInt) => (number) => {
        const divisor = maxNumber(decimalsNumber) + 1;
        let _number = number;
        let newFullNumber = valueParsed;
        if (isInt) {
            setIntNumb(number);
            newFullNumber = number + (floatNumb / divisor);
        } else {
            if (number < 10) {
                _number = number * 10
            }
            setFloatNumb(_number)
            newFullNumber = intNumb + (_number / divisor);
        }
        setValueParsed(newFullNumber);
        if (onChange) onChange(newFullNumber);
    }


    return (
        <>
            <div className="row">
                <InputNumber className="mx-2" style={{ width: 150 }} placeholder="00" {...defaultProps} onKeyDown={handleKeyPressInt} value={intNumb} onChange={_onChange(true)} keyboard={true} />
                <span style={{ margin: "0 5px", fontSize: "24px", width: "1%" }}>,</span>
                <InputNumber className="mx-2" style={{ width: 100 }} placeholder="00" disabled={decimalsNumber <= 0} min={0} onKeyDown={handleKeyPressDecimal} value={floatNumb} onChange={_onChange(false)} keyboard={true} />
            </div>
            <div className="row mt-3">
                <label className="p-0 ml-2"> Valore totale </label>
                <span style={{ margin: "0 5px", fontSize: "24px" }}>{parseFloat(valueParsed).toFixed(decimalsNumber)}</span>
            </div>
        </>
    )
}

export default NumberInput
