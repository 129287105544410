import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { resetALL } from "../../../../../../../config/redux/actions";




//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    result: null,
    error: null
};


const route = 'modules/management/companies/modals/historyPAStates/slice'

export const getAllNotesByStepOpen = createAsyncThunk('historyPAStates/getAllNotesByStepOpen', async ({ limit = 10, offset = 0, companyId = null, stateId = null, historyId = null }, { rejectWithValue, dispatch }) => {

    try {

        let data = await clientQuery(
            `
            query SearchNotesByCompany($limit: Int!, $companyId: Int!, $stateId: Int, $offset: Int!, $historyId: Int) {
                result: searchNotesByCompany(limit: $limit, companyId: $companyId, stateId: $stateId, offset: $offset, historyId: $historyId) {
                  items {
                    companyId
                    id
                    note
                    stateId
                  }
                  count
                }
              }
        `,
            {
                limit: Number(limit),
                companyId: Number(companyId),
                stateId: Number(stateId),
                offset: Number(offset),
                historyId: Number(historyId)
            },
            endpoints.GRAPHQL_GENERAL
        );
        // console.log(data.result, 'getAllNotesByStepOpen')
        return data.result
    } catch (exc) {
        //Se guarda la excepcion
        ExceptionManager(exc, route, 'getAllNotesByStepOpen', dispatch);
        return rejectWithValue(exc)
    }

});


export const historyNotesRedux = createSlice({
    name: 'historyNotes',
    initialState: INIT_STATE,
    reducers: {
        historyNotesClose() {
            return INIT_STATE
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(getAllNotesByStepOpen.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(getAllNotesByStepOpen.fulfilled, (state, action) => {
            state.loading = false;
            state.isOpen = true;
            state.data = action.payload
        })
        builder.addCase(getAllNotesByStepOpen.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})


// Extract and export the action creators object and the reducer
export const { actions, reducer } = historyNotesRedux;

// Action creators are generated for each case reducer function
export const { historyNotesClose, reset } = actions;

export default reducer;