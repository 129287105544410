import companyAnnouncementsListRedux from '../../views/list/redux/slice';
import companyAnnouncementDetailRedux from '../../views/detail/redux/slice';
import dashboardEditNoteRedux from '../../modals/editNote/redux/slice';
import dashboardNotesListRedux from '../../components/listNotes/redux/slice';
import companyAnnouncementChangesRedux from '../../components/changeStatus/redux/slice';
import statusChangesHistoryRedux from '../../modals/statusChangesHistory/redux/slice';
import userAnnouncementsListRedux from '../../views/listAgent/redux/slice';
import userAnnouncementDetailRedux from '../../views/detailAgent/redux/slice';
import dinamicFormByStateRedux from '../../modals/dinamicFormByStep/redux/slice'

//export redux objects
export default {
	companyAnnouncementsListRedux,
	companyAnnouncementDetailRedux,
	dashboardEditNoteRedux,
	dashboardNotesListRedux,
	companyAnnouncementChangesRedux,
	statusChangesHistoryRedux,
	userAnnouncementsListRedux,
	userAnnouncementDetailRedux,
	dinamicFormByStateRedux
};
