import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    businessCode: 0,
    loading: false,
    isOpen: false,
    data: null,
    result: null,
    error: null
};

const route = 'modules/management/companies/modals/edit/slice'

//Extend async Action
export const companiesEditOpen = createAsyncThunk('companies/edit/open', async (
    {id, businessCode},
    { rejectWithValue, dispatch }
) => {
    try {
        //si es una creacion se retorna
        if (!id) {
            return {data: null, businessCode}
        }
        
        //si es edicion se obtiene la informacion
        let data = await clientQuery(
            `query getCompanyById($id: Int!) {
                company: getCompanyById(id: $id) {
                    id
                    userId
                    name
                    email
                    paridaIVA
                    state
                    stateId # PAState
                    city
                    address
                    phone1
                    phone2
                    workState
                    workActivity
                    freeStartDate
                    freeEndDate
                    agentsAssigned {
                        companyId
                        agentId
                        agentProfile{
                            id
                            firstName
                            lastName
                        }
                    }
                    user {
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }`,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL
        );

        //Se retorna la data del server
        return {data: data.company, businessCode}

    } catch (exc) {
        //Se guarda la excepcion
        ExceptionManager(exc, route, 'companiesEditOpen', dispatch);
        return rejectWithValue(exc)
    }
})

export const companiesEditSave = createAsyncThunk('companies/edit/save', async (
    {
        id, email, firstName, lastName, name, paridaIVA, state, city, address, phone1, phone2, workState,
        workActivity, freeStartDate, freeEndDate, businessCode, agentsAssigned,
    },
    { rejectWithValue, dispatch }
) => {

    try {
        let data
        if (id) {
            data = await clientMutation(
                `mutation updateCompany ($id: Int!, $email: String!, $firstName: String, $lastName: String, $name: String, 
                    $paridaIVA: String, $state: Int, $city: String, $address: String, $phone1: String, $phone2: String, $workState: [Int], 
                    $workActivity: String, $freeStartDate: Datetime, $freeEndDate: Datetime, $agentsAssigned: [Int]) {
                    updateCompany (id: $id, email: $email, firstName: $firstName, lastName: $lastName, name: $name, 
                        paridaIVA: $paridaIVA, state: $state, city: $city, address: $address, phone1: $phone1, phone2: $phone2, 
                        workState: $workState, workActivity: $workActivity,
                        freeStartDate: $freeStartDate, freeEndDate: $freeEndDate, agentsAssigned: $agentsAssigned)
                    {
                        id
                    }
                }`,
                {
                    id, email, firstName, lastName, name, paridaIVA, state, city, address, phone1, phone2, workState,
                    workActivity, freeStartDate, freeEndDate, agentsAssigned
                },
                endpoints.GRAPHQL_GENERAL
            );
            // console.log('data del slice editado',{data})
            return data.updateCompany.id
        }
        else {
            //create
            data = await clientMutation(
                `mutation createCompany ($businessCode: Int!, $firstName: String!, $email: String!, $lastName: String!, $name: String!, $paridaIVA: String, 
                    $state: Int, $city: String, $address: String, $phone1: String, $phone2: String, $workState: [Int], $workActivity: String,
                    $freeStartDate: Datetime, $freeEndDate: Datetime, $agentsAssigned: [Int]) {
                        createCompany (businessCode: $businessCode, email: $email, firstName: $firstName, lastName: $lastName, name: $name, paridaIVA: $paridaIVA, 
                            state: $state, city: $city, address: $address, phone1: $phone1, phone2: $phone2, workState: $workState, 
                            workActivity: $workActivity, freeStartDate: $freeStartDate, freeEndDate: $freeEndDate, agentsAssigned: $agentsAssigned) 
                    {
                        id
                    }
                }`,
                {
                    email, firstName, lastName, name, paridaIVA, state, city, address, phone1, phone2, workState,
                    freeStartDate, freeEndDate, businessCode, workActivity, agentsAssigned
                },
                endpoints.GRAPHQL_GENERAL
            );
            // console.log('data del slice nuevo',{data})
            return data.createCompany.id

        }

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesEditSave', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const companiesEdittRedux = createSlice({
    name: 'companies/edit',
    initialState: INIT_STATE,
    reducers: {
        companiesEditClose() {
            return INIT_STATE
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //thunk editOpen
        builder.addCase(companiesEditOpen.pending, (state) => {
            state.loading = true
            state.isOpen = true
        })
        builder.addCase(companiesEditOpen.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload.data
            state.businessCode = payload.businessCode
        })
        builder.addCase(companiesEditOpen.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
        })

        //thunk save
        builder.addCase(companiesEditSave.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(companiesEditSave.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(companiesEditSave.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companiesEdittRedux

// Extract and export each action creator by name
export const { companiesEditClose, reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer