import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL, logout } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
};

const route = 'modules/client/dashboard/views/list/slice'

//Extend async Action
export const companyAnnouncementsListSearch = createAsyncThunk('dashboard/companyAnnouncementsListSearch', async (
    { searchText, limit, offset, filterSendDate, companyId, statusId },
    { rejectWithValue, dispatch }
) => {
    const filterDate = {
        filterSendDateIni: filterSendDate ? filterSendDate[0].format('YYYY-MM-DD') : null,
        filterSendDateEnd: filterSendDate ? filterSendDate[1].format('YYYY-MM-DD') : null
    }

    let service = 'searchCompanyAnnouncement';
    let param1 = '';
    let param2 = '';
    if (statusId) {
        service = 'searchCompanyAnnouncement' //"searchCompanyAnnouncementByStatus"
        param1 = ',$status: Int';
        param2 = ',status: $status';
    }

    try {
        let data = await clientQuery(
            `query ${service}($openSearch: String, $limit: Int, $offset: Int, $companyId: Int, $filterSendDateIni: Datetime, $filterSendDateEnd: Datetime ${param1}){
                result: ${service}(openSearch: $openSearch, limit: $limit, offset: $offset,
                    companyId: $companyId, filterSendDateIni: $filterSendDateIni, filterSendDateEnd: $filterSendDateEnd ${param2} ) {
                    items {
                        status
                        totalProgrammedNotes {
                            total
                            totalSent
                            totalSentByAdmins
                            totalSentByAgents
                            totalPending
                            totalPendingByAdmins
                            totalPendingByAgents
                        }
                        id
                        companyId
                        announcementId
                        announcement {
                            id
                            product
                            name
                            surname
                        }
                        numberNotes
                        lastNote {
                            note
                            createdOn
                            created {
                                firstName
                                lastName
                            }
                        }
                        hasOpen
                        contactCount
                        viewCount
                        campaignDetailId
                        status
                        campaignDetail {
                            campaignId
                            from
                            to
                            companyName
                            sendDate
                            readDate
                            campaign {
                                from
                                subject
                                status
                            }
                        }
                        approvedDocument
                        isRequestingForDocument
                        document {
                            id
                            url
                            name
                            type
                        }
                        updatedFileByUser {
                            fullName
                            company {
                                name
                            }
                        }
                        documentHistory {
                            id
                            document {
                                id
                                url
                                name
                                type
                            }
                            updatedFileByUser {
                                fullName
                                company {
                                    name
                                }
                            }
                            createdByUser {
                                fullName
                            }
                            isDeleted
                            date
                        }
                        createdOn
                        createdBy
                    }
                    count
                    countByStatus
                }
            }`,
            {
                openSearch: searchText || '',
                limit: limit || 10,
                offset: offset || 0,
                companyId: companyId,
                status: statusId,
                ...filterDate,
            },
            endpoints.GRAPHQL_GENERAL
        );


        return {...data?.result, countByStatus: JSON.parse(data.result.countByStatus)} || []

    } catch (exc) {
        ExceptionManager(exc, route, 'companyAnnouncementsListSearch', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const companyAnnouncementsListRedux = createSlice({
    name: 'dashboard/companyAnnouncements',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(companyAnnouncementsListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(companyAnnouncementsListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(companyAnnouncementsListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companyAnnouncementsListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer