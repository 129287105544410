import { createAction } from "@reduxjs/toolkit"

const resetALL = createAction('RESET/ALL');

export { resetALL }
export { changeMenu, changeLang } from './theme/slice'

export * from '../../core/security/.config/redux/actions'
export * from '../../modules/.config/redux/actions'
export { reset } from '../../components/Notifications/.config/redux/slice'
export * from '../../components/TextDescriptionCompanies/.config/redux/slice'
export { openModalAffiliatePartner } from '../../components/ModalAffiliatePartner/.config/redux/slice'

