import { Alert, Input, Table, notification } from "antd"
import { Fragment, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { usePaggination, useSecurity } from "../../../../../../config/hooks"
import { getEmailsList, getUserEmailsList, reset } from "./redux/slice"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { Link } from "react-router-dom"
import { P_REMINDER_NOTE_HISTORY } from "../../../../../../config/helpers/permissionsHash"
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies"

export const EmailProgrammingListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { data, loading, error } = useSelector(
    (state) => state.emailsList);

  const [agenteEmails, setAgentesEmail] = useState(true)

  const paggination = usePaggination(agenteEmails ? getUserEmailsList :getEmailsList);

  useEffect(() => {
    paggination.refresh();
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"]?.toString(),
        description: messages[error] || error.message,
      });
      reset('error');
    }
  }, [error])

  const R = useSecurity(P_REMINDER_NOTE_HISTORY, ["R"]);

  const typeoFNoticationMessage = (item) => {
    if (item == 0) {
      return {
        type: "warning",
        message: messages["listNotificacionStatusPending"]
      }
    } else if (item == 1) {
      return {
        type: "success",
        message: messages["listNotificacionStatusSuccess"]
      }
    } else {
      return {
        type: "error",
        message: messages["listNotificacionStatusError"]
      }
    }
  }

  const refresh = (curr) => {
    if(curr){
      dispatch(getUserEmailsList({ 
        searchText: '',
        limit: 10,
        offset: 0}))
    } else {
      dispatch(getEmailsList({ 
        searchText: '',
        limit: 10,
        offset: 0}))
    }
  }

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {R && <Input
                type="text"
                className="form-control my-1 w-100"
                placeholder={messages["search"]}
                onChange={paggination.onSearchChange}
                value={paggination.searchText}
              />}
            </div>
            <div className="col-md-8 col-sm-12" >
              <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                {R && <>
                  <button
                    className="btn btn-primary m-1"
                    onClick={() => {
                      paggination.refresh();
                    }}
                  >
                    {messages["refresh"]}
                  </button>
                  <button
                    className="btn btn-primary m-1"
                    onClick={() => {
                      setAgentesEmail(!agenteEmails)
                      refresh(!agenteEmails);
                    }}
                  >
                    {agenteEmails ? messages["emailProgramming_showAffiliati"] : messages["emailProgramming_showAgent"]}
                  </button>
                </>}
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>
          <div className="mt-4 table-responsive">
            {R && <Table
              rowKey="id"
              loading={loading}
              columns={[
                {
                  title: messages["emailProgramming_table_subject"],
                  key: "subject",
                  // dataIndex: "subject",
                  width: 200,
                  render: (values) => (

                    <Link
                      target="blank"
                      to={values?.companyAnnouncement ? `/admin/client/dashboard/detail/${values?.companyAnnouncement.announcementId}/${values?.companyAnnouncement.companyId}` : `/admin/client/dashboard/detail/user/${values?.userAnnouncement.announcementId}/${values?.userAnnouncement.userId}`}
                    >
                      {values.subject}
                    </Link>
                  )
                },
                {
                  title: messages["emailProgramming_table_note"],
                  key: "note",
                  dataIndex: "note",
                  width: 300
                },
                {
                  title: messages["emailProgramming_table_updated"],
                  key: "updated",
                  // dataIndex: "note",
                  width: 300,
                  render: (row) =>
                    row.updated &&
                    <p>
                      {row.updated.firstName} {row.updated.lastName}
                    </p>
                },
                {
                  title: messages["emailProgramming_table_emailSchedule"],
                  key: "emailSchedule",
                  // dataIndex: "updatedOn",
                  width: 150,
                  render: (row) =>
                    row.emailSchedule &&
                    moment(row.emailSchedule).format("DD/MM/yyyy, h:mm:ss a")
                },
                {
                  title: messages["emailProgramming_table_updatedOn"],
                  key: "updatedOn",
                  // dataIndex: "updatedOn",
                  width: 150,
                  render: (row) =>
                    row.updatedOn &&
                    moment(row.updatedOn).format("DD/MM/yyyy, h:mm:ss a")
                },
                {
                  title: messages["emailProgramming_table_sendStatus"],
                  key: "sendStatus",
                  // dataIndex: "sendStatus",
                  width: 50,
                  render: (row) =>
                    <Alert
                      message={typeoFNoticationMessage(row.sendStatus).message}
                      type={typeoFNoticationMessage(row.sendStatus).type}
                      showIcon
                    />
                }
              ]}
              dataSource={data?.items || []}
              pagination={{
                total: data?.count || 0,
                ...paggination.tablePaggination,
              }}
            />}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
