import { Fragment, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, Input, notification, Popover } from "antd";
import moment from "moment";

import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { announcementToManageListSearch, reset } from "./redux/slice";
import { getCatalog } from "../../../../../.config/redux/actions";

import { P_ANNOUNCEMENT_CONTRACT_MANAGE } from "../../../../../../config/helpers/permissionsHash";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
import validatorStatusContract from "../../helpers/validatorStatusContract";

//import { useNavigate } from "react-router";
const AnnouncementsContractsListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  //const navigate = useNavigate();

  const paggination = usePaggination(announcementToManageListSearch);

  //const { user } = useSelector((state) => state.authRedux);
  const { data, loading, error } = useSelector(
    (state) => state.announcementToManageListRedux
  );
  const { companies } = useSelector((state) => state.catalogRedux);
  const R = useSecurity(P_ANNOUNCEMENT_CONTRACT_MANAGE, ["R"]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"]?.toString(),
        description: messages[error] || error.message,
      });
      reset("error");
    }
  }, [error]);

  useEffect(() => {
    dispatch(getCatalog({ keys: ["companies"] }));
    paggination.refresh();
  }, []);

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {R && (
                <Input
                  type="text"
                  className="form-control my-1 w-100"
                  placeholder={messages["search"]}
                  onChange={paggination.onSearchChange}
                  value={paggination.searchText}
                />
              )}
            </div>
            <div className="col-md-8 col-sm-12">
              {R && (
                <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                  <button
                    className="btn btn-primary m-1"
                    onClick={() => {
                      paggination.refresh();
                    }}
                  >
                    {messages["refresh"]}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>

          <div className="mt-4 table-responsive">
            {R && (
              <Table
                rowKey="announcementId"
                loading={loading}
                dataSource={data?.items || []}
                pagination={{
                  total: data?.count || 0,
                  ...paggination.tablePaggination,
                }}
                columns={[
                  {
                    title: messages["dashboardList_announcementCode"],
                    key: "announcementId",
                    dataIndex: "announcementId",
                    width: 150,
                  },
                  {
                    title: messages["dashboardList_announcementTitle"],
                    key: "product",
                    dataIndex: "announcement",
                    render: (row) => row?.product,
                  },
                  {
                    title: messages["dashboardList_announcementAuthor"],
                    key: "author",
                    width: 250,
                    dataIndex: "announcement",
                    render: (values) => (
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="w-50">
                          <a>{`${values?.name} ${values?.surname} `} </a>
                        </div>
                      </div>
                    ),
                  },
                ]}
                expandable={{
                  expandedRowRender: ({ companyAnnouncements }) => {
                    const count =
                      companyAnnouncements &&
                      companyAnnouncements.reduce(
                        (accumulator, currentValue) => {
                          if (currentValue?.isRequestingForDocument) {
                            return accumulator + 1;
                          }
                          return accumulator;
                        },
                        0
                      );

                    return (
                      <>
                        <p>
                          {count} partner affiliato devono ancora caricare i
                          contratto di accettazione
                        </p>
                        <div className="d-flex flex-wrap">
                          {companyAnnouncements.map((values) => {
                            const company = companies.find(
                              (v) => v.id == values?.companyId
                            );
                            const updatedFileByUserCompany = companies.find(
                              (v) =>
                                v.id == values?.updatedFileByUser?.companyId
                            );
                            return (
                              <div
                                key={values.id}
                                style={{ width: "24%" }}
                                className="card m-1 p-2"
                              >
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column justify-content-between">
                                    <Link
                                      target="blank"
                                      to={`/admin/client/dashboard/detail/${values?.announcementId}/${values?.companyId}`}
                                    >
                                      {company?.name}
                                    </Link>
                                    <span>
                                      {values?.createdOn &&
                                        moment(values.createdOn).format(
                                          "DD/MM/yyyy HH:mm"
                                        )}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center w-20">
                                    <div>
                                      <Popover
                                        trigger="hover"
                                        content={
                                          <div>
                                            {!values?.document && !values?.isRequestingForDocument && (
                                              <span>{messages["dashboardList_noDocumentRequest"]}</span>
                                            )}
                                            {values?.document?.url && (
                                              <>
                                                <p>
                                                  {" "}
                                                  {
                                                    messages[
                                                      "dashboardList_AcceptanceInsertedCustomer"
                                                    ]
                                                  }{" "}
                                                  {updatedFileByUserCompany
                                                    ?.company?.name == null
                                                    ? values?.updatedFileByUser
                                                        ?.fullName
                                                    : updatedFileByUserCompany
                                                        ?.company?.name}{" "}
                                                </p>
                                                <a
                                                  className="text-primary"
                                                  href={values.document.url}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {
                                                    messages[
                                                      "dashboardList_seeMore"
                                                    ]
                                                  }
                                                </a>
                                              </>
                                            )}

                                            {!values?.document && values?.isRequestingForDocument && (
                                              <span>
                                                {
                                                  messages[
                                                    "dashboardList_UploadDocument"
                                                  ]
                                                }
                                              </span>
                                            )}
                                          </div>
                                        }
                                      >
                                        {validatorStatusContract(
                                          values?.isRequestingForDocument,
                                          values?.document,
                                          values?.approvedDocument
                                        )}
                                      </Popover>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  },
                  rowExpandable: (record) =>
                    record?.companyAnnouncements?.length,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AnnouncementsContractsListView;
