import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardRouter from './dashboard'
import EmailProgrammingRouter from './emailProgramming';
import NotificationsListRouter from './notificationsList';
import DocumentsAndContractsRouter from './documentsAndContracts';
import { useSecurity } from '../../../config/hooks';
import { P_DOCUMENT_CONTRACTS, P_LEAD_CENTER, P_REMINDER_NOTE_HISTORY, P_ANNOUNCEMENT_CONTRACT_MANAGE } from '../../../config/helpers/permissionsHash';
import AnnouncementsContractRouter from './announcementsContractManage';


const ClientRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to="/admin/client/dashboard" />}></Route>
            {useSecurity(P_LEAD_CENTER) && <>
                <Route path='/dashboard/*' element={<DashboardRouter />} />
                {useSecurity(P_REMINDER_NOTE_HISTORY) &&  <Route path='/email-programming/*' element=    {<EmailProgrammingRouter />} />}
            </>}
            
            <Route path='/notifications-list/*' element={<NotificationsListRouter />} />
            {useSecurity(P_DOCUMENT_CONTRACTS) && <Route path='/documents-contracts/*' element={<DocumentsAndContractsRouter />} />}
            {useSecurity(P_ANNOUNCEMENT_CONTRACT_MANAGE) && <Route path='/announcements-contracts/*' element={<AnnouncementsContractRouter />} />}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default ClientRouter;