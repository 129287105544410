export const P_HOME = null;

export const P_USERS_MANAGEMENT = "21262a6e-81dc-11ee-a4f2-12f9dde0de7b";
export const P_PERMISSIONS = "9ee40204-7e97-11ee-a4f2-12f9dde0de7b";
export const P_ROLES = "04776554-810c-11ee-a4f2-12f9dde0de7b";
export const P_USERS = "e8404a74-8198-11ee-a4f2-12f9dde0de7b";

export const P_COMPANIES = "5cb8b2cb-8348-11ee-a4f2-12f9dde0de7b";
export const P_MARKETING_SERVICES = "4f679c7d-8349-11ee-a4f2-12f9dde0de7b";
export const P_AFFILIATED_PARTNERS = "b50c6c51-8349-11ee-a4f2-12f9dde0de7b";
export const P_AGENTS = "7319b770-834a-11ee-a4f2-12f9dde0de7b";
export const P_ASSIGN_AGENT_TO_PA = "484f7cd6-8437-11ee-a4f2-12f9dde0de7b";
export const P_CONTRACTUALIZATION_DATE = "7db7aee4-843a-11ee-a4f2-12f9dde0de7b";

export const P_AUTOLOGIN = "62554b13-843f-11ee-a4f2-12f9dde0de7b";
export const P_CHANGE_PASSWORD = "833f2cb5-843f-11ee-a4f2-12f9dde0de7b";

export const P_EMAIL_CAMPAIGN = "5059f1a5-8504-11ee-a4f2-12f9dde0de7b";
export const P_EMAIL_SENT = "7a4780a6-8505-11ee-a4f2-12f9dde0de7b";

export const P_EMAIL_ARCHIVE = "b5fb2f9b-8590-11ee-a4f2-12f9dde0de7b";
export const P_CONFIGURATIONS = "0860468e-8591-11ee-a4f2-12f9dde0de7b";
export const P_SENDERS = "609ca83c-8591-11ee-a4f2-12f9dde0de7b";
export const P_TEMPLATES = "6ed93f20-8591-11ee-a4f2-12f9dde0de7b";
export const P_POPUP_CONTACTS = "91ea3825-8591-11ee-a4f2-12f9dde0de7b";
export const P_PARAMETRIZABLE_TEXTS = "6e06acf7-9db5-11ee-9f31-12f9dde0de7b";
export const P_NOTIFICATIONS = "a3a16624-8591-11ee-a4f2-12f9dde0de7b";

export const P_LEAD_CENTER = "5836dc67-85b7-11ee-a4f2-12f9dde0de7b";
export const P_LEAD_MANAGER = "742af58d-85b7-11ee-a4f2-12f9dde0de7b";
export const P_GESTIONE_LEAD_DETAIL = "be290bab-05b8-11ef-bfe5-12f9dde0de7b";
export const P_REMINDER_NOTE_HISTORY = "d50a5559-85b7-11ee-a4f2-12f9dde0de7b";
export const P_COMPANY_SELECTOR = "09b8866a-85b8-11ee-a4f2-12f9dde0de7b";
export const P_UPDATE_ANNOUNCEMENT_STATUS = "4b6c875f-85b8-11ee-a4f2-12f9dde0de7b";
export const P_ANNOUNCEMENT_STATUS_TABS = "72466340-85b8-11ee-a4f2-12f9dde0de7b";
export const P_ANNOUNCEMET_STATUS_COLUMN = "ae40843f-85b8-11ee-a4f2-12f9dde0de7b";
export const P_ANNOUNCMENT_LASTNOTE_COLUMN = "cdd32564-85b8-11ee-a4f2-12f9dde0de7b";
export const P_ANNOUNCEMENT_MAIL_WAS_OPENNED_COLUMN = "e4b46cc4-85b8-11ee-a4f2-12f9dde0de7b";
export const P_ANNOUNCEMENT_NOTES = "5f529447-85c6-11ee-a4f2-12f9dde0de7b";
export const P_ANNOUNCEMENT_NOTES_HISTORY = "8a037af6-85c6-11ee-a4f2-12f9dde0de7b";
export const P_ANNOUNCEMENT_CONTRACTS = "3d46c96f-e087-11ee-bfe5-12f9dde0de7b";
export const P_ANNOUNCEMENT_VIEW_HISTORY_CONTRATCS = "054a2fc4-e087-11ee-bfe5-12f9dde0de7b";
export const P_VIEW_DELETED_USERS_IN_PARNERT_AFILIATY = "9653540e-eaf1-11ee-bfe5-12f9dde0de7b";

export const P_DOCUMENT_CONTRACTS = "94a6f07d-85cb-11ee-a4f2-12f9dde0de7b";
export const P_FILES = "a8a518af-85cb-11ee-a4f2-12f9dde0de7b";

export const P_WATHCHDOCUMENTSINNOTES = "041d1867-a4fb-11ee-9f31-12f9dde0de7b";
export const P_NOTES = "aed26353-a4f3-11ee-9f31-12f9dde0de7b";
export const P_AGENTS_PASTATES = "d6a8b5bf-ba1a-11ee-9f31-12f9dde0de7b";
export const P_FILTER_PER_ROLE_USER_CAMPAIGNS = "e548ec89-e951-11ee-bfe5-12f9dde0de7b"

export const P_ANNOUNCEMENT_CONTRACT_MANAGE = "6781505d-fa91-11ee-bfe5-12f9dde0de7b"

export const P_GL_AGENTE = "66037979-0530-11ef-bfe5-12f9dde0de7b";
export const P_GL_AGENTE_UPDATE_ANNOUNCEMENT_STATUS = "bb8ec2d1-0531-11ef-bfe5-12f9dde0de7b";
export const P_GL_AGENTE_NOTES = "693d834c-0531-11ef-bfe5-12f9dde0de7b";
export const P_GL_AGENTE_NOTES_HISTORY = "ebe951aa-0531-11ef-bfe5-12f9dde0de7b";
export const P_GL_AGENTE_STATUS_TABS = "c11484b2-0531-11ef-bfe5-12f9dde0de7b";
export const P_GL_AGENTE_EMAIL_WAS_OPENNED_COLUMN = "e890f74d-0531-11ef-bfe5-12f9dde0de7b";
export const P_GL_AGENTE_LASTNOTE_COLUMN = "e3a97482-0531-11ef-bfe5-12f9dde0de7b";
export const P_GL_AGENTE_STATUS_COLUMN = "de334018-0531-11ef-bfe5-12f9dde0de7b";

export const P_AREA_MANAGER = '28aea597-0e45-11ef-bfe5-12f9dde0de7b';
export const P_HTTP_LOGS = 'd96080cf-1793-11ef-bfe5-12f9dde0de7b';

export const P_AREA_MANAGER_COLUMN = '20a79d4f-2129-11ef-bfe5-12f9dde0de7b'
export const P_AGENTE_ASSIGN_SUPERVISORS = '0fba31da-2129-11ef-bfe5-12f9dde0de7b'

export const P_GL_AREA_MANAGER = 'a880e639-4f5f-11ef-bfe5-12f9dde0de7b';
export const P_GL_AREA_MANAGER_SELECTOR = '3a9c5710-4f67-11ef-bfe5-12f9dde0de7b'
export const P_GL_AREA_MANAGER_UPDATE_ANNOUNCEMENT_STATUS = 'ce109979-4f77-11ef-bfe5-12f9dde0de7b';
export const P_GL_AREA_MANAGER_EMAIL_WAS_OPENNED_COLUMN = '2c6dafc4-4f78-11ef-bfe5-12f9dde0de7b';
export const P_GL_AREA_MANAGER_STATUS_COLUMN = '69c21153-4f78-11ef-bfe5-12f9dde0de7b';
export const P_GL_AREA_MANAGER_LASTNOTE_COLUMN = 'a341b084-4f78-11ef-bfe5-12f9dde0de7b';
export const P_GL_AREA_MANAGER_STATUS_TABS = '12c164c7-4f79-11ef-bfe5-12f9dde0de7b';
export const P_GL_AREA_MANAGER_NOTES = "b49b217e-4f7b-11ef-bfe5-12f9dde0de7b";
export const P_GL_AREA_MANAGER_NOTES_HISTORY = "e39725fa-4f7b-11ef-bfe5-12f9dde0de7b";