import { useImperativeHandle, forwardRef, useState, useCallback, useRef, useMemo } from "react";
import cx from 'classnames';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getBase64Logo } from './utils';
import { useIntl } from 'react-intl';
import './styles.scss';

export const TableView = forwardRef((props, ref) => {
  const { messages } = useIntl();

  const {
    loading,
    columns = [],
    items = [],
    onChange,
    searchText,
    rowsPerPage = 10,
    height,
    name,
    count,
    isLocal,
    isSelectable,
    customRowStyle,
    onSelectedItem
  } = props;

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [seletedItems, setSeletedItems] = useState([]);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      onChange && onChange((page - 1) * rowsPerPage, rowsPerPage)
    },
    exportPdf: exportPdf,
    seletedItems: seletedItems.filter((item) => !!item),
    clearSelectedItems: () => {
      setSeletedItems([]);
    },
  }));

  const getClearValue = (value) => {
    if (!value) return "";

    if (typeof value == 'undefined') return "";

    if (Array.isArray(value) || typeof value === 'object') return "";

    if (isNaN(value)) return value.toLowerCase();

    return value;
  }

  const exportPdf = useCallback(() => {
    const doc = new jsPDF('p', 'pt', 'letter');
    doc.setFontSize(10);

    let base64Img = getBase64Logo();
    jsPDF.autoTableSetDefaults({
      headStyles: {
        font: "helvetica",
        fillColor: "#de3980",
        textColor: "white",
        halign: "left",
        fontStyle: "bold",
        fontSize: 10,
        lineColor: "#dc4e6c",
        lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }
      }
    }, doc)

    let rows = [...filteredItems];
    const headers = [...columns];

    let content = {
      pageBreak: 'auto',
      startY: 70,
      didDrawPage: (data) => {
        // Header
        if (base64Img && data?.pageNumber === 1) {
          doc.addImage(base64Img, 'JPEG', 480, 20, 100, 45);
        }

        if (data?.pageNumber === 1) {
          doc.setFontSize(16);
          doc.setTextColor(40);
          doc.text(name ? name?.replace("-", " ") : "Credi10-data", data.settings.margin.right, 50);
        }
      },
      head: [headers.map(x => x.title)],
      body: rows.map((row) => {
        return headers.map((col) => {
          return {
            content: col.exportValue && col.exportValue(row) || row[col.key] || "",
            styles: {
              cellWidth: 'auto',
              overflow: 'linebreak',
              minCellWidth: 60
            }
          }
        })
      }),
    };

    doc.autoTable(content);
    doc.save(`${name}.pdf`);
  });

  const [filteredItems, total] = useMemo(() => {
    let newItems = [...items];
    let total = count || items.length

    if (searchText && typeof searchText !== 'undefined' && searchText.length > 0) {
      newItems = items.filter(x => {
        let result = false;
        columns.forEach(col => {
          let value;
          if (col?.sortValue) {
            value = getClearValue(col?.sortValue(x));
          } else {
            value = getClearValue(x[col.key]);
          }

          if (value && value?.toString().indexOf(searchText.toLowerCase()) >= 0) {
            result = true;
          }
        });

        return result;
      })

      total = newItems?.length
    }

    if (sort) newItems = sortData(newItems || [], sort, columns || []);
    return [newItems, total];
  });

  const paginatedItems = useMemo(() => {
    let currentPage = page - 1;
    return isLocal ? filteredItems.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : items;
  });

  const onSeletItem = (item, key) => {
    let currentSeletedItems = [...seletedItems];

    // console.log("item", key, item)
    const index = currentSeletedItems.findIndex(x => x === item)
    if (index > -1) {
      currentSeletedItems.splice(index, 1);
    }
    else{
      currentSeletedItems.push(item)
    }
    // if (currentSeletedItems[key]) {
    //   currentSeletedItems[key] = null;
    // } else {
    //   currentSeletedItems[key] = item;
    // }


    setSeletedItems(currentSeletedItems);
    if (onSelectedItem) {
      onSelectedItem(item, currentSeletedItems)
    }
  }

  const onSelectAllItems = () => {
    let newValue = [...items]
    if (seletedItems.length === items.length) {
      newValue = [];
    }
    setSeletedItems(newValue);
    if (onSelectedItem) {
      console.log("onSelectedItem2", newValue)
      onSelectedItem(null, newValue)
    }
  }

  return (
    <div>
      {
        loading &&
        <div className="linear-activity">
          <div className="indeterminate"></div>
        </div>
      }
      <div
        className="table-responsive min-vh-75"
        style={{
          height: height,
          overflow: "auto"
        }}
      >
        <table className="table search-table v-middle">
          <thead className="header-item">
            <tr>
              {
                isSelectable && <th>
                  <div className="n-chk align-self-center text-center">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input secondary"
                        checked={!loading && seletedItems.length === items.length}
                        onChange={onSelectAllItems}
                      />
                      <label className="form-check-label" htmlFor="contact-check-all" />
                      <span className="new-control-indicator" />
                    </div>
                  </div>
                </th>
              }
              {
                columns.map((col, key) => {
                  return (
                    <th
                      key={key}
                      className={cx(sort && sort?.key === col.key && sort?.order == 'asc' ? 'sorting-desc' : 'sorting-asc', col?.headerClass)}
                      style={{ ...col?.headerStyle, minWidth: col?.width || 'auto' }}
                      onClick={() => {
                        let newSort = { ...sort };

                        if (sort?.key === col.key) {
                          newSort = { key: col.key, order: sort?.order == 'asc' ? 'desc' : 'asc' };
                        } else {
                          newSort = { key: col.key, order: 'desc' };
                        }

                        setSort(newSort);
                      }}
                    >
                      {col?.title}
                    </th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              paginatedItems.map((item, key) => {
                const posKey = (10 * (page - 1)) + key;
                let newCustomRowStyle = customRowStyle && customRowStyle(item)
                return (
                  <tr className="search-items" key={key} style={newCustomRowStyle}>
                    {isSelectable && <td key={'selectable'}>
                      <div className="n-chk align-self-center text-center">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input secondary"
                            checked={!loading && !!seletedItems[posKey]}
                            onChange={() => onSeletItem(item, posKey)}
                          />
                          <label className="form-check-label" htmlFor="contact-check-all" />
                          <span className="new-control-indicator" />
                        </div>
                      </div>
                    </td>}
                    {
                      columns.map((col, colKey) => {
                        return (
                          <td key={colKey}>
                            {
                              col?.render && col?.render(item)
                              || item[col?.key]
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <div className="container-fluid pt-0 ps-3 pe-3 mt-2 ms-0 me-0">
        <div className="row align-items-center" style={{ paddingBottom: 20 }}>
          <div
            className="col-md-4 col-xs-12 col-sm-12 ps-0 pe-0 text-xs-center text-sm-center text-md-start"
            style={{ paddingTop: 15, paddingBottom: 15 }}
          >
            <span className="fw-bolder">{`${count || total} ${messages['table_rows']}`}</span>
          </div>

          <div className="col-md-4 col-xs-12 col-sm-12"></div>

          <div className="col-md-4 col-xs-12 col-sm-12 ps-0 pe-0">
            <div className="d-flex justify-content-xs-center justify-content-sm-center justify-content-md-end">
              <TableView.Pagination
                currentPage={page}
                pageLimit={rowsPerPage}
                onChange={(newPage) => { setPage(newPage); onChange && onChange((newPage - 1) * rowsPerPage, rowsPerPage) }}
                count={count || total}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
});

TableView.Pagination = ({ currentPage, pageLimit, onChange, count }) => {

  const pageNumberLimit = 5;
  const [maxPageLimit, setMaxPageLimit] = useState(5)
  const [minPageLimit, setMinPageLimit] = useState(0)

  // init
  //const { currentPage, maxPageLimit, minPageLimit } = props;
  const totalPages = Math.ceil(count / pageLimit)


  // build page numbers list based on total number of pages
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const handlePrevClick = () => {
    if (currentPage > 1) {
      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
      }
      onChange(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
      }
      onChange(currentPage + 1)
    }
  }

  const handlePageClick = (e) => {
    onChange(e)
  }

  const pageNumbers = pages.map(page => {

    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        <li key={page} id={page} onClick={() => handlePageClick(page)} className={cx("page-item", currentPage == page && 'active')}>
          <a className="page-link">{page}</a>
        </li>
      );
    } else {
      return null;
    }
  }

  );

  // page ellipses
  let pageIncrementEllipses = null;
  if (pages.length > maxPageLimit) {
    pageIncrementEllipses = <li onClick={() => handleNextClick()} className="page-item"><a className="page-link">&hellip;</a></li>
  }
  let pageDecremenEllipses
  if (minPageLimit >= 1) {
    pageDecremenEllipses = <li onClick={() => handlePrevClick()} className="page-item"><a className="page-link">&hellip;</a></li>
  }

  return (
    <nav>
      <ul className="pagination mb-0">
        <li className="page-item page-indicator cursor-pointer">
          <a className="page-link" onClick={() => handlePrevClick()}>
            <i className='bx bxs-chevron-left'/>       
          </a>
        </li>
        {pageDecremenEllipses}
        {pageNumbers}
        {pageIncrementEllipses}
        <li className="page-item page-indicator cursor-pointer">
          <a className="page-link" onClick={() => handleNextClick()}>
            <i className='bx bx-chevron-right' />
          </a>
        </li>
      </ul>
    </nav>
  )
}

TableView.useTableView = () => {
  let ref = useRef()
  return {
    ref: ref,
    refresh: () => {
      ref?.current?.refresh();
    },
    exportPdf: () => {
      ref?.current?.exportPdf();
    },
    seletedItems: () => ref?.current?.seletedItems,
    clearSelectedItems: () => {
      ref?.current?.clearSelectedItems();
    },
  }
}

const getClearValue = (value) => {
  if (!value) return "";

  if (typeof value == 'undefined') return "";

  if (Array.isArray(value) || typeof value === 'object') return "";

  if (isNaN(value)) return value.toLowerCase();

  return value
}

const normalizeMixedDataValue = (value) => {
  const padding = "000000000000000";
  if (!value) return "";
  // Loop over all numeric values in the string and
  // replace them with a value of a fixed-width for
  // both leading (integer) and trailing (decimal)
  // padded zeroes.
  value = value?.toString()?.replace(
    /(\d+)((\.\d+)+)?/g,
    ($0, integer, decimal, $3) => {

      // If this numeric value has "multiple"
      // decimal portions, then the complexity
      // is too high for this simple approach -
      // just return the padded integer.
      if (decimal !== $3) {
        return (
          padding.slice(integer.length) +
          integer +
          decimal
        );
      }

      decimal = (decimal || ".0");

      return (
        padding.slice(integer.length) +
        integer +
        decimal +
        padding.slice(decimal.length)
      );

    }
  );

  return value;
}

const sortData = (data, sort, columns) => {
  return [...data].sort((a, b) => {
    let i = 0, result = 0;

    let column = columns?.find(column => column?.key === sort?.key);
    let order = (sort.order == 'asc' ? 1 : -1);

    let valueA;
    let valueB;
    if (column && column?.sortValue) {
      valueA = getClearValue(column?.sortValue(a));
      valueB = getClearValue(column?.sortValue(b));
    } else {
      valueA = getClearValue(a[sort.key]);
      valueB = getClearValue(b[sort.key]);
    }

    if (column?.alphanumeric) {
      valueA = normalizeMixedDataValue(valueA);
      valueB = normalizeMixedDataValue(valueB);
    }

    result = order * (valueA < valueB ? -1 : (valueA > valueB ? 1 : 0));

    return result;
  })
}
