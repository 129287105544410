import { useEffect, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { Table } from "antd";
import { TableOptions } from "components";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { permissionsListSearch, reset } from "./redux/slice";
import ModalEditPermission from "../../modals/edit";
import { openEditpermission } from "../../.config/redux/actions";

import { P_PERMISSIONS } from "../../../../../../config/helpers/permissionsHash";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
const PermissionsList = () => {
    /**
     * Orden:
     * 1. constantes globales
     * 2. useStates
     * 3. callback
     * 4. effects
     * 5. uso de hooks propios, calculos de valores que se hagan constantes
     */

    //1.
    //manejo de lenguaje
    const intl = useIntl();
    const { messages } = intl;

    //despachador de redux
    const dispatch = useDispatch();
    // const navigate = useNavigate()

    //2.
    //manejo de seleccion
    const [selectedRowKeys /* setSelectedRowKeys */] = useState([]);
    //redux del view
    const { loading, data } = useSelector(
        (state) => state.permissionsListRedux
    );

    //4.
    //efecto de carga de view
    useEffect(() => {
        paggination.refresh();

        //efecto de salida del view
        return () => {
            //reset del redux
            dispatch(reset(null));
        };
    }, []);

    const paggination = usePaggination(permissionsListSearch);

    const C = useSecurity(P_PERMISSIONS, ["C"]);
    const R = useSecurity(P_PERMISSIONS, ["R"]);
    const U = useSecurity(P_PERMISSIONS, ["U"]);

    return (
        <Fragment>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="card ">
                    {/* BARRA DE TAREA (BUSQUEDA BOTONES) */}
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                {R && (
                                    <input
                                        type="text"
                                        className="form-control form-control-solid w-250px ps-13"
                                        placeholder={messages[
                                            "search"
                                        ]?.toString()}
                                        onChange={paggination.onSearchChange}
                                    />
                                )}
                            </div>
                            <div className="col-md-8 col-sm-12 mt-2 mt-sm-0">
                                {selectedRowKeys.length == 0 ? (
                                    <div className="d-flex justify-content-end">
                                        {R && (
                                            <button
                                                type="button"
                                                className="btn btn-primary me-3"
                                                onClick={() =>
                                                    paggination.refresh()
                                                }
                                            >
                                                <i className="ki-duotone ki-arrow-circle-right fs-2">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </i>
                                                {messages[
                                                    "refresh"
                                                ]?.toString()}
                                            </button>
                                        )}

                                        {C && (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    dispatch(
                                                        openEditpermission({})
                                                    )
                                                }
                                            >
                                                <i className="ki-duotone ki-plus fs-2"></i>
                                                {messages["add"]?.toString()}
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div className="fw-bold me-5">
                                            <span
                                                className="me-2"
                                                data-kt-user-table-select="selected_count"
                                            ></span>
                                            {selectedRowKeys.length}{" "}
                                            {messages["selected"]}
                                        </div>
                                        {/* <button
                                            type="button"
                                            className="btn btn-danger"
                                            data-kt-user-table-select="delete_selected"
                                        >
                                            {`${messages["deleteSelected"]}`}
                                        </button> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12 mt-4 mb-4">
                            <TextDescriptionCompanies />
                        </div>
                    </div>
                    {/* TABLA */}
                    <div className="card-body py-4 table-responsive">
                        {loading && <div className="linear-loading " />}
                        {R && (
                            <Table
                                rowKey="id"
                                scroll={{ x: 1000 }}
                                style={{ minHeight: "calc(100vh - 350px)" }}
                                showSorterTooltip={false}
                                columns={[
                                    {
                                        key: "actions",
                                        width: 50,
                                        title: "",
                                        className: "min-vh-30",
                                        render: (values) => {
                                            return (
                                                <TableOptions
                                                    options={[
                                                        {
                                                            show: U,
                                                            label: messages[
                                                                "edit"
                                                            ],
                                                            onClick: () => {
                                                                dispatch(
                                                                    openEditpermission(
                                                                        {
                                                                            id: values.id,
                                                                        }
                                                                    )
                                                                );
                                                            },
                                                        },
                                                        {
                                                            show: false,
                                                            label: messages[
                                                                "delete"
                                                            ],
                                                            onClick: () => { },
                                                        },
                                                    ]}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: messages[
                                            "permissions_parentId"
                                        ]?.toString(),
                                        key: "parentId",
                                        dataIndex: "parent",
                                        width: 250,
                                        render: (value) =>
                                            messages[value?.name]?.toString() ||
                                            value?.name,
                                    },
                                    {
                                        title: messages["name"]?.toString(),
                                        dataIndex: "name",
                                        render: (value) =>
                                            messages[value]?.toString() ||
                                            value,
                                        width: 250,
                                    },
                                    {
                                        title: messages[
                                            "description"
                                        ]?.toString(),
                                        dataIndex: "description",
                                    },
                                    {
                                        title: messages[
                                            "permissions_hash"
                                        ]?.toString(),
                                        key: "hash",
                                        dataIndex: "hash",
                                        width: 300,
                                    },
                                ]}
                                dataSource={data.items}
                                // rowSelection={{
                                //     selectedRowKeys,
                                //     onChange: (newSelectedRowKeys) => {
                                //         setSelectedRowKeys(newSelectedRowKeys);
                                //     },
                                // }}
                                onChange={paggination.onChange}
                                pagination={{
                                    total: data?.count || 0,
                                    ...paggination.tablePaggination,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ModalEditPermission onRefresh={() => paggination.refresh()} />
        </Fragment>
    );
};

export default PermissionsList;
