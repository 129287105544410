import { Routes, Route, Navigate } from 'react-router-dom';

import { EmailProgrammingListView } from './views/list';

const EmailProgrammingRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/client/email-programming/list`} />}></Route>
            <Route path='/list' element={<EmailProgrammingListView />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default EmailProgrammingRouter;