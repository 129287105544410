import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation } from '../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../config//constants';
import { ExceptionManager } from '../../../../../config//helpers/Logging'

import { resetALL } from '../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    result: null
};

const route = 'security/views/login/views/redux/slice'

//Extend async Action
export const loginSingIn = createAsyncThunk('security/login/singin', async ({ username, password, navigate, redirect, isHash = false }, { rejectWithValue }) => {
    try {

        let queryHead = `
                mutation login ($username: String!, $password: String!) {
                    login (username: $username, password: $password) {
            `

        if (isHash)
            queryHead = `
                mutation loginH ($username: String!, $password: String!) {
                    login: loginH (username: $username, password: $password) {
            `

        let data = await clientMutation(
            `
                ${queryHead}
                        ... on Authentication {
                            token
                        }
                        ... on CustomResponse {
                            code
                            message
                            data
                        }
                    }
                }
            `,
            {
                username,
                password
            },
            endpoints.GRAPHQL_AUTH
        );


        if (data?.login?.token) {
            localStorage.setItem("token", data.login.token)
            navigate(redirect ? redirect : '/');
            return {}
        }
        else if (data?.login?.code == "LOGIN_VALIDATE_OTP") {
            return data?.login
        }
        else
            throw Error("Incorrect Username or Password");

    } catch (exc) {
        ExceptionManager(exc, route, 'loginSingIn');
        return rejectWithValue(exc)
    }
})

export const loginOTP = createAsyncThunk('security/login/otp', async ({ id, code, navigate }, { rejectWithValue }) => {
    try {

        let data = await clientMutation(`
                    mutation loginOTP ($id: String!, $code: String!) {
                        login: loginOTP (id: $id, code: $code) {
                            ... on Authentication {
                                token
                            }
                            ... on CustomResponse {
                                code
                                message
                                data
                            }
                        }
                    }
                `,
            {
                id,
                code
            },
            endpoints.GRAPHQL_AUTH
        );


        if (data?.login?.token) {
            localStorage.setItem("token", data.login.token)
            navigate('/');
            return {}
        }
        else
            throw Error("Incorrect Username or Password");

    } catch (exc) {
        ExceptionManager(exc, route, 'loginSingIn');
        return rejectWithValue(exc)
    }
})

export const loginOTPResend = createAsyncThunk('security/login/otpResend', async ({ id }, { rejectWithValue }) => {
    try {

        let data = await clientMutation(`
                    mutation loginOTPResendCode ($id: String!) {
                        response: loginOTPResendCode (id: $id) {
                            code
                            message
                            data
                        }
                    }
                `,
            {
                id
            },
            endpoints.GRAPHQL_AUTH
        );


        if (data?.response) {
            return data?.response
        }
        else
            throw Error("Incorrect Username or Password");

    } catch (exc) {
        ExceptionManager(exc, route, 'loginSingIn');
        return rejectWithValue(exc)
    }
})

//Slice
const loginRedux = createSlice({
    name: 'security/login',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(loginSingIn.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loginSingIn.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(loginSingIn.rejected, (state, action) => {
            state.loading = false
            state.result = { error: action.payload }
        })

        builder.addCase(loginOTP.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loginOTP.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(loginOTP.rejected, (state, action) => {
            state.loading = false
            state.result = { error: action.payload }
        })

        builder.addCase(loginOTPResend.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loginOTPResend.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(loginOTPResend.rejected, (state, action) => {
            state.loading = false
            state.result = { error: action.payload }
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = loginRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer