let locales = {
    users_editUser_edit: 'Modifica utente',
    users_editUser_create: 'Crea utente',
    users_editUser_avatar: 'Avatar',
    users_editUser_username: 'Username',
    users_editUser_email: 'Email',
    users_editUser_roles: 'Ruoli',
    users_editUser_firstName: 'Nome',
    users_editUser_lastName: 'Cognome',

};

export default locales;