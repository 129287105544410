import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientQuery, clientMutation } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";

//STATE INITIAL
const INIT_STATE = {
  data: null,
  loading: false,
  error: null,
};

const route = "modules/management/httpLogs/views/list/redux/slice";

// searchDocumentsContracts(searchText: String, limit: Int, offset: Int): DocumentContractList

// type DocumentContract {
//     id: Int
//     note: String
//     name: String
//     files: [File]
//     visibleTo: [Int]
//     createdBy: Int
//     createdOn: Datetime
//   }

// Extend async Action

export const searchHtppErrorLogs = createAsyncThunk(
  "httpLogs/searchHtppErrorLogs",
  async (
    { searchText = null, limit = 10, offset = 0, filters, orderBy = [] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let data = await clientQuery(
        `query searchHtppErrorLogs( $searchText: String, $limit: Int, $offset: Int, $filters: FiltersHEL, $orderBy: String ){ 
                  result: searchHtppErrorLogs(searchText: $searchText, limit: $limit, offset: $offset, filters: $filters, orderBy: $orderBy ){
                  count
                  items {
                      id
                      userId
                      originLink
                      destinationLink
                      code
                      ip
                      date
                      user {
                        id
                        fullName
                        email
                        roles {
                          name
                        }
                      }
                  }
                }
            
            }`,
        {
          searchText,
          limit,
          offset,
          filters,
          orderBy: JSON.stringify(orderBy)
        },
        endpoints.GRAPHQL_GENERAL
      );

      return data?.result || null;
    } catch (exc) {
      ExceptionManager(exc, route, "searchHtppErrorLogs", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const createHttpErrorLog = createAsyncThunk(
  "httpLogs/createHtppErrorLog",
  async (
    {originLink, destinationLink, code},
    { rejectWithValue, dispatch }
  ) => {
    try {
      let data = await clientMutation(
        `mutation createHttpErrorLog($originLink: String, $destinationLink: String, $code: Int){ 
          result: createHttpErrorLog(originLink: $originLink, destinationLink: $destinationLink, code: $code){
                    id
                }
            }`,
        {
          originLink, destinationLink, code
        },
        endpoints.GRAPHQL_GENERAL
      );

      return data?.result || null;
    } catch (exc) {
      ExceptionManager(exc, route, "createHttpErrorLog", dispatch);
      return rejectWithValue(exc);
    }
  }
);
//Slice
export const httpLogSlice = createSlice({
  name: "httpLogList",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    // getDocumentsList
    builder.addCase(searchHtppErrorLogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchHtppErrorLogs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(searchHtppErrorLogs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = httpLogSlice;

// Extract and export each action creator by name
export const { reset } = actions;

// Export the reducer, either as a default or named export
export default reducer;
