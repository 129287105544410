import { Modal } from "antd";
import { useIntl } from "react-intl";

export function ConfirmDeleteModal(props) {
    const { show = false, onCancel, onOk } = props;
    const intl = useIntl();
    const { messages } = intl;


    const _onCancel = () => {
        if(onCancel){
            onCancel();
        }
    }
    const _onOk = () => {
        if(onOk){
            onOk();
        }
    }

    return (
        <Modal
            open={show}
            onCancel={_onCancel}
            width={400}
            title={messages["delete"].toString()}
            footer={null}
        >
            <div>
                    <div className="card-body my-5">
                        <p>{messages["confirmDelete"].toString()}</p>
                    </div>
                    <div className="text-center pt-2">
                        <button
                            className="btn btn-danger me-3"
                            onClick={_onOk}
                        >
                            {messages["delete"]?.toString()}
                        </button>
                        <button
                            className="btn btn-label-secondary"
                            onClick={_onCancel}
                        >
                            {messages["cancel"]?.toString()}
                        </button>
                       
                    </div>
                </div>
        </Modal>
    );
}