import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';

import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: { count: 0, items: [] },
    error: null
};

const route = 'routes/admin/management/permissions/views/list/redux/slice'

//Extend async Action
export const permissionsListSearch = createAsyncThunk('permissions/list/search', async ({ searchText, limit, offset, filters, orderBy }, { rejectWithValue }) => {
    try {
        let data = await clientQuery(
            `
                query searchPermissions($searchText: String, $limit: Int, $offset: Int) {
                    result: searchPermissions(searchText: $searchText, limit: $limit, offset: $offset) {
                        count
                        items { 
                            id
                            parentId
                            parent {
                                name
                            }
                            name
                            hash
                            description
                        }
                    }
                }
            `,
            {
                searchText, limit, offset, filters, orderBy
            },
            endpoints.GRAPHQL_GENERAL
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'permissionsListSearch');
        return rejectWithValue(exc)
    }
})


//Slice
const permissionsListRedux = createSlice({
    name: 'permissions/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(permissionsListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(permissionsListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(permissionsListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = permissionsListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer