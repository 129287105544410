import { Routes, Route, Navigate } from 'react-router-dom';

import UsersRouter from './users'
import CompaniesRouter from './companies'
import CampaignsRouter from './campaigns'
import ParameterRouter from './parameters'
import PermissionsRouter from './permissions';
import RolesRouter from './roles';
import { useSecurity } from '../../../config/hooks';
import { P_COMPANIES, P_EMAIL_ARCHIVE, P_EMAIL_CAMPAIGN, P_USERS_MANAGEMENT, P_HTTP_LOGS } from '../../../config/helpers/permissionsHash';
import HTTPLogsRouter from './httpLogs';
import FormsRouter from './forms';

const ManagementRouter = () => {

    return (
        <Routes>
            {useSecurity(P_COMPANIES,) && <Route path='/' element={<Navigate to="/admin/management/companies" />}></Route>}
            {useSecurity(P_COMPANIES,) && <Route path='/companies/*' element={<CompaniesRouter />} />}
            {useSecurity(P_EMAIL_CAMPAIGN,) && <Route path='/campaigns/*' element={<CampaignsRouter />} />}
            {useSecurity(P_EMAIL_ARCHIVE,) && <Route path='/parameters/*' element={<ParameterRouter />} />}
            {useSecurity(P_USERS_MANAGEMENT,) && <>
                <Route path='/users/*' element={<UsersRouter />} />
                <Route path='/permissions/*' element={<PermissionsRouter />}/>
                <Route path='/roles/*' element={<RolesRouter />}/>
            </>}
            {useSecurity(P_HTTP_LOGS) &&
            <Route path='/http-logs/*' element={<HTTPLogsRouter />}/>}
            <Route path='/forms/*' element={<FormsRouter />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default ManagementRouter;