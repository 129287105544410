let locales = {
  dashboardList_announcementDate: `Data dell'annuncio`,
  dashboardList_announcementTitle: `Titolo dell'annuncio`,
  dashboardList_announcementCode: "Code richiesta",
  dashboardList_announcementAuthor: "Autore richiesta",
  dashboardList_announcementHasOpen: "E-mail aperta",
  dashboardList_announcementContactCount: "Quantità di contatto",
  dashboardList_announcementViewCount: "Quantità Visualizzazioni",
  dashboardList_numberNotes: "Numero di note",
  dashboardList_lastNote: "Ultima nota",
  dashboardList_changeStatus: "Cambia stato",
  dashboardList_status: "Stato",
  dashboardList_totalNote: "di {totalNote} note totali",
  dashboardList_lastNoteDescription: "Descripzione ultima nota",
  dashboardList_seeMore: "vedere di più",
  dashboardList_AcceptanceInsertedCustomer:
    "Accettazione del cliente inserito:",
  dashboardList_UploadDocument: "Devi caricare un documento",
  dashboardList_noDocumentRequest: "Non è stato richiesto alcun documento",
};

export default locales;
