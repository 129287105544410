//Global keys
let locales = {
    "management_menu": "Administración"
};

//Merge Modules
import users from '../../users/.config/lan/es'
import companies from '../../companies/.config/lan/es'
import parameters from '../../parameters/.config/lan/es'
import campaigns from '../../campaigns/.config/lan/es'
import permissions from '../../permissions/.config/lan/es'
import roles from '../../roles/.config/lan/es'
import workFlowStatesModule from '../../workFlowStates/.config/lan/es'
import httplogs from '../../httpLogs/.config/lan/es'
import forms from '../../forms/.config/lan/es'

locales = {
    ...locales,
    ...users,
    ...companies,
    ...parameters,
    ...campaigns,
    ...permissions,
    ...roles,
    ...workFlowStatesModule,
    ...httplogs,
    ...forms
};

export default locales;