import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clientQuery,
  clientMutation,
} from "../../../../../../../config/helpers/GraphQLApi";

import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  error: null,
};

const route = "modules/management/parameters/modals/editCampaignTemplate/slice";

//Extend async Action
export const campaignTemplateEditOpen = createAsyncThunk(
  "parameter/editCampaignTemplate/open",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      //si es una creacion se retorna
      if (!id) {
        return { data: null };
      }

      //si es edicion se optiene la informacion
      let data = await clientQuery(
        `query getCampaignTemplateById($id: Int!) {
                campaignTemplate: getCampaignTemplateById(id: $id) {
                    id
                    name
                    body
                    subject
                    parameters
                }
             }`,
        {
          id: Number(id),
        },
        endpoints.GRAPHQL_GENERAL
      );

      //Se retorna la data del server
      return { data: data.campaignTemplate };
    } catch (exc) {
      //Se guarda la excepcion
      ExceptionManager(exc, route, "campaignTemplateEditOpen", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const campaignTemplateEditSave = createAsyncThunk(
  "parameters/campaignTemplate/save",
  async ({ id, body, name, subject }, { rejectWithValue, dispatch }) => {
    try {
      let data;
      if (id) {
        data = await clientMutation(
          `mutation updateCampaignsTemplate ($id: Int!, $body: String!, $subject: String, $name: String) {
                updateCampaignsTemplate (id: $id, body: $body, subject: $subject, name: $name)
                {
                  id
                }
            }`,
          {
            id,
            body,
            name,
            subject,
          },
          endpoints.GRAPHQL_GENERAL
        );

        return data.updateCampaignsTemplate.id;
      } else {
        //create
        data = await clientMutation(
          `mutation createCampaignsTemplate ($body: String!, $subject: String, $name: String!) {
                createCampaignsTemplate (body: $body, subject: $subject, name: $name) 
                {
                    id
                }
            }`,
          {
            body,
            name,
            subject,
          },
          endpoints.GRAPHQL_GENERAL
        );

        return data.createCampaignsTemplate.id;
      }
    } catch (exc) {
      ExceptionManager(exc, route, "campaignTemplateEditSave", dispatch);
      return rejectWithValue(exc);
    }
  }
);

//Slice
const campaignTemplateEditRedux = createSlice({
  name: "parameters/campaignTemplateEdit",
  initialState: INIT_STATE,
  reducers: {
    campaignTemplateEditClose(state, action) {
      return INIT_STATE;
    },
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    //thunk editOpen
    builder.addCase(campaignTemplateEditOpen.pending, (state) => {
      state.loading = true;
      state.isOpen = true;
    });
    builder.addCase(campaignTemplateEditOpen.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    });
    builder.addCase(campaignTemplateEditOpen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //thunk save
    builder.addCase(campaignTemplateEditSave.pending, (state) => {
      state.loading = true;
      state.result = null;
      state.error = null;
    });
    builder.addCase(campaignTemplateEditSave.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(campaignTemplateEditSave.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = campaignTemplateEditRedux;

// Extract and export each action creator by name
export const { campaignTemplateEditClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
