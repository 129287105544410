import { useMemo } from "react"
import { useSelector } from "react-redux"

export const useSecurity = (hash, keys = ['C','R','U','D'], user) => {

   let userInfo = user   

   if (!userInfo)
      userInfo = useSelector((state) => state.authRedux)?.user

   if(userInfo.isAdmin){
      return true
   }

   const result = useMemo(() => {
      const userPermission = userInfo?.permissions || []
      const userSpecificPermission = userPermission?.find((x) => x.hash == hash);
      let _result = false
      if (userSpecificPermission) {
         if(userSpecificPermission?.isSingle){
            _result = userSpecificPermission['R'];
         } else {
            keys.forEach((k) => {
               if (userSpecificPermission[k])
                  _result = true
            })
         }
      }
      return _result;
   }, [userInfo, keys, hash])

   return result
}