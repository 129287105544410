//Global keys
let locales = {
    "notificationsList_title": "Titulo",
    "notificationsList_body": "messaggio di notifica",
    "notificationsList_createdOn": "data di creazione",
    "notificationsList_state": "Stato",
    "notificationsList_read": "Letto",
    "notificationsList_not_read": "Non letto",
    "notificationsList_user": "Utenti",
    "notificationsList_date": "Data"
};


export default locales;