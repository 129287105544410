import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetALL } from "../../../../../../../config/redux/actions";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { endpoints } from "../../../../../../../config/constants";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    show: false,
    data: null,
    result: null,
    error: null
};

const route = 'modules/management/parameters/workFlowStates/modals/editStepForm/redux/slice'

//Extend async Action

export const openEditStep = createAsyncThunk('workFlowStates/modals/editStepForm/redux/slice', async ({ id }, { rejectWithValue }) => {
    try {


        if (!id) return null;
        let data = await clientQuery(
            `
            query GetPAStateById($id: Int) {
                result: getPAStateById(id: $id) {
                 color
                 description
                 helpText
                 id
                 limitAttemps
                 limitHours
                 name
               }
             }
            `,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL
        );

        // console.log(data.result, "llego la data del query");
        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'openEditStep', dispatch);
        return rejectWithValue(exc)
    }
});

export const saveEditStep = createAsyncThunk('workFlowStates/modals/saveEditStep/redux/slice', async ({ id, color, description, helpText, limitAttemps, limitHours, name, }, { rejectWithValue, dispatch }) => {
    try {

        let data;
        if (id) {

            // update step

            data = await clientMutation(`
            mutation Mutation($id: Int!, $color: String, $name: String, $helpText: String, $description: String, $limitAttemps: Int, $limitHours: Int) {
                updatePAState(id: $id, color: $color, name: $name, helpText: $helpText, description: $description, limitAttemps: $limitAttemps, limitHours: $limitHours) {
                  color
                  description
                  helpText
                  limitAttemps
                  limitHours
                  name
                  id
                }
              }
            `,
                {
                    id,
                    color,
                    description,
                    helpText,
                    limitAttemps,
                    limitHours,
                    name,
                },
                endpoints.GRAPHQL_GENERAL

            );
            return data.updatePAState.id;
        }

    } catch (exc) {
        ExceptionManager(exc, route, 'saveEditStep', dispatch);
        return rejectWithValue(exc)
    }
})


//slice
const globalWorkflowStepsEditRedux = createSlice({
    name: "parameters/editStepForm",
    initialState: INIT_STATE,
    reducers: {
        globalWorkflowStepsEditClose(state, action) {
            return INIT_STATE;
        },
        reset(state, action) {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //thunk editOpen
        builder.addCase(openEditStep.pending, (state) => {
            state.loading = true;
            state.isOpen = true;
        });
        builder.addCase(openEditStep.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        });
        builder.addCase(openEditStep.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        //thunk save
        builder.addCase(saveEditStep.pending, (state) => {
            state.loading = true;
            state.isOpen = true;
            state.result = null;
        });
        builder.addCase(saveEditStep.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.isOpen = false;
            state.result = payload;
        });
        builder.addCase(saveEditStep.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    }
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = globalWorkflowStepsEditRedux;

// Extract and export each action creator by name
export const { globalWorkflowStepsEditClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;

