import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    show: false,
    data: null,
    result: null,
    resultError: null
};

const route = 'routes/admin/configurations/permissions/modals/edit/redux/slice'

//Extend async Action
export const openEditpermission = createAsyncThunk('permissions/edit/open', async ({ id }, { rejectWithValue }) => {
    try {
        if (!id) return null;

        let data = await clientQuery(
            `
            query GetpermissionById($id: Int!) {
                result: getPermissionById(id: $id) {
                    id
                    name
                    description
                    parentId
                    isSingle
                }
            }
            `,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL,
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'openEditpermission');
        return rejectWithValue(exc)
    }
})

export const saveEditpermission= createAsyncThunk('permissions/edit/save', async ({ id, name, parentId,description, isSingle }, { rejectWithValue }) => {
    try {

        let data

        if (id) {
            data = await clientMutation(
                `mutation UpdatePermission($id: Int!, $name: String!, $parentId: Int, $description: String, $isSingle: Boolean) {
                    result: updatePermission(id: $id, name: $name, parentId: $parentId, description: $description, isSingle: $isSingle) {
                        id
                    }
                }`,
                {
                    id: Number(id),
                    name,
                    parentId,
                    description,
                    isSingle
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }
        else {
            data = await clientMutation(
                `mutation CreatePermission($name: String!, $parentId: Int, $description: String, $isSingle: Boolean) {
                    result: createPermission(name: $name, parentId: $parentId, description: $description, isSingle: $isSingle) {
                        id
                    }
                }`,
                {
                    name,
                    parentId,
                    description,
                    isSingle
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'saveEditPermission');
        return rejectWithValue(exc)
    }
})


//Slice
const permissionEditRedux = createSlice({
    name: 'permission/edit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        hide(state) {
            state.show = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(openEditpermission.pending, (state) => {
            state.loading = true
            state.show = true
        })
        builder.addCase(openEditpermission.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(openEditpermission.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })

        builder.addCase(saveEditpermission.pending, (state) => {
            state.loading = true
        })
        builder.addCase(saveEditpermission.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(saveEditpermission.rejected, (state, { payload }) => {
            state.loading = false
            state.resultError = payload;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = permissionEditRedux

// Extract and export each action creator by name
export const { reset, hide } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer