let locales = {
    "forms_menu": "Formularios",
    "forms_title": "Título",
    "forms_description": "Descriptión",
    "forms_questions": "Preguntas"
};

// merge modal SubmitDocument
import list from '../../views/list/lan/es'

locales = {
    ...locales,
    ...list,
};

export default locales;