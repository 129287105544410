import { useState } from "react";
import { InputFile } from "../InputFile"


const UploadInput = (props) => {
  const { value, onChange } = props;

  const [files, setFiles] = useState([]);

  // add a file to note
  const addFileToNote = (file) => {
    console.log(files, '---files---', file, '--file--')
    if (files.length === 10) {
      console.log('files.length === 10')
      return;
    }

    if (files.length == 0) {
      console.log('entro en la condicion?')
      setFiles([file]);
      onChange([file]);
    }

    setFiles([...files, file]);
    if(onChange){
      onChange([...files, file])
    }
  }
  // delete a file in component
  const onDeteleFileToNote = (index) => {
    console.log(index, 'index')
    if (files?.length > 0) {
      let newValues = files.filter((v, i) => index !== i);
      setFiles(newValues);
      if(onChange){
        onChange(newValues)
      }
    }
  }

  return (
    <div>
      <InputFile multi value={value? value : files } onChange={addFileToNote} accept=".png, .jpg, .jpeg, application/pdf" onDelete={onDeteleFileToNote}/>
    </div>
  )
}

export default UploadInput