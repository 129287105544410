import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    result: null,
    email: null,
    error: null
};

const route = 'modules/management/companies/modals/changePassword/redux/slice'

//Extend async Action
export const changePassword = createAsyncThunk('companies/changePassword/changePassword', async (
    {email, newPassword},
    { rejectWithValue, dispatch }
) => {
    try {
        let result = await clientMutation(
            `mutation changePasswordUser($newPassword: String!, $email: String!) {
                changePasswordUser(newPassword: $newPassword, email: $email)
            }`,
            {
                email, 
                newPassword
            },
            endpoints.GRAPHQL_GENERAL
        );
        return result;

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesEditOpen', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const companiesChangePasswordRedux = createSlice({
    name: 'companies/changePassword',
    initialState: INIT_STATE,
    reducers: {
        companiesChangePasswordClose(state, action) {
            return INIT_STATE
        },
        companiesChangePasswordOpen(state, action) {
            state.isOpen = true;
            state.email = action.payload.email
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //thunk save
        builder.addCase(changePassword.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(changePassword.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(changePassword.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companiesChangePasswordRedux

// Extract and export each action creator by name
export const { companiesChangePasswordClose, reset, companiesChangePasswordOpen } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer