import cx from 'classnames'

export const Button = (props) => {

    const { className, type, loading = false, loadingText, onClick, disabled } = props

    return (
        <button type={type} className={className} disabled={loading || disabled} onClick={onClick}>
            {
                loading
                    ? <span className={cx('indicator-progress', loading && 'd-inline')}>
                        {loadingText ? loadingText : props.children} {" "}
                        <div className="spinner-grow" style={{ width: 15, height: 15 }}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </span>
                    : <span className={cx('indicator-label')}>
                        {props.children}
                    </span>
            }


        </button>
    )
}

export const Link = (props) => {

    const { className, onClick, href, target } = props

    return (
        <a className={className} onClick={onClick} href={href} target={target}>
            {props.children}
        </a>
    )
}