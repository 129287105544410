let locales = {
    login_welcome_message: 'Bienvenido a Edilnet',
    login_welcome_detail: 'Crea y Administra tus tareas de una manera simple y eficiente, maneja tus proyectos y recursos sin tener que usar diferentes herramientas todo lo puedes encontrar aqui.',
    login_create_account: 'Crea una cuenta',
    login_forgot_password: 'Olvido la contraseña?',
    login_username: 'Usuario',
    login_password: 'Contraseña',
    login_register: 'Registrarse',
    login_singin: 'Iniciar',
    login_with_facebook: 'Iniciar con Facebook',
    login_with_google: 'Iniciar con Google',
    login_otp_title: 'Por favor ingrese el codigo OTP para validar su cuenta',
    login_otp_description: 'El codigo ha sido enviado a ',
    login_otp_no_code: '¿No tiene un codigo?',
    login_recovery_password: 'Recuperar Contraseña',
};

export default locales;