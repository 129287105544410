let locales = {
    campaignDetail_title: 'Dettagli della spedizione',
    campaignDetail_emailContent: 'Contenido del correo',
    campaignDetail_emailStatus: 'Estado del correo',
    campaignDetail_emailError: 'Errore',
    campaignDetail_emailSent: 'Messaggio inviato',
    campaignDetail_emailRead: 'Messaggio letto',
    campaignDetail_emailNoRead: 'Messaggio non letto',
};

export default locales;