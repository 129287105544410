let locales = {
    "documentsandContracts_submitDocument": "caricare il documento",
    "documentsandContracts_dateOfCreation": "Data Insertimiento",
    "documentsandContracts_nomeFile": "Nombre File",
    "documentsandContracts_noteFile": "Nota",
    "documentsandContracts_file": "Documenti",
    "documentsandContracts_visibleTo": "Visible A",
};


export default locales;