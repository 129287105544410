let locales = {
    changePAState_nextState: 'Prossimo stato',
    changePAState_state: 'Stato',
    changePAState_note: 'Testo della nota',
    changePAState_date: 'Data della nota',
    changePAState_rememberChanges: 'Ricordate che la nota è obbligatoria per i cambiamenti di stato',
    changePAState_noteMessage: 'Vuoi inserire solo la nota?',
    changePAState_confirmOnlyNote: 'Si, solo la nota'
};

export default locales;