import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
    Modal,
    Form,
    Input,
    Skeleton,
    notification,
    Select,
    Checkbox,
} from "antd";

import { hide, reset, saveEditpermission } from "./redux/slice";
import { getCatalog } from "../../../../../.config/redux/actions";

const ModalEditPermission = ({ onRefresh }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { messages } = intl;
    const [notificationApi, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();

    const { show, data, loading, resultError, result } = useSelector(
        (state) => state.permissionEditRedux
    );
    const { permissions } = useSelector((state) => state.catalogRedux);

    const [id, setId] = useState();
    useEffect(() => {
        open && dispatch(getCatalog({ keys: ["permissions"], force: true }));
    }, [open]);

    useEffect(() => {
        if (data && !id) {
            setId(data.id);
            form.setFieldsValue({
                ...data,
            });
        }
    }, [data]);

    useEffect(() => {
        dispatch(reset({ result: null, resultError: null }));

        if (result) {
            notificationApi.success({
                message: messages["saveSuccessfullyTitle"].toString(),
                description: messages["saveSuccessfully"].toString(),
            });

            close(true);
        }

        if (resultError) {
            const { error } = resultError;
            notificationApi.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error,
            });
        }
    }, [resultError, result]);

    const close = useCallback((refresh) => {
        dispatch(hide());
        setId(null);
        form.resetFields();
        dispatch(reset(null));

        if (refresh && onRefresh) onRefresh();
    }, []);

    return (
        <Modal
            title={
                <h5>
                    {id
                        ? messages["permissionEdit_titleEdit"]?.toString()
                        : messages["permissionEdit_title"]?.toString()}
                </h5>
            }
            open={show}
            width={800}
            onCancel={() => close()}
            footer={null}
        >
            {contextHolder}
            <div className="p-3 p-md-5">
                <div className="card-body">
                    <div className="alert alert-warning d-flex" role="alert">
                        <i className='bx bxs-error me-2' />
                        <h6 className="alert-heading mb-1">{messages["permissionEdit_warningTitle"]?.toString()}</h6>
                    </div>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{parentId: 0}}
                        onValuesChange={() => {}}
                        onFinish={(values) => {
                            dispatch(saveEditpermission({ id, ...values }));
                        }}
                    >
                        {loading ? (
                            <>
                                <Skeleton active />
                            </>
                        ) : (
                            <div className="row g-3">
                                <Form.Item
                                    className="col-12"
                                    valuePropName="checked"
                                    label={
                                        <label className="form-label">
                                            {'Just a single action'}
                                        </label>
                                    }
                                    name="isSingle"
                                >
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item
                                    className="col-12 col-md-6"
                                    label={
                                        <label className="form-label">
                                            {messages["name"]}
                                        </label>
                                    }
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: messages["required_field"],
                                        },
                                    ]}
                                >
                                    <Input
                                        className="form-control "
                                        placeholder={messages[
                                            "name"
                                        ]?.toString()}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="col-12 col-md-6"
                                    label={
                                        <label className="form-label">
                                            {messages["permissions_parentId"]}
                                        </label>
                                    }
                                    name="parentId"
                                >
                                    <Select
                                        placeholder={messages[
                                            "permissions_parentId"
                                        ]?.toString()}
                                        options={[{
                                            label: '_',
                                            value: 0,
                                        },...permissions.filter(v => v.id != id).map(v => ({
                                            label: messages[v.name]?.toString() || v.name,
                                            value: v.id,
                                        }))]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="col-12"
                                    label={
                                        <label className="form-label">
                                            {messages["description"]}
                                        </label>
                                    }
                                    name="description"
                                >
                                    <Input.TextArea
                                        className="form-control "
                                        placeholder={messages[
                                            "description"
                                        ]?.toString()}
                                    />
                                </Form.Item>
                            </div>
                        )}
                    </Form>
                </div>
                <div className="row pt-5">
                    <div className="text-center pt-10">
                        <button
                            className="btn btn-primary me-sm-3 me-1"
                            disabled={loading}
                            onClick={() => form.submit()}
                        >
                            {messages["save"]?.toString()}
                        </button>
                        <button
                            className="btn btn-label-secondary"
                            onClick={() => close({})}
                        >
                            {messages["close"]?.toString()}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalEditPermission;
