import { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { notification, Spin } from 'antd'
import { useNavigate } from 'react-router-dom';

import {
    companiesChangePasswordOpen,
    getCatalog,
    getUserDetail,
    openEditUser,
} from "../../../../../../config/redux/actions";

import { useSecurity } from "../../../../../../config/hooks";
import {
    P_USERS,
    P_AGENTS,
    P_CHANGE_PASSWORD,
    P_AUTOLOGIN,
} from "../../../../../../config/helpers/permissionsHash";

import EditUserModal from "../../modals/editUser";
import UserNotes from "../../components/notes";
import ChangePasswordModal from "../../../companies/modals/changePassword";
import moment from "moment";

const STATUS = {
    1: "user_userList_activeStatus",
    2: "user_userList_inactiveStatus",
    0: "user_userList_deletedStatus",
};

const MANAGEMENT_PATH = 'users/detail/';
const COMPANIES_PATH = 'companies/user/detail/';

const UserViewDetail = () => {
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const navigate = useNavigate()
    let { id } = useParams();

    const R = useSecurity(P_USERS, ["R"]) || useSecurity(P_AGENTS, ["R"])
    const U = useSecurity(P_USERS, ["U"]);
    const D = useSecurity(P_USERS, ["D"]);

    const { states } = useSelector(state => state.catalogRedux)
    const { data, loading, error } = useSelector((state) => state.userDetailRedux);

    const [currentTab, setCurrentTab] = useState(0);
    const [showFullompaniesAssigned, setShowFullompaniesAssigned] = useState(false)
    const [showFullSupervisors, setShowFullSupervisors] = useState(false)

    useEffect(() => {
        if (R) {
            dispatch(getUserDetail({ id }));
            dispatch(getCatalog({ keys: ['states'] }))
        } else {
            navigate('/error')
        }
    }, []);

    useEffect(() => {
        if (error) {
            notification.error({ message: messages["saveError"], description: messages[error] || error })
            if (error == 'UnAuthorized')
                navigate('/unauthorized')
        }
    }, [error])

    const status = useMemo(() => {
        const values = { ...data };

        if (values.isDeleted) {
            return (
                <span className="badge bg-label-danger">
                    {messages[STATUS[0]]}
                </span>
            );
        }
        switch (values.status) {
            case 1:
                return (
                    <span className="badge bg-label-success">
                        {messages[STATUS[1]]}
                    </span>
                );
            case 2:
                return (
                    <span className="badge bg-label-secondary">
                        {messages[STATUS[2]]}
                    </span>
                );
            default:
                return <></>;
        }
    }, [data]);

    const companiesAssigned = useMemo(() => {
        if (data?.companiesAssigned && data.companiesAssigned.length) {
            const currentPath = window.location.pathname;
            let pathToReplace = MANAGEMENT_PATH + data.id
            if (currentPath.includes(COMPANIES_PATH)) {
                pathToReplace = COMPANIES_PATH + data.id
            }
            return data.companiesAssigned.map(v =>
                <li key={v.id}>
                    <a target="_blank" href={window.location.pathname.replace(pathToReplace, 'companies/detail/' + v.id)} rel="noreferrer">{v.name}</a>
                </li>)
        }
        return []
    }, [data])

    const supervisors = useMemo(() => {
        if (data?.supervisors && data.supervisors.length) {
            const currentPath = window.location.pathname;
            let pathToReplace = MANAGEMENT_PATH + data.id
            if (currentPath.includes(COMPANIES_PATH)) {
                pathToReplace = COMPANIES_PATH + data.id
            }
            return data.supervisors.map(v =>
                <li key={v.id}>
                    <a target="_blank" href={window.location.pathname.replace(pathToReplace, 'users/detail/' + v.id)} rel="noreferrer">{v.fullName}</a>
                </li>)
        }
        return []
    }, [data])

    // Funcion que permite traer un solo estado(provincia)
    const getState = useMemo(() => {
        let state = states?.find(x => x.id == data?.state)
        if (!state)
            return ('')
        return (`${state?.name} (${state?.abrev})`)
    }, [data])

    // Funcion que permite traer un listado de estados(provincias de trabajo)
    const getworkState = useMemo(() => {
        let items = data && JSON.parse(data?.workState) || []
        let workState = items.map(s => states?.find(x => x.id == s))
        return workState.map((x, index) => <div key={index} className='col-4'>
            <i className="me-2 mdi mdi-briefcase-check"></i>{`${x?.name} (${x?.abrev})`}
        </div>)

    }, [data])

    const onChangeTab = (keyTab) => () => {
        setCurrentTab(keyTab);
    };

    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            <div className="row">

                <div className="col-md-12 col-lg-4 col-xlg-3 mb-4">
                    <div className="card w-100">
                        <Spin spinning={loading}>
                            <div className="user-avatar-section">
                                <div className="user-info text-center my-4">
                                    <div className="avatar-wrapper me-2">
                                        <img
                                            className="img-fluid rounded my-4"
                                            src={data?.imageUrl || "/assets/images/avatars/blank.png"}
                                            height="110"
                                            width="110"
                                            alt="User avatar"
                                        />
                                    </div>
                                    <h2>{data?.fullName}</h2>
                                    {data?.roles && data.roles.map((v) => (
                                        <span
                                            key={v?.id}
                                            className={`badge bg-label-secondary mr-1`}
                                        >
                                            {v?.name}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="card-body">
                                <h5 className="pb-2 border-bottom mb-4">
                                    {messages["companiesDetail_title"]}
                                </h5>
                                <div className="row">
                                    <ul className="list-unstyled">
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {
                                                    messages[
                                                    "users_editUser_username"
                                                    ]
                                                }
                                                :
                                            </span>
                                            <span>{data?.username}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["email"]}:
                                            </span>
                                            <span>{data?.email}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["status"]}:
                                            </span>
                                            <span>{status}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["companiesDetail_paridaIVA"]}:
                                            </span>
                                            <span>{data?.paridaIVA}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["companiesDetail_state"]}:
                                            </span>
                                            <span>{getState}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["companiesDetail_city"]}:
                                            </span>
                                            <span>{data?.city}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["companiesDetail_address"]}:
                                            </span>
                                            <span>{data?.address}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">{messages["companiesDetail_phone1"]}:</span>
                                            <span>{data?.phone1}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">{messages["companiesDetail_phone2"]}:</span>
                                            <span>{data?.phone2}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["companiesDetail_workActivity"]}:
                                            </span>
                                            <span>{data?.workActivity}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">{messages["companiesDetail_freeStartDate"]}:</span>
                                            <span>{data?.freeStartDate && moment(data.freeStartDate).format('DD/MM/yyyy')}</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fw-bold me-2">{messages["companiesDetail_freeEndDate"]}:</span>
                                            <span>{data?.freeEndDate && moment(data.freeEndDate).format('DD/MM/yyyy')}</span>
                                        </li>

                                        <li className="mb-3">
                                            <span className="fw-bold me-2">
                                                {messages["companiesDetail_workState"]}:
                                            </span>
                                            <span>{getworkState}</span>
                                        </li>

                                        {data?.companiesAssigned &&
                                            <li>
                                                <span className="fw-bold me-2" >
                                                    {messages['sidebar_list2']}:
                                                </span>
                                                <ul>
                                                    {companiesAssigned.slice(0, showFullompaniesAssigned ? 100 : 5)}
                                                </ul>
                                                {companiesAssigned.length > 5 && <a onClick={() => setShowFullompaniesAssigned(!showFullompaniesAssigned)}>{showFullompaniesAssigned ? '^' : '...' + messages['seeMore']}</a>}
                                            </li>
                                        }
                                        {data?.supervisors &&
                                            <li>
                                                <span className="fw-bold me-2" >
                                                    {messages['sidebar_areaManager']}:
                                                </span>
                                                <ul>
                                                    {supervisors.slice(0, showFullSupervisors ? 100 : 5)}
                                                </ul>
                                                {supervisors.length > 5 && <a onClick={() => setShowFullSupervisors(!showFullSupervisors)}>{showFullSupervisors ? '^' : '...' + messages['seeMore']}</a>}
                                            </li>
                                        }
                                    </ul>
                                </div>

                                <div className="d-flex justify-content-center pt-3 flex-wrap">
                                    {U && (
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={() => {
                                                dispatch(openEditUser({ id }));
                                            }}
                                        >
                                            <i
                                                data-feather="users"
                                                className="feather-sm fill-white me-1"
                                            />
                                            {messages["edit"]}
                                        </button>
                                    )}
                                    {useSecurity(P_CHANGE_PASSWORD) && (
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={() => {
                                                dispatch(
                                                    companiesChangePasswordOpen({
                                                        email: data?.email,
                                                    })
                                                );
                                            }}
                                        >
                                            <i
                                                data-feather="users"
                                                className="feather-sm fill-white me-1"
                                            />
                                            {
                                                messages[
                                                "companiesDetail_changePassword"
                                                ]
                                            }
                                        </button>
                                    )}
                                    {useSecurity(P_AUTOLOGIN) && (
                                        <a
                                            className="btn btn-primary m-2"
                                            href={`/login/${data?.username}/${data?.hashp}`}
                                            target="blank"
                                        >
                                            <i
                                                data-feather="users"
                                                className="feather-sm fill-white me-1"
                                            />
                                            {messages["companiesDetail_autologin"]}
                                        </a>
                                    )}
                                    {D && <button
                                        className="btn btn-label-danger m-2"
                                        onClick={() => { }}
                                    >

                                        {messages["delete"]}
                                    </button>}
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
                <div className="col-md-12 col-lg-8 col-xlg-9">
                    <ul className="nav nav-pills flex-column flex-md-row mb-3">
                        <li className="nav-item mr-2">
                            <a
                                className={`nav-link ${currentTab === 0 ? "active" : ""
                                    }`}
                                onClick={onChangeTab(0)}
                            >
                                <i
                                    data-feather="users"
                                    className="feather-sm fill-white me-1"
                                >
                                    {" "}
                                </i>
                                {"Notes"}
                            </a>
                        </li>
                    </ul>
                    <div className="card min-vh-75">
                        <div>{<UserNotes userId={id} />}</div>
                    </div>
                </div>
            </div>
            <ChangePasswordModal />
            <EditUserModal
                onRefresh={(refresh) =>
                    refresh && dispatch(getUserDetail({ id }))
                }
            />
        </div>
    );
};

export default UserViewDetail;
