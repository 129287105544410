import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
};

const route = 'modules/management/companies/views/list/slice'

//Extend async Action
export const campaignListSearch = createAsyncThunk('companies/campaignListSearch', async (
    { searchText, limit, offset, filterSendDate, filterError, filterRead, filterAction, companyId, roleId, createdBy, userId },
    { rejectWithValue, dispatch }
) => {
    const filterDate = {
        sendStartDate: filterSendDate ? filterSendDate[0].format('YYYY-MM-DD') : null,
        sendEndDate: filterSendDate ? filterSendDate[1].format('YYYY-MM-DD') : null
    }
    try {
        let data = await clientQuery(
            `query searchCampaingDetails($searchText: String, $limit: Int, $offset: Int, 
                $companyId: Int, $sendStartDate: Datetime, $sendEndDate: Datetime, 
                $hasError: Boolean, $hasRead: Boolean, $roleId: Int, $createdBy: Int,$userId: Int){
                result: searchCampaingDetails(searchText: $searchText, limit: $limit, offset: $offset,
                    companyId: $companyId, sendStartDate: $sendStartDate, sendEndDate: $sendEndDate, 
                    hasError: $hasError, hasRead: $hasRead, roleId: $roleId, createdBy: $createdBy, userId: $userId) {
                    items {
                        id
                        campaignId
                        companyId
                        companyName
                        userName
                        from
                        to
                        body
                        subject
                        sendDate
                        readDate
                        error
                        createdOn
                        createdBy
                        createdByUser {
                            fullName,
                            isAdmin,
                            roles {
                                name
                            }
                        }
                    }
                    count
                }
            }`,
            {
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0,
                ...filterDate,
                hasError: filterError === 99 ? null : filterError,
                hasRead: filterRead === 99 ? null : filterRead,
                filterAction: filterAction === 99 ? null : filterAction,
                companyId,
                roleId,
                createdBy,
                userId
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data?.result || []

    } catch (exc) {
        ExceptionManager(exc, route, 'campaignListSearch', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const campaignsListRedux = createSlice({
    name: 'companies/campaigns',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(campaignListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(campaignListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(campaignListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = campaignsListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer