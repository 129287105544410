let locales = {
    parametrizableText_Edit: "Modifica del testo parametrizzabile",
    parametrizableText_Create: "Creare testo parametrizzabile",
    parametrizableText_ROL: "Ruolo utente",
    parametrizableText_URI: "Modulo utente da implementare",
    parametrizableText_Description: "Breve descrizione per il testo parametrizzabile",
    parametrizableText_Content: "Contenuto del testo a discesa parametrizzabile",
    parametrizableTextOption_Main: "La tua Home",
};

export default locales;