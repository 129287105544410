import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    data0: null,
    data1: null,
    result: null,
    error: null,
};

const route = 'modules/management/companies/views/list/slice'

//Extend async Action
export const companiesListSearch = createAsyncThunk('companies/list/listSearch', async (
    { searchText, limit, offset, businessCode, justDeleted = false, filters },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query searchCompanies($businessCode: Int!, $searchText: String, $limit: Int, $offset: Int, $justDeleted: Boolean, $filters: Filters){
                companies: searchCompanies(businessCode: $businessCode, searchText: $searchText, limit: $limit, offset: $offset, justDeleted: $justDeleted, filters: $filters) {
                    items {
                        id
                        userId
                        stateId
                        name
                        workActivity
                        state
                        workState
                        email
                        freeEndDate
                        lastPayment {
                            payEndDate
                        }
                        status
                        isDeleted
                        agentsAssigned {
                            agentId
                            companyId
                            agentProfile{
                                id
                                fullName
                                firstName
                                lastName
                            }
                        }
                        user {
                            companiesAssigned {
                                id
                                name
                            }
                        }
                    }
                    count
                    PAStatesCount
                }
            }`,
            {
                businessCode,
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0,
                justDeleted,
                filters
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data?.companies ? {...data.companies, PAStatesCount: JSON.parse(data.companies?.PAStatesCount), businessCode} : null

    } catch (exc) {
        ExceptionManager(exc, route, 'companiesListSearch', dispatch);
        return rejectWithValue(exc)
    }
})


//Action Activate Companies
export const activateCompany = createAsyncThunk('companies/list/activateCompany', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const result = await clientMutation(
            `mutation activateCompany ($id: Int!) {
                activateCompany(id: $id) {
                    id
                }
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return result;
    } catch (exc) {
        ExceptionManager(exc, route, 'activateCompany', dispatch);
        return rejectWithValue(exc)
    }
})

//Action Delete Companies
export const deleteCompany = createAsyncThunk('companies/list/deleteCompany', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const result = await clientMutation(
            `mutation deleteCompany ($id: Int!) {
                deleteCompany(id: $id)
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return result;
    } catch (exc) {
        ExceptionManager(exc, route, 'deleteCompany', dispatch);
        return rejectWithValue(exc)
    }
})


//Slice
const companiesListRedux = createSlice({
    name: 'companies/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(companiesListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(companiesListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            switch (payload.businessCode) {
                case 0:
                    state.data0 = payload
                    break;
                case 1:
                    state.data1 = payload
                    break;
                default:
                    state.data = payload
                    break;
            }
            
        })
        builder.addCase(companiesListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
        })

        //thunk activateCompany
        builder.addCase(activateCompany.pending, (state) => {
            state.loading = true
        })
        builder.addCase(activateCompany.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(activateCompany.rejected, (state, action) => {
            state.loading = false
            state.result = action.payload.error
        })


        //thunk deleteCompany
        builder.addCase(deleteCompany.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteCompany.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(deleteCompany.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
        })

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companiesListRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer