let locales = {
  dashboardList_announcementDate: "Fecha del anuncio",
  dashboardList_announcementTitle: "Título del anuncio",
  dashboardList_announcementCode: "Código de solicitud",
  dashboardList_announcementAuthor: "Autor",
  dashboardList_announcementHasOpen: "E-mail aperta",
  dashboardList_announcementContactCount: "Quantità di contatto",
  dashboardList_announcementViewCount: "Quantità Visualizzazioni",
  dashboardList_numberNotes: "Número de notas",
  dashboardList_lastNote: "Última nota",
  dashboardList_changeStatus: "Cambiar estado",
  dashboardList_status: "Estado",
  dashboardList_totalNote: "{totalNote} notas en total",
  dashboardList_lastNoteDescription: "Ultima nota",
  dashboardList_seeMore: "ver más",
  dashboardList_AcceptanceInsertedCustomer: "Aceptación del cliente insertado:",
  dashboardList_UploadDocument: "Se necesita subir un documento",
  dashboardList_noDocumentRequest: "No se ha solicitado ningún documento"
};

export default locales;
