import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTextDescriptionCompanies } from './.config/redux/slice';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { getCatalog } from '../../config/redux/actions';


export const TextDescriptionCompanies = () => {

  // const { data, loading, error } = useSelector((state) => state.textDescriptionCompanies)
  const { user } = useSelector((state) => state.authRedux);
  const [isOpen, setisOpen] = useState(false)
  const { parametrizableTexts, loadingParametrizableTexts } = useSelector((state) => state.catalogRedux);

  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    dispatch(getCatalog({ keys: ["parametrizableTexts"] }));

  }, []);

  const value = useMemo(() => {
    if (parametrizableTexts?.length) {
      const userRole = user?.roles[0].id; //role of user
      const v = parametrizableTexts.find(v => `${pathname}` == v.uri && v.rolesId.includes(userRole));
      return v
    }
    return null
  }, [parametrizableTexts, location?.pathname])

  return (
    <>
      {
        !loadingParametrizableTexts
          ?
          <div >
            {value?.description}

            <a className='link-primary'
              onClick={(e) => {
                e.preventDefault()
                setisOpen(!isOpen)
              }}> Scopri di più...</a>
            {
              isOpen ?
                <div dangerouslySetInnerHTML={{ __html: value?.content }} />
                : <div />
            }

          </div>
          :

          <div />
      }
    </>

  )
}
