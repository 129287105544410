import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    result: null,
    errorResult: null,
    error: null
};

const route = 'modules/client/dashboard/views/detailAgent/slice'

//Extend async Action
export const userAnnouncementDetailGetById = createAsyncThunk('dashboard/userAnnouncementDetailGetById', async (
    { id, userId },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query getUserAnnouncementById($id: Int!, $userId: Int){
                result: getUserAnnouncementById(id: $id, userId: $userId) {
                    id
                    status
                    userId
                    lastStatus {
                        status
                        updatedOn
                        user {
                            firstName
                            lastName
                        }
                    }
                    announcement {
                        id
                        product
                        name
                        title
                        max_price
                        description
                        date
                        request_type
                        looking_for
                        looking_for2
                        inspection
                        codicemd5
                        id_comuna
                        phone
                        email
                        contact_time
                        surname
                        definition
                        comuna_name
                        province_name
                    }
                }
            }`,
            {
                id: parseInt(id, 10),
                userId: parseInt(userId, 10),
            },
            endpoints.GRAPHQL_GENERAL
        );
        if(data?.result)
            return data.result
        else
            throw "Error: no tienes acceso"
    } catch (exc) {
        ExceptionManager(exc, route, 'userAnnouncementDetailGetById', dispatch);
        return rejectWithValue(exc)
    }
})

export const updateViewUserAnnouncement = createAsyncThunk('dashboard/updateViewUserAnnouncement', async (
    { id, viewType },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation updateViewsUserAnnouncement($id: Int, $viewType: enumViewType){
                result: updateViewsUserAnnouncement(id: $id, viewType: $viewType) 
            }`,
            {
                id: parseInt(id, 10),
                viewType
            },
            endpoints.GRAPHQL_GENERAL
        );

        if(data?.result)
            return data.result
        else
            throw "Error: no tienes acceso"
    } catch (exc) {
        ExceptionManager(exc, route, 'updateViewUserAnnouncement', dispatch);
        return rejectWithValue(exc)
    }
})
//Slice
const userAnnouncementDetailRedux = createSlice({
    name: 'dashboard/userAnnouncementDetailRedux',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(userAnnouncementDetailGetById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(userAnnouncementDetailGetById.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(userAnnouncementDetailGetById.rejected, (state, {payload}) => {
            state.loading = false
            state.error = payload.error
        })

        builder.addCase(updateViewUserAnnouncement.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateViewUserAnnouncement.fulfilled, (state) => {
            state.loading = false
            //state.data = payload
        })
        builder.addCase(updateViewUserAnnouncement.rejected, (state, action) => {
            state.loading = false
            state.resultError = action.payload.error
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = userAnnouncementDetailRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer