let locales = {
    login_welcome_message: 'Pronto per il tuo trionfo? Effettua il login per continuare...',
    login_welcome_detail: 'Crea y Administra tus tareas de una manera simple y eficiente, maneja tus proyectos y recursos sin tener que usar diferentes herramientas todo lo puedes encontrar aqui.',
    login_create_account: 'Creare un account',
    login_forgot_password: 'Hai dimenticato la password?',
    login_username: 'La tua E-mail',
    login_username_placeholder: 'E-mail',
    login_password: 'Password',
    login_register: 'Registrati',
    login_singin: 'Accedi',
    login_with_facebook: 'Iniciar con Facebook',
    login_with_google: 'Iniciar con Google',
    login_otp_title: 'Por favor ingrese el codigo OTP para validar su cuenta',
    login_otp_description: 'El codigo ha sido enviado a ',
    login_otp_no_code: '¿No tiene un codigo?',
    login_recovery_password: 'Ho dimenticato la password?',
};

export default locales;