import { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AutContainer from '../../core/security/auth'

import Header from '../../core/layouts/header'
import Footer from '../../core/layouts/footer'
import Sidebar from '../../core/layouts/sidebar'

import MainRouter from './main'
import ManagementRouter from './management'
import ClientRouter from './client'
import useBlockRoute from '../../config/hooks/tools/useBlockRoute';
import { ModalAffiliatePartner } from '../../components/ModalAffiliatePartner';
import { P_GL_AGENTE, P_LEAD_MANAGER } from '../../config/helpers/permissionsHash';

const ModulesRouter = () => {

    const {blocked, Alert} = useBlockRoute()

    const { user } = useSelector(state => state.authRedux)

    const defaultRoot = useMemo(() => {
        if(!user || user?.isAdmin){
            return '/admin/main'
        }
        let permission = user.permissions.find(p => p.hash == P_LEAD_MANAGER).R
        if(permission){
            return '/admin/client/dashboard/list'
        }
        permission = user.permissions.find(p => p.hash == P_GL_AGENTE).R
        if(permission){
            return '/admin/client/dashboard/agentlist'
        }
        return '/admin/main'
    }, [user])
    return (
        <div className="layout-wrapper layout-content-navbar">
            <AutContainer mandatory className="layout-container">
                <Sidebar collapMenu={blocked} show={!blocked} />

                <div className="layout-page">
                    <Header className="layout-navbar container-fluid navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" />
                    <ModalAffiliatePartner />
                    <Alert />
                    <div className="content-wrapper">
                        {
                            user &&
                            <Routes>
                                <Route path='/' element={<Navigate to={defaultRoot} />}></Route>
                                
                                <Route path='main' element={<MainRouter />} />
                                <Route path='management/*' element={<ManagementRouter />} />
                                
                                <Route path='client/*' element={<ClientRouter />} />
                                <Route path="*" element={<Navigate to="/error" />} />
                            </Routes>
                        }
                        <Footer />
                    </div>
                </div>
            </AutContainer>
        </div>
    )
}

export default ModulesRouter;