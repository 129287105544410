import { Divider, Switch, notification } from 'antd'
import { Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { configurationNotificationsList, configurationNotificationsChange } from './redux/slice';
import { useSecurity } from '../../../../../../config/hooks';
import { P_NOTIFICATIONS } from '../../../../../../config/helpers/permissionsHash';

export const AgentConfigNotifications = () => {

    const { messages } = useIntl();
    const dispatch = useDispatch();
    const { data, error } = useSelector((state) => state.configListNotifications);

    const [configuration, setConfiguration] = useState(null);

    useEffect(() => {
      dispatch(
          configurationNotificationsList()
      )
    }, [])

    useEffect(() => {
      if(data){
          setConfiguration(data);
      }
    }, [data])

    useEffect(() => {
        if (error) {
            notification.error({
                message: messages["saveError"],
                description: messages[error] || error
            });
        }
    }, [error])

    const R = useSecurity(P_NOTIFICATIONS, ['R']);
    const U = useSecurity(P_NOTIFICATIONS, ['U']);

    const updateConfig = (param, value) => {
      if(configuration ){
          let currentConfig = {...configuration, [param] : value };
          setConfiguration(currentConfig);
          dispatch(
              configurationNotificationsChange({param,value})
          ).unwrap().then(()=> (notification.success({ message: messages["saveSuccessfully"] }))).catch((e)=> notification.error({
              message: messages["saveError"],
              description: messages[e.message] || e.message,
            }) )
      }
    }

    return (
        <Fragment >
           {R && <div className='container'>
                <div className='w-50 row align-items-start'>
                    <p className='col'> {messages["agetn_configNotications_create_admin"]}</p>
                    <div className='col'>
                        <Switch checked={configuration?.noteCreate_admin} onChange={ (e) =>  updateConfig('noteCreate_admin', e) } />
                    </div>

                </div>
                <Divider />
                <div className='w-50 row align-items-start'>
                    <p className='col' > {messages["agetn_configNotications_modificate_admin"]} </p>
                    <div className='col'>
                        <Switch disabled={!U} checked={configuration?.noteUpdate_admin} onChange={ (e) =>  updateConfig('noteUpdate_admin', e) } />
                    </div>
                </div>
                <Divider />
                <div className='w-50 row align-items-start'>
                    <p className='col' > {messages["agetn_configNotications_delete_admin"]} </p>
                    <div className='col'>
                        <Switch disabled={!U} checked={configuration?.noteDelete_admin} onChange={ (e) =>  updateConfig('noteDelete_admin', e) } />
                    </div>
                </div>
                <Divider />
                <div className='w-50 row align-items-start'>
                    <p className='col' > {messages["agetn_configNotications_create_agent"]} </p>
                    <div className='col'>
                        <Switch disabled={!U} checked={configuration?.noteCreate_agenty} onChange={ (e) =>  updateConfig('noteCreate_agenty', e) } />
                    </div>
                </div>
                <Divider />
                <div className='w-50 row align-items-start'>
                    <p className='col' > {messages["agetn_configNotications_modificate_agent"]} </p>
                    <div className='col'>
                        <Switch disabled={!U} checked={configuration?.noteUpdate_agenty} onChange={ (e) =>  updateConfig('noteUpdate_agenty', e) } />
                    </div>
                </div>
                <Divider />
                <div className='w-50 row align-items-start'>
                    <p className='col' > {messages["agetn_configNotications_delete_agent"]} </p>
                    <div className='col'>
                        <Switch disabled={!U} checked={configuration?.noteDelete_agenty} onChange={ (e) =>  updateConfig('noteDelete_agenty', e) } />
                    </div>
                </div>
            </div>}
        </Fragment>
    )
}