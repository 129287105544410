import {
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Loading, TableOptions } from "components";
import { Table, Input, Modal, notification } from "antd";
import { campaignTemplateListSearch, reset } from "./redux/slice";
import {
  campaignTemplateEditOpen,
  campaignTemplateDelete,
} from "../../../../../../config/redux/actions";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import EditCampaignTemplateModal from "../../modals/editTemplate";
import { P_TEMPLATES } from "../../../../../../config/helpers/permissionsHash";

const Templates = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const [showDeleteAlert, setshowDeleteAlert] = useState({
    visible: false,
    itemID: null,
  });

  const { data, loading, error, errorDelete, successDelete, loadingDelete } =
    useSelector((state) => state.templatesRedux);
  const paggination = usePaggination(campaignTemplateListSearch);

  useEffect(() => {
    paggination.refresh();
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error
      });
    }
  }, [error])

  useEffect(() => {
    if (successDelete) {
      notification.success({ message: messages["saveSuccessfully"] });
      paggination.refresh();
      onShowModalDeleteAlert(null);
    }
    if (errorDelete) {
      notification.error({
        message: messages["saveError"],
        description: messages[errorDelete] || errorDelete
      });
    }
  }, [successDelete, errorDelete]);

  //const C = useSecurity(P_TEMPLATES, ['C']);
  const R = useSecurity(P_TEMPLATES, ['R']);
  const U = useSecurity(P_TEMPLATES, ['U']);
  //const D = useSecurity(P_TEMPLATES, ['D']);

  const columns = useMemo(
    () => [
      {
        width: 50,
        render: (values) => {
          return (<TableOptions options={[
            {
              label: messages["edit"],
              onClick: () => dispatch(
                campaignTemplateEditOpen({
                  id: values.id,
                })
              ),
              show: U
            },
          ]}/>)
        },
      },
      {
        title: messages['parametersTemplates_name'],
        key: "name",
        dataIndex: "name",
      },
      {
        title: messages['parametersTemplates_subject'],
        key: "subject",
      },
      /* {
        title: messages['parametersTemplates_template'],
        key: "body",
        render: () => {
          return <a onClick={() => { window.open("data:text/html;charset=utf-8," + "<html>Hola mundo</html>" , '_blank')}}>ver mas</a>
        }
      }, */
    ],
    []
  );

  const onDeleteItem = () => {
    dispatch(campaignTemplateDelete({ id: showDeleteAlert.itemID }));
  };

  const onShowModalDeleteAlert = (id) => {
    const showDeleteValues = {
      visible: !!id,
      itemID: id,
    };
    setshowDeleteAlert(showDeleteValues);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          {R && <Input
            type="text"
            className="form-control my-1 w-100"
            placeholder={messages["search"]}
            onChange={paggination.onSearchChange}
            value={paggination.searchText}
          />}
        </div>
        <div className="col-md-8 col-sm-12">
          <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
            {R && <button
              className="btn btn-primary m-1"
              onClick={() => {
                paggination.refresh();
              }}
            >
              {messages["refresh"]}
            </button>}
            {/* <button
              className="btn btn-primary m-1"
              onClick={() => {
                dispatch(campaignTemplateEditOpen({ id: null }));
              }}
            >
              <i data-feather="users" className="feather-sm fill-white me-1">
                {" "}
              </i>
              {messages["new"]}
            </button> */}
          </div>
        </div>
      </div>

      <div className="mt-4 table-responsive">
        {R && <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={data?.items || []}
          pagination={{
            total: data?.count || 0,
            ...paggination.tablePaggination,
          }}
        />}
      </div>
      <Modal
        open={showDeleteAlert.visible}
        title={messages["parameter_deleteCampaignTemplate_message"]}
        //dialogClassName="modal-dialog-centered"
        onCancel={() => onShowModalDeleteAlert(null)}
        footer={null}
        maskClosable={false}
      >
        <div className="p-3 p-md-2">
          <Loading loading={loadingDelete}>
            <div className="modal-body">
              <div>
                <h6 className="card-subtitle mb-0">
                  {messages["companiesDetailPayments_deletePaymentMessage"]}
                </h6>
              </div>
            </div>
          </Loading>
        </div>
        <div className="row pt-5">
          <div className="col-12 text-center">
            <button
              type="button"
              className="btn btn-primary me-sm-3 me-1"
              onClick={onDeleteItem}
              loading={loadingDelete}
            >
              {messages["delete"]}
            </button>
            <button
              type="button"
              className="btn btn-label-secondary"
              onClick={() => onShowModalDeleteAlert(null)}
            >
              {messages["cancel"]}
            </button>
          </div>
        </div>
      </Modal>
      <EditCampaignTemplateModal
        onClose={(refresh) => {
          refresh && paggination.refresh();
        }}
      />
    </Fragment>
  );
};

export default Templates;
