//Global keys
let locales = {
    ModalAffiliatePartner_title: "Nuovo Cliente",
    ModalAffiliatePartner_initialText: "Hai firmato l’accettazione cliente e scaricato la versione PDF dal sito",
    ModalAffiliatePartner_submitText: "Inserisci il PDF scaricato dell'accettazione cliente per il nuovo cliente",
    ModalAffiliatePartner_cancelButton: "No, cosa devo fare?",
    ModalAffiliatePartner_confirmPDF: "Si, ho firmato e scaricato il PDF",
    ModalAffiliatePartner_awaitingApproval: 'In attesa di approvazione',
    ModalAffiliatePartner_awaitingApprovalDescription1: `Il documento che hai inviato è stato trasmesso all'amministrazione per l'approvazione finale. Riceverai presto una nostra comunicazione via email.`,
    ModalAffiliatePartner_awaitingApprovalDescription2: `Per ulteriori domande o informazioni non esitate a contattare l'amministrazione o il tuo agente di referenza.`,
    ModalAffiliatePartner_cancel: "Annulla, e tona indietro ⤶",
    ModalAffiliatePartner_save: "Salva PDF e prosegui ➜"
};

export default locales;