import { Routes, Route, Navigate } from 'react-router-dom';

import AnnouncementsContractList from './views/list'
//import { useSecurity } from '../../../../config/hooks';
//import { P_LEAD_MANAGER } from '../../../../config/helpers/permissionsHash';

const AnnouncementsContractRouter = () => {
    return (
        <Routes>
            <Route path='/list' element={<AnnouncementsContractList />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default AnnouncementsContractRouter;