import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Input, Button, Loading, Form, } from "components";
import { Modal, notification } from "antd";
import { sendEmailAgain, campaignDetailClose, reset } from "./redux/slice";

const DetailCampaignModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { loading, isOpen, data, result, error } = useSelector( (state) => state.campaignDetailRedux);

  //Verificacion de carga
  useEffect(() => {

    if (result) {
      dispatch(reset({ result: null }));
      notification.success(messages["saveSuccessfully"]); //, description: messages["saveSuccessfully"] })
      closeModal(true);
    }

    if (error) {
      dispatch(reset({ error: null }));
      notification.error(messages["saveError"], messages[error] || error);
    }
  }, [result, error]);

  //se valida y se limpia todo lo que pueda causar errores
  const closeModal = useCallback((refresh) => {
    dispatch(campaignDetailClose());
    onClose && onClose(refresh);
  });
  return (
    <div>
      <Modal
        open={isOpen}
        width={800}
        onCancel={() => { closeModal() }}
        footer={null}
        maskClosable={false}
      >
        <div className="p-3 p-md-5">
          <div className="text-center mb-4">
            <h3>{messages["campaignDetail_title"]}</h3>
          </div>

          <Loading loading={loading}>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-12 col-md-6">
                  <label className="form-label">
                    {messages["campaignsList_mailingDate"]}
                  </label>
                  <p>
                    {
                      data?.sendDate &&
                      moment(data.sendDate).format("DD/MM/yyyy, h:mm:ss a") || " --- "
                    }
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">
                    {messages["campaignsList_dateEmailRead"]}
                  </label>
                  <p>
                    {
                      data?.readDate &&
                      moment(data.readDate).format("DD/MM/yyyy, h:mm:ss a") || " --- "
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <label className="form-label">
                  {messages["campaignDetail_emailContent"]}
                </label>
                <p>
                  {`${messages["companiesSendEmail_from"]}: ${data?.from}`}
                </p>
                <p>
                  {`${messages["companiesSendEmail_to"]}: ${data?.to}`}
                </p>
                <p>
                  {`${messages["companiesSendEmail_subject"]}: ${data?.subject}`}
                </p>
                <div>
                  {`${messages["parameters_editTemplate_body"]}:`}
                  <p
                    dangerouslySetInnerHTML={{ __html: data?.body }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <label className="form-label">
                  {messages["campaignDetail_emailStatus"]}
                </label>
                <p>
                  {`${data?.error
                    ? messages["campaignDetail_emailError"]
                    : messages["campaignDetail_emailSent"]
                    } - ${data?.error
                      ? data?.error
                      : data?.readDate
                        ? messages["campaignDetail_emailRead"]
                        : messages["campaignDetail_emailNoRead"]
                    }`}
                </p>
              </div>
            </div>
          </Loading>

          <div className="row pt-5">
            <div className="col-12 text-center">
              {/* <button
                type="button"
                className="btn btn-primary me-sm-3 me-1"
                onClick={() => { dispatch(sendEmailAgain({ id: data?.id })) }}
                disabled={loading}
              >
                {messages["resend"]}
              </button> */}
              <button
                type="button"
                className="btn btn-label-secondary"
                onClick={() => closeModal()}
              >
                {messages["close"]}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailCampaignModal;
