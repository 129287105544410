let locales = {
    parameters_name: 'Parametri del sistema',
    parameters_senders: 'Mittenti',
    parameters_templates: 'Templates',
    parameters_parametizableText: 'Testi parametrizzabili',
    parameters_config_agent_notifications: 'notifiche degli agenti',
    parameter_deleteCampaignFrom_message: 'Rimuovere il mittente',
    parameter_deleteCampaignTemplate_message: 'Rimuovere il template',
    parameter_configStatusLeads: 'Configurazione dello stato dei conduttori'
};

export default locales;