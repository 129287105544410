import { Modal as ModalInternal } from 'antd'

export const Modal = ({ show, title, onHide, children, footer, dialogClassName, width }) => {

    return (
        <ModalInternal
            open={show}
            onCancel={onHide}
            //wrapClassName={dialogClassName}
            //backdrop="static"
            title={title}
            footer={footer ? footer : null}
            width={width}
            centered
            maskClosable={false}
        >
            {children}
        </ModalInternal>
    )
}