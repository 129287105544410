
import { Fragment, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { Input, Table, notification } from "antd";

import { TextDescriptionCompanies } from "components";
import ListFilters from "../../components/Filters";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";
import { P_HTTP_LOGS } from "../../../../../../config/helpers/permissionsHash";
import { searchHtppErrorLogs, reset } from "./redux/slice";

export const HTTPLogsListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();


  const { data, loading, error } = useSelector((state) => state.httpLogsRedux);
  //const { user } = useSelector((state) => state.authRedux);

  const paggination = usePaggination(searchHtppErrorLogs);

  const [currFilters, setCurrFilters] = useState(null);

  useEffect(() => {
    paggination.refresh();
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"]?.toString(),
        description: messages[error] || error.message,
      });
      reset("error");
    }
  }, [error]);

  const R = useSecurity(P_HTTP_LOGS, ["R"]);

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {R && (
                <Input
                  type="text"
                  className="form-control my-1 w-100"
                  placeholder={messages["search"]}
                  onChange={paggination.onSearchChange}
                  value={paggination.searchText}
                />
              )}
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                {R && (
                  <>
                    <ListFilters onApply={(values) => {
                      paggination.refresh(null, null, {...currFilters, filters: values})
                      setCurrFilters({...currFilters, filters: values})
                    }}/>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => {
                        paggination.refresh();
                      }}
                    >
                      {messages["refresh"]}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>
          <div className="mt-4 table-responsive">
            {R && (
              <Table
                rowKey="id"
                loading={loading}
                showSorterTooltip={false}
                columns={[
                  {
                    title: messages['httpLog_user'],
                    key: "userId",
                    dataIndex: "user",
                    width: 250,
                    sorter: true,
                    render: ({ imageUrl, fullName, email, roles, id }) => {
                        let src =
                            "/assets/images/avatars/blank.png";
                        if (imageUrl) {
                            src = imageUrl;
                        }
                        return (
                            <div className="d-flex justify-content-start align-items-center user-name">
                                <div className="avatar-wrapper me-2">
                                    <img
                                        className="rounded-circle"
                                        src={src}
                                        height="40"
                                        width="40"
                                        alt="User avatar"
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label className="text-body text-truncate fw-mediumml-2">
                                        ({id}) {fullName}
                                    </label>
                                    <small>
                                        <a target="_blank"  href={window.location.pathname.replace('http-logs/list', 'users/detail/' + id)} rel="noreferrer">{email}</a>
                                    </small>
                                    <small>
                                      {roles?.length ? (
                                          roles.map((v) => (
                                              <span
                                                  key={v?.name}
                                                  className={`badge bg-label-secondary mr-1`}
                                              >
                                                  {v?.name}
                                              </span>
                                          ))
                                      ) : (
                                          <></>
                                      )}
                                    </small>
                                </div>
                            </div>
                        );
                    },
                  },
                  {
                    title: messages["httpLog_date"],
                    key: "date",
                    dataIndex: "date",
                    width: 300,
                    sorter: true,
                    defaultSortOrder: 'descend',
                    render: (row) => moment(row).format("DD/MM/yyyy, h:mm:ss a"),
                  },
                  {
                    title: "Code",
                    key: "code",
                    dataIndex: "code",
                    width: 200,
                    sorter: true,
                  },
                  {
                    title: messages["httpLog_originLink"],
                    key: "originLink",
                    dataIndex: "originLink",
                    width: 300,
                    sorter: true,
                    render: (row) => <a target="_blank" href={row} rel="noreferrer">{row}</a>
                  },
                  {
                    title: messages["httpLog_destinyLink"],
                    key: "destinationLink",
                    dataIndex: "destinationLink",
                    width: 300,
                    sorter: true,
                    render: (row) => <a target="_blank" href={row} rel="noreferrer">{row}</a>
                  },
                  {
                    title: messages["httpLog_ip"],
                    key: "ip",
                    dataIndex: "ip",
                    width: 200,
                    sorter: true,
                  }
                ]}
                onChange={(p,f,sorter) => {
                  //for sort
                  let newSort = sorter.order && { field: sorter.columnKey || sorter.field, order: sorter.order == 'descend' ? 'DESC' : 'ASC' } || null
                  if(newSort){
                    paggination.refresh(null, null, {...currFilters, orderBy: [newSort]})
                    setCurrFilters({...currFilters, orderBy: [newSort]})
                  } else {
                    //default Sort
                    paggination.refresh(null, null, {...currFilters, orderBy: [{field: 'date', order: 'DESC'}]})
                    setCurrFilters({...currFilters, orderBy: [{field: 'date', order: 'DESC'}]})
                  }
                }}
                dataSource={data?.items || []}
                pagination={{
                  total: data?.count || 0,
                  ...paggination.tablePaggination,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
