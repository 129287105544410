//Global keys
let locales = {
    ModalAffiliatePartner_title: "Nuevo Cliente",
    ModalAffiliatePartner_initialText: "Ya ha firmado el acuerdo de cliente y descargado la versión PDF del contrato en ",
    ModalAffiliatePartner_submitText: "Inserte el PDF descargado de la aceptación del cliente para el nuevo cliente",
    ModalAffiliatePartner_cancelButton: "No, ¿qué debo hacer?",
    ModalAffiliatePartner_confirmPDF: "Si, he firmado y descargado el PDF",
    ModalAffiliatePartner_awaitingApproval: 'En espera de aprobación, si vuelve a la vista anterior significa que los documentos no han sido aprobados.',
    ModalAffiliatePartner_awaitingApprovalDescription1: `El documento que ha enviado ha sido remitido a la administración para su aprobación definitiva. En breve recibirá una comunicación nuestra por correo electrónico.`,
    ModalAffiliatePartner_awaitingApprovalDescription2: `Para cualquier pregunta o información adicional, no dude en ponerse en contacto con la administración o con su agente de referencia.`,
    ModalAffiliatePartner_cancel: "Cancelar ⤶",
    ModalAffiliatePartner_save: "Guardar PDF ➜"
};

export default locales;