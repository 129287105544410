import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, notification, Modal } from "antd";
// import { ANNOUNCEMENT_STATUS_OPTIONS } from "../../../../../../config/helpers/Utils";
import {
  clearUpdateValues,
  updateStatusCompanyAnnouncement,
  closeChangeStatus,
  setIsUserAnnouncement
} from "./redux/slice";
import { dinamicFormOpen, getCatalog } from "../../../../../.config/redux/actions";
import { getAvailableStatus } from "../../../../management/forms/views/edit/redux/slice";

function ChangeStatus(props) {
  const { refresh, initialValue: _initialValue, id: _id, showNotification = true, onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const { updateResult, error, initialValue, id } = useSelector(
    (state) => state.companyAnnouncementChangesRedux
  );

  const { availableStatus } = useSelector(
    (state) => state.formsEditRedux
  );

  const { announcementStatusList } = useSelector((state) => state.catalogRedux);

  const { result } = useSelector(
    (state) => state.dinamicFormByStateRedux
  );

  useEffect(() => {
    dispatch(getCatalog({ keys: ["announcementStatus"] }));
    dispatch(getAvailableStatus());
  }, [])

  // useEffect(() => {
  //   form.setFieldValue("status", (_initialValue || initialValue));
  // }, [_initialValue, initialValue]);

  useEffect(() => {
    if (error && showNotification) {
      api.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
    }
    if (updateResult && showNotification) {
      api.success({
        message: messages["saveSuccessfully"],
      });
      refresh && refresh();
      dispatch(clearUpdateValues());
    }
  }, [updateResult, error]);

  useEffect(() => {
    if (!!result) {
      form.submit()
    }
  }, [result])



  const handleChange = (value) => {
    availableStatus.find((item) => item?.id == value.status) ? form.submit() : dispatch(dinamicFormOpen(value))
  };

  return (
    <div>
      {contextHolder}
      <div className="card-body">
        {/* {codeRichiesta && (
          <div className="row">
            <p className="text-muted mr-2" style={{ lineHeight: 1.1 }}>
              {messages["dashboardList_announcementCode"]}:
            </p>
            <h6>{codeRichiesta}</h6>
          </div>
        )} */}
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            // console.log('cambio de estado', values)
            // condicion anterior: (values?.status && values?.status !== (_initialValue ? _initialValue : initialValue))
            if (values?.status) {
              dispatch(setIsUserAnnouncement(true))
              dispatch(
                updateStatusCompanyAnnouncement({ id: _id ? _id : id, status: values.status })
              );
              form.resetFields();
              refresh();
              // dispatch(closeChangeStatus());
              // onClose && onClose();
            }
          }}
          onValuesChange={handleChange}
        >
          <div className="col-12 mb-2">
            <Form.Item
              name="status"
              label={<label className="form-label">{messages["dashboardList_status"]}</label>}
              required
            >
              <Select className="w-100"
                options={announcementStatusList.map((item) => {
                  return {
                    value: item?.id,
                    label: item?.name
                  }
                })} />
            </Form.Item>
          </div>
        </Form>

        {/* <div className="row pt-3">
            <div className="col-12 text-end">
              <button className="btn btn-label-secondary me-1" disabled={loadingUpdate} onClick={() => {
                  new Dropdown(ref.current).toggle()
                  form.setFieldValue("status", initialValue)
                }}>
                {messages["cancel"]}
              </button>
              <button className="btn btn-primary" disabled={loadingUpdate} onClick={() => form.submit()}>
                {messages["apply"]}
              </button>
            </div>
          </div> */}
      </div>
    </div>
  );
}
ChangeStatus.Modal = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { isOpen, codeRichiesta } = useSelector(
    (state) => state.companyAnnouncementChangesRedux
  );

  return (
    <Modal
      open={isOpen}
      title={<h6>{codeRichiesta}</h6>}
      width={300}
      onCancel={() => {
        dispatch(closeChangeStatus())
        onClose && onClose();
      }}
      footer={null}
      maskClosable={false}
    >
      <ChangeStatus {...props} />
    </Modal>
  );
};
export default ChangeStatus;
