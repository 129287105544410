let locales = {
    parameters_editCampaignFrom_edit: 'Editar remitente',
    parameters_editCampaignFrom_create: 'Crear remitente',
    parameters_editCampaignFrom_name: 'Nombre',
    parameters_editCampaignFrom_email: 'Correo',
    parameters_editCampaignFrom_host: 'Host',
    parameters_editCampaignFrom_port: 'Port',
    parameters_editCampaignFrom_secure: 'Secure',
    parameters_editCampaignFrom_user: 'Usuario',
    parameters_editCampaignFrom_password: 'Contraseña',
    parameters_editCampaignFrom_test: 'Probar',
    parameters_editCampaignFrom_isDefault: 'Por Defecto',
    parameters_editCampaingFrom_sendTestEmailTo: 'Enviar un email de prueba a',
    parameters_editCampaingFrom_emailSuccess: 'El correo electrónico se ha enviado correctamente',
};

export default locales;