import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
    //
    loadingDelete: false,
    errorDelete: null,
    successDelete: null,
};

const route = 'modules/management/parameters/components/senders/slice'

//Extend async Action
export const campaignFromListSearch = createAsyncThunk('parameter/campaignFromListSearch', async (
    { searchText, limit, offset },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query searchCampaignsFrom($searchText: String, $limit: Int, $offset: Int){
                result: searchCampaignsFrom(searchText: $searchText, limit: $limit, offset: $offset) {
                    items {
                        id
                        name
                        email
                        createdOn
                        createdBy
                    }
                    count
                }
            }`,
            {
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data?.result || []

    } catch (exc) {
        ExceptionManager(exc, route, 'campaignFromListSearch', dispatch);
        return rejectWithValue(exc)
    }
})
export const campaignFromDelete = createAsyncThunk('parameter/campaignFromDelete', async (
    { id },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation deleteCampaignsFrom($id: Int!){
                result: deleteCampaignsFrom(id: $id)
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );


        return data?.result

    } catch (exc) {
        ExceptionManager(exc, route, 'campaignFromDelete', dispatch);
        return rejectWithValue(exc)
    }
})
//Slice
const sendersListRedux = createSlice({
    name: 'parameters/list/senders',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(campaignFromListSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(campaignFromListSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(campaignFromListSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        // delete compaign from
        builder.addCase(campaignFromDelete.pending, (state) => {
            state.loadingDelete = true
        })
        builder.addCase(campaignFromDelete.fulfilled, (state, { payload }) => {
            state.loadingDelete = false
            state.successDelete = payload
        })
        builder.addCase(campaignFromDelete.rejected, (state, action) => {
            state.loadingDelete = false
            state.errorDelete = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = sendersListRedux;

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer